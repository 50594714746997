import React from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { AutoMessage } from '../../../api/types';
import { SendTimeType } from '../../../shared/Primitives';

type TimeSelectorProps = {
    message: AutoMessage,
    sendTimeTypeChanged: (value: SendTimeType) => void,
    sendTimeDaysChanged: (value: number) => void,
    sendTimeHoursChanged: (value: number) => void,
    sendTimeMinutesChanged: (value: number) => void,
    sendTimeSecondsChanged: (value: number) => void
};

const TimeSelector = (props: TimeSelectorProps) => {

    const days = Array.from(Array(31).keys());
    const hours = Array.from(Array(24).keys());
    const minutes = Array.from(Array(60).keys());
    const seconds = Array.from(Array(60).keys());
    const timeTypes = [SendTimeType.Delay, SendTimeType.Exact];

    return <>
        <Row>
            <Col sm={3}>
                <InputGroup>
                    <InputGroup.Text>Type</InputGroup.Text>
                    <Form.Control as="select" value={props.message.sendTimeType} onChange={(e) => props.sendTimeTypeChanged(e.target.value as SendTimeType)}>
                        {
                            timeTypes.map((x) => {
                                return <option key={x} value={x}>{x}</option>
                            })
                        }
                    </Form.Control>
                </InputGroup>
            </Col>
            <Col sm={2}>
                <InputGroup>
                    <Form.Control as="select" value={props.message.sendTimeDays} onChange={(e) => props.sendTimeDaysChanged(parseInt(e.target.value))}>
                        {
                            days.map((x) => {
                                return <option key={x} value={x}>{x}</option>
                            })
                        }
                    </Form.Control>
                    <InputGroup.Text>day(s)</InputGroup.Text>
                </InputGroup>
            </Col>
            <Col sm={2}>
                <InputGroup>
                    <Form.Control as="select" value={props.message.sendTimeHours} onChange={(e) => props.sendTimeHoursChanged(parseInt(e.target.value))}>
                        {
                            hours.map((x) => {
                                return <option key={x} value={x}>{x}</option>
                            })
                        }
                    </Form.Control>
                    <InputGroup.Text>hour(s)</InputGroup.Text>
                </InputGroup>
            </Col>
            <Col sm={2}>
                <InputGroup>
                    <Form.Control as="select" value={props.message.sendTimeMinutes} onChange={(e) => props.sendTimeMinutesChanged(parseInt(e.target.value))}>
                        {
                            minutes.map((x) => {
                                return <option key={x} value={x}>{x}</option>
                            })
                        }
                    </Form.Control>
                    <InputGroup.Text>min</InputGroup.Text>
                </InputGroup>
            </Col>
            <Col sm={2}>
                <InputGroup>
                    <Form.Control as="select" value={props.message.sendTimeSeconds} onChange={(e) => props.sendTimeSecondsChanged(parseInt(e.target.value))}>
                        {
                            seconds.map((x) => {
                                return <option key={x} value={x}>{x}</option>
                            })
                        }
                    </Form.Control>
                    <InputGroup.Text>sec</InputGroup.Text>
                </InputGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Text id="helpBlock" muted>
                    {
                        props.message.sendTimeType === SendTimeType.Delay
                            ? "Your message will be sent with delay from selected event. For example in 5 sec (00:00:05) after new user was created."
                            : "Your message will be sent at exact time after selected event. For example in 1 day at 14:00:00 after new user was created."
                    }
                </Form.Text>
            </Col>
        </Row>
    </>
}

export default TimeSelector