import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { UserTelegramMessage } from "../../../api/types";
import { telegramUserChatMessageSenders } from "../../../fixtures/telegram-message-sender";
import Loader from '../../Loader';
import UserProfileButtons, { ProfileType } from './UserProfileButtons';

// @ts-ignore
import ShowMore from "react-simple-show-more";

import './UsersProfileView.scss';

interface Props extends RouteComponentProps<any> { }

const UsersProfileMessagesView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const [loading, setLoading] = useState(true);
    const [request, setRequest] = useState({
        messages: [] as UserTelegramMessage[],
        totalRows: 0,
        lastTelegramMessageId: 0,
        errorMessage: null
    });
    const [refresh, setRefresh] = useState(false);
    const perPage = 20;
    const userId = props.match.params.userId;

    const [directMessage, setDirectMessage] = useState("");
    const [sendingDirectMessage, setSendingDirectMessage] = useState(false);
    const [messageSent, setMessageSent] = useState("");
    const [messageError, setMessageError] = useState("");

    const [telegramId, setTelegramId] = useState<number | undefined>(undefined);

    useEffect(() => {
        setLoading(true);
        baseCamp.getUserTelegramId(props.match.params.userId)
            .then(resp => {
                setTelegramId(resp);
                if (resp) {
                    baseCamp.getUserTelegramMessages(props.match.params.userId, request.lastTelegramMessageId, perPage)
                        .then(data => {
                            setRequest({
                                messages: prepareMessages(request.messages.concat(data.messages)),
                                totalRows: data.totalCount,
                                lastTelegramMessageId: data.messages.length ? data.messages.sort((a, b) => a.id - b.id)[0].id : 0,
                                errorMessage: null
                            });
                        })
                        .catch(e => {
                            console.error(e);
                            setRequest({
                                messages: [],
                                totalRows: 0,
                                lastTelegramMessageId: 0,
                                errorMessage: e.response?.data?.title ? e.response.data.title : `Error: ${JSON.stringify(e)}`
                            });
                        })
                }
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, props.match.params.userId]);

    const prepareMessages = (messages: UserTelegramMessage[]) => {
        const formatDates = [] as string[];
        return messages.map(message => {
            if (!formatDates.some(fd => fd === message.createdAtFormat)) {
                formatDates.push(message.createdAtFormat);
                message.showDay = true;
            }

            return message;
        });
    }

    const loadMoreMessages = () => {
        setRefresh(!refresh);
    }

    const sendDirectMessage = async () => {
        setSendingDirectMessage(true);

        try {
            await baseCamp.sendUserTelegramMessage(userId, directMessage);
            setMessageSent("Sent!");
            setMessageError("");
            setDirectMessage("");
            loadMoreMessages();
        }
        catch (error) {
            console.error(error);
            setMessageSent("");
            setMessageError(error.toString());
        }
        finally {
            setSendingDirectMessage(false);
        }
    }

    return (
        <div className="users-profile users-profile-messages">
            <div>
                <h3 className="float-left">User ID: {userId}</h3>
                <div className="float-right">
                    <UserProfileButtons userId={userId} type={ProfileType.TelegramMessages} />
                </div>
                <div className="clear-both p-2" />
            </div>
            {
                telegramId ?
                    <React.Fragment>
                        <h4>Telegram messages</h4>
                        <div className={`alert alert-danger ${request.errorMessage ? "d-block" : "d-none"}`}>
                            {request.errorMessage}
                        </div>
                        <div className="w-50">
                            <div className="form-group clearfix">
                                <label htmlFor="outgoing-message">Direct message to the user:</label>
                                <textarea
                                    className="form-control"
                                    id="outgoing-message"
                                    rows={3}
                                    value={directMessage}
                                    onChange={e => {
                                        setDirectMessage(e.target.value);
                                        setMessageSent("");
                                    }}
                                ></textarea>
                                {messageSent !== "" ? <span className="badge badge-success">{messageSent}</span> : null}
                                {messageError !== "" ? <span className="badge badge-danger">{messageError}</span> : null}
                                <button
                                    onClick={sendDirectMessage}
                                    className="btn btn-danger btn-sm my-2 float-right"
                                    disabled={sendingDirectMessage}
                                >
                                    {sendingDirectMessage ?? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                    {sendingDirectMessage ? " Sending..." : "Send"}</button>
                            </div>
                        </div>
                        {
                            request.messages.length ?
                                <div className={`w-50 ${request.errorMessage ? "d-none" : "d-block"}`}>
                                    {
                                        request.messages.map((message, index) => {
                                            const fixtureSender = telegramUserChatMessageSenders.find(t => t.value === message.type);
                                            return (
                                                <div key={`message${index}`}>
                                                    {
                                                        message.showDay ?
                                                            <div className="message-day mt-4 mb-2"><span className="px-2">{message.createdAtFormat}</span></div> : ""
                                                    }
                                                    <div className={`message-data message-data-${fixtureSender?.className}`}>
                                                        <span className="message-data-time">{message.createdAtTimeFormat}</span>
                                                        <span className="message-data-name">
                                                            {fixtureSender?.name}
                                                        </span>
                                                    </div>
                                                    <div className={`message-text message-text-${fixtureSender?.className}`}>
                                                        <ShowMore text={message.message} showMoreLabel="  More" showLessLabel="  Less" className="show-more-link" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                !loading ? <h5>No messages</h5> : ""
                        }
                        <div className="mx-auto w-50 text-center my-2" style={{ position: "relative" }}>
                            {
                                request.messages.length && request.totalRows > request.messages.length && !loading ?
                                    <button onClick={loadMoreMessages} className="btn btn-primary">Load more</button> : ""
                            }
                            {
                                loading ? <Loader className="absolute" /> : ""
                            }
                        </div>
                    </React.Fragment>
                    :
                    <div>User doesn't have a telegram connection</div>
            }
        </div>
    );
}

export default UsersProfileMessagesView;