export enum ChatRole {
    None = "None",
    User = "User",
    Admin = "Admin",
    Coach = "Coach",
}

export enum ChatClient {
    Mobile = "Mobile",
    Web = "Web",
    Bot = "Bot",
    Admin = "Admin"
}