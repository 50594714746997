import React, { useState } from 'react';

import useForm from 'react-hook-form'
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';

import { useAuth0 } from "../../../react-auth0-spa";
import { BaseCampClient } from '../../../api/BaseCampClient';
import { UserRegistrationRequest } from '../../../api/types';
import { Gender } from '../../../shared/Primitives';
import { genders } from '../../../fixtures/genders';

// import './UsersAddView.scss';

registerLocale('en-GB', enGB)
setDefaultLocale('en-GB');

const UsersAddView = () => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const { register, handleSubmit, errors } = useForm()

    const [email, setEmail] = useState("");
    const [sendEmailInvite, setSendEmailInvite] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState(Gender.Other);
    const [age, setAge] = useState("");
    const [weeklyVolume, setWeeklyVolume] = useState("");
    const [longRun, setLongRun] = useState("");
    const [vdot, setVdot] = useState("");
    const [goalDistance, setGoalDistance] = useState("");
    const [goalDate, setGoalDate] = useState(undefined as Date | undefined);
    const [fitness, setFitness] = useState(true);
    const [fasting, setFasting] = useState(true);

    const [submitStatusShown, setSubmitStatusShown] = useState(false);
    const [submitMessageType, setSubmitMessageType] = useState(0);
    const [submitMessage, setSubmitMessage] = useState("");

    const handleSubmitClick = () => {
        const request: UserRegistrationRequest = {
            email: email,
            weeklyRunVolumeKm: +weeklyVolume,
            longRunKm: +longRun,
            vdot: +vdot,
            firstName: firstName,
            lastName: lastName,
            age: +age,
            gender: gender,
            goalRaceDistanceKm: +goalDistance,
            goalRaceDate: goalDate,
            fitness: fitness,
            fasting: fasting,
            sendEmailInvite: sendEmailInvite
        };
        console.log(request);

        baseCamp.registerUserForTelegram(request)
            .then((response) => {
                console.log(response);
                setSubmitStatusShown(true);
                setSubmitMessageType(0);
                setSubmitMessage("Successful!");
            })
            .catch((error) => {
                console.error(error);
                setSubmitStatusShown(true);
                setSubmitMessageType(1);
                setSubmitMessage("Error: " + JSON.stringify(error));
            });
    };

    const afterToday = (date: Date) => {
        return date > new Date();
    }

    const showMessage = () => {
        let className = submitMessageType === 0 ? "alert alert-success" : "alert alert-danger";
        return (<div className={className} role="alert">{submitMessage}</div>)
    }

    return (<div className="w-50">
        <h3 className="pb-2">Register a user for Telegram Bot</h3>
        <div className="alert alert-primary" role="alert">
            You can also update the existing user by entering his email. In this case all entered properties will be updated.
        </div>
        <div>
            <form>
                <div className="form-group row">
                    <label htmlFor="inputEmail" className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                        <input
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            name="email"
                            placeholder="Required"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            ref={register({ required: true })}
                        />
                        {errors.email && <span className="text-danger">Email is required</span>}
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="sendEmailInvite" checked={sendEmailInvite} onChange={(event) => setSendEmailInvite(event.target.checked)} />
                            <label className="custom-control-label" htmlFor="sendEmailInvite">Send a bot invitation email to this user (in Russian!)</label>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputFirstName" className="col-sm-3 col-form-label">First name</label>
                    <div className="col-sm-9">
                        <input type="text" className="form-control" id="inputFirstName" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputLastName" className="col-sm-3 col-form-label">Last name</label>
                    <div className="col-sm-9">
                        <input type="text" className="form-control" id="inputLastName" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputGender" className="col-sm-3 col-form-label">Gender</label>
                    <div className="col-sm-9">
                        <div className="btn-group" role="group" aria-label="Plan type">
                            {genders.map((g) => {
                                const classes = g.value === gender ? 'btn btn-dark' : 'btn btn-light';
                                const handleClick = () => setGender(g.value);
                                return (<button key={g.value} type="button" className={classes} onClick={handleClick}>{g.caption}</button>)
                            })}
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputAge" className="col-sm-3 col-form-label">Age</label>
                    <div className="col-sm-3">
                        <input type="number" className="form-control" id="inputAge" value={age} onChange={(event) => setAge(event.target.value)} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputWeeklyVolume" className="col-sm-3 col-form-label">Weekly volume</label>
                    <div className="col-sm-4">
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="inputWeeklyVolume"
                                name="weeklyVolume"
                                placeholder="Required"
                                value={weeklyVolume}
                                onChange={(event) => setWeeklyVolume(event.target.value)}
                                ref={register({ required: true })}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">km</span>
                            </div>
                        </div>
                        {errors.weeklyVolume && <span className="text-danger">Weekly volume is required</span>}
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputLongRun" className="col-sm-3 col-form-label">Long run</label>
                    <div className="col-sm-4">
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="inputLongRun"
                                name="longRun"
                                placeholder="Required"
                                value={longRun}
                                onChange={(event) => setLongRun(event.target.value)}
                                ref={register({ required: true })}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">km</span>
                            </div>
                        </div>
                        {errors.longRun && <span className="text-danger">Long run is required</span>}
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputVdot" className="col-sm-3 col-form-label">VDot</label>
                    <div className="col-sm-3">
                        <input
                            type="number"
                            className="form-control"
                            id="inputVdot"
                            name="vdot"
                            placeholder="Required"
                            value={vdot}
                            onChange={(event) => setVdot(event.target.value)}
                            ref={register({ required: true })}
                        />
                        {errors.vdot && <span className="text-danger">VDot is required</span>}
                    </div>
                </div>

                <div className="form-group row pt-3">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <h4>Goal</h4>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputGoalDistance" className="col-sm-3 col-form-label">Distance</label>
                    <div className="col-sm-9">
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="inputGoalDistance"
                                name="goalDistance"
                                placeholder="Required"
                                value={goalDistance}
                                onChange={(event) => setGoalDistance(event.target.value)}
                                ref={register({ required: true })}
                                aria-describedby="goalDistanceHelpBlock"
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">km</span>
                            </div>
                        </div>
                        <small id="goalDistanceHelpBlock" className="form-text text-muted">
                            If the user doesn't have a goal race, then just enter here plan preparation distance (5, 10, 21, 42)
                        </small>
                        {errors.goalDistance && <span className="text-danger">Goal distance is required</span>}
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputGoalDate" className="col-sm-3 col-form-label">Date</label>
                    <div className="col-sm-4">
                        <DatePicker
                            selected={goalDate}
                            onChange={date => setGoalDate(date as Date)}
                            className="form-control"
                            id="inputGoalDate"
                            dateFormat="yyyy-MM-dd"
                            locale="en-GB"
                            filterDate={afterToday}
                            aria-describedby="goalDateHelpBlock"
                        />
                        <small id="goalDateHelpBlock" className="form-text text-muted">
                            Leave it empty if the user doesn't have a race date
                        </small>
                    </div>
                </div>

                <div className="form-group row pt-3">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <h4>Coaching types</h4>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="inputCoachingFitness" checked={fitness} onChange={(event) => setFitness(event.target.checked)} />
                            <label className="custom-control-label" htmlFor="inputCoachingFitness">Fitness</label>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="inputCoachingFasting" checked={fasting} onChange={(event) => setFasting(event.target.checked)} />
                            <label className="custom-control-label" htmlFor="inputCoachingFasting">Fasting</label>
                        </div>
                    </div>
                </div>

                <div className="form-group row py-3">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit(handleSubmitClick)}>Submit</button>
                    </div>
                </div>

                <div className="form-group row pb-4">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        {submitStatusShown && showMessage()}
                    </div>
                </div>

            </form>
        </div>
    </div>);
}

export default UsersAddView;
