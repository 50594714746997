import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './AutoMessages.scss';
import { BaseCampClient } from '../../api/BaseCampClient';
import { AutoMessageFilter, AutoMessage } from '../../api/types';
import { useAuth0 } from '../../react-auth0-spa';
import { AutoMessageChannel, AutoMessageEvent, FilterSortDirection, AutoMessageSender, SportType } from '../../shared/Primitives';
import { getSendTimeText } from '../../api/Helpers';
import Loader from '../Loader';
import * as Icon from 'react-feather';
import { Form } from 'react-bootstrap';
import ListPagination from '../common/ListPagination';

const AutoMessagesView = () => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const [filter, setFilter] = useState<AutoMessageFilter>({
        page: 0,
        perPage: 30,
        searchString: undefined,
        sortDirection: FilterSortDirection.Asc,
        sortField: "sendtime",
        sportType: undefined,
        senderType: undefined,
        channel: undefined
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<AutoMessage[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        loadMessages();
    }, [filter]);

    const loadMessages = () => {
        setError(undefined);
        setIsLoading(true);
        baseCamp.getAutoMessageList(filter)
            .then((res) => {
                setTotalCount(res.totalCount);
                setMessages(res.autoMessages);
            })
            .catch((ex) => {
                console.log("loading auto messages error:", ex);
                let msg = "There was an error during load data. Please try latter.";
                if (ex.response?.status == 400) {
                    msg = ex.response.data;
                }
                setError(msg);
            })
            .finally(() => setIsLoading(false));
    }

    const deleteMessage = (id: number) => {
        if (!window.confirm(`Are you sure you want to delete auto message #${id}?`)) {
            return;
        }

        setIsLoading(true);
        baseCamp.deleteAutoMessage(id)
            .then(() => {
                loadMessages();
            })
            .catch((ex) => {
                console.log("loading auto messages error:", ex);
                let msg = "There was an error during load data. Please try latter.";
                if (ex.response?.status == 400) {
                    msg = ex.response.data;
                }
                setError(msg);
            })
            .finally(() => setIsLoading(false));
    }

    const sortHeaderSign = (field: string) => {
        if (field !== filter.sortField) {
            return null;
        }
        const sortCss = `sort${filter.sortDirection === FilterSortDirection.Desc ? " sort-desc" : ""}`;
        return <span className={sortCss}>▲</span>
    }

    const handleSort = (field: string) => {
        const dir = filter.sortField === field ?
            filter.sortDirection === FilterSortDirection.Asc ? FilterSortDirection.Desc : FilterSortDirection.Asc
            : FilterSortDirection.Asc;

        setFilter({
            ...filter,
            sortField: field,
            sortDirection: dir
        });
    }

    let timer: NodeJS.Timeout | undefined;
    const handleSearchString = (s: string) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            if (s.length || (filter.searchString?.length ?? 0) > 0) {
                setFilter({ ...filter, searchString: s });
            }
        }, 400);
    }

    const handleSenderType = (value: string) => {
        if (value === "any") {
            setFilter({ ...filter, senderType: undefined });
        }
        else {
            setFilter({ ...filter, senderType: value as AutoMessageSender });
        }
    }

    const handleSportType = (value: string) => {
        if (value === "any") {
            setFilter({ ...filter, sportType: undefined });
        }
        else {
            setFilter({ ...filter, sportType: value as SportType });
        }
    }

    const handleChannel = (value: string) => {
        if (value === "any") {
            setFilter({ ...filter, channel: undefined });
        }
        else {
            setFilter({ ...filter, channel: value as AutoMessageChannel });
        }
    }

    return <div className="auto-messages auto-messages-list">
        {
            error ? <div className="fade alert alert-danger show">{error}</div> : null
        }
        {
            isLoading ? <Loader /> : ""
        }
        <div className="command-panel">
            <div className="command-panel-group">
                <button className="btn btn-sm btn-primary" onClick={() => { window.location.href = "/auto-messages/0" }} ><Icon.Plus /> New message</button>
            </div>
            <div className="command-panel-group">
                <div>Sport:</div>
                <div>
                    <Form.Control as="select" size="sm" value={filter.sportType} onChange={(e) => { handleSportType(e.target.value) }}>
                        <option value={"any"}>Any</option>
                        <option value={SportType.Running}>Running</option>
                        <option value={SportType.Cycling}>Cycling</option>
                        <option value={SportType.Swimming}>Swimming</option>
                    </Form.Control>
                </div>
            </div>
            <div className="command-panel-group">
                <div>Channel:</div>
                <div>
                    <Form.Control as="select" size="sm" value={filter.channel} onChange={(e) => { handleChannel(e.target.value) }}>
                        <option value={"any"}>Any</option>
                        <option value={AutoMessageChannel.Chat}>Chat</option>
                        <option value={AutoMessageChannel.Telegram}>Telegram</option>
                    </Form.Control>
                </div>
            </div>
            <div className="command-panel-group">
                <div>Sender:</div>
                <div>
                    <Form.Control as="select" size="sm" value={filter.senderType} onChange={(e) => { handleSenderType(e.target.value) }}>
                        <option value={"any"}>Any</option>
                        <option value={AutoMessageSender.Admin}>Admin</option>
                        <option value={AutoMessageSender.Bot}>Bot</option>
                        <option value={AutoMessageSender.Coach}>Coach</option>
                        <option value={AutoMessageSender.SingleUser}>SingleUser</option>
                    </Form.Control>
                </div>
            </div>
            <div className="command-panel-group w-30">
                <input type="text" className="form-control form-control-sm" placeholder="Search by ID or Title"
                    onChange={(e) => handleSearchString(e.target.value.trim())} />
            </div>
        </div>
        <div>
            <table className="table table-dashboard">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col" onClick={() => handleSort("id")}>Id {sortHeaderSign("id")}</th>
                        <th scope="col" onClick={() => handleSort("title")}>Title {sortHeaderSign("title")}</th>
                        <th scope="col" onClick={() => handleSort("createdat")}>Created At {sortHeaderSign("createdat")}</th>
                        <th scope="col" onClick={() => handleSort("eventtype")}>Event {sortHeaderSign("eventtype")}</th>
                        <th scope="col" onClick={() => handleSort("sporttypes")}>Sports {sortHeaderSign("sporttypes")}</th>
                        <th scope="col" onClick={() => handleSort("sendtime")}>Time {sortHeaderSign("sendtime")}</th>
                        <th scope="col" onClick={() => handleSort("channels")}>Channels {sortHeaderSign("channels")}</th>
                        <th scope="col" onClick={() => handleSort("sendertype")}>Sender {sortHeaderSign("sendertype")}</th>
                        <th scope="col" onClick={() => handleSort("isactive")}>Active {sortHeaderSign("isactive")}</th>
                        <th scope="col" colSpan={2}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        messages.length > 0 ? messages.map((m) => {
                            return (
                                <tr key={m.id}>
                                    <td>{m.id}</td>
                                    <td><a href={`/auto-messages/${m.id}`}>{m.title}</a></td>
                                    <td>{moment(m.createdAt).format("DD.MM.YYYY HH:mm:ss")}</td>
                                    <td>{m.eventType}</td>
                                    <td>{m.sportTypes.join("; ")}</td>
                                    <td>{getSendTimeText(m)}</td>
                                    <td>{m.channels.join("; ")}</td>
                                    <td>{m.senderType}</td>
                                    <td><input type="checkbox" checked={m.isActive} disabled /></td>
                                    <td><a href={`/auto-messages/${m.id}`}>Edit</a></td>
                                    <td><a onClick={() => { deleteMessage(m.id) }}>Delete</a></td>
                                </tr>
                            );
                        })
                        : <tr><td colSpan={11}><h3>Auto message list is empty.</h3></td></tr>
                    }
                </tbody>
            </table>
            <div>
                <ListPagination
                    page={filter.page || 0}
                    perPage={filter.perPage || 30}
                    totalCount={totalCount}
                    pageNumberChanged={(value: number) => { setFilter({ ...filter, page: value }) }}
                    perPageChanged={(value: number) => { setFilter({ ...filter, perPage: value }) }}
                />
            </div>
        </div>
    </div>
}

export default AutoMessagesView;
