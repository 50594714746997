import React, { useState, useEffect } from 'react';
import '../TrainingsView.scss';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { useAuth0 } from "./../../../react-auth0-spa";

import {
    ComposedChart,
    Line,
    LineProps,
    Tooltip,
    TooltipProps,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Legend,
} from 'recharts';

import { TrainingActivityData, TrainingFullInfo } from '../../../api/types';
import { Loader } from 'react-feather';
import { SportType } from '../../../shared/Primitives';

const LineChartProps: Partial<LineProps> = {
    type: 'monotone',
    dot: false,
    connectNulls: true,
    strokeWidth: 2,
};

  const CustomTooltip = (training: TrainingFullInfo) => ({
    active,
    payload,
  }: TooltipProps) => {
    if (active && payload?.[0]?.payload) {
      const data = payload[0].payload as TrainingActivityData;
      const isCycling = training.sportType === SportType.Cycling;

      return (
        <dl className='tooltip-container'>
            <dt className='definition-term'>Distance</dt>
            <dt className='definition-data'>{parseFloat(((data.distance as number) / 1000).toFixed(2))} Km</dt>
          
            {isCycling && data.velocitySmoothKmPerHour && (
                <>
                <dt className='definition-term'>Velocity</dt>
                <dt className='definition-data'>{data.velocitySmoothKmPerHour} km/h</dt>
                </>
            )}

          {!isCycling && data.paceLabel && (
              <>
              <dt className='definition-term'>Pace</dt>
              <dt className='definition-data'>{data.paceLabel} min/km</dt>
              </>
          )}

          {data.altitude != null && (
              <>
              <dt className='definition-term'>Altitude</dt>
              <dt className='definition-data'>{data.altitude} m</dt>
              </>
          )}

          {data.heartrate != null && (
              <>
              <dt className='definition-term'>Heart Rate</dt>
              <dt className='definition-data'>{data.heartrate} bpm</dt>
              </>
          )}

          {data.watts != null && (
              <>
              <dt className='definition-term'>Watts</dt>
              <dt className='definition-data'>{data.watts} w</dt>
              </>
          )}

          {data.gradeSmooth != null && (
              <>
              <dt className='definition-term'>Grade</dt>
              <dt className='definition-data'>{data.gradeSmooth} %</dt>
              </>
          )}
          {data.cadence != null && (
              <>
              <dt className='definition-term'>Cadence</dt>
              <dt className='definition-data'>{data.cadence} spm</dt>
              </>
          )}
        </dl>
      );
    }
};

type TrainingChartsProps = {
    trainingId: number
}

const TrainingCharts = (props: TrainingChartsProps) => {
    const { accessToken } = useAuth0();
    const basecamp = new BaseCampClient(accessToken);

    const [training, setTraining] = useState<TrainingFullInfo | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const StrokeWidth = 0.5;

    useEffect(() => {
        setIsLoading(true);
        basecamp.getTrainingFullInfo(props.trainingId)
            .then(res => setTraining(res))
            .finally(() => setIsLoading(false));
    }, [props.trainingId]);

    const formatLegend = (value?: any, entry?: any) => {
        if (!entry) {
            return null;
          }
    
          switch (entry['dataKey']) {
            case 'velocitySmoothKmPerHour':
              return 'Velocity';
            case 'cadence':
              return 'Cadence';
            case 'heartrate':
              return 'Heart Rate';
            case 'pace':
              return 'Pace';
            case 'watts':
              return 'Watts';
            default:
              throw Error(entry['dataKey']);
          }
    }

    const formatWithUnits = (value: number | string | undefined) => {
        if (value == null) return '';
        const formattedValue = parseFloat(((value as number) / 1000).toFixed(2));
        return `${formattedValue} km`;
    };

    const isCycling = training?.sportType === SportType.Cycling;
    const mainData = isCycling ? 'velocitySmoothKmPerHour' : 'pace';

    return (
        <div className='chart-wrapper'>
            {
                isLoading ? <Loader /> : training?.activityData ? 
                    <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart data={training?.activityData}>
                            <Legend verticalAlign="top" formatter={formatLegend} />
                            <XAxis
                                type="number"
                                domain={['dataMin', 'dataMax']}
                                tickFormatter={label => formatWithUnits(label)}
                                tickCount={training.distanceCompleted! / 1000}
                                dataKey={'distance'}
                            />
                            <YAxis type="number" domain={['dataMin', 'dataMax']} />
                            <YAxis
                                yAxisId="backgroundYAxis"
                                scale="linear"
                                hide={true}
                                dataKey={mainData}
                                type="number"
                                domain={['dataMin', 'dataMax']}
                            />
                            <Line
                                stroke='rgba(133,133,133,0.5)'
                                strokeWidth={StrokeWidth}
                                yAxisId="backgroundYAxis"
                                dot={false}
                                dataKey={mainData}
                            />
                            <Line
                                {...LineChartProps}
                                dataKey='cadence'
                                strokeWidth={StrokeWidth}
                                stroke='rgba(148,94,207,1)'
                                dot={false}
                            />
                            <Line
                                {...LineChartProps}
                                dataKey='heartrate'
                                stroke='rgba(238,106,65,1)'
                                strokeWidth={StrokeWidth}
                                dot={false}
                            />
                            {isCycling && (
                                <Line
                                    stroke='rgba(54,166,199,1)'
                                    strokeWidth={StrokeWidth}
                                    dataKey='watts'
                                    dot={false}
                                />
                            )}
                            <Tooltip filterNull={false} content={CustomTooltip(training)} />
                        </ComposedChart>
                    </ResponsiveContainer>
                    : null
            }
        </div>
    );
}

export default TrainingCharts;