import React, { useEffect, useState } from 'react';
import { Form, Pagination } from 'react-bootstrap';
import './ListPagination.scss';

export type PaginationProps = {
    page: number,
    perPage: number,
    totalCount: number,
    pageNumberChanged: (value: number) => void,
    perPageChanged: (value: number) => void
};

const ListPagination = (props: PaginationProps) => {
    const [pageCount, setPageCount] = useState<number>(0);
    const [currentPages, setCurrentPages] = useState<number[]>([]);

    const paginationItemCount = 5;

    useEffect(() => {
        let pageCount = Math.ceil(props.totalCount / props.perPage);
        let startItem = props.page - 2 < 0 ? 0 : props.page - 2;

        if (startItem + paginationItemCount > pageCount) {
            startItem = pageCount - paginationItemCount;
        }
        if (startItem < 0) {
            startItem = 0;
        };

        let pages: number[] = [];
        for (var i = startItem; i < startItem + paginationItemCount && i < pageCount; i++) {
            pages.push(i);
        }

        setPageCount(pageCount);
        setCurrentPages(pages);
    }, [props]);


    return <>
        {
            pageCount > 0 ?
                <div className="list-pagination-wrapper">
                    <div className="per-page">
                        <span>Per page: </span>
                        <Form.Control as="select" size="sm" style={{width:"70px"}} value={props.perPage} onChange={(e) => { props.perPageChanged(parseInt(e.target.value)) }}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                        </Form.Control>
                    </div>
                    <Pagination>
                        <Pagination.First disabled={props.page === 0} onClick={() => { props.pageNumberChanged(0) }}></Pagination.First>
                        <Pagination.Prev disabled={props.page === 0} onClick={() => { props.pageNumberChanged(props.page - 1) }}></Pagination.Prev>
                        {
                            currentPages.length > 0 && currentPages[0] > 0 ? <Pagination.Ellipsis disabled /> : null
                        }
                        {
                            currentPages.map((x) => {
                                return <Pagination.Item key={x} active={x === props.page} onClick={() => { props.pageNumberChanged(x) }}>{x + 1}</Pagination.Item>
                            })

                        }
                        {
                            currentPages.length > 0 && currentPages[currentPages.length - 1] < pageCount - 1 ? <Pagination.Ellipsis disabled /> : null
                        }
                        <Pagination.Next disabled={props.page === pageCount - 1} onClick={() => { props.pageNumberChanged(props.page + 1) }}></Pagination.Next>
                        <Pagination.Last disabled={props.page === pageCount - 1} onClick={() => { props.pageNumberChanged(pageCount - 1) }}></Pagination.Last>
                    </Pagination>
                </div>
                : null
        }
    </>
}
export default ListPagination;