import React from 'react';
import { CoachingType, TrainingDescriptionType, ExternalSyncAppTypes } from '../../shared/Primitives';
import { ActivityInfo, TrainingCore, TrainingFileInfo } from '../../api/types';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Modal, Button, InputGroup, Form, Row, Col, Tabs, Tab } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import * as Icon from 'react-feather';
import { coachingTypeValues } from "../../fixtures/coaching-types";
import { workoutTypeValues } from "../../fixtures/global-workout-types";
import { ReactComponent as StravaSvg } from '../../imgs/strava.svg';
import { ReactComponent as GarminSvg } from '../../imgs/garmin.svg';
import TrainingLinkRow from "./TrainingLinkRow"
import { formatDate, getRandomKey } from '../../api/Helpers';
import moment from 'moment';
import { BaseCampClient } from '../../api/BaseCampClient';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import TrainingCharts from './components/TrainingCharts';
import TrainingExport from './components/TrainingExport';

interface Props {
    userId: number,
    training: TrainingCore,
    files: TrainingFileInfo[] | undefined,
    basecamp: BaseCampClient,
    onDelete: (trainingId: number) => void,
    onClose: () => void,
    onSave: (training: TrainingCore, files?: File[]) => void,
    onFileDelete: (trainingFileId: number) => void,
    onResetDescription: (trainingId: number, descriptionType: TrainingDescriptionType, description: string | undefined) => void
}

interface Validation {
    isDateValid: boolean,
    isDescriptionRuValid: boolean,
    isDescriptionEnValid: boolean,
    isWorkoutTypeValid: boolean,
    isCoachingTypeValid: boolean
}

enum TabName {
    Details,
    Charts,
    Export
}

interface IState {
    date: Date,
    dateOriginal: Date | undefined,
    descriptionRu: string,
    descriptionEn: string,
    descriptionAltRu: string | undefined,
    descriptionAltEn: string | undefined,
    sourceWorkoutType: string,
    sourceCoachingType: CoachingType | undefined,
    sourceDescriptionRu: string,
    sourceDescriptionEn: string,
    sourceDescriptionAltRu: string | undefined,
    sourceDescriptionAltEn: string | undefined,
    isDescriptionAltEnEdited: boolean,
    isDescriptionAltRuEdited: boolean,
    isDescriptionEnEdited: boolean,
    isDescriptionRuEdited: boolean,
    workoutType: string,
    workoutGenerator: string | undefined,
    title: string,
    selectedFiles: File[] | undefined,
    coachingType: CoachingType | undefined,
    activity: ActivityInfo | undefined,
    validation: Validation,
    links: string[] | undefined,
    distancePlanned: number | undefined,
    distancePlannedMeters: number | undefined,
    durationPlannedMoment: moment.Moment | undefined,
    durationPlannedALtMoment: moment.Moment | undefined,
    currentTab: TabName
}

class ModifyTrainingModal extends React.Component<Props, IState> {

    //trainingTypes: any[] = Object.values(TrainingType)
    //    .filter((v: any) => typeof v === 'string' || v instanceof String)
    //    .map((s: any) => { return { value: TrainingType[s], label: s } });
    basecamp: BaseCampClient;

    constructor(props: Props) {
        super(props);

        this.basecamp = props.basecamp;

        this.state = {
            date: props.training.date,
            dateOriginal: props.training.dateOriginal,
            workoutType: props.training.workoutType,
            sourceWorkoutType: props.training.workoutType,
            workoutGenerator: props.training.workoutGenerator,
            title: props.training.title,
            descriptionRu: props.training.descriptionRu,
            descriptionEn: props.training.descriptionEn,
            descriptionAltRu: props.training.descriptionAltRu,
            descriptionAltEn: props.training.descriptionAltEn,
            sourceDescriptionRu: props.training.descriptionRu,
            sourceDescriptionEn: props.training.descriptionEn,
            sourceDescriptionAltRu: props.training.descriptionAltRu,
            sourceDescriptionAltEn: props.training.descriptionAltEn,
            isDescriptionAltEnEdited: props.training.isDescriptionAltEnEdited,
            isDescriptionAltRuEdited: props.training.isDescriptionAltRuEdited,
            isDescriptionEnEdited: props.training.isDescriptionEnEdited,
            isDescriptionRuEdited: props.training.isDescriptionRuEdited,
            coachingType: props.training.coachingType,
            sourceCoachingType: props.training.coachingType,
            activity: props.training.activity,
            links: props.training.links,
            distancePlanned: props.training.distancePlanned,
            distancePlannedMeters: props.training.distancePlannedMeters,
            durationPlannedMoment: props.training.durationPlanned ? moment().startOf('day').seconds(props.training.durationPlanned) : undefined,
            durationPlannedALtMoment: props.training.durationPlannedAlt ? moment().startOf('day').seconds(props.training.durationPlannedAlt) : undefined,
            selectedFiles: undefined,
            validation: {
                isCoachingTypeValid: !!props.training.coachingType,
                isDateValid: !!props.training.date,
                isDescriptionRuValid: props.training.descriptionRu.length > 0,
                isDescriptionEnValid: props.training.descriptionEn.length > 0,
                isWorkoutTypeValid: props.training.workoutType.length > 0
            },
            currentTab: TabName.Details
        };
    }

    onDateChanged(selectedDate: Date | null) {
        const date = selectedDate || this.state.date;
        this.setState({
            date: date
        });
    }

    onDescriptionRuChanged(event: React.FormEvent<any>) {
        const htmlInput = (event.currentTarget as any) as HTMLInputElement;
        this.setState({ descriptionRu: htmlInput.value });
        this.validate();
    }

    onDescriptionEnChanged(event: React.FormEvent<any>) {
        const htmlInput = (event.currentTarget as any) as HTMLInputElement;
        this.setState({ descriptionEn: htmlInput.value });
        this.validate();
    }

    onDescriptionAltEnChanged(event: React.FormEvent<any>) {
        const htmlInput = (event.currentTarget as any) as HTMLInputElement;
        this.setState({ descriptionAltEn: htmlInput.value });
    }

    onDescriptionAltRuChanged(event: React.FormEvent<any>) {
        const htmlInput = (event.currentTarget as any) as HTMLInputElement;
        this.setState({ descriptionAltRu: htmlInput.value });
    }

    onDelete = () => { this.props.onDelete(this.props.training.id) }
    onClose = () => { this.props.onClose() }
    onSave = () => {
        const isValid = this.validate();
        if (isValid) {
            this.props.onSave({
                id: this.props.training.id,
                date: new Date(formatDate(this.state.date)!),
                dateOriginal: undefined,
                workoutType: this.state.workoutType,
                workoutGenerator: this.state.workoutGenerator,
                title: this.state.title,
                descriptionRu: this.state.descriptionRu,
                descriptionEn: this.state.descriptionEn,
                descriptionAltRu: this.state.descriptionAltRu,
                descriptionAltEn: this.state.descriptionAltEn,
                isDescriptionAltEnEdited: this.checkDescriptionChanged(this.state.sourceDescriptionAltEn, this.state.descriptionAltEn)
                    ? true
                    : this.state.isDescriptionAltEnEdited,
                isDescriptionAltRuEdited: this.checkDescriptionChanged(this.state.sourceDescriptionAltRu, this.state.descriptionAltRu)
                    ? true
                    : this.state.isDescriptionAltRuEdited,
                isDescriptionEnEdited: this.checkDescriptionChanged(this.state.sourceDescriptionEn, this.state.descriptionEn)
                    ? true
                    : this.state.isDescriptionEnEdited,
                isDescriptionRuEdited: this.checkDescriptionChanged(this.state.sourceDescriptionRu, this.state.descriptionRu)
                    ? true
                    : this.state.isDescriptionRuEdited,
                coachingType: this.state.coachingType,
                activity: this.state.activity,
                links: this.state.links?.filter(x => x.length > 0),
                distancePlanned: this.state.distancePlanned,
                distancePlannedMeters: this.state.distancePlannedMeters,
                durationPlanned: this.getMomentTotalSeconds(this.state.durationPlannedMoment),
                durationPlannedAlt: this.getMomentTotalSeconds(this.state.durationPlannedALtMoment)
            }, this.state.selectedFiles)
        }
    }

    checkDescriptionChanged = (sourceDesc: string | undefined, newDesc: string | undefined): boolean => {
        return (sourceDesc || "") !== (newDesc || "")
            || !!sourceDesc && (
                this.state.sourceCoachingType !== this.state.coachingType
                || this.state.sourceWorkoutType !== this.state.workoutType
            );
    }

    validate = () => {
        const isDateValid = !!this.state.date;
        const isDescriptionRuValid = this.state.descriptionRu.length > 0;
        const isDescriptionEnValid = this.state.descriptionEn.length > 0;
        const isWorkoutTypeValid = this.state.workoutType.length > 0;
        const isCoachingTypeValid = !!this.state.coachingType;

        this.setState({
            validation: {
                isDateValid: isDateValid,
                isCoachingTypeValid: isCoachingTypeValid,
                isDescriptionRuValid: isDescriptionRuValid,
                isDescriptionEnValid: isDescriptionEnValid,
                isWorkoutTypeValid: isWorkoutTypeValid
            }
        });

        return isDateValid && isDescriptionRuValid && isDescriptionEnValid && isWorkoutTypeValid && isCoachingTypeValid;
    }

    onCoachingTypeChanged = (coachingOption: any) => {
        this.setState({ coachingType: coachingOption.value });
        this.validate();
    };

    onWorkoutTypeChanged = (workoutOption: any) => {
        this.setState({ workoutType: workoutOption.value });
        this.validate();
    }

    onFileUploadChange = (event: any) => {
        this.setState({ selectedFiles: event.target.files });
    }

    onFileDelete = (trainingFileId: number) => {
        const conf = window.confirm('Are you sure you want to delete this file?');
        if (conf) {
            this.props.onFileDelete(trainingFileId);
        }
    }

    onAddNewLink = (event: any) => {
        const newLinks = this.state.links || [];
        newLinks.push("");
        this.setState({
            links: newLinks,
        });
    }

    onSaveLink = (link: string, rowIndex: number) => {
        const newLinks = this.state.links || [];
        newLinks[rowIndex] = link;
        this.setState({
            links: newLinks,
        });
    }

    onResetDescription = (descriptionType: TrainingDescriptionType) => {
        if (window.confirm("Are you sure you want to reset description?")) {
            this.basecamp
                .resetTrainingDescription(this.props.training.id, descriptionType)
                .then((response) => {
                    switch (descriptionType) {
                        case TrainingDescriptionType.En:
                            this.setState({
                                descriptionEn: response.description || "",
                                isDescriptionEnEdited: false
                            });
                            break;
                        case TrainingDescriptionType.Ru:
                            this.setState({
                                descriptionRu: response.description || "",
                                isDescriptionRuEdited: false
                            });
                            break;
                        case TrainingDescriptionType.AltEn:
                            this.setState({
                                descriptionAltEn: response.description || "",
                                isDescriptionAltEnEdited: false
                            });
                            break;
                        case TrainingDescriptionType.AltRu:
                            this.setState({
                                descriptionAltRu: response.description || "",
                                isDescriptionAltRuEdited: false
                            });
                            break;
                    }
                    this.props.onResetDescription(this.props.training.id, descriptionType, response.description);
                });
        }
    }

    onDeleteLink = (rowIndex: number) => {
        const newLinks = this.state.links!;
        newLinks.splice(rowIndex, 1);
        this.setState({
            links: newLinks
        });
    }

    getDecriptionTabTitle = (title: string, isManualEdited: boolean) => {
        return isManualEdited ? `🅰️ ${title}` : title;
    }

    showDistancePlanned = (coachingType: CoachingType): boolean => {
        const durationTypes = [CoachingType.RunningFitness, CoachingType.RunningRace];
        return durationTypes.includes(coachingType);
    }

    onDistanceChanged = (e: React.FormEvent<any>) => {
        const htmlInput = (e.currentTarget as any) as HTMLInputElement;
        const txtVal = htmlInput.value;
        if (!txtVal) {
            this.setState({ distancePlannedMeters: undefined });
            return;
        }

        const newVal = parseInt(txtVal);
        if (newVal) {
            this.setState({ distancePlannedMeters: newVal });
        }
    }

    onDurationChange = (value: moment.Moment) => {
        this.setState({ durationPlannedMoment: value });
    }

    onDurationChangeAlt = (value: moment.Moment) => {
        this.setState({ durationPlannedALtMoment: value });
    }

    getMomentTotalSeconds = (value: moment.Moment | undefined) => {
        if (!value) return undefined;

        const d = moment.duration({
            seconds: value.seconds(),
            minutes: value.minutes(),
            hours: value.hours()
        });
        return d.as("seconds");
    }

    render() {
        //let locale:Locale = { options : { weekStartsOn: 1, firstWeekContainsDate: 4 } };

        return <div>
            <Modal show={true} onHide={this.onClose.bind(this)} className="modify-training-modal">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title-fullwidth">
                        <div className="modal-title-inner">
                            <div>
                                {this.props.training.id === 0 ? "New training" : "Edit training"}
                            </div>
                            {this.props.training.activity?.url == null ? null :
                                <a target={this.props.training.activity.url ? "_blank" : ""} href={this.props.training.activity.url}>
                                    <div className="activity-link">
                                        {this.props.training.activity?.appType === ExternalSyncAppTypes.Omy ? "Recorded by OmySports" : null}
                                        {this.props.training.activity?.appType === ExternalSyncAppTypes.Strava ? <StravaSvg title="Strava Activity Link" /> : null}
                                        {this.props.training.activity?.appType === ExternalSyncAppTypes.Garmin ? <GarminSvg title="Garmin Activity Link" /> : null}
                                    </div>
                                </a>
                            }
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="details" id="tab-training">
                        <Tab eventKey="details" title='Training details' onEntered={() => {this.setState({ currentTab: TabName.Details });}}>
                            <div style={{paddingTop: '20px'}}></div>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Training date:</Form.Label>
                                <Col sm="4">
                                    <DatePicker
                                        className={"form-control training-date-container" + (!this.state.validation.isDateValid ? " error" : "")}
                                        selected={this.state.date}
                                        onChange={this.onDateChanged.bind(this)}
                                        dateFormat="yyyy-MM-dd" />
                                    {
                                        !this.state.validation.isDateValid ?
                                            <Form.Text className="error-text">
                                                required
                                            </Form.Text>
                                            : null
                                    }
                                </Col>
                                <Col sm="6">
                                    {
                                        !this.state.activity ? "" :
                                            <div className="params-zones">
                                                {!this.state.activity.trainingLoad ? "" :<div>
                                                    Training load: {this.state.activity.trainingLoad} (
                                                    {!this.state.activity.rTSS ? "" : <span className="training-indicator" title="Running training stress score (rTSS)">rTSS {this.state.activity.rTSS}</span>}
                                                    {!this.state.activity.TSS ? "" : <span className="training-indicator" title="Training stress score (TSS)">TSS {this.state.activity.TSS}</span>}
                                                    {!this.state.activity.heartTrainingLoad ? "" : <span className="training-indicator" title="Heart training load">HTL {this.state.activity.heartTrainingLoad}</span>}
                                                    {!this.state.activity.runningFitnessScore ? "" : <span className="training-indicator" title="Running fitness score">RFS {this.state.activity.runningFitnessScore}</span>}
                                                )
                                                </div>}    
                                                <div>Params zones (in minutes):</div>
                                                {
                                                    !this.state.activity.heartRateZones ? "" : <div>
                                                        <span className="param-zones-title">HR:</span>{this.state.activity.heartRateZones.map((h, i) =>
                                                            <span className="zone-item" key={`heartRateZones${i}`}><div>{h.value}</div><div className="type">{h.type}</div></span>
                                                        )}
                                                    </div>
                                                }
                                                {!this.state.activity.paceZones ? "" : <div>
                                                    <span className="param-zones-title">Pace:</span> {this.state.activity.paceZones.map((h, i) =>
                                                        <span className="zone-item" key={`paceZones${i}`}><div>{h.value}</div><div className="type">{h.type}</div></span>
                                                    )}
                                                </div>
                                                }
                                                {
                                                    !this.state.activity.powerZones ? "" : <div>
                                                        <span className="param-zones-title">Power:</span> {this.state.activity.powerZones.map((h, i) =>
                                                            <span className="zone-item" key={`powerZones${i}`}><div>{h.value}</div><div className="type">{h.type}</div></span>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                    }
                                </Col>
                            </Form.Group>

                            {
                                this.state.dateOriginal ?
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2">Original date:</Form.Label>
                                        <Form.Label column sm="10">{moment(this.state.dateOriginal).format("DD.MM.YYYY")}</Form.Label>
                                    </Form.Group>
                                    : null
                            }

                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Title:</Form.Label>
                                <Form.Label column sm="10">{this.state.title}</Form.Label>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Workout type:</Form.Label>
                                <Col sm="4">
                                    <Select id="inputWokroutType"
                                        className={"coaching-type-container" + (!this.state.validation.isWorkoutTypeValid ? " error" : "")}
                                        placeholder="Workout Type..."
                                        options={workoutTypeValues}
                                        value={workoutTypeValues.find(x => x.value === this.state.workoutType)}
                                        onChange={this.onWorkoutTypeChanged.bind(this)}
                                    />
                                    {
                                        !this.state.validation.isWorkoutTypeValid ?
                                            <Form.Text className="error-text">
                                                required
                                            </Form.Text>
                                            : null
                                    }
                                </Col>
                                <Form.Label column sm="3">{this.state.workoutGenerator ? "Generator: " : ""}{this.state.workoutGenerator}</Form.Label>
                                <Form.Label column sm="3">{this.state.activity?.category ? "Category: " : ""} {this.state.activity?.category}</Form.Label>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Coaching type:</Form.Label>
                                <Col sm="4">
                                    <Select id="inputCoachingType"
                                        className={"coaching-type-container" + (!this.state.validation.isCoachingTypeValid ? " error" : "")}
                                        placeholder="Coaching Type..."
                                        options={coachingTypeValues}
                                        getOptionLabel={x => x.name}
                                        value={coachingTypeValues.find(x => x.value === this.state.coachingType)}
                                        onChange={this.onCoachingTypeChanged.bind(this)}
                                    />
                                    {
                                        !this.state.validation.isCoachingTypeValid ?
                                            <Form.Text className="error-text">
                                                required
                                            </Form.Text>
                                            : null
                                    }
                                </Col>
                                {
                                    this.showDistancePlanned(this.state.coachingType || CoachingType.RunningFitness) ?
                                        <>
                                            <Form.Label column sm="2">Distance: </Form.Label>
                                            <Col sm="4">
                                                <div className="input-group">
                                                    <Form.Control as="input"
                                                        value={this.state.distancePlannedMeters || ''}
                                                        onChange={this.onDistanceChanged.bind(this)}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">meters</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                        :
                                        <Col sm="6">
                                            <Row className="form-group">
                                                <Form.Label column sm="4">Duration: </Form.Label>
                                                <Col sm="8">
                                                    <TimePicker className="duration-picker-form-control"
                                                        allowEmpty={true}
                                                        value={this.state.durationPlannedMoment}
                                                        onChange={this.onDurationChange}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Form.Label column sm="4">Duration Alt: </Form.Label>
                                                <Col sm="8">
                                                    <TimePicker className="duration-picker-form-control"
                                                        allowEmpty={true}
                                                        value={this.state.durationPlannedALtMoment}
                                                        onChange={this.onDurationChangeAlt}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                }
                            </Form.Group>

                            <Form.Group>
                                <Tabs defaultActiveKey="ru" id="tab-descriptions">
                                    <Tab eventKey="ru" title={this.getDecriptionTabTitle("Description RU", this.state.isDescriptionRuEdited)}>
                                        {
                                            this.props.training.id > 0 ?
                                                <button className="btn btn-sm btn-primary btn-reset" onClick={() => this.onResetDescription(TrainingDescriptionType.Ru)}>Reset description ru</button>
                                                : null
                                        }
                                        <Form.Control as="textarea" rows={8} placeholder="Type training description"
                                            className={"training-description-container" + (!this.state.validation.isDescriptionRuValid ? " error" : "")}
                                            value={this.state.descriptionRu} onChange={this.onDescriptionRuChanged.bind(this)} />
                                    </Tab>
                                    <Tab eventKey="en" title={this.getDecriptionTabTitle("Description EN", this.state.isDescriptionEnEdited)}>
                                        {
                                            this.props.training.id > 0 ?
                                                <button className="btn btn-sm btn-primary btn-reset" onClick={() => this.onResetDescription(TrainingDescriptionType.En)}>Reset description en</button>
                                                : null
                                        }
                                        <Form.Control as="textarea" rows={8} placeholder="Type training description"
                                            className={"training-description-container" + (!this.state.validation.isDescriptionEnValid ? " error" : "")}
                                            value={this.state.descriptionEn} onChange={this.onDescriptionEnChanged.bind(this)} />
                                    </Tab>
                                    <Tab eventKey="altru" title={this.getDecriptionTabTitle("Description Alt RU", this.state.isDescriptionAltRuEdited)}>
                                        {
                                            this.props.training.id > 0 ?
                                                <button className="btn btn-sm btn-primary btn-reset" onClick={() => this.onResetDescription(TrainingDescriptionType.AltRu)}>Reset description alt ru</button>
                                                : null
                                        }
                                        <Form.Control as="textarea" rows={8} placeholder="Type training description"
                                            className={"training-description-container"}
                                            value={this.state.descriptionAltRu || ''} onChange={this.onDescriptionAltRuChanged.bind(this)} />
                                    </Tab>
                                    <Tab eventKey="alten" title={this.getDecriptionTabTitle("Description Alt EN", this.state.isDescriptionAltEnEdited)}>
                                        {
                                            this.props.training.id > 0 ?
                                                <button className="btn btn-sm btn-primary btn-reset" onClick={() => this.onResetDescription(TrainingDescriptionType.AltEn)}>Reset description alt en</button>
                                                : null
                                        }
                                        <Form.Control as="textarea" rows={8} placeholder="Type training description"
                                            className={"training-description-container"}
                                            value={this.state.descriptionAltEn || ''} onChange={this.onDescriptionAltEnChanged.bind(this)} />
                                    </Tab>
                                </Tabs>
                                {
                                    !this.state.validation.isDescriptionRuValid ?
                                        <Form.Text className="error-text">
                                            russian description is required
                                        </Form.Text>
                                        : null
                                }
                                {
                                    !this.state.validation.isDescriptionEnValid ?
                                        <Form.Text className="error-text">
                                            english description is required
                                        </Form.Text>
                                        : null
                                }
                                <Form.Text className="training-placeholders">
                                    <span>Placeholders</span>
                                    <div className="placeholder-item">
                                        [[ftpN]] - ftp value by percentage or zone name (e.g. [[ftp80]], [[ftp]], [[ftp120]], [[ftpZ1]], [[ftpSS]])
                                    </div>
                                    <div className="placeholder-item">
                                        [[Z1]], [[Z6]], [[SS]] - short variant for ftp value by zone name
                                    </div>
                                </Form.Text>
                            </Form.Group>

                            <Form.Group>
                                <Tabs defaultActiveKey="files" id="tab-file-links">
                                    <Tab eventKey="files" title={"Files (" + (this.props.files?.length ?? 0) + ")"}>
                                        <InputGroup>
                                            <div className="files-container">
                                                {
                                                    !this.props.files || this.props.files.length === 0 ? "There is no any file yet." :
                                                        <table className="file-table">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={3}>
                                                                        Files
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.props.files?.map((file) => {
                                                                        return (
                                                                            <tr key={file.id.toString()} className="py-1">
                                                                                <td className="p-2" style={{ fontSize: "90%" }}>{new Intl.DateTimeFormat("de", {
                                                                                    day: "2-digit",
                                                                                    month: "2-digit",
                                                                                    year: "numeric",
                                                                                    hour12: false
                                                                                }).format(new Date(file.createdAt))}</td>
                                                                                <td className="middle p-1"><a href={file.fileUrl}>{file.filename}</a></td>
                                                                                <td className="p-1" style={{ fontSize: "80%" }}>
                                                                                    <a onClick={() => { this.onFileDelete(file.id) }}>
                                                                                        <Icon.Trash className="nav__icon" />
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                }
                                            </div>
                                        </InputGroup>

                                        <InputGroup>
                                            <div style={{ width: "200px", marginTop: "10px" }}>
                                                <input type="file" multiple onChange={this.onFileUploadChange.bind(this)} />
                                                {this.state.selectedFiles !== undefined && this.state.selectedFiles?.length > 0
                                                    ? <label>Click Save to save new files</label>
                                                    : ""
                                                }
                                            </div>
                                        </InputGroup>

                                    </Tab>
                                    <Tab eventKey="links" title={"Links (" + (this.state.links?.length ?? 0) + ")"}>
                                        <InputGroup>
                                            <div className="files-container">
                                                {
                                                    !this.state.links || this.state.links.length === 0 ? "There is no any link yet." :
                                                        <table className="file-table">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={3}>
                                                                        Links
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.links?.map((link, index) => {
                                                                        return (
                                                                            <TrainingLinkRow
                                                                                key={getRandomKey()}
                                                                                link={link}
                                                                                rowIndex={index}
                                                                                onSave={this.onSaveLink}
                                                                                onDelete={this.onDeleteLink}
                                                                            />
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                }

                                                <div>
                                                    <a style={{ cursor: "pointer" }} onClick={this.onAddNewLink.bind(this)}>
                                                        <Icon.PlusCircle></Icon.PlusCircle> Add link
                                                    </a>
                                                </div>
                                            </div>
                                        </InputGroup>
                                    </Tab>
                                </Tabs>
                            </Form.Group>
                        </Tab>
                        {
                            !!this.props.training.id && 
                            <Tab eventKey="charts" title='Charts' onEntered={() => {this.setState({ currentTab: TabName.Charts});}}>
                                <TrainingCharts trainingId={this.props.training.id} />
                            </Tab>
                        }
                        {
                            !!this.props.training.id &&
                            <Tab eventKey="export" title='Export' onEntered={() => {this.setState({ currentTab: TabName.Export });}}>
                                <TrainingExport trainingId={this.props.training.id} userId={this.props.userId} />
                            </Tab>
                        }
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.training.id === 0 || this.state.currentTab !== TabName.Details ? null : <Button variant="danger" onClick={this.onDelete.bind(this)}>Delete</Button>}
                    <Button variant="secondary" onClick={this.onClose.bind(this)}>Close</Button>
                    {this.state.currentTab !== TabName.Details ? null : <Button variant="primary" onClick={this.onSave.bind(this)}>Save</Button>}
                </Modal.Footer>
            </Modal>
        </div>
    }
}

export default ModifyTrainingModal;
