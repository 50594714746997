import { OperationSystemType } from '../shared/Primitives';

export const operationSystems = [
    {
        value: OperationSystemType.Ios, 
        name: 'iOs'
    },
    {
        value: OperationSystemType.Android, 
        name: 'Android'
    },
    {
        value: OperationSystemType.Unknown, 
        name: 'Unknown'
    },
];