import React from 'react';
import { Col, Form,  Row } from 'react-bootstrap';

type MessageTextEditorProps = {
    messageText: string,
    messageTextChanged: (value: string) => void,
};

const MessageTextEditor = (props: MessageTextEditorProps) => {

    return <Row>
        <Col sm={6}>
            <Form.Control as="textarea" rows={3} value={props.messageText} onChange={(e)=>props.messageTextChanged(e.target.value)}></Form.Control>
        </Col>
        <Col sm={6}>
            <ul className="placeholder-tips">
                <li><a onClick={() => {props.messageTextChanged(props.messageText + "[[user_name]]")}}>[[user_name]]</a> - insert user name</li>
                <li><a onClick={() => {props.messageTextChanged(props.messageText + "[[coach_name]]")}}>[[coach_name]]</a> - insert coach name</li>
            </ul>
        </Col>
    </Row>
}

export default MessageTextEditor;