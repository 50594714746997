import React, { useState, useEffect } from 'react';
import './Blog.scss';
import { BaseCampClient } from '../../api/BaseCampClient';
import { BlogFilter, BlogItem } from '../../api/types';
import { useAuth0 } from '../../react-auth0-spa';
import { FilterSortDirection, Language } from '../../shared/Primitives';
import Loader from '../Loader';
import * as Icon from 'react-feather';
import { Form } from 'react-bootstrap';
import DataTable, { DataTableColumn } from '../common/DataTable';


const BlogView = () => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
    const [error, setError] = useState<string | undefined>(undefined);

    const [filter, setFilter] = useState<BlogFilter>({
        page: 0,
        perPage: 10,
        searchString: undefined,
        sortDirection: FilterSortDirection.Desc,
        sortField: "createdAt",
        userId: undefined,
        language: undefined
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [blogItems, setBlogItems] = useState<BlogItem[] | undefined>(undefined);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        loadMessages();
    }, [filter]);

    const loadMessages = () => {
        setError(undefined);
        setIsLoading(true);
        baseCamp.getBlogList(filter)
            .then((res) => {
                setTotalCount(res.totalCount);
                setBlogItems(res.items);
            })
            .catch((ex) => {
                console.log("loading blog error:", ex);
                let msg = "There was an error during load data. Please try latter.";
                if (ex.response?.status == 400) {
                    msg = ex.response.data;
                }
                setError(msg);
            })
            .finally(() => setIsLoading(false));
    }

    const columns: DataTableColumn[] = [
        {
            fieldName: "id",
            allowSort: true,
            title: "Id",
        },
        {
            fieldName: "createdAt",
            allowSort: true,
            title: "Date"
        },
        {
            fieldName: "language",
            allowSort: true,
            title: "Language"
        },
        {
            fieldName: "title",
            allowSort: true,
            title: "Title"
        },
        {
            fieldName: "summary",
            allowSort: false,
            title: "Summary",
            cutLongText: true
        },
    ];

    let timer: NodeJS.Timeout | undefined;
    const handleSearchString = (s: string) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            if (s.length || (filter.searchString?.length ?? 0) > 0) {
                setFilter({ ...filter, searchString: s });
            }
        }, 400);
    }

    const handleLanguage = (value: string) => {
        if (value === "any") {
            setFilter({ ...filter, language: undefined });
        }
        else {
            setFilter({ ...filter, language: parseInt(value) as Language });
        }
    }

    const onSort = (field: string, sortDirection: FilterSortDirection) => {
        setFilter({
            ...filter,
            sortField: field,
            sortDirection: sortDirection
        });
    }

    const onDeleteClick = (blogItem: BlogItem) => {
        if (window.confirm(`Are you sure you want to delete blog item #${blogItem.id}`)) {
            setIsLoading(true);
            baseCamp.deleteBlogItem(blogItem.id)
                .then(() => {
                    loadMessages();
                })
                .catch((ex) => {
                    console.log("delete blog item error:", ex);
                    let msg = "There was an error during delete blog item. Please try latter.";
                    if (ex.response?.status == 400) {
                        msg = ex.response.data;
                    }
                    setError(msg);
                })
                .finally(() => setIsLoading(false));
        }
    }

    const onEditClick = (row: Object) => {
        var blogItem = row as BlogItem;
        window.location.href = `/blog/${blogItem.id}`;
    }

    const onPageNumberChanged = (value: number) => {
        setFilter({ ...filter, page: value });
    }

    const onPerPageChanged = (value: number) => {
        setFilter({ ...filter, perPage: value })
    }

    return (
        <div className="blog blog-list">
            {
                error ? <div className="fade alert alert-danger show">{error}</div> : null
            }
            {
                isLoading ? <Loader /> : ""
            }
            <div className="command-panel">
                <div className="command-panel-group">
                    <button className="btn btn-sm btn-primary" onClick={() => { window.location.href = "/blog/0" }} ><Icon.Plus /> New blog item</button>
                </div>
                <div className="command-panel-group">
                    <div>Language:</div>
                    <div>
                        <Form.Control as="select" size="sm" value={filter.language} onChange={(e) => { handleLanguage(e.target.value) }}>
                            <option value={"any"}>Any</option>
                            <option value={Language.En}>En</option>
                            <option value={Language.Ru}>Ru</option>
                        </Form.Control>
                    </div>
                </div>
                <div className="command-panel-group w-30">
                    <input type="text" className="form-control form-control-sm" placeholder="Search by ID or Title/Content"
                        onChange={(e) => handleSearchString(e.target.value.trim())} />
                </div>
            </div>
            <DataTable
                data={blogItems}
                columns={columns}
                emptyDataMessage="There is no any article."
                showCmdButtons={true}
                usePagination={true}
                pageNumber={filter.page}
                perPage={filter.perPage}
                totalCountPagination={totalCount}

                onSort={onSort}
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
                onPageNumberChanged={onPageNumberChanged}
                onPerPageChanged={onPerPageChanged}
            />
        </div>
    );
}

export default BlogView;