import { ChatClient, ChatRole } from "./chatDirectorCoreTypes";

export enum ChannelType {
    Main = "Main",
    Assistant = "Assistant",
}

export interface ChatMessageV3 {
    id: number;
    createdAt: Date;
    updatedAt: Date | undefined;
    userId: number;
    userName: string;
    userRole: ChatRole;
    userAvatarUrl: string | undefined;
    senderUserId: number;
    senderName: string;
    senderRole: ChatRole;
    senderAvatarUrl: string | undefined;
    body: string;
    isRead: boolean;
    isSeen: boolean;
    isYours: boolean;
    chatClient: ChatClient | undefined;
}

export interface SendChatMessageRequestV3 {
    channelType: ChannelType;
    userId: number;
    body: string;
}