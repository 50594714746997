import { Gender } from '../shared/Primitives';

export const genders = [
    {
        value: Gender.Male, 
        caption: 'Male',
    },
    {
        value: Gender.Female, 
        caption: 'Female',
    },
    {
        value: Gender.Other, 
        caption: 'Other',
    },
];