import React, { useState, useEffect } from 'react';
import { SurveyDefinition, SurveyQuestionTypeFilterData } from '../../../api/types';
import { isDefinitionConstraintsEmpty, isDefinitionOptionsEmpty } from '../../../api/Helpers';
import DefinitionInfoBlock from './DefinitionInfoBlock'
import DefinitionEditBlock from './DefinitionEditBlock'
import { Language } from '../../../shared/Primitives';

type DefinitionViewProps = {
    selectedLanguage: Language,
    definition: SurveyDefinition,
    index: number,
    questionTypes: SurveyQuestionTypeFilterData[],
    questionIds: string[],
    definitionsCount: number,
    onKeepDefinition: (definition: SurveyDefinition, index: number) => void,
    onDeleteDefinition: (index: number) => void,
    onMoveDefinition: (index: number, step: -1 | 1) => void,
    onEditModeChange: (definitionId: number, isEdit: boolean) => void
};

const DefinitionBlock = (props: DefinitionViewProps) => {

    const [isEditMode, setIsEditMode] = useState(false);
    const [definition, setDefinition] = useState<SurveyDefinition>({} as SurveyDefinition);
    const [definitionErrors, setDefinitionErrors] = useState<string[]>([]);

    useEffect(() => {
        setDefinition(props.definition);
        setIsEditMode(props.definition.questionId ? false : true);
    }, []);

    const editDefinition = () => {
        setIsEditMode(true);
        props.onEditModeChange(definition.id, true);
    }

    const keepDefinition = (definition: SurveyDefinition) => {
        if (validateDefinition(definition)) {
            props.onKeepDefinition(definition, props.index);
            setIsEditMode(false);
            props.onEditModeChange(definition.id, false);
        }
    }

    const cancel = () => {
        if (isDefinitionEmpty() && definition.id <= 0) {
            props.onDeleteDefinition(props.index);
        }
        else {
            setDefinition(props.definition);
            props.onKeepDefinition(props.definition, props.index);
        }

        setIsEditMode(false);
        props.onEditModeChange(definition.id, false);
    }

    const deleteDefinition = () => {
        if (window.confirm('Are you sure you want to delete definition?')) {
            props.onDeleteDefinition(props.index);
        }
    }

    const isDefinitionEmpty = (): boolean => {
        return !definition.questionId && !definition.questionTextEn && !definition.questionType &&
            isDefinitionConstraintsEmpty(definition.constraints) &&
            isDefinitionOptionsEmpty(definition.options);
    }

    const validateDefinition = (definition: SurveyDefinition): boolean => {
        const errs = [];
        if (!definition.questionId) {
            errs.push("QuestionId is required.");
        }
        if ((props.selectedLanguage === Language.En && !definition.questionTextEn) ||
            (props.selectedLanguage === Language.Ru && !definition.questionTextRu) ||
            (props.selectedLanguage === Language.De && !definition.questionTextDe)) {
            errs.push("QuestionText is required.");
        }
        if (!definition.questionType) {
            errs.push("QuestionType is required.");
        }
        if (!isDefinitionConstraintsEmpty(definition.constraints)) {
            if (definition.constraints?.digits && definition.constraints?.digits <= 0) {
                errs.push("Constraint digits must be greater than 0.");
            }
        }
        setDefinitionErrors(errs);

        return errs.length === 0;
    }

    const moveDefinition = (step: -1 | 1) => {
        props.onMoveDefinition(props.index, step);
    }



    const definitionCompletedMessage = (definition: SurveyDefinition) => {
        let langNotCompleted: string[] = [];
        if (definition.questionTextEn?.length === 0 || (definition.options && definition.options.some(x => x.captionEn.length === 0))) {
            langNotCompleted.push("En");
        }
        if (definition.questionTextRu?.length === 0 || (definition.options && definition.options.some(x => x.captionRu.length === 0))) {
            langNotCompleted.push("Ru");
        }
        return langNotCompleted.length === 0 ? null : (
            <small className="text-danger">{`There are not populated fields for ${langNotCompleted.join(", ")} language(s).`}</small>
        );
    }

    return (
        <div className={"definition-block" + (isEditMode ? " definition-block-edit" : "")}>
            <div className="row">
                <div className="col">
                    <h6>Question {props.index + 1} {definitionCompletedMessage(props.definition)}</h6>
                </div>
            </div>

            {
                isEditMode
                    ?
                    <React.Fragment>
                        <DefinitionEditBlock
                            key={props.definition.id}
                            definition={props.definition}
                            selectedLanguage={props.selectedLanguage}
                            index={props.index}
                            questionIds={props.questionIds}
                            questionTypes={props.questionTypes}
                            onCancel={cancel}
                            onKeepDefinition={keepDefinition}
                        />
                        {
                            definitionErrors?.length > 0 ?
                                <div className="error-summary">
                                    <ul>
                                        {
                                            definitionErrors.map((err, index) => {
                                                return (
                                                    <li key={index}>{err}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                : null
                        }
                    </React.Fragment>

                    :
                    <DefinitionInfoBlock
                        key={props.definition.id}
                        definition={props.definition}
                        selectedLanguage={props.selectedLanguage}
                        definitionsCount={props.definitionsCount}
                        index={props.index}
                        questionIds={props.questionIds}
                        questionTypes={props.questionTypes}
                        onMoveDefinition={moveDefinition}
                        onEditDefinition={editDefinition}
                        onDeleteDefinition={deleteDefinition}
                    />
            }
        </div>
    );

}

export default DefinitionBlock;