import React, { createRef, useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { useAuth0 } from "./../../../react-auth0-spa";
import { ChatUser } from '../../../api/types/chatDirectorV2Types';
import { hasRoles } from '../../../api/Helpers';
import { ChatRole } from '../../../api/types/chatDirectorCoreTypes';

type ChatUserListProps = {
    users: ChatUser[],
    selectedUser: ChatUser | undefined,
    filterSelectedRoles: ChatRole[],
    isLoading: boolean,
    filterRoleChanged: (role: ChatRole, checked: boolean) => void,
    filterSerchStringChanges: (s: string) => void,
    importUsers: () => void,
    addUserClick: () => void,
    selectUser: (userId: number, hideUserList: boolean) => void
};

const ChatUserList = (props: ChatUserListProps) => {
    const { accessToken } = useAuth0();
    //to mark we scrolled to user after loading
    const[scrolledToSelectedUser, setScrolledToSelectedUser] = useState<boolean>(false);

    let currentUserRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (!scrolledToSelectedUser && currentUserRef?.current)
        {
            currentUserRef?.current?.scrollIntoView(true);
            //mark we scrolled to user after loading
            setScrolledToSelectedUser(true);
        }
    }, [props.selectedUser]);

    const isRoleChecked = (role: ChatRole): boolean => {
        return props.filterSelectedRoles.indexOf(role) >= 0;
    }

    return <>
        <div className="search-panel">
            <input type="text" className="form-control form-control-sm" placeholder="Search users..."
                onChange={(e) => props.filterSerchStringChanges(e.target.value.trim())} />
            {
                hasRoles(accessToken, "admin") ?
                    <ul className="role-selector d-none d-md-flex">
                        <li>
                            <input id="cbUsersRole" type="checkbox"
                                checked={isRoleChecked(ChatRole.User)}
                                onChange={(event) => { props.filterRoleChanged(ChatRole.User, event.target.checked) }}
                            />
                            <label htmlFor="cbUsersRole">Users</label>
                        </li>
                        <li>
                            <input id="cbAdminsRole" type="checkbox"
                                checked={isRoleChecked(ChatRole.Admin)}
                                onChange={(event) => { props.filterRoleChanged(ChatRole.Admin, event.target.checked) }}
                            />
                            <label htmlFor="cbAdminsRole">Admins</label>
                        </li>
                        <li>
                            <input id="cbCoachesRole" type="checkbox"
                                checked={isRoleChecked(ChatRole.Coach)}
                                onChange={(event) => { props.filterRoleChanged(ChatRole.Coach, event.target.checked) }}
                            />
                            <label htmlFor="cbCoachesRole">Coaches</label>
                        </li>
                    </ul>
                    : null
            }
        </div>
        <div className="user-list">
            {
                props.isLoading ?
                    <div className="overlay">
                        <div className="spinner-border loader" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    : null
            }
            {
                props.users.map((u) => {
                    return (
                        <div key={u.userId} ref={u.userId === props.selectedUser?.userId ? currentUserRef : null} className={`user-item ${u.userId === props.selectedUser?.userId ? "active" : ""}`} onClick={() => props.selectUser(u.userId, true)}>
                            <div className={`name ${u.role === ChatRole.Admin ? "text-primary" : u.role === ChatRole.Coach ? "text-success" : ""}`}>
                                {u.userId}: {u.name}{u.role == ChatRole.Admin || u.role == ChatRole.Coach ? ` - ${u.role}` : ""}
                            </div>
                            <div className="email">{u.email}</div>
                            <div className={`stats ${u.unreadUsersMessageCount ? "stats-bold text-danger" : ""}`}>{u.unreadUsersMessageCount} New messages</div>
                        </div>
                    );
                }
                )
            }
        </div>
        {
            hasRoles(accessToken, "admin") ?
                <div className="btn-panel d-none d-md-block">
                    <div className="title">
                        System functions
                    </div>
                    <div className="buttons">
                        <button className="btn btn-sm btn-success" onClick={(e) => {
                            props.addUserClick();
                        }}
                        >
                            <Icon.UserPlus /> User
                        </button>
                        <button className="btn btn-sm btn-primary" onClick={(e) => {
                            if (window.confirm("Are you sure you want to import users from basecamp?")) {
                                props.importUsers();
                            }
                        }}
                        >
                            <Icon.Download /> Import
                        </button>
                    </div>
                </div>
                : null
        }
    </>
}

export default ChatUserList;