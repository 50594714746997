import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Select from 'react-select';
import UserProfileButtons, { ProfileType } from './UserProfileButtons';
import Loader from '../../Loader';
import { surveySportTypeValues } from "../../../fixtures/sport-types";
import { CoachingType, SurveySportType } from '../../../shared/Primitives';
import { StartNewUserTrainingPlanRequest } from '../../../api/types';
import { formatDate } from '../../../api/Helpers';

interface Props extends RouteComponentProps<any> { }

const UsersProfileNewPlanView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
    const userId = props.match.params.userId;

    const [startAt, setStartAt] = useState(new Date());
    const [endAt, setEndAt] = useState<Date | undefined>(undefined);
    const [sportType, setSportType] = useState(SurveySportType.Running);
    const [coachingType, setCoachingType] = useState<CoachingType | undefined>(undefined);
    const [coachingTypeOptions, setCoachingTypeOptions] = useState<any[]>([])
    const [distanceKm, setDistanceKm] = useState<number | undefined>(undefined);
    const [isRace, setIsRace] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessStartPlan, setShowSuccessStartPlan] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const sportTypeOptions: any[] = surveySportTypeValues.map((x) => { return { value: x.value, label: x.label, isDisabled: x.isDisabled } });

    useEffect(() => {
        updateCoahingTypeOptions(sportType);
    }, []);

    const updateCoahingTypeOptions = (targetSportType: any) => {
        var coachingTypeFiltered =
            surveySportTypeValues.find(x => x.value === targetSportType)?.coachingTypes?.map((x) => {
                return { value: x.value, label: x.label, isDisabled: x.isDisabled }
            })
            ?? [];
        const selectedCoachingType = coachingTypeFiltered.find(x => x.isDisabled === false)?.value ?? undefined;
        setCoachingType(selectedCoachingType);
        setCoachingTypeOptions(coachingTypeFiltered);
        updateDataByCoachingType(selectedCoachingType);
    }

    const updateDataByCoachingType = (selectedCoachingType: CoachingType | undefined) => {
        if (!selectedCoachingType)
        {
            return;
        }

        if (selectedCoachingType === CoachingType.CyclingRace || selectedCoachingType === CoachingType.RunningRace) {
            setIsRace(true);
        }
        else {
            setIsRace(false);
            setDistanceKm(undefined);
            setEndAt(undefined);
        }
    }

    const startNewPlan = () => {

        if (!coachingType) {
            setErrorMessage("Coaching type is not selected!");
            return;
        }

        if (isRace && (!endAt || endAt < startAt))
        {
            setErrorMessage("End at date must be specified and older than start at date!");
            return;
        }

        if (isRace && !distanceKm)
        {
            setErrorMessage("Distance must be specified!");
            return;
        }

        setIsLoading(true);
        const request: StartNewUserTrainingPlanRequest = {
            startAt: startAt,
            allowNotifications: true,
            coachingType: coachingType,
            goalDistanceKm: distanceKm,
            goalRaceDate: endAt

        }

        baseCamp.addUserTrainingPlan(userId, request)
            .then(() => {
                setShowSuccessStartPlan(true);
                setTimeout(() => setShowSuccessStartPlan(false), 5000);
            })
            .catch(e => {
                setErrorMessage(e.response.data);
                console.log(e.response);
                console.error(e);
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <div className="users-profile users-profile-new-plan">
            <div>
                <h3 className="float-left">User ID: {userId}</h3>
                <div className="float-right">
                    <UserProfileButtons userId={userId} type={ProfileType.StartNewPlan} />
                </div>
                <div className="clear-both p-2" />
            </div>
            <h4>Start new plan</h4>

            <div className="parameters-wrapper">
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Sport type</label>
                    <div className="col-sm-9">
                        <Select
                            options={sportTypeOptions}
                            value={sportTypeOptions.find(x => x.value === sportType)}
                            getOptionLabel={x => x.label}
                            getOptionValue={x => x.value}
                            isOptionDisabled={x => x.isDisabled}
                            onChange={(x) => {
                                setSportType(x.value);
                                updateCoahingTypeOptions(x.value);
                            }}
                        />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Coaching type</label>
                    <div className="col-sm-9">
                        <Select
                            options={coachingTypeOptions}
                            value={coachingTypeOptions.find(x => x.value === coachingType)}
                            getOptionLabel={x => x.label}
                            getOptionValue={x => x.value}
                            onChange={x => {
                                setCoachingType(x.value);
                                updateDataByCoachingType(x.value);
                            }}
                        />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Start at</label>
                    <div className="col-sm-9">
                        <DatePicker
                            selected={startAt}
                            onChange={date => {
                                if (date) {
                                    setStartAt(date);
                                }
                            }}
                            className="form-control"
                            placeholderText="Start at..."
                            id="inputRunPlanStartAt"
                            dateFormat="yyyy-MM-dd"
                            locale="en-GB"

                        />
                    </div>
                </div>

                {
                    isRace ?
                        <React.Fragment>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">End at</label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        selected={endAt}
                                        onChange={date => {
                                            if (date) {
                                                setEndAt(new Date(formatDate(date)!));
                                            }
                                        }}
                                        className="form-control"
                                        placeholderText="End at..."
                                        id="inputRunPlanEndAt"
                                        dateFormat="yyyy-MM-dd"
                                        locale="en-GB"

                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Distance Km</label>
                                <div className="col-sm-9">
                                    <input type="Number" value={distanceKm || 0} min={0}
                                        onChange={x => setDistanceKm(parseInt(x.target.value))}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                        : null
                }

                <div className="form-group row">
                    <div className="col-sm-3" />
                    <div className="col-sm-9">
                        {
                            isLoading
                                ?
                                <div style={{ height: 40 }}><Loader className="absolute" /> </div>
                                :
                                <button type="button" className="btn btn-primary mr-2" onClick={startNewPlan}>Start new plan</button>
                        }

                    </div>
                </div>

                {
                    errorMessage.length > 0 ?
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                        : null
                }

                {
                    showSuccessStartPlan ?
                        <div className="alert alert-success">
                            Plan was successfully started
                        </div>
                        : null
                }

            </div>
        </div>
    )
};

export default UsersProfileNewPlanView;

