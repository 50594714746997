import React from 'react';

import { PlanOverviewWeekResponse } from "../../api/types";
import { CoachingType, DayOfWeak } from '../../shared/Primitives';
import { coachingTypeValues } from "../../fixtures/coaching-types";
import './PlanNewOverview.scss';

interface Props {
    weeks: PlanOverviewWeekResponse[],
    isShowWeekInfo: boolean
}

const PlanNewOverview = ({ weeks, isShowWeekInfo }: Props) =>
    <div className="plan-new-overview">
        <table className="table">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Week</th>
                    <th scope="col">Mon</th>
                    <th scope="col">Tue</th>
                    <th scope="col">Wed</th>
                    <th scope="col">Thu</th>
                    <th scope="col">Fri</th>
                    <th scope="col">Sat</th>
                    <th scope="col">Sun</th>
                </tr>
            </thead>
            <tbody>
                {weeks.map((week) => {
                    return (
                        <tr key={week.weekNumber}>
                            <td>
                                <h6>
                                    <span style={{ marginRight: 10 }}>
                                        #{week.weekNumber}
                                        {week.planWeeklyVolumeMeters && isShowWeekInfo && <div>(plan: {week.planWeeklyVolumeMeters})</div>}
                                        {week.factWeeklyVolumeMeters && isShowWeekInfo && <div>(fact: {week.factWeeklyVolumeMeters})</div>}
                                        {week.planLongRunMeters && isShowWeekInfo && <div>(long: {week.planLongRunMeters})</div>}
                                    </span>
                                    {
                                        week.labels.map((label, i) => {
                                            const coachingTypeName = coachingTypeValues.find(c => c.value === label.coachingType)?.name;
                                            const badgeStyle = getBadgeType(label.coachingType);
                                            return (
                                                <span className="badge badge-primary" key={`label${i}`} style={{ ...badgeStyle, marginRight: 10 }}
                                                    title={coachingTypeName}
                                                >
                                                    {label.label}
                                                </span>
                                            );
                                        })
                                    }
                                </h6>
                            </td>
                            <td>{getWorkoutOfDay(week, DayOfWeak.Monday)}</td>
                            <td>{getWorkoutOfDay(week, DayOfWeak.Tuesday)}</td>
                            <td>{getWorkoutOfDay(week, DayOfWeak.Wednesday)}</td>
                            <td>{getWorkoutOfDay(week, DayOfWeak.Thursday)}</td>
                            <td>{getWorkoutOfDay(week, DayOfWeak.Friday)}</td>
                            <td>{getWorkoutOfDay(week, DayOfWeak.Saturday)}</td>
                            <td>{getWorkoutOfDay(week, DayOfWeak.Sunday)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>

const getWorkoutOfDay = (week: PlanOverviewWeekResponse, day: DayOfWeak) => {
    if (!week.content || !week.content.length) {
        return <span />
    }

    const target = week.content.find(c => c.dayOfWeek === day);
    if (!target) {
        return <span />
    }

    return target.workouts.map((workout, index) => {
        const badgeStyle = getBadgeType(workout.coachingType);
        const coachingTypeName = coachingTypeValues.find(c => c.value === workout.coachingType)?.name;

        return (
            <div className="workout" key={`workout-${target.dayOfWeek}-${index}`}>
                <div className="workout__type" title={coachingTypeName}>
                    <span className="badge" style={{...badgeStyle}}>
                        {workout.workoutType}
                    </span>
                    <span className="workout__distance">{workout.totalDistanceMeters} м.</span>
                    {
                        workout.workoutGenerator
                        ? <span className="workout__generator">{workout.workoutGenerator}</span>
                        : null
                    }
                </div>
                <div className="workout__desc">{workout.description?.split("\n").map(function(item, idx) {
                    return (
                        <span key={idx}>
                            {item}
                            <br/>
                        </span>
                    )
                })}</div>
                {
                    workout.links?.length ?
                    <ul className="workout__links">
                        {
                            workout.links?.map((l, idx) => {
                                return (<li><a target="_blank" href={l}>link{idx + 1}</a></li>)
                            })
                        }
                    </ul>
                    : null
                }
            </div>
        );
    });
}

const getBadgeType = (coachingType: CoachingType) => {
    let color = {
        color: "#fff"
    };

    switch (coachingType) {
        case CoachingType.RunningRace:
            return {
                ...color,
                backgroundColor: "#28a745"
            };
        case CoachingType.RunningFitness:
            return {
                ...color,
                backgroundColor: "#6600cc"
            };
        case CoachingType.CyclingFitness:
            return {
                ...color,
                backgroundColor: "#f8b82c"
            };
        default:
            return {
                ...color,
                backgroundColor: "#6c757d"
            };
    }
}

export default PlanNewOverview;