import React, { useState } from 'react';

import useForm from 'react-hook-form'

import { PlanType } from '../../shared/Primitives';
import { planTypes } from '../../fixtures/plan-types';
import { PlanConfiguration } from './Plan';

const athleteLevels = [1, 2, 3, 4];

interface Props {
    onGeneratePlan: (planParams: PlanConfiguration) => void
}

const PlanParams = ({ onGeneratePlan }: Props) => {
    const [plantTypeSelected, setPlantTypeSelected] = useState(PlanType.Marathon);
    const [athleteLevelSelected, setAthleteLevelSelected] = useState(2);
    const [vdot, setVdot] = useState(50);
    const [weeksCount, setWeeksCount] = useState(20);
    const [longRun, setLongRun] = useState(10);

    const { handleSubmit, errors } = useForm()

    const handleGenerateClick = () => {
        const planParamsState: PlanConfiguration = {
            planType: plantTypeSelected,
            athleteLevel: athleteLevelSelected,
            vdot: vdot,
            weeksCount: weeksCount,
            longRunKm: longRun,
        };

        onGeneratePlan(planParamsState);
    };

    return <div className="plan-request-params">
        <form>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Plan type</label>
                <div className="col-sm-10">
                    <div className="btn-group" role="group" aria-label="Plan type">
                        {planTypes.map((planType) => {
                            const classes = planType.type === plantTypeSelected ? 'btn btn-primary' : 'btn btn-light';
                            const handleClick = () => setPlantTypeSelected(planType.type);
                            return (<button key={planType.type} type="button" className={classes} onClick={handleClick}>{planType.caption}</button>)
                        })}
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Athlete level</label>
                <div className="col-sm-10">
                    <div className="btn-group btn-group-lg" role="group" aria-label="Athlete level">
                        {athleteLevels.map((level) => {
                            const classes = level === athleteLevelSelected ? 'btn btn-primary' : 'btn btn-light';
                            const handleClick = () => setAthleteLevelSelected(level);
                            return (<button key={level} type="button" className={classes} onClick={handleClick}>{level}</button>)
                        })}
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputVdot" className="col-sm-2 col-form-label">VDOT</label>
                <div className="col-sm-2">
                    <input type="number" step="0.1" className="form-control" id="inputVdot" name="vdot" value={vdot} placeholder="VDOT" onChange={(event) => setVdot(+event.target.value)} />
                    {errors.vdot && <span className="text-danger">VDOT is required</span>}
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputWeeksCount" className="col-sm-2 col-form-label">Number of weeks</label>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="inputWeeksCount" name="weeksCount" value={weeksCount} placeholder="Number of weeks" onChange={(event) => setWeeksCount(+event.target.value)} />
                    {errors.weeksCount && <span className="text-danger">Weeks count is required</span>}
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputLongRun" className="col-sm-2 col-form-label">Long run (km)</label>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id="inputLongRun" name="longRun" value={longRun} placeholder="Long run (km)" onChange={(event) => setLongRun(+event.target.value)} />
                    {errors.longRun && <span className="text-danger">Long run value is required</span>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                    <button type="button" className="btn btn-primary" onClick={handleSubmit(handleGenerateClick)}>Generate</button>
                </div>
            </div>
        </form>
    </div>
}

export default PlanParams;
