import React, { useState } from 'react';
import { AthleteParametersHistoryResponse, AthleteParameters } from '../../../../api/types';
import * as Icon from 'react-feather';
import { dayOfWeakValues, daysOfWeakValues, daysOfWeekFlaged } from '../../../../fixtures/days-of-weak';

type ParametersHistoryRowProps = {
    parametersHistory: AthleteParametersHistoryResponse,
    rowIndex: number
};

const ParametersHistoryRow = (props: ParametersHistoryRowProps) => {
    const getCreatedDate = (row: AthleteParametersHistoryResponse) => {
        return new Intl.DateTimeFormat("de", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
        }).format(row.createdAt);
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    }

    return (
        <React.Fragment key={props.rowIndex}>
            <tr onClick={toggleExpand} style={isExpanded ? {fontWeight: "bold"} : {}}>
                <td>
                    {
                        isExpanded 
                        ? <Icon.ChevronDown className="nav__icon"/>
                        : <Icon.ChevronRight className="nav__icon"/>
                    }
                    
                </td>
                <td>{getCreatedDate(props.parametersHistory)}</td>
                <td>{props.parametersHistory.actor}</td>
                <td>{props.parametersHistory.action}</td>
                <td>
                    <div>
                        {props.parametersHistory.changes}
                    </div>
                    <div>
                        <a onClick={toggleExpand} href="#">{isExpanded ? "hide parameters" : "show parameters"}</a>
                    </div>
                </td>
            </tr>
            {
                isExpanded ?
                    <tr className="sub-row">
                        <td colSpan={5}>
                            <div className="athlete-parameters-wrapper">
                                <div className="column">
                                    <div className="athlete-parameter">
                                        <div>DistanceKmTrainFor:</div>
                                        <div>{props.parametersHistory.snapshot.distanceKmTrainFor}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>Level:</div>
                                        <div>{props.parametersHistory.snapshot.level}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>LongRunMeters:</div>
                                        <div>{props.parametersHistory.snapshot.longRunMeters}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>Vdot:</div>
                                        <div>{props.parametersHistory.snapshot.vdot}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>MaxHeartRate:</div>
                                        <div>{props.parametersHistory.snapshot.maxHeartRate}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>StrictNumberOfDays:</div>
                                        <div>{props.parametersHistory.snapshot.strictNumberOfDays}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>WeekVolumeMeters:</div>
                                        <div>{props.parametersHistory.snapshot.weekVolumeMeters}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>QualityWorkoutsNumber:</div>
                                        <div>{props.parametersHistory.snapshot.qualityWorkoutsNumber}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>AvailableTrainingDays:</div>
                                        <div>
                                            {
                                                props.parametersHistory.snapshot.availableTrainingDays
                                                ? daysOfWeekFlaged.filter(x=>(x.value & props.parametersHistory.snapshot.availableTrainingDays!) !== 0)?.map(x => x.name).join(", ")
                                                : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>LongRunDay:</div>
                                        <div>
                                            {
                                                props.parametersHistory.snapshot.longRunDay !== undefined
                                                ? dayOfWeakValues.find(x=>x.value === props.parametersHistory.snapshot.longRunDay)?.name
                                                : ""
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="athlete-parameter">
                                        <div>Ftp:</div>
                                        <div>{props.parametersHistory.snapshot.ftp}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>BikeMaxHeartRate:</div>
                                        <div>{props.parametersHistory.snapshot.bikeMaxHeartRate}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>BikeStrictNumberOfDays:</div>
                                        <div>{props.parametersHistory.snapshot.bikeStrictNumberOfDays}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>LongRideOutdoorMinutes:</div>
                                        <div>{props.parametersHistory.snapshot.longRideOutdoorMinutes}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>BaseRideIndoorMinutes:</div>
                                        <div>{props.parametersHistory.snapshot.baseRideIndoorMinutes}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>BaseRideOutdoorMinutes:</div>
                                        <div>{props.parametersHistory.snapshot.baseRideOutdoorMinutes}</div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>LongBikeDay:</div>
                                        <div>
                                            {
                                                props.parametersHistory.snapshot.longBikeDay !== undefined
                                                ? dayOfWeakValues.find(x=>x.value == props.parametersHistory.snapshot.longBikeDay)?.name
                                                : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>BikeAvailableTrainingDays:</div>
                                        <div>
                                            {
                                                props.parametersHistory.snapshot.bikeAvailableTrainingDays
                                                ? daysOfWeekFlaged.filter(x=>(x.value & props.parametersHistory.snapshot.bikeAvailableTrainingDays!) !== 0)?.map(x => x.name).join(", ")
                                                : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="athlete-parameter">
                                        <div>BikeIntensityIndication:</div>
                                        <div>{props.parametersHistory.snapshot.bikeIntensityIndication}</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    : null
            }
        </React.Fragment>
    );
}

export default ParametersHistoryRow;