import axios, { AxiosRequestConfig } from 'axios';

import config from "../config"

import {
    ActivityTopItem, UserShortInfo, TrainingInfo, TrainingCore, UserFullInfo, WeekPlanInfo,
    UserRegistrationRequest, UserRegistrationResponse, UserFullInfoList, UserInfoFilter, UserTelegramMessageList,
    UserTrainingPlanList, PlanOverviewInputDataRequest, PlanOverviewResponse, StatisticsResponse, WeekFeedback,
    SurveyResult, SubscriptionPlanInfo, TimeZoneType, BroadcastingMessage, TrainingFileInfo,
    AthleteParametersHistoryResponse, Survey, SurveyFilterData, StartNewUserTrainingPlanRequest, BikeMasterUserInfo, CoachingCardResponse, StatisticsDetailsResponse, ProfileDefaultData, AutoMessageFilter, AutoMessage, AutoMessageListResponse, AutoMessageSenderUser, SaveAutoMessageRequest, BlogFilter, BlogItemList, BlogItem, BlogSaveRequest, TrainingUpdateResponse, ResetTrainingDescriptionResponse, UserActions, Race, RaceResult, TrainingFullInfo, TrainingFullInfoResponse, UserChartsAdminDataItem, TrainingExportStatus, PromoOfferHistory,
    UserPaymentList
} from './types';
import { BroadcastChannel, CoachingType, SubscriptionPeriods, TrainingDescriptionType } from '../shared/Primitives';
import { formatDate } from './Helpers';
import {
    mapToUserFullInfo, mapToActivity, mapToTimeZoneType, mapToTrainingFileInfo,
    mapToWeekPlanInfo, mapToUserRegistrationResponse, mapToUserTelegramMessageList,
    mapToPlanOverviewResponse, mapToStatisticsResponse, mapToSubscriptionPlanInfo,
    mapToBroadcastingMessage, mapToUserShortInfo, mapToUserFullInfoList,
    mapToSurveyResult, mapToUserTrainingPlanList, mapToTrainingInfo, mapToRace,
    mapToAthleteParametersHistoryResponse,
    mapToSurvey,
    mapToUserUpdateRequest,
    mapToAutoMessageListResponse,
    mapToAutoMessage,
    mapToBlogItemListResponse,
    mapToBlogItemResponse,
    mapToTrainingUpdateResponse,
    mapActions,
    mapToRaceResult,
    mapToTrainingFullInfo,
    mapToUserChartsAdminDataItem,
    mapToTrainingExportStatus,
    mapToPromoOfferHistory,
    mapPayments
} from './mappings';

import { runningCoachingTypes, cyclingCoachingTypes, triathlonCoachingTypes } from '../shared/coaching-type-groups';

import qs from "qs";
const FileSaver = require('file-saver');

const apiUrl = config.basecampHost + "/api/v1/admin";

export class BaseCampClient {
    config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json' } };
    configFormData: AxiosRequestConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

    constructor(token: string) {
        this.config.headers['Authorization'] = `Bearer ${token}`;
        this.configFormData.headers['Authorization'] = `Bearer ${token}`;
    }

    public async getUsers(): Promise<UserShortInfo[]> {
        const usersResponse = await axios.get<any[]>(`${apiUrl}/users/short`, this.config);
        const result = usersResponse.data.map(mapToUserShortInfo);
        return result;
    }

    public async getUsersList(filter: UserInfoFilter): Promise<UserFullInfoList> {
        const usersResponse = await axios.get<any>(`${apiUrl}/users`,
            {
                ...this.config,
                params: filter,
                paramsSerializer: params => qs.stringify(params)
            });
        const result = mapToUserFullInfoList(usersResponse.data);
        return result;
    }

    /** Donwload filtered users as csv file */
    public async getUsersCsv(filter: UserInfoFilter): Promise<any> {
        const resp = await axios.get(`${apiUrl}/users/csv`,
            {
                ...this.config,
                params: filter,
                paramsSerializer: params => qs.stringify(params),
                responseType: 'blob'
            });

        FileSaver.saveAs(resp.data, "users.csv");
        return resp.data;
    }

    public async getUserPayments(userId: number): Promise<UserPaymentList> {
        const response = await axios.get<any>(`${apiUrl}/payments/${userId}`, this.config);
        const result = {
            items: response.data.items.map(mapPayments),
            totalCount: response.data.totalCount,
        }
        return result;
    }

    public async getPaymentsCsv(): Promise<any> {
        const resp = await axios.get(`${apiUrl}/payments/csv`,
            {
                ...this.config,
                responseType: 'blob'
            });

        FileSaver.saveAs(resp.data, "payments.csv");
        return resp.data;
    }

    public async getUsersActions(userId: number, page: number): Promise<UserActions> {
        const response = await axios.get(`${apiUrl}/user-actions/${userId}?SortField=createdAt&Page=${page}&SortDirection=1`, this.config);
        const result = {
            items: response.data.items.map(mapActions),
            totalCount: response.data.totalCount,
        }
        return result;
    }

    public async updateUser(userId: number, userInfo: UserFullInfo): Promise<void> {
        const request = mapToUserUpdateRequest(userInfo);
        await axios.put(`${apiUrl}/users/${userId}`, request, this.config);
    }

    public async deleteUser(userId: number): Promise<void> {
        await axios.delete(`${apiUrl}/users/${userId}`, this.config);
    }

    public async getUserTelegramMessages(userId: number, lastTelegramMessageId: number, perPage: number): Promise<UserTelegramMessageList> {
        const response = await axios.get<any>(`${apiUrl}/users/${userId}/messages`,
            {
                ...this.config,
                params: { lastTelegramMessageId, perPage }
            });
        const result = mapToUserTelegramMessageList(response.data);
        return result;
    }

    public async getUserWeekFeedbacks(userId: number): Promise<WeekFeedback[]> {
        const response = await axios.get<WeekFeedback[]>(`${apiUrl}/users/${userId}/week-feedbacks`, this.config);
        return response.data;
    }

    public async getUserSurveyResults(userId: number): Promise<SurveyResult[]> {
        const response = await axios.get<any[]>(`${apiUrl}/users/${userId}/survey-results`, this.config);
        const result = response.data.map(mapToSurveyResult);
        return result;
    }

    public async getUserTrainingPlans(userId: number): Promise<UserTrainingPlanList> {
        const response = await axios.get<any>(`${apiUrl}/users/${userId}/training-plans`, this.config);
        const result = mapToUserTrainingPlanList(response.data);
        return result;
    }

    public async addUserTrainingPlan(userId: number, request: StartNewUserTrainingPlanRequest): Promise<void> {
        const mappedRequest = {
            ...request,
            startAt: formatDate(request.startAt)
        }
        await axios.post<void>(`${apiUrl}/training-plan/user/${userId}`, mappedRequest, this.config);
    }

    public async getTrainings(userId: number, dateFrom: Date, dateTo: Date): Promise<TrainingInfo[]> {
        const trainingsResponse = await axios.get<any[]>(`${apiUrl}/users/${userId}/trainings?dateFrom=${formatDate(dateFrom)}&dateTo=${formatDate(dateTo)}`, this.config);

        const results = trainingsResponse.data.map(mapToTrainingInfo);
        return results;
    }

    public async getTrainingFullInfo(trainingId: number): Promise<TrainingFullInfo | undefined> {
        const response = await axios.get<TrainingFullInfoResponse>(`${apiUrl}/trainings/${trainingId}`, this.config);

        const result = mapToTrainingFullInfo(response.data);
        return result;
    }

    public async createOrUpdateGarminWorkout(trainingId: number, isAlternate: boolean): Promise<void> {
        try {
            await axios.post<any>(`${apiUrl}/trainings/${trainingId}/garmin-workout?completionType=${isAlternate ? "outdoor" : "indoor"}`, {}, this.config);
        }
        catch (ex) {
            const response = (ex as any)?.response;
            if (response?.status === 403) {
                throw new Error('The user token does not have privileges to export workouts to Garmin. Training owner must to revoke his token and connect Garmin again');
            }
            throw response ? new Error(response?.status + ": " + response?.data.message) : ex;
        }
    }

    public async deleteGarminWorkout(trainingId: number): Promise<void> {
        await axios.delete<any>(`${apiUrl}/trainings/${trainingId}/garmin-workout`, this.config);
    }

    public async addTraining(userId: number, training: TrainingCore): Promise<number> {
        const request: any = {
            userId: userId,
            training: {
                id: training.id,
                date: formatDate(training.date),
                descriptionRu: training.descriptionRu,
                descriptionEn: training.descriptionEn,
                descriptionAltRu: training.descriptionAltRu,
                descriptionAltEn: training.descriptionAltEn,
                workoutType: training.workoutType,
                coachingType: training.coachingType,
                distancePlanned: training.distancePlannedMeters,
                durationPlanned: training.durationPlanned,
                durationPlannedAlt: training.durationPlannedAlt,
                links: training.links
            }
        };

        try {
            const response = await axios.post<number>(`${apiUrl}/trainings`, request, this.config);
            console.log(response);
            const trainingId = response.data;
            return trainingId;
        } catch (error) {
            console.error(error);
            return 0;
        }
    }

    public async updateTraining(training: TrainingCore): Promise<TrainingUpdateResponse> {
        const request: any = {
            ...training,
            distancePlanned: training.distancePlannedMeters,
            date: formatDate(training.date)
        };
        const response = await axios.patch<any>(`${apiUrl}/trainings`, request, this.config);
        var result = mapToTrainingUpdateResponse(response.data);
        return result;
    }

    public async resetTrainingDescription(id: number, descType: TrainingDescriptionType): Promise<ResetTrainingDescriptionResponse> {
        const request: any = {
            id: id,
            descriptionType: descType
        };

        const response = await axios.post<ResetTrainingDescriptionResponse>(`${apiUrl}/trainings/reset-description`, request, this.config);
        return response.data;
    }

    public async deleteTraining(trainingId: number): Promise<void> {
        await axios.delete(`${apiUrl}/trainings/${trainingId}`, this.config);
    }

    public async uploadTrainingFiles(trainingId: number, files: File[]): Promise<void> {
        var fd = new FormData();
        for (var i = 0; i < files.length; i++) {
            fd.append(`files[${i}]`, files[i]);
        }
        await axios.post(`${apiUrl}/trainings/${trainingId}/files`, fd, this.configFormData);
    }

    public async getTrainingFiles(trainingId: number): Promise<TrainingFileInfo[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/trainings/${trainingId}/files`, this.config);
        const result = resp.data.map(mapToTrainingFileInfo);
        return result;
    }

    public async deleteTrainingFile(trainingFileId: number): Promise<TrainingFileInfo[]> {
        const resp = await axios.delete<any[]>(`${apiUrl}/trainings/files/${trainingFileId}`, this.config);
        const result = resp.data.map(mapToTrainingFileInfo);
        return result;
    }

    public async getTopActivities(): Promise<ActivityTopItem[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/activities/top`, this.config);
        const result = resp.data.map(mapToActivity);
        return result;
    }

    public async getUserProfile(userId: number): Promise<UserFullInfo> {
        const resp = await axios.get<any>(`${apiUrl}/users/${userId}`, this.config);
        const user = mapToUserFullInfo(resp.data);
        return user;
    }

    public async getTimezones(): Promise<TimeZoneType[]> {
        const resp = await axios.get<TimeZoneType[]>(`${apiUrl}/users/timezones`, this.config);
        const result = resp.data.map(mapToTimeZoneType);
        return result;
    }

    public async getBikeMasterUsers(): Promise<BikeMasterUserInfo[]> {
        const resp = await axios.get<BikeMasterUserInfo[]>(`${apiUrl}/users/bike-master-users`, this.config);
        return resp.data;
    }

    public async getProfileDefaultData(): Promise<ProfileDefaultData> {
        const resp = await axios.get<ProfileDefaultData>(`${apiUrl}/users/default-data`, this.config);
        return resp.data;
    }

    public async getWeekPlans(userId: number, dateFrom: Date, dateTo: Date): Promise<WeekPlanInfo[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/users/${userId}/week-plans?dateFrom=${formatDate(dateFrom)}&dateTo=${formatDate(dateTo)}`, this.config);
        const plans = resp.data.map(mapToWeekPlanInfo);
        return plans;
    }

    public async addWeekPlan(userId: number, weekPlan: WeekPlanInfo): Promise<WeekPlanInfo> {
        const request = {
            userId: userId,
            startAt: formatDate(weekPlan.startAt),
            planType: weekPlan.planType,
            weekType: weekPlan.weekType,
            weekSubType: weekPlan.weekSubType,
            coachingType: weekPlan.coachingType
        };

        try {
            const response = await axios.post<any>(`${apiUrl}/week-plans`, request, this.config);
            console.log(`New week plan created: ${response}`);
            return mapToWeekPlanInfo(response.data);
        }
        catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    public async updateWeekPlan(userId: number, weekPlan: WeekPlanInfo): Promise<WeekPlanInfo> {
        const request: any = {
            userId: userId,
            startAt: weekPlan.startAt,
            planType: weekPlan.planType,
            weekSubType: weekPlan.weekSubType,
            coachingType: weekPlan.coachingType,
            weekType: weekPlan.weekType
        };

        try {
            const response = await axios.put<any>(`${apiUrl}/week-plans`, request, this.config);
            console.log(`New week plan edited: ${response}`);
            const result = mapToWeekPlanInfo(response.data);
            return result;
        }
        catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    public async deleteWeekPlan(weekPlanId: number): Promise<void> {
        await axios.delete(`${apiUrl}/week-plans/${weekPlanId}`, this.config);
    }

    public async generateWeekPlan(weekPlanId: number): Promise<Boolean> {
        try {
            await axios.post(`${apiUrl}/week-plans/${weekPlanId}/generate`, null, this.config);
            return true;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }

    public async registerUserForTelegram(model: UserRegistrationRequest): Promise<UserRegistrationResponse> {
        const request = {
            email: model.email,
            weeklyRunVolumeKm: model.weeklyRunVolumeKm,
            longRunKm: model.longRunKm,
            vdot: model.vdot,
            firstName: model.firstName,
            lastName: model.lastName,
            age: model.age,
            gender: model.gender,
            goalRaceDistanceKm: model.goalRaceDistanceKm,
            goalRaceDate: formatDate(model.goalRaceDate),
            fitness: model.fitness,
            fasting: model.fasting,
            sendEmailInvite: model.sendEmailInvite
        };
        var response = await axios.post<any>(`${apiUrl}/bot`, request, this.config);
        const result = mapToUserRegistrationResponse(response.data);
        return result;
    }

    public async sendUserTelegramMessage(userId: number, message: string): Promise<void> {
        await axios.post(`${apiUrl}/bot/users/${userId}/messages`, { message: message }, this.config);
    }

    public async sendBroadcastTelegramMessage(message: string, language: string): Promise<void> {
        await axios.post(`${apiUrl}/bot/users/messages`, { message: message, language: language }, this.config);
    }

    public async sendTelegramMessageToUsers(message: string, userIds: number[]): Promise<void> {
        await axios.post(`${apiUrl}/bot/users/messages`, { message: message, userIds: userIds }, this.config);
    }

    public async broadcastMessage(message: string, language: string, channel: BroadcastChannel): Promise<void> {
        await axios.post(`${apiUrl}/broadcast`, { message: message, language: language, channel: channel }, this.config);
    }

    public async trainingPlanOverview(model: PlanOverviewInputDataRequest): Promise<PlanOverviewResponse> {
        var request: any = {
            coachingTypes: model.coachingTypes,
            startDate: formatDate(model.startDate),
            endDate: formatDate(model.endDate),
            language: model.language,
            goalDistanceKm: model.goalDistanceKm,
            params: model.params,
            useImperialSystem: model.useImperialSystem,
            paceHrPreferenceType: model.paceHrPreferenceType,
            runningData: model.coachingTypes.some(ct => runningCoachingTypes.includes(ct) || triathlonCoachingTypes.includes(ct)) ? {
                vdot: model.runningData?.vdot,
                currentLongRunMeters: model.runningData?.currentLongRunKm ? model.runningData?.currentLongRunKm * 1000 : undefined, //km => meters
                currentRunWeekVolumeMeters: model.runningData?.currentRunWeekVolumeKm ? model.runningData?.currentRunWeekVolumeKm * 1000 : undefined, //km => meters
                maxHeartRate: model.runningData?.maxHeartRate,
                qualityWorkoutsNumber: model.runningData?.qualityWorkoutsNumber,
                weekAvailability: {
                    availabilityDays: model.runningData?.weekAvailability.availabilityDays,
                    longWorkoutDay: model.runningData?.weekAvailability.longWorkoutDay,
                    strictNumberOfDays: model.runningData?.weekAvailability.strictNumberOfDays
                }
            } : undefined,
            bikeData: model.coachingTypes.some(ct => cyclingCoachingTypes.includes(ct) || triathlonCoachingTypes.includes(ct)) ? {
                ftp: model.bikeData?.ftp,
                bikeMaxHeartRate: model.bikeData?.bikeMaxHeartRate,
                baseRideIndoorMinutes: model.bikeData?.baseRideIndoorMinutes,
                baseRideOutdoorMinutes: model.bikeData?.baseRideOutdoorMinutes,
                longRideOutdoorMinutes: model.bikeData?.longRideOutdoorMinutes,
                weekAvailability: {
                    availabilityDays: model.bikeData?.weekAvailability.availabilityDays,
                    longWorkoutDay: model.bikeData?.weekAvailability.longWorkoutDay,
                    strictNumberOfDays: model.bikeData?.weekAvailability.strictNumberOfDays
                }
            } : undefined,
            swimmingData: model.coachingTypes.some(ct => ct === CoachingType.Swimming || triathlonCoachingTypes.includes(ct)) ? {
                weekAvailability: {
                    availabilityDays: model.swimmingData?.weekAvailability.availabilityDays,
                    longWorkoutDay: model.swimmingData?.weekAvailability.longWorkoutDay,
                    strictNumberOfDays: model.swimmingData?.weekAvailability.strictNumberOfDays
                },
                swimAverageWorkoutMeters: model.swimmingData?.swimAverageWorkoutMeters
            } : undefined,
            triathlonData: model.coachingTypes.some(ct => triathlonCoachingTypes.includes(ct)) ? {
                twoWorkoutsPerDay: model.triathlonData?.twoWorkoutsPerDay
            } : undefined
        }
        var response = await axios.post<any>(`${apiUrl}/training-plan/overview`, request, this.config);
        const result = mapToPlanOverviewResponse(response.data);
        return result;
    }

    /** Get training export status */
    public async getTrainingExportStatus(trainingId: number): Promise<TrainingExportStatus> {
        const resp = await axios.get<any>(`${apiUrl}/trainings/${trainingId}/export-status`, this.config);
        const result = mapToTrainingExportStatus(resp.data);
        return result;
    }

    public async getSystemStats(): Promise<StatisticsResponse> {
        const resp = await axios.get<any>(`${apiUrl}/statistics`, this.config);
        const result = mapToStatisticsResponse(resp.data);
        return result;
    }

    public async getSystemStatsDetails(date: Date): Promise<StatisticsDetailsResponse> {
        const response = await axios.get<StatisticsDetailsResponse>(`${apiUrl}/statistics/details?date=${formatDate(date)}`, this.config);
        return response.data;
    }

    /** Donwload bot stats reports as csv file */
    public async getUsersStatsCsv(): Promise<any> {
        const resp = await axios.get(`${apiUrl}/statistics/users/csv`, { ...this.config, responseType: 'blob' });
        FileSaver.saveAs(resp.data, "bot-stats-report.csv");
        return resp.data;
    }

    public async getSubscriptionPlansActive(): Promise<SubscriptionPlanInfo[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/subscriptions/active`, this.config);
        const result = resp.data.map(mapToSubscriptionPlanInfo);
        return result;
    }

    public async changeUserSubscription(userId: number, planId: number, period: SubscriptionPeriods | undefined, expirationDate: Date | undefined): Promise<void> {
        const request: any = {
            subscriptionPlanId: planId,
            subscriptionPeriod: period,
            expirationDate: formatDate(expirationDate)
        };
        await axios.post(`${apiUrl}/users/${userId}/subscription`, request, this.config);
    }

    public async removeUserSubscription(userId: number): Promise<void> {
        await axios.delete(`${apiUrl}/users/${userId}/subscription`, this.config);
    }

    public async getUserRaceResult(userId: number): Promise<RaceResult[]> {
        const resp = await axios.get<any>(`${apiUrl}/users/${userId}/race-results`, this.config);
        const result = resp.data?.results.map(mapToRaceResult) || [];
        return result;
    }

    public async getUserRaces(userId: number): Promise<Race[]> {
        const resp = await axios.get<any>(`${apiUrl}/users/${userId}/races`, this.config);
        const result = resp.data?.map(mapToRace) || [];
        return result;
    }

    public async deleteRace(raceId: number, userId: number): Promise<void> {
        await axios.delete<any>(`${apiUrl}/users/${userId}/races/${raceId}`, this.config);
    }

    public async getBroadcastingMessage(): Promise<BroadcastingMessage[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/broadcast`, this.config);
        const result = resp.data.map(mapToBroadcastingMessage);
        return result;
    }

    public async optInUsers(): Promise<void> {
        await axios.post(`${apiUrl}/users/opt-in`, null, this.config);
    }

    public async getAthleteParametersHistory(userId: number): Promise<AthleteParametersHistoryResponse[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/users/${userId}/athlete-params/logs`, this.config);
        const result = resp.data.map(mapToAthleteParametersHistoryResponse);
        return result;
    }

    public async getAllSurveys(): Promise<Survey[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/surveys`, this.config);
        const result = resp.data.map(mapToSurvey);
        return result;
    }

    public async getSurvey(id: number): Promise<Survey> {
        const resp = await axios.get<any>(`${apiUrl}/surveys/${id}`, this.config);
        const result = mapToSurvey(resp.data);
        return result;
    }

    public async getSurveyFilterData(): Promise<SurveyFilterData> {
        const resp = await axios.get<SurveyFilterData>(`${apiUrl}/surveys/survey-filter-data`, this.config);
        return resp.data;
    }

    public async saveSurvey(survey: Survey): Promise<Survey> {
        var resp = await axios.post<any>(`${apiUrl}/surveys`, survey, this.config);
        const result = mapToSurvey(resp.data);
        return result;
    }

    public async copySurvey(surveyId: number): Promise<number> {
        var resp = await axios.post<number>(`${apiUrl}/surveys/${surveyId}/copy`, null, this.config);
        return resp.data;
    }

    public async deleteSurvey(surveyId: number): Promise<void> {
        await axios.delete(`${apiUrl}/surveys/${surveyId}`, this.config);
    }

    public async importSurvey(survey: Survey): Promise<number> {
        var resp = await axios.post<number>(`${apiUrl}/surveys/import`, survey, this.config);
        return resp.data;
    }

    public async getSurveyObligatoryQuestionIds(surveyType: number): Promise<string[]> {
        const resp = await axios.get<string[]>(`${apiUrl}/surveys/valid-question-ids/${surveyType}`, this.config);
        return resp.data;
    }

    public async importSurveysFromStage(isPreview: boolean): Promise<string[]> {
        var resp = await axios.post<string[]>(`${apiUrl}/surveys/import-stage?isPreview=${isPreview}`, null, this.config);
        return resp.data;
    }

    public async GetUserCoachingCards(userId: number): Promise<CoachingCardResponse[]> {
        var resp = await axios.get<CoachingCardResponse[]>(`${apiUrl}/users/${userId}/coaching-cards`, this.config);
        return resp.data;
    }

    public async DeleteUserCoachingCard(userId: number, coachingCardId: number): Promise<void> {
        await axios.delete<void>(`${apiUrl}/users/${userId}/coaching-cards/${coachingCardId}`, this.config);
    }

    public async getUserTelegramId(userId: number): Promise<number | undefined> {
        const response = await axios.get<number | undefined>(`${apiUrl}/users/${userId}/telegramid`, this.config);
        return response.data;
    }

    public async getAutoMessageList(filter: AutoMessageFilter): Promise<AutoMessageListResponse> {
        const response = await axios.get<any>(`${apiUrl}/auto-messages`,
            {
                ...this.config,
                params: filter,
                paramsSerializer: params => qs.stringify(params)
            });
        const result = mapToAutoMessageListResponse(response.data);
        return result;
    }

    public async getAutoMessage(messageId: number): Promise<AutoMessage> {
        const response = await axios.get<any>(`${apiUrl}/auto-messages/${messageId}`, this.config);
        const result = mapToAutoMessage(response.data);
        return result;
    }

    public async getAutoMessageSenders(): Promise<AutoMessageSenderUser[]> {
        const result = await axios.get<AutoMessageSenderUser[]>(`${apiUrl}/auto-messages/sender-users`, this.config);
        return result.data;
    }

    public async saveAutoMessage(request: SaveAutoMessageRequest): Promise<AutoMessage> {
        var resp = await axios.post<any>(`${apiUrl}/auto-messages/`, request, this.config);
        const result = mapToAutoMessage(resp.data);
        return result;
    }


    public async deleteAutoMessage(messageId: number): Promise<void> {
        await axios.delete<void>(`${apiUrl}/auto-messages/${messageId}`, this.config);
    }

    public async getBlogList(filter: BlogFilter): Promise<BlogItemList> {
        const response = await axios.get<any>(`${apiUrl}/blogs`,
            {
                ...this.config,
                params: filter,
                paramsSerializer: params => qs.stringify(params)
            });
        const result = mapToBlogItemListResponse(response.data);
        return result;
    }

    public async getBlogItem(id: number): Promise<BlogItem> {
        const response = await axios.get<any>(`${apiUrl}/blogs/${id}`, this.config);
        const result = mapToBlogItemResponse(response.data);
        return result;
    }

    public async saveBlogItem(id: number | undefined, request: BlogSaveRequest): Promise<BlogItem> {
        var resp = id && id > 0
            ? await axios.put<any>(`${apiUrl}/blogs/${id}`, request, this.config)       //update
            : await axios.post<any>(`${apiUrl}/blogs`, request, this.config);           //create
        const result = mapToBlogItemResponse(resp.data);
        return result;
    }

    public async deleteBlogItem(id: number): Promise<void> {
        await axios.delete<void>(`${apiUrl}/blogs/${id}`, this.config);
    }

    public async getUserChartsAdminData(userId: number, from?: Date, to?: Date): Promise<UserChartsAdminDataItem[]> {
        const response = await axios.get<any>(`${apiUrl}/users/${userId}/charts-data?from=${from ? formatDate(from) : ''}&to=${to ? formatDate(to) : ''}`, this.config);
        const result = response.data.map(mapToUserChartsAdminDataItem);
        return result;
    }

    public async getPromoOfferHistory(): Promise<PromoOfferHistory[]> {
        const response = await axios.get<PromoOfferHistory[]>(`${apiUrl}/promo-offers/history`, this.config);
        const result = response.data.map(mapToPromoOfferHistory);
        return result;
    }
}