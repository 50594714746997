import { AutoMessageEvent } from "../shared/Primitives";

export const autoMessageEventTypeValues: AutoMessageEventTypeItem[] = [
    {
        value: AutoMessageEvent.CreateUser,
        label: 'Create User',
    },
    {
        value: AutoMessageEvent.BotRunPaywall,
        label: 'Bot Run Paywall'
    },
    {
        value: AutoMessageEvent.BotBikePaywall,
        label: 'Bot Bike Paywall'
    }
]

export interface AutoMessageEventTypeItem {
    value: AutoMessageEvent;
    label: string;
}