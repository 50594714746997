import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { useAuth0 } from "./../../../react-auth0-spa";
import { UsersDayStats, StatisticsDetailsResponse } from '../../../api/types';

type DashboardRowProps = {
    dayStats: UsersDayStats,
    rowIndex: number
};

const DashboardRowRow = (props: DashboardRowProps) => {
    const { accessToken } = useAuth0();
    const basecamp = new BaseCampClient(accessToken);

    const [statDetails, setstatDetails] = useState<StatisticsDetailsResponse | undefined>(undefined);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleExpand = () => {
        let toggledValue = !isExpanded;
        setIsExpanded(toggledValue);

        if (!toggledValue) {
            setstatDetails(undefined);
        }
        else {
            getStatDetails();
        }
    }

    const getStatDetails = () => {
        setIsLoading(true);
        basecamp.getSystemStatsDetails(props.dayStats.date)
            .then(r => {
                setstatDetails(r);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <React.Fragment key={props.dayStats.date.toString()}>
            <tr onClick={toggleExpand} style={{cursor: "pointer"}}>
                <td>
                    {
                        isExpanded
                            ? <Icon.ChevronDown className="nav__icon" />
                            : <Icon.ChevronRight className="nav__icon" />
                    }

                </td>
                <td className={"date" + (props.dayStats.date.getDay() === 0 || props.dayStats.date.getDay() === 6 ? " weekend" : "")}>
                    {props.dayStats.date.toString().substring(0, 10)}
                </td>
                <td>{props.dayStats.botNewUserCount > 0 ? "🤖 " + props.dayStats.botNewUserCount : null}</td>
                <td>{props.dayStats.webNewUserCount > 0 ? "🖥️ " + props.dayStats.webNewUserCount : null}</td>
                <td>{props.dayStats.mobileNewUserCount > 0 ? "📱 " + props.dayStats.mobileNewUserCount : null}</td>
                <td>{props.dayStats.userMessageCount > 0 ? "💬 " + props.dayStats.userMessageCount : null}</td>
                <td>{props.dayStats.runCommandCount > 0 ? "👟 " + props.dayStats.runCommandCount : null}</td>
                <td>{props.dayStats.surveyCommandCount > 0 ? "🧭 " + props.dayStats.surveyCommandCount : null}</td>
                <td>{props.dayStats.botNewPlanCount > 0 ? "🤖 " + props.dayStats.botNewPlanCount : null}</td>
                <td>{props.dayStats.webNewPlanCount > 0 ? "🖥️ " + props.dayStats.webNewPlanCount : null}</td>
                <td>{props.dayStats.mobileNewPlanCount > 0 ? "📱 " + props.dayStats.mobileNewPlanCount : null}</td>
                <td>{props.dayStats.weekFeedbackCount > 0 ? "🌡 " + props.dayStats.weekFeedbackCount : null}</td>
                <td>{props.dayStats.newStravaUserCount > 0 ? "⚙️ " + props.dayStats.newStravaUserCount : null}</td>
                <td>{props.dayStats.oneMonthPaymentCount > 0 ? "💰 " + props.dayStats.oneMonthPaymentCount : null}</td>
                <td>{props.dayStats.quarterlyPaymentCount > 0 ? "💰 " + props.dayStats.quarterlyPaymentCount : null}</td>
                <td>{props.dayStats.semiannuallyPaymentCount > 0 ? "💰 " + props.dayStats.semiannuallyPaymentCount : null}</td>
                <td>{props.dayStats.annuallyPaymentCount > 0 ? "💰 " + props.dayStats.annuallyPaymentCount : null}</td>
            </tr>
            {
                isExpanded ?
                    <tr className="expanded-row">
                        {
                            isLoading
                                ?
                                <td colSpan={17}>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                </td>
                                :
                                <React.Fragment>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        {
                                            statDetails?.botNewUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.webNewUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.mobileNewUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.userMessageUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.runCommandUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.surveyCommandUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.botNewPlanUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.webNewPlanUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.mobileNewPlanUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.weekFeedbackUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.newStravaUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.oneMonthPaymentUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.quarterlyPaymentUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.semiannuallyPaymentUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                    <td>
                                        {
                                            statDetails?.annuallyPaymentUsers?.map((x, i) => {
                                                return (<div key={i}><a target="_blank" href={x.profileUrl}>{x.email}</a></div>)
                                            })
                                        }
                                    </td>
                                </React.Fragment>
                        }
                    </tr>
                    : null
            }
        </React.Fragment>
    );
}

export default DashboardRowRow;