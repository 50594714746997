import axios, { AxiosRequestConfig } from 'axios';

import config from "../config"

import { PlanOverviewWeek, TrainingDay } from './types';
import { PlanType } from '../shared/Primitives';

const apiUrl = config.plannerHost + "/api/v1";

export class PlannerClient {
    config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json' } };

    public async generatePlanOverview(planType: PlanType, level: number, vdot: number, weeksCount: number, longRunMeters: number): Promise<PlanOverviewWeek[]> {
        const req = {
            weeksCount: weeksCount,
            planType: planType,
            athleteData: {
                level: level,
                vdot: vdot,
                currentLongRunMeters: longRunMeters,
            }
        };

        try {
            const resp = await axios.post<any>(`${apiUrl}/plan-overview`, req);
            if (!resp.data.weeks) {
                console.warn('No weeks property');
                return [];
            }

            const weeks = resp.data.weeks.map(function (w: any) {
                return {
                    number: w.number,
                    subTypeString: w.subTypeString,
                    trainingDays: w.trainingDays.map(function (day: any) {
                        return {
                            dayOfWeek: day.dayOfWeek,
                            trainingType: day.trainingType,
                            description: day.description,
                            totalDistance: day.totalDistanceMeters
                        } as TrainingDay;
                    })
                } as PlanOverviewWeek;
            });

            return weeks;
        }
        catch (err) {
            console.error(err);
            return [];
        }
    }
}
