import React, { useState, useRef, MutableRefObject } from 'react';
import useForm from 'react-hook-form';

import DatePicker from "react-datepicker";
import Select from 'react-select';

import { useAuth0 } from '../../react-auth0-spa';
import { BaseCampClient } from '../../api/BaseCampClient';
import { coachingTypeValues } from "../../fixtures/coaching-types";
import { CoachingType, DayOfWeak, PaceHrPreferenceType } from '../../shared/Primitives';
import { PlanOverviewInputDataRequest, PlanOverviewWeekResponse, PlanOverviewWeekAvailabilityRequest } from "../../api/types";
import { daysOfWeakValues, dayOfWeakValues } from "../../fixtures/days-of-weak";
import PlanNewOverview from "./PlanNewOverview";
import Loader from '../Loader';
import './PlanNewOverview.scss';
import { cyclingCoachingTypes, runningCoachingTypes, triathlonCoachingTypes } from '../../shared/coaching-type-groups';

enum OptionalParameterMode {
    Auto = 1,
    Manual = 2
}

const PlansNewView = () => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
    const { handleSubmit, register, errors } = useForm();

    const defaultDaysOfWeak = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
    }

    type OptionType = { label: string, value: string }
    const languages = [
        { label: "ru", value: "ru" },
        { label: "en", value: "en" }
    ] as OptionType[];

    const descPreferences = [
        { label: "Mix", value: PaceHrPreferenceType.Mix },
        { label: "Pace", value: PaceHrPreferenceType.Pace },
        { label: "Hr", value: PaceHrPreferenceType.Hr }
    ] as OptionType[];

    const defaultRequest: PlanOverviewInputDataRequest = {
        coachingTypes: [CoachingType.RunningFitness],
        language: "ru",
        startDate: new Date(new Date().toDateString()),
        endDate: undefined,
        goalDistanceKm: undefined,
        params: undefined,
        useImperialSystem: false,
        paceHrPreferenceType: PaceHrPreferenceType.Mix,
        runningData: {
            weekAvailability: {
                availabilityDays: defaultDaysOfWeak,
                longWorkoutDay: DayOfWeak.Sunday,
                strictNumberOfDays: undefined,
            },
            currentLongRunKm: undefined,
            currentRunWeekVolumeKm: undefined,
            maxHeartRate: undefined,
            qualityWorkoutsNumber: undefined,
            vdot: undefined
        },
        bikeData: {
            weekAvailability: {
                availabilityDays: defaultDaysOfWeak,
                longWorkoutDay: DayOfWeak.Sunday,
                strictNumberOfDays: undefined
            },
            baseRideIndoorMinutes: undefined,
            baseRideOutdoorMinutes: undefined,
            bikeMaxHeartRate: undefined,
            ftp: undefined,
            longRideOutdoorMinutes: undefined
        },
        swimmingData: {
            weekAvailability: {
                availabilityDays: defaultDaysOfWeak,
                longWorkoutDay: undefined,
                strictNumberOfDays: undefined
            },
            swimAverageWorkoutMeters: undefined
        },
        triathlonData: {
            twoWorkoutsPerDay: defaultDaysOfWeak
        }
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [request, setRequest] = useState({ ...defaultRequest });
    const [weeks, setWeeks] = useState([] as PlanOverviewWeekResponse[]);

    const [params, setParams] = useState<string>("");
    const [paramsError, setparamsError] = useState<boolean>(false);

    const [longRunMode, setLongRunMode] = useState(OptionalParameterMode.Auto);
    const [runStrictNumberDaysMode, setRunStrictNumberDaysMode] = useState(OptionalParameterMode.Auto);

    const weekRef = useRef() as MutableRefObject<HTMLDivElement>;

    const generatePlans = () => {
        if ((request.coachingTypes.includes(CoachingType.CyclingRace) || request.coachingTypes.includes(CoachingType.RunningRace)) && !request.endDate) {
            alert("You have to specify End Date for Race Coaching type!");
            return;
        }
        if (request.coachingTypes.length && !paramsError) {
            setLoading(true);
            setError("");
            baseCamp.trainingPlanOverview(request)
                .then(data => {
                    setWeeks(data.weeks);
                    setTimeout(() => {
                        window.scrollTo(0, weekRef.current.offsetTop - 30);
                    });
                })
                .catch(e => {
                    console.error(e);
                    setError(e.response ? e.response.data : `Error: ${JSON.stringify(e)}`);
                })
                .finally(() => setLoading(false));
        }
    }

    const onParamsChange = (value: string) => {
        setParams(value);

        setparamsError(false);
        let lines = value.split("\n");
        const newParams = {} as Record<string, string>;
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].length === 0) continue;

            let data = lines[i].split(":");
            if (data.length !== 2) {
                setparamsError(true);
                return;
            }

            newParams[data[0].trim()] = data[1].trim();
        }
        setRequest({ ...request, params: (newParams && Object.keys(newParams).length > 0 ? newParams : undefined) });
    }

    const isRunningRace = request.coachingTypes.some(ct => ct === CoachingType.RunningRace);

    return (
        <React.Fragment>
            {
                loading ? <Loader /> : ""
            }
            <div className={`form-group row pb-4 ${!error.length ? "d-none" : ""}`}>
                <div className="col-sm-12">
                    <div className="alert alert-danger" role="alert">{error}</div>
                </div>
            </div>
            <div className={`w-50 ${loading ? "d-none" : ""} plans-new-view`}>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Coaching type</label>
                    <div className="col-sm-9">
                        <div className="btn-group" role="group" aria-label="Coaching type">
                            {coachingTypeValues.map(ct => {
                                const isActive = request.coachingTypes.some(t => t === ct.value);
                                return (
                                    <button key={ct.name} type="button" className={isActive ? 'btn btn-dark' : 'btn btn-light'}
                                        onClick={() => {
                                            // let newCoachingTypes = request.coachingTypes;
                                            // if (isActive) {
                                            //     newCoachingTypes.splice(newCoachingTypes.indexOf(ct.value), 1);
                                            // }
                                            // else {
                                            //     newCoachingTypes.push(ct.value);
                                            // }
                                            setRequest({ ...request, coachingTypes: [ct.value] });
                                        }}
                                        disabled={[CoachingType.Swimming].some(t => t === ct.value)}
                                    >
                                        {ct.name}
                                    </button>
                                );
                            })}
                        </div>
                        {
                            !request.coachingTypes.length ? <div className="text-danger">Coaching type is required</div> : ""
                        }
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Start date</label>
                    <DatePicker
                        className={"form-control"}
                        selected={request.startDate}
                        onChange={(e) => setRequest({
                            ...request,
                            startDate: e ?? new Date(new Date().toDateString())
                        })}
                        dateFormat="yyyy-MM-dd" />
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">End date</label>
                    <DatePicker
                        className={"form-control"}
                        selected={request.endDate}
                        onChange={(e) => setRequest({
                            ...request,
                            endDate: e ?? undefined
                        })}
                        dateFormat="yyyy-MM-dd" />
                </div>
                {
                    isRunningRace ?
                        <div className="form-group row">
                            <label htmlFor="inputGoalDistanceKm" className="col-sm-3 col-form-label">Goal distance</label>
                            <div>
                                <div className="input-group">
                                    <input min={0}
                                        style={{ width: 152 }}
                                        type="number"
                                        className="form-control"
                                        id="inputGoalDistanceKm"
                                        name="goalDistanceKm"
                                        placeholder="Required"
                                        defaultValue={request.goalDistanceKm || ""}
                                        onChange={(event) => setRequest({
                                            ...request,
                                            goalDistanceKm: parseInt(event.target.value)
                                        })}
                                        ref={register({ required: true })}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">km</span>
                                    </div>
                                    {errors.goalDistanceKm && <span className="text-danger" style={{ marginLeft: 20 }}>Goal Distance is required</span>}
                                </div>
                            </div>
                        </div>
                        : null
                }
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Language</label>
                    <div style={{ "width": "200px" }}>
                        <Select id="inputLanguage"
                            options={languages}
                            value={languages.find(l => l.value === request.language)}
                            getOptionValue={v => v.value}
                            onChange={value => setRequest({ ...request, language: (value as OptionType).value })}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Params</label>
                    <div>
                        <textarea style={{ "width": "300px" }}
                            className="form-control"
                            id="params"
                            rows={1}
                            value={params}
                            onChange={e => {
                                onParamsChange(e.target.value);
                            }}
                        ></textarea>
                        <small style={{ "display": "block", "color": "grey" }}>Key:Value</small>
                        <small style={{ "display": "block", "color": "grey" }}>Each parameter in new line.</small>
                        {paramsError && <div className="text-danger">Wrong params format</div>}
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="cbUseImperialSystem" className="col-sm-3 col-form-label">Use Imperial System</label>
                    <div>
                        <input type="checkbox"
                            id="cbUseImperialSystem"
                            checked={request.useImperialSystem}
                            onChange={e => setRequest({ ...request, useImperialSystem: e.target.checked })}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Description preference</label>
                    <div style={{ "width": "200px" }}>
                        <Select id="inputDescPreference"
                            options={descPreferences}
                            value={descPreferences.find(l => l.value === request.paceHrPreferenceType)}
                            getOptionValue={v => v.value}
                            onChange={value => setRequest({ ...request, paceHrPreferenceType: (value as OptionType).value as PaceHrPreferenceType})}
                        />
                    </div>
                </div>

                {
                    request.coachingTypes.some(ct => runningCoachingTypes.includes(ct) || triathlonCoachingTypes.includes(ct)) ?
                        <React.Fragment>
                            <div className="form-group row pt-3">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-9">
                                    <h4>Run</h4>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Week availability</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Week availability">
                                        {daysOfWeakValues.map(d =>
                                            <button key={d.value} type="button"
                                                className={(d.func(request.runningData?.weekAvailability.availabilityDays) ? 'btn btn-dark' : 'btn btn-light')}
                                                onClick={(event) => setRequest({
                                                    ...request,
                                                    runningData: {
                                                        ...request.runningData!,
                                                        weekAvailability: {
                                                            ...request.runningData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                            availabilityDays: d.changeTrainingDays(request.runningData!.weekAvailability.availabilityDays)
                                                        }
                                                    }
                                                })}
                                            >{d.name}</button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Long run day</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Long run">
                                        {dayOfWeakValues.map(d =>
                                            <button key={d.value} type="button"
                                                className={d.value === request.runningData?.weekAvailability.longWorkoutDay ? 'btn btn-dark' : 'btn btn-light'}
                                                onClick={() => setRequest({
                                                    ...request,
                                                    runningData: {
                                                        ...request.runningData!,
                                                        weekAvailability: {
                                                            ...request.runningData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                            longWorkoutDay: d.value
                                                        }
                                                    }
                                                })}>{d.name}</button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputStrictNumberOfDays" className="col-sm-3 col-form-label">Strict Number Of Days</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Strict number of days">
                                        <button type="button" className={runStrictNumberDaysMode === OptionalParameterMode.Auto ? 'btn btn-dark' : 'btn btn-light'}
                                            onClick={(e) => {
                                                if (runStrictNumberDaysMode !== OptionalParameterMode.Auto) {
                                                    setRunStrictNumberDaysMode(OptionalParameterMode.Auto);
                                                    setRequest({
                                                        ...request,
                                                        runningData: {
                                                            ...request.runningData!,
                                                            weekAvailability: {
                                                                ...request.runningData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                                strictNumberOfDays: undefined
                                                            }
                                                        }
                                                    })
                                                }
                                            }}>Auto</button>
                                        <button type="button" className={runStrictNumberDaysMode === OptionalParameterMode.Manual ? 'btn btn-dark' : 'btn btn-light'}
                                            onClick={(e) => {
                                                if (runStrictNumberDaysMode !== OptionalParameterMode.Manual) {
                                                    setRunStrictNumberDaysMode(OptionalParameterMode.Manual);
                                                }
                                            }}>Manual</button>

                                        {
                                            runStrictNumberDaysMode === OptionalParameterMode.Manual ?
                                                <div style={{ "marginLeft": "20px" }}>
                                                    <input min={1}
                                                        type="number"
                                                        className="form-control"
                                                        id="inputStrictNumberOfDays"
                                                        name="runningStrictNumberOfDays"
                                                        defaultValue={request.runningData?.weekAvailability.strictNumberOfDays || ""}
                                                        placeholder="Required"
                                                        onChange={(event) => setRequest({
                                                            ...request,
                                                            runningData: {
                                                                ...request.runningData!,
                                                                weekAvailability: {
                                                                    ...request.runningData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                                    strictNumberOfDays: parseInt(event.target.value)
                                                                }
                                                            }
                                                        })}
                                                        ref={register({ required: true })}
                                                    />
                                                </div>
                                                : null
                                        }
                                    </div>
                                    {
                                        runStrictNumberDaysMode === OptionalParameterMode.Manual ?
                                            <div>{errors.runningStrictNumberOfDays && <span className="text-danger">Strict number of days is required</span>}</div>
                                            : null
                                    }
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputVdot" className="col-sm-3 col-form-label">VDot</label>
                                <div className="col-sm-4">
                                    <input min={0}
                                        type="number"
                                        className="form-control"
                                        id="inputVdot"
                                        name="vdot"
                                        placeholder="Required"
                                        defaultValue={request.runningData?.vdot || ""}
                                        onChange={(event) => setRequest({
                                            ...request,
                                            runningData: {
                                                ...request.runningData!,
                                                vdot: parseFloat(event.target.value)
                                            }
                                        })}
                                        ref={register({ required: true })}
                                    />
                                    {errors.vdot && <span className="text-danger">VDot is required</span>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputRunWeekVolume" className="col-sm-3 col-form-label">Run week volume</label>
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <input min={0}
                                            type="number"
                                            className="form-control"
                                            id="inputRunWeekVolume"
                                            name="inputRunWeekVolume"
                                            placeholder="Required"
                                            defaultValue={request.runningData?.currentRunWeekVolumeKm || ""}
                                            onChange={(event) => setRequest({
                                                ...request,
                                                runningData: {
                                                    ...request.runningData!,
                                                    currentRunWeekVolumeKm: parseFloat(event.target.value)
                                                }
                                            })}
                                            ref={register({ required: true })}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">kilometers</span>
                                        </div>
                                    </div>
                                    {errors.inputRunWeekVolume && <span className="text-danger">Run week volume is required</span>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputLogRun" className="col-sm-3 col-form-label">Long run</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Long run">
                                        <button type="button" className={longRunMode === OptionalParameterMode.Auto ? 'btn btn-dark' : 'btn btn-light'}
                                            onClick={(e) => {
                                                if (longRunMode !== OptionalParameterMode.Auto) {
                                                    setLongRunMode(OptionalParameterMode.Auto);
                                                    setRequest({
                                                        ...request,
                                                        runningData: {
                                                            ...request.runningData!,
                                                            currentLongRunKm: undefined
                                                        }
                                                    })
                                                }
                                            }}>Auto</button>
                                        <button type="button" className={longRunMode === OptionalParameterMode.Manual ? 'btn btn-dark' : 'btn btn-light'}
                                            onClick={(e) => {
                                                if (longRunMode !== OptionalParameterMode.Manual) {
                                                    setLongRunMode(OptionalParameterMode.Manual);
                                                }
                                            }}>Manual</button>
                                        {
                                            longRunMode === OptionalParameterMode.Manual ?
                                                <React.Fragment>
                                                    <div className="input-group" style={{ "marginLeft": "20px" }}>
                                                        <input min={0}
                                                            type="number"
                                                            className="form-control"
                                                            id="inputLongRun"
                                                            name="inputLongRun"
                                                            placeholder="Required"
                                                            onChange={(event) => setRequest({
                                                                ...request,
                                                                runningData: {
                                                                    ...request.runningData!,
                                                                    currentLongRunKm: parseFloat(event.target.value)
                                                                }
                                                            })}
                                                            ref={register({ required: true })}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">kilometers</span>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                : null
                                        }
                                    </div>

                                    {
                                        longRunMode === OptionalParameterMode.Manual ?
                                            <div>{errors.inputLongRun && <span className="text-danger">Long run is required</span>}</div>
                                            : null
                                    }

                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputMaxHeartRate" className="col-sm-3 col-form-label">Max heart rate</label>
                                <div className="col-sm-4">
                                    <input min={0}
                                        type="number"
                                        className="form-control"
                                        id="inputMaxHeartRate"
                                        name="runningMaxHeartRate"
                                        placeholder=""
                                        defaultValue={request.runningData?.maxHeartRate || ""}
                                        onChange={(event) => setRequest({
                                            ...request,
                                            runningData: {
                                                ...request.runningData!,
                                                maxHeartRate: parseInt(event.target.value)
                                            }
                                        })}
                                    />
                                    {errors.runningMaxHeartRate && <span className="text-danger">Max heart rate is required</span>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputQualityDays" className="col-sm-3 col-form-label">Quality Workouts Number</label>
                                <div className="col-sm-4">
                                    <input min={0}
                                        type="number"
                                        className="form-control"
                                        id="inputQualityDays"
                                        name="qualityDays"
                                        placeholder=""
                                        defaultValue={request.runningData?.qualityWorkoutsNumber || ""}
                                        onChange={(event) => setRequest({
                                            ...request,
                                            runningData: {
                                                ...request.runningData!,
                                                qualityWorkoutsNumber: parseInt(event.target.value)
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                        : ""
                }

                {
                    request.coachingTypes.some(ct => cyclingCoachingTypes.includes(ct) || triathlonCoachingTypes.includes(ct)) ?
                        <React.Fragment>
                            <div className="form-group row pt-3">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-9">
                                    <h4>Bike</h4>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Week availability</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Week availability">
                                        {daysOfWeakValues.map(d =>
                                            <button key={d.value} type="button"
                                                className={(d.func(request.bikeData?.weekAvailability.availabilityDays) ? 'btn btn-dark' : 'btn btn-light')}
                                                onClick={(event) => setRequest({
                                                    ...request,
                                                    bikeData: {
                                                        ...request.bikeData!,
                                                        weekAvailability: {
                                                            ...request.bikeData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                            availabilityDays: d.changeTrainingDays(request.bikeData!.weekAvailability.availabilityDays)
                                                        }
                                                    }
                                                })}
                                            >{d.name}</button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Long ride day</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Long bike">
                                        {dayOfWeakValues.map(d =>
                                            <button key={d.value} type="button"
                                                className={d.value === request.bikeData?.weekAvailability.longWorkoutDay ? 'btn btn-dark' : 'btn btn-light'}
                                                onClick={() => setRequest({
                                                    ...request,
                                                    bikeData: {
                                                        ...request.bikeData!,
                                                        weekAvailability: {
                                                            ...request.bikeData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                            longWorkoutDay: d.value
                                                        }
                                                    }
                                                })}>{d.name}</button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputBikeStrictNumberOfDays" className="col-sm-3 col-form-label">Strict number of days</label>
                                <div className="col-sm-4">
                                    <input type="number"
                                        id="inputBikeStrictNumberOfDays"
                                        name="inputBikeStrictNumberOfDays"
                                        defaultValue={request.bikeData?.weekAvailability?.strictNumberOfDays || ""}
                                        className="form-control"
                                        placeholder="Required"
                                        onChange={(event) => setRequest({
                                            ...request,
                                            bikeData: {
                                                ...request.bikeData!,
                                                weekAvailability: {
                                                    ...request.bikeData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                    strictNumberOfDays: parseInt(event.target.value)
                                                }
                                            }
                                        })}
                                        ref={register({ required: true })}
                                    />
                                    {errors.inputBikeStrictNumberOfDays && <span className="text-danger">Strict number of days is required</span>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputFtp" className="col-sm-3 col-form-label">Ftp</label>
                                <div className="col-sm-4">
                                    <input type="number"
                                        id="inputFtp"
                                        name="inputFtp"
                                        defaultValue={request.bikeData?.ftp || ""}
                                        className="form-control"
                                        placeholder="Required"
                                        onChange={(event) => setRequest({
                                            ...request,
                                            bikeData: {
                                                ...request.bikeData!,
                                                ftp: parseInt(event.target.value)
                                            }
                                        })}
                                        ref={register({ required: true })}
                                    />
                                    {errors.inputFtp && <span className="text-danger">Ftp is required</span>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputBikeMaxHeartRate" className="col-sm-3 col-form-label">Max heart rate</label>
                                <div className="col-sm-4">
                                    <input type="number" className="form-control"
                                        id="inputBikeMaxHeartRate"
                                        value={request.bikeData?.bikeMaxHeartRate || ""}
                                        onChange={(event) => setRequest({
                                            ...request,
                                            bikeData: {
                                                ...request.bikeData!,
                                                bikeMaxHeartRate: parseInt(event.target.value)
                                            }
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputBaseRideIndoorMinutes" className="col-sm-3 col-form-label">Indoor base</label>
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <input min={0}
                                            type="number"
                                            className="form-control"
                                            id="inputBaseRideIndoorMinutes"
                                            name="inputBaseRideIndoorMinutes"
                                            defaultValue={request.bikeData?.baseRideIndoorMinutes || ""}
                                            onChange={(event) => setRequest({
                                                ...request,
                                                bikeData: {
                                                    ...request.bikeData!,
                                                    baseRideIndoorMinutes: parseInt(event.target.value)
                                                }
                                            })}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">minutes</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputBaseRideOutdoorMinutes" className="col-sm-3 col-form-label">Outdoor base</label>
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <input min={0}
                                            type="number"
                                            className="form-control"
                                            id="inputBaseRideOutdoorMinutes"
                                            name="inputBaseRideOutdoorMinutes"
                                            defaultValue={request.bikeData?.baseRideOutdoorMinutes || ""}
                                            onChange={(event) => setRequest({
                                                ...request,
                                                bikeData: {
                                                    ...request.bikeData!,
                                                    baseRideOutdoorMinutes: parseInt(event.target.value)
                                                }
                                            })}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">minutes</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputLongRideOutdoorMinutes" className="col-sm-3 col-form-label">Outdoor long</label>
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <input min={0}
                                            type="number"
                                            className="form-control"
                                            id="inputLongRideOutdoorMinutes"
                                            name="inputLongRideOutdoorMinutes"
                                            defaultValue={request.bikeData?.longRideOutdoorMinutes || ""}
                                            onChange={(event) => setRequest({
                                                ...request,
                                                bikeData: {
                                                    ...request.bikeData!,
                                                    longRideOutdoorMinutes: parseInt(event.target.value)
                                                }
                                            })}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">minutes</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Intensity indication</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Intensity indication">
                                        {bikeIntensityIndications.map(d =>
                                            <button key={d.value} type="button" className={d.value === request.bikeData?.bikeIntensityIndication ? 'btn btn-dark' : 'btn btn-light'}
                                                onClick={(event) => setRequest({
                                                    ...request,
                                                    bikeData: {
                                                        ...request.bikeData!,
                                                        bikeIntensityIndication: d.value
                                                    }
                                                })}>{d.name}</button>
                                        )}
                                    </div>
                                </div>
                            </div> */}
                        </React.Fragment>
                        : ""
                }

                {
                    request.coachingTypes.some(ct => ct === CoachingType.Swimming || triathlonCoachingTypes.includes(ct)) ?
                        <React.Fragment>
                            <div className="form-group row pt-3">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-9">
                                    <h4>Swimming</h4>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Week availability</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Week availability">
                                        {daysOfWeakValues.map(d =>
                                            <button key={d.value} type="button"
                                                className={(d.func(request.swimmingData?.weekAvailability.availabilityDays) ? 'btn btn-dark' : 'btn btn-light')}
                                                onClick={(event) => setRequest({
                                                    ...request,
                                                    swimmingData: {
                                                        ...request.swimmingData!,
                                                        weekAvailability: {
                                                            ...request.swimmingData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                            availabilityDays: d.changeTrainingDays(request.swimmingData!.weekAvailability.availabilityDays)
                                                        }
                                                    }
                                                })}
                                            >{d.name}</button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputSwimmingAverageWorkoutMeters" className="col-sm-3 col-form-label">Average swimming workout length</label>
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <input min={0}
                                            type="number"
                                            className="form-control"
                                            id="inputSwimmingAverageWorkoutMeters"
                                            name="inputSwimmingAverageWorkoutMeters"
                                            placeholder="Required"
                                            defaultValue={request.swimmingData?.swimAverageWorkoutMeters || ""}
                                            onChange={(event) => setRequest({
                                                ...request,
                                                swimmingData: {
                                                    ...request.swimmingData!,
                                                    swimAverageWorkoutMeters: parseFloat(event.target.value)
                                                }
                                            })}
                                            ref={register({ required: true })}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">meters</span>
                                        </div>
                                    </div>
                                    {errors.inputSwimmingAverageWorkoutMeters && <span className="text-danger">Average swimming workout length is required</span>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="inputSwimmingStrictNumberOfDays" className="col-sm-3 col-form-label">Strict number of days</label>
                                <div className="col-sm-4">
                                    <input type="number"
                                        id="inputSwimmingStrictNumberOfDays"
                                        name="inputSwimmingStrictNumberOfDays"
                                        defaultValue={request.swimmingData?.weekAvailability?.strictNumberOfDays || ""}
                                        className="form-control"
                                        placeholder="Required"
                                        onChange={(event) => setRequest({
                                            ...request,
                                            swimmingData: {
                                                ...request.swimmingData!,
                                                weekAvailability: {
                                                    ...request.swimmingData!.weekAvailability || {} as PlanOverviewWeekAvailabilityRequest,
                                                    strictNumberOfDays: parseInt(event.target.value)
                                                }
                                            }
                                        })}
                                        ref={register({ required: true })}
                                    />
                                    {errors.inputSwimmingStrictNumberOfDays && <span className="text-danger">Swimming Strict number of days is required</span>}
                                </div>
                            </div>
                        </React.Fragment>
                        : ""
                }

                {
                    request.coachingTypes.some(ct => triathlonCoachingTypes.includes(ct))
                        ? <>
                            <div className="form-group row pt-3">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-9">
                                    <h4>Triathlon</h4>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Two workouts per day</label>
                                <div className="col-sm-9">
                                    <div className="btn-group" role="group" aria-label="Two workouts per day">
                                        {daysOfWeakValues.map(d =>
                                            <button key={d.value} type="button"
                                                className={(d.func(request.triathlonData?.twoWorkoutsPerDay) ? 'btn btn-dark' : 'btn btn-light')}
                                                onClick={(event) => setRequest({
                                                    ...request,
                                                    triathlonData: {
                                                        ...request.triathlonData!.twoWorkoutsPerDay,
                                                        twoWorkoutsPerDay: d.changeTrainingDays(request.triathlonData!.twoWorkoutsPerDay)
                                                    }
                                                })}
                                            >{d.name}</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                        : ""
                }

                <div className="form-group row py-3">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit(generatePlans)}>Generate plans</button>
                    </div>
                </div>
            </div>
            <div ref={weekRef}>
                {
                    weeks.length && !loading ? <PlanNewOverview weeks={weeks} isShowWeekInfo={!request.coachingTypes.some(ct => triathlonCoachingTypes.includes(ct))} /> : ""
                }
            </div>
        </React.Fragment>
    );
}

export default PlansNewView;