// this is a primitives shared across the services

// Planner is a source of this primitive
export enum PlanType {
    Fitness = 0, // Fitness running
    km5 = 1,
    km10 = 2,
    HalfMarathon = 3,
    Marathon = 4,
}

// Planner is the source of this primitive
export enum TrainingType {
    LongRun = 1,
    IntervalRun = 2,
    TempoRun = 3,
    EasyRun = 4,
    RunWalk = 5,
    StridesRun = 6,
    ProgressiveRun = 7,
    RunRace = 8
}

// BaseCamp is a source of this primitive
export enum Gender {
    Male = 1,
    Female = 2,
    Other = 3
}

// BaseCamp is a source of this primitive
export enum RaceType {
    Goal = "Goal",
    A = "A",
    B = "B",
    C = "C"
}

// Race discipline
export enum RaceDiscipline {
    Running = "Running",
    Cycling = "Cycling",
    Swimming = "Swimming",
    Triathlon = "Triathlon"
}

// Planner is a source of this primitive
export enum WeekType {
    Standard = 0,
    Friel = 1,
    Graded = 2,

    // RunningWeekType
    Walking = 100,
    Base = 101,
    Threshold = 104,
    Speed = 105,
    Vo2Max = 106,
    Active = 107,
    Test = 108,

    // General
    Fun = 102,
    Recovery = 103,
    Taper = 207,

    //CyclingWeekType
    Preparation = 200,
    Strength1 = 201,
    Strength2 = 202,
    Aerobic1 = 203,
    Aerobic2 = 204,
    Fitness = 205,
    Tempo = 206,
}

// This is a copy from Planner.
// Planner is a source of this primitive
export enum GradedWeekType {
    Grade1 = 1,
    Grade1Rest = 2,
    Grade2 = 3,
    Grade2Rest = 4,
    GradeFit = 5,
    GradeFitRest = 6,
    Grade3 = 7,
    Grade3Rest = 8,
    Grade4 = 9,
    Grade4Rest = 10,
    Grade5 = 11,
    Grade5Rest = 12,
    Grade6 = 13,
    Grade6Rest = 14,
    Taper = 15
}

// BaseCamp is the source of this primitive
export enum TrainingCompletionStatus {
    None = 0,
    Done = 1,
    Partially = 2,
    Unplanned = 3
}

// BaseCamp is the source of this primitive
export enum TrainingCompletionType {
    None = 'None',
    Indoor = 'Indoor',
    Outdoor = 'Outdoor'
}

export enum ExternalSyncAppTypes {
    /** Activity tracking by OmySport app */
    Omy = 0,

    /** Strava */
    Strava = 1,

    /** Garmin */
    Garmin = 2
}

/** Training load types */
export enum TrainingLoadType {
    None = 'None',
    TRIMPZone = 'TRIMPZone',
    rTSS = 'rTSS',
    TSS = 'TSS'
}

// UserType is the source of this primitive
export enum UserType {
    Unknown = 0,
    //Normal = 1,
    Telegram = 2,
    System = 3,
    Web = 4,
    Mobile = 5
}

export enum DayOfWeak {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export enum DaysOfWeek {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 4,
    Thursday = 8,
    Friday = 16,
    Saturday = 32,
    Sunday = 64,
    All = 127
}

// BikeIntensityIndicationType is the source of this primitive
export enum BikeIntensityIndicationType {
    HeartRate = 1,
    PowerWatts = 2
}

export enum CoachingType {
    CyclingRace = 6,
    CyclingFitness = 7,
    RunningFitness = 8,

    Swimming = 9,
    RunningRace = 10,
    TriathlonRace = 11,
    TriathlonFitness = 12
}

export enum SportType {
    Undefined = "Undefined",
    Running = "Running",
    Cycling = "Cycling",
    Swimming = "Swimming",
    Fitness = "Fitness",
    Fasting = "Fasting"
}

export enum SurveySportType {
    Running = "Running",
    Cycling = "Cycling",
    Triathlon = "Triathlon",
}

export enum TelegramUserChatMessageSender {
    User = 1,
    Bot = 2,
    Coach = 3
}

export enum SurveyType {
    MobileEntry = 1,
    MobileNewRunningGoal = 2,
    MobileWeekFeedback = 5,
    MobileRaceResult = 7,
    MobileNewCyclingGoal = 8,
    MobileRunningEntry = 10,
    MobileCyclingEntry = 11,

    WebEntry = 100,
    WebNewRunningGoal = 101,
    WebNewCyclingGoal = 102,
    WebRunningEntry = 103,
    WebCyclingEntry = 104,
    WebWeekFeedback = 105,

    RunTilda = 3,
    RunTelegramEntry = 4,
    BikeTelegramEntry = 6,
    RunFunTelegramEntry = 9,
}

export enum SubscriptionPeriods {
    Monthly = 1,
    Quarterly = 2,
    Annually = 3,
    SemiAnnually = 4
}

export enum ExternalProductSource {
    None = 0,
    AppleStore = 1,
    GooglePlay = 2,
    Stripe = 3
}

// BaseCamp is the source of this primitive
export enum Language {
    En = 1,
    Ru = 2,
    De = 3
}

export enum AutoMessageEvent {
    CreateUser = "CreateUser",
    BotRunPaywall = "BotRunPaywall",
    BotBikePaywall = "BotBikePaywall"
}

export enum AutoMessageSender {
    Bot = "Bot",
    Admin = "Admin",
    Coach = "Coach",
    SingleUser = "SingleUser"
}

export enum AutoMessageChannel {
    Chat = "Chat",
    Telegram = "Telegram"
}

export enum FilterSortDirection {
    Asc = 0,
    Desc = 1
}

export enum DateFormat {
    Date = 1,
    DateTime = 2
}

export enum SendTimeType {
    Delay = "Delay",
    Exact = "Exact"
}

export enum AutoMessageBotActionType {
    Command = 1,
    StravaUrl = 2,
    Script = 3,
    Paywall = 4
}

// BaseCamp is the source of this primitive
export enum TrainingDescriptionType {
    En = 1,
    Ru = 2,
    AltEn = 3,
    AltRu = 4
}

export enum BroadcastChannel {
    All = "All",
    Bot = "Bot",
    Chat = "Chat"
}

export enum ProgressStatus
{
    Ok = 'Ok',
    InProgress = 'InProgress',
    Fail = 'Fail'
}

export enum PaceHrPreferenceType
{
    Pace = "Pace",
    Hr = "Hr",
    Mix = "Mix"
}

export enum OperationSystemType
{
    Unknown = "Unknown",
    Ios = "Ios",
    Android = "Android"
}