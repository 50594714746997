import React from 'react';
import { secondsToTimeString } from '../../api/Helpers';
import { TrainingInfo } from '../../api/types';

interface Props {
    training: TrainingInfo;
    useImperialSystem: boolean
}

const TrainingEvent = ({ training, useImperialSystem }: Props) => {
    const trainingTypeCaption = training.title !== "" ? training.title : training.workoutType;
    const trainingText = training.description ? training.description.substring(0, 50) + "..." : "";

    let distance = null;
    let duration = null;
    let pace = null;
    if (training.activity) {
        if (training.distanceCompleted) {
            const dist = useImperialSystem ? training.distanceCompleted : training.distanceCompleted / 1000;
            distance = <div className='training__completion-param'>{dist.toFixed(2)} {useImperialSystem ? "mi" : "km"}</div>
        }
        if (training.durationCompleted) {
            const time = secondsToTimeString(training.durationCompleted);
            duration = <div className='training__completion-param'>{time}</div>
        }
        if (training.paceCompleted && training.workoutType) {
            const time = secondsToTimeString(training.paceCompleted);
            pace = <div className='training__completion-param'>{time} {useImperialSystem ? "min/mi" : "min/km"}</div>
        }
    }

    return <div>
        <div className='training__type mb-1'>
            {trainingTypeCaption}
            {
                training.dateOriginal ? <span> (moved)</span> : null
            }
        </div>
        {distance || duration || pace ? <div className='training__completion'>
            {distance ? distance : null}
            {duration ? duration : null}
            {pace ? pace : null}
        </div> : null}
        <div className='training__desc'>{trainingText}</div>
    </div>;
}

export default TrainingEvent;