import React, { useState } from 'react';
import { SurveyDefinitionOption } from '../../../api/types';
import Select from 'react-select';
import * as Icon from 'react-feather';
import { Language } from '../../../shared/Primitives';
import { getTextLocalization } from '../../../api/Helpers';
import ShowHintLocalization from './ShowHintLocalization';

type DefinitionOptionEditProps = {
    option: SurveyDefinitionOption,
    selectedLanguage: Language,
    index: number,
    questionIds: string[],
    onDelete: (index: number) => void,
    onChanged: (option: SurveyDefinitionOption, index: number) => void
};

const DefinitionOptionEditBlock = (props: DefinitionOptionEditProps) => {

    const [option, setOption] = useState(props.option);

    const onOptionCaptionChange = (value: string) => {
        switch (props.selectedLanguage) {
            case Language.De:
                option.captionDe = value;
                setOption({ ...option, captionDe: value });
                break;
            case Language.Ru:
                option.captionRu = value;
                setOption({ ...option, captionRu: value });
                break;
            default:
                option.captionEn = value;
                setOption({ ...option, captionEn: value });
                break;
        }

        props.onChanged(option, props.index);
    }

    const onOptionValueChange = (value: string) => {
        option.value = value;
        setOption({ ...option, value: value });

        props.onChanged(option, props.index);
    }

    const onOptionNextQuestionIdChange = (selectedOption: any) => {
        option.nextQuestionId = selectedOption.value;
        setOption({ ...option, nextQuestionId: selectedOption.value });

        props.onChanged(option, props.index);
    }

    const onOptionHintChange = (value: string) => {
        switch (props.selectedLanguage) {
            case Language.De:
                option.hintDe = value;
                setOption({ ...option, hintDe: value });
                break;
            case Language.Ru:
                option.hintRu = value;
                setOption({ ...option, hintRu: value });
                break;
            default:
                option.hintEn = value;
                setOption({ ...option, hintEn: value });
                break;
        }

        props.onChanged(option, props.index);
    }

    const onOptionIconChange = (value: string) => {
        option.icon = value;
        setOption({ ...option, icon: value });

        props.onChanged(option, props.index);
    }

    const onOptionDelete = () => {
        props.onDelete(props.index);
    }

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="form-group row">
                    <div className="col">
                        <h6>Option {props.index + 1}</h6>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col col-md-5">
                        <label className="form-label">Caption:</label>
                        <input className="form-control" type="text" value={getTextLocalization(props.option.captionEn, props.option.captionRu, props.option.captionDe, props.selectedLanguage)}
                            onChange={(e) => { onOptionCaptionChange(e.target.value) }}
                        />
                        <ShowHintLocalization selectedLanguage={props.selectedLanguage} 
                                txtEn={props.option.captionEn} txtRu={props.option.captionRu}  txtDe={props.option.captionDe} />
                    </div>
                    <div className="col col-md-2">
                        <label className="form-label">Value:</label>
                        <input className="form-control" type="text" value={props.option.value || ""}
                            onChange={(e) => { onOptionValueChange(e.target.value) }}
                        />
                    </div>
                    <div className="col col-md-4">
                        <label className="form-label">NextQuestionId:</label>
                        <Select style={{ "padding-left": "0px" }}
                            options={props.questionIds.map((x) => { return { label: x, value: x } })}
                            getOptionLabel={x => x.label || ""}
                            getOptionValue={x => x.value}
                            value={{ value: props.option.nextQuestionId || "", label: props.option.nextQuestionId || "" }}
                            onChange={onOptionNextQuestionIdChange}
                        />
                    </div>
                    <div className="col col-md-1">
                        <div className="option-delete-link">
                            <a onClick={(e) => { onOptionDelete() }}><Icon.Trash /></a>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col">
                        <label className="form-label">Hint:</label>
                        <input className="form-control" type="text" value={getTextLocalization(props.option.hintEn, props.option.hintRu, props.option.hintDe, props.selectedLanguage)}
                            onChange={(e) => { onOptionHintChange(e.target.value) }}
                        />
                        <ShowHintLocalization selectedLanguage={props.selectedLanguage} 
                                txtEn={props.option.hintEn} txtRu={props.option.hintRu}  txtDe={props.option.hintDe} />
                    </div>
                    <div className="col">
                        <label className="form-label">Icon:</label>
                        <input className="form-control" type="text" value={props.option.icon || ""}
                            onChange={(e) => { onOptionIconChange(e.target.value) }}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DefinitionOptionEditBlock