import React, { useState, useEffect } from 'react';

import { useAuth0 } from "./../../react-auth0-spa";

import { datetimeFormat } from '../../shared/consts';
import { BaseCampClient } from '../../api/BaseCampClient';
import { ActivityTopItem } from '../../api/types';
import { format } from "date-fns";

const ActivitiesView = () => {
    const { accessToken } = useAuth0();

    const [acts, setActs] = useState(
        [] as ActivityTopItem[]
    );

    useEffect(() => {
        const basecamp: BaseCampClient = new BaseCampClient(accessToken);
        basecamp.getTopActivities().then(res => setActs(res));
    }, [accessToken]); // second argument prevent to run on EVERY render.

    return <div className="top-activities">
        <h2>Last 10 activities</h2>
        <table className="table">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">User ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Distance</th>
                    <th scope="col">Elapsed Time</th>
                    <th scope="col">Started At</th>
                    <th scope="col">Average HR</th>
                    <th scope="col">Max HR</th>
                </tr>
            </thead>
            <tbody>
                {acts.map((act) => {
                    return (
                        <tr key={act.activityId}>
                            <td>{act.userId}</td>
                            <td>{act.name}</td>
                            <td>{act.type}</td>
                            <td>{act.distance}</td>
                            <td>{act.elapsedTime}</td>
                            <td>{format(act.startDate, datetimeFormat)}</td>
                            <td>{act.averageHeartRate}</td>
                            <td>{act.maxHeartRate}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>;
}

export default ActivitiesView;
