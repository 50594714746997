import React from 'react';
import { Language } from '../../../shared/Primitives';

type ShowHintLocalizationProps = {
    selectedLanguage: Language,
    txtEn: string | undefined,
    txtRu: string | undefined,
    txtDe: string | undefined
};

const ShowHintLocalization = (props: ShowHintLocalizationProps) => {

    const showHint = () => {
        var txt = "";
        switch (props.selectedLanguage) {
            case Language.En:
                txt = props.txtRu ?? props.txtDe ?? "";
                break;
            case Language.Ru:
                txt = props.txtEn ?? props.txtDe ?? "";
                break;
            case Language.De:
                txt = props.txtEn ?? props.txtRu ?? "";
                break;
        }

        return txt ? <small className="text-muted form-text">{txt}</small> : null;
    }

    return (
        showHint()
    );
}

export default ShowHintLocalization