import { CoachingType } from "./Primitives";

export const runningCoachingTypes: CoachingType[] = [
    CoachingType.RunningFitness,
    CoachingType.RunningRace,
]

export const cyclingCoachingTypes: CoachingType[] = [
    CoachingType.CyclingFitness,
    CoachingType.CyclingRace,
]

export const swimmingCoachingTypes: CoachingType[] = [
    CoachingType.Swimming
]

export const triathlonCoachingTypes: CoachingType[] = [
    CoachingType.TriathlonFitness,
    CoachingType.TriathlonRace
]