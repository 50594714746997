import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { autoMessageBotActionTypeValues } from '../../../fixtures/auto-message-bot-action-types';
import { AutoMessageBotActionType } from '../../../shared/Primitives';

type BotActionSelectorProps = {
    show: boolean,
    actionType: AutoMessageBotActionType | undefined,
    action: string | undefined,
    actionCaptionRu: string | undefined,
    actionCaptionEn: string | undefined,
    actionTypeChanged: (value: AutoMessageBotActionType | undefined) => void,
    actionChanged: (value: string | undefined) => void,
    actionCaptionRuChanged: (value: string | undefined) => void,
    actionCaptionEnChanged: (value: string | undefined) => void,
};

const BotActionSelector = (props: BotActionSelectorProps) => {
    const botCommands: string[] = ["/run", "/bike", "/profile", "/update", "/pay", "/help"];
    const botScripts: string[] = ["SundayFeedback"];

    return props.show ?
        <>
            <Form.Group controlId="botActionTypes">
                <Row>
                    <Form.Label column sm="2">Action type:</Form.Label>
                    <Col sm={2}>
                        <Form.Control as="select" value={props.actionType || ""} onChange={(e) => {
                            if (!e.target.value) {
                                props.actionChanged(undefined);
                                props.actionCaptionEnChanged(undefined);
                                props.actionCaptionRuChanged(undefined);
                                props.actionTypeChanged(undefined);
                                return;
                            }
                            const actionType = parseInt(e.target.value) as AutoMessageBotActionType;
                            if (actionType === AutoMessageBotActionType.StravaUrl || actionType === AutoMessageBotActionType.Paywall) {
                                props.actionChanged(undefined);
                            }
                            props.actionTypeChanged(actionType);
                        }}>
                            <option value="">None</option>
                            {
                                autoMessageBotActionTypeValues.map((x) => {
                                    return <option key={x.value} value={x.value}>{x.label}</option>
                                })
                            }
                        </Form.Control>
                    </Col>
                </Row>
            </Form.Group>
            {
                props.actionType === AutoMessageBotActionType.Command ?
                    <Form.Group controlId="botActionCommands">
                        <Row>
                            <Form.Label column sm="2">Action:</Form.Label>
                            <Col sm={2}>
                                <Form.Control as="select" value={props.action || ""} onChange={(e) => { props.actionChanged(e.target.value === "" ? undefined : e.target.value) }}>
                                    <option value=""></option>
                                    {
                                        botCommands.map((x) => {
                                            return <option key={x} value={x}>{x}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Text id="botActionDescription" muted>
                                    {autoMessageBotActionTypeValues.find(x => x.value === props.actionType)?.description}
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>
                    : null
            }
            {
                props.actionType === AutoMessageBotActionType.StravaUrl || props.actionType === AutoMessageBotActionType.Paywall ?
                    <Form.Group controlId="botActionStravaUrl">
                        <Row>
                            <Form.Label column sm="2">Action:</Form.Label>
                            <Col sm={10}>
                                <Form.Text id="botActionDescription" muted>
                                    {autoMessageBotActionTypeValues.find(x => x.value === props.actionType)?.description}
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>
                    : null
            }
            {
                props.actionType === AutoMessageBotActionType.Script ?
                    <Form.Group controlId="botActionScripts">
                        <Row>
                            <Form.Label column sm="2">Action:</Form.Label>
                            <Col sm={2}>
                                <Form.Control as="select" value={props.action} onChange={(e) => { props.actionChanged(e.target.value === "" ? undefined : e.target.value) }}>
                                    <option value=""></option>
                                    {
                                        botScripts.map((x) => {
                                            return <option key={x} value={x}>{x}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Text id="botActionDescription" muted>
                                    {autoMessageBotActionTypeValues.find(x => x.value === props.actionType)?.description}
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>
                    : null
            }

            {
                props.actionType ? <>
                    <Form.Group controlId="botActionCaptionRu">
                        <Row>
                            <Form.Label column sm="2">Action Caption Ru:</Form.Label>
                            <Col sm={4}>
                                <Form.Control type="text" value={props.actionCaptionRu || ""}
                                    onChange={(e) => { props.actionCaptionRuChanged(e.target.value) }} />
                                <Form.Text id="botActionCaptionDescriptionRu" muted>
                                    Optional. Caption for action element in Russian. For example button caption.
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group controlId="botActionCaptionEn">
                        <Row>
                            <Form.Label column sm="2">Action Caption En:</Form.Label>
                            <Col sm={4}>
                                <Form.Control type="text" value={props.actionCaptionEn || ""}
                                    onChange={(e) => { props.actionCaptionEnChanged(e.target.value) }} />
                                <Form.Text id="botActionCaptionDescriptionEn" muted>
                                    Optional. Caption for action element in English. For example button caption.
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>
                </>
                    : null
            }
        </>
        : null
}

export default BotActionSelector;