import React from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "../PrivateRoute";

import TrainingsView from "../trainings/TrainingsView";
import PlansView from "../plans/PlansView";
import ActivitiesView from "../activities/ActivitiesView";
import UsersView from "../users/UsersView";
import UsersAddView from "../users/add/UsersAddView";
import UsersProfileView from "../users/profile/UsersProfileView";
import UsersProfileMessagesView from "../users/profile/UsersProfileMessagesView";
import UsersProfileTrainingPlansView from "../users/profile/UsersProfileTrainingPlansView";
import UsersProfileWeekFeedbackView from "../users/profile/UsersProfileWeekFeedbacksView";
import UsersProfileLoggerView from "../users/profile/UsersProfileLoggerView";
import UsersProfileSurveyResultView from "../users/profile/UsersProfileSurveyResultsView";
import UserProfileSubscriptionView from "../users/profile/UserProfileSubscriptionView";
import UserProfileRaceResultsView from "../users/profile/UsersProfileRaceView";
import UserProfileParametersHistoryView from "../users/profile/UserProfileParametersHistoryView";
import UserProfileNewPlanView from "../users/profile/UserProfileNewPlanView";
import UserProfileCoachingCardsView from "../users/profile/UserProfileCoachingCardsView";
import UserProfileChartsView from "../users/profile/UserProfileChartsView";
import UsersProfilePaymentsView from "../users/profile/UsersProfilePaymentsView";
import PlansNewView from "../plans/PlansNewView";
import DashBoardView from "../dashboard/DashBoardView";
import MessagingView from "../messaging/MessagingView";
import SurveysView from "../survey/SurveysView";
import SurveyDetailView from "../survey/SurveyDetailView";
import AutoMessagesView from "../automessages/AutoMessagesView";
import AutoMessageDetailsView from "../automessages/AutoMessageDetailsView";
import ChatView from "../chat/ChatView";
import BlogView from "../blog/BlogView";
import BlogEditView from "../blog/BlogEditView";
import PromoOfferHistoryView from "../promohistory/PromoOfferHistoryView";

export default () =>
    <Switch>
        <PrivateRoute roles="admin" path="/" exact component={DashBoardView} />
        <PrivateRoute roles="admin" path="/dashboard" exact component={DashBoardView} />
        <PrivateRoute roles="" path="/users" exact component={UsersView} />
        <PrivateRoute roles="" path="/users/add" exact component={UsersAddView} />
        <PrivateRoute roles="" path="/user/:userId/profile" exact component={UsersProfileView} />
        <PrivateRoute roles="" path="/user/:userId/messages" exact component={UsersProfileMessagesView} />
        <PrivateRoute roles="" path="/user/:userId/training-plans" exact component={UsersProfileTrainingPlansView} />
        <PrivateRoute roles="" path="/user/:userId/week-feedback" exact component={UsersProfileWeekFeedbackView} />
        <PrivateRoute roles="" path="/user/:userId/logger" exact component={UsersProfileLoggerView} />
        <PrivateRoute roles="" path="/user/:userId/survey-result" exact component={UsersProfileSurveyResultView} />
        <PrivateRoute roles="" path="/user/:userId/subscription" exact component={UserProfileSubscriptionView} />
        <PrivateRoute roles="" path="/user/:userId/races" exact component={UserProfileRaceResultsView} />
        <PrivateRoute roles="" path="/user/:userId/parameters-history" exact component={UserProfileParametersHistoryView} />
        <PrivateRoute roles="" path="/user/:userId/new-plan" exact component={UserProfileNewPlanView} />
        <PrivateRoute roles="" path="/user/:userId/coaching-cards" exact component={UserProfileCoachingCardsView} />
        <PrivateRoute roles="" path="/user/:userId/charts" exact component={UserProfileChartsView} />
        <PrivateRoute roles="" path="/payments/:userId" exact component={UsersProfilePaymentsView} />
        <PrivateRoute roles="" path="/trainings" exact component={TrainingsView} />
        <PrivateRoute roles="" path="/trainings/:userId" exact component={TrainingsView} />
        <PrivateRoute roles="admin" path="/messaging" exact component={MessagingView} />
        <PrivateRoute roles="admin" path="/plans" exact component={PlansView} />
        <PrivateRoute roles="admin" path="/activities" exact component={ActivitiesView} />
        <PrivateRoute roles="admin" path="/new-plans" exact component={PlansNewView} />
        <PrivateRoute roles="admin" path="/surveys" exact component={SurveysView} />
        <PrivateRoute roles="admin" path="/surveys/:surveyId" exact component={SurveyDetailView} />
        <PrivateRoute roles="admin" path="/auto-messages" exact component={AutoMessagesView} />
        <PrivateRoute roles="admin" path="/auto-messages/:messageId" exact component={AutoMessageDetailsView} />
        <PrivateRoute roles="admin" path="/blog" exact component={BlogView} />
        <PrivateRoute roles="admin" path="/blog/:blogId" exact component={BlogEditView} />
        <PrivateRoute roles="admin" path="/promo-offer-history" exact component={PromoOfferHistoryView} />
        <PrivateRoute roles="" path="/chat/" exact component={ChatView} />
        <PrivateRoute roles="" path="/chat/:userId" exact component={ChatView} />
    </Switch>;
