import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as Icon from 'react-feather';

export enum ProfileType {
    Main,
    TelegramMessages,
    TrainingPlans,
    WeekFeedback,
    SurveyResult,
    Races,
    ParametersHistory,
    StartNewPlan,
    CoachingCards,
    Chat,
    Actions,
    Charts,
    Payments
}

type UserProfileButtonsProps = { type: ProfileType, userId: any };

const UserProfileButtons = (props: UserProfileButtonsProps) => {
    const history = useHistory();

    return (
        <div>
            <Link to={`/trainings/${props.userId}`} className="users-profile-calendar" title="Training calendar">
                <Icon.Calendar />
            </Link>
            {
                props.type !== ProfileType.Main ?
                    <Link to={`/user/${props.userId}/profile`} className="users-profile-calendar" title="User profile">
                        <Icon.User />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.TrainingPlans ?
                    <Link to={`/user/${props.userId}/training-plans`} className="users-profile-calendar" title="Training plans">
                        <Icon.TrendingUp />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.Chat ?
                    <Link to={`/chat/${props.userId}`} className="users-profile-calendar" title="Chat">
                        <Icon.MessageSquare />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.TelegramMessages ?
                    <Link to={`/user/${props.userId}/messages`} className="users-profile-calendar" title="Telegram messages">
                        <Icon.Send />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.WeekFeedback ?
                    <Link to={`/user/${props.userId}/week-feedback`} className="users-profile-calendar" title="Weekly feedbacks">
                        <Icon.UserCheck />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.SurveyResult ?
                    <Link to={`/user/${props.userId}/survey-result`} className="users-profile-calendar" title="Survey results">
                        <Icon.Info />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.Races ?
                    <Link to={`/user/${props.userId}/races`} className="users-profile-calendar" title="Races">
                        <Icon.Award />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.ParametersHistory ?
                    <Link to={`/user/${props.userId}/parameters-history`} className="users-profile-calendar" title="Parameters History">
                        <Icon.Archive />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.StartNewPlan ?
                    <Link to={`/user/${props.userId}/new-plan`} className="users-profile-calendar" title="Start new plan">
                        <Icon.PlayCircle />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.CoachingCards ?
                    <Link to={`/user/${props.userId}/coaching-cards`} className="users-profile-calendar" title="Coaching Cards">
                        <Icon.CreditCard />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.Actions ?
                    <Link to={`/user/${props.userId}/logger`} className="users-profile-calendar" title="Activity">
                        <Icon.Activity />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.Charts ?
                    <Link to={`/user/${props.userId}/charts`} className="users-profile-calendar" title="Charts">
                        <Icon.PieChart />
                    </Link> : ""
            }
            {
                props.type !== ProfileType.Payments ?
                    <Link to={`/payments/${props.userId}`} className="users-profile-calendar" title="Payments">
                        <Icon.ShoppingBag />
                    </Link> : ""
            }
        </div>
    );
}

export default UserProfileButtons;