import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useParams } from "react-router";
import DataTable from "../../common/DataTable";
import { BaseCampClient } from "../../../api/BaseCampClient";
import { useAuth0 } from "../../../react-auth0-spa";
import UserProfileButtons, { ProfileType } from "./UserProfileButtons";
import { UserAction } from "../../../api/types";

interface Props extends RouteComponentProps<any> {}

const columns = [
  {
    fieldName: "id",
    title: "ID",
    allowSort: true,
  },
  {
    fieldName: "date",
    title: "Date",
    allowSort: true,
  },
  {
    fieldName:'medium',
    title: 'Medium',
    allowSort: true,
  },
  {
    fieldName: 'actionType',
    title: 'Activity type',
    allowSort: true,
  },
  {
    fieldName: 'params',
    title: 'Params',
    allowSort: true,
  }
];

const UsersProfileLoggerView = (props: Props) => {
  const userId = props.match.params.userId;
  const { accessToken } = useAuth0();
  const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

  const [actions, setActions] = useState<UserAction[]>([])
  const [totalActions, setTotal] = useState(0)
  const [page, setPage] = useState(0);
  const params: { userId: string } = useParams();

  const handlePageChange = (value:any) => {
    setPage(value)
  }
  
  useEffect(() => {
    baseCamp.getUsersActions(Number(params.userId), page).then((res)=> {
      setActions(res.items)
      setTotal(res.totalCount)
    })
  }, [page]);

  return (
    <div className="users-profile users-profile-race-results">
      <div className="users-profile users-profile-messages">
        <div>
          <h3 className="float-left">User ID: {userId}</h3>
          <div className="float-right">
            <UserProfileButtons
              userId={userId}
              type={ProfileType.ParametersHistory}
            />
          </div>
          <div className="clear-both p-2" />
        </div>
      </div>

      <h4>User's Actions</h4>
      <DataTable
        data={actions}
        columns={columns}
        emptyDataMessage="There is no any actions."
        showCmdButtons={false}
        usePagination={true}
        pageNumber={page}
        perPage={30}
        totalCountPagination={totalActions}
        onPageNumberChanged={handlePageChange}
      />
    </div>
  );
};

export default UsersProfileLoggerView;
