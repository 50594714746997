import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from "qs";
import config from "../config"
import { 
    BroadcastChatMessageRequest, 
    ChatMessage, 
    ChatUser, 
    MarkReadRequest, 
    SearchUserFilterRequest, 
    SendChatMessageRequest, 
    SetChatAssistantEnabledRequest, 
    UpdateUserRequest 
} from './types/chatDirectorV2Types';
import { parseDate } from './Helpers';
import { ChannelType, ChatMessageV3, SendChatMessageRequestV3 } from './types/chatDirectorV3Types';

const apiUrl = config.chatDirectorHost + "/api/v2/admin";
const apiV3Url = config.chatDirectorHost + "/api/v3/admin";

export class ChatDirectorClient {
    config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json' } };

    constructor(token: string) {
        this.config.headers['Authorization'] = `Bearer ${token}`;
    }

    public async getMessages(userId: number): Promise<ChatMessage[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/messages/${userId}`, this.config);
        const result = resp.data.map(m => {
            return {
                ...m,
                createdAt: parseDate(m.createdAt),
                updatedAt: m.updatedAt && parseDate(m.updatedAt),
            } as ChatMessage;
        });
        return result;
    }

    public async getMessages3(userId: number, channelType: ChannelType, skip: number, take: number): Promise<ChatMessageV3[]> {
        const resp = await axios.get<any[]>(`${apiV3Url}/messages/${userId}/channel/${channelType}?skip=${skip}&take=${take}`, this.config);
        const result = resp.data.map(m => {
            return {
                ...m,
                createdAt: parseDate(m.createdAt),
                updatedAt: m.updatedAt && parseDate(m.updatedAt),
            } as ChatMessageV3;
        });
        return result;
    }

    public async sendMessage(request: SendChatMessageRequest): Promise<ChatMessage> {
        const result = await axios.post<any>(`${apiUrl}/messages`, request, this.config);
        return {
            ...result.data,
            createdAt: parseDate(result.data.createdAt),
            updatedAt: result.data.updatedAt && parseDate(result.data.updatedAt),
        } as ChatMessage;
    }

    public async sendMessageV3(request: SendChatMessageRequestV3): Promise<ChatMessageV3> {
        const result = await axios.post<any>(`${apiV3Url}/messages`, request, this.config);
        return {
            ...result.data,
            createdAt: parseDate(result.data.createdAt),
            updatedAt: result.data.updatedAt && parseDate(result.data.updatedAt),
        } as ChatMessageV3;
    }

    public broadcastMessage(request: BroadcastChatMessageRequest): Promise<AxiosResponse<void>> {
        return axios.post<any>(`${apiUrl}/messages/broadcast`, request, this.config);
    }

    public async markMessagesRead(request: MarkReadRequest): Promise<void> {
        await axios.post<void>(`${apiUrl}/messages/read`, request, this.config);
    }

    public async getUsers(filter: SearchUserFilterRequest): Promise<ChatUser[]> {
        const resp = await axios.get<any[]>(`${apiUrl}/users`,
            {
                ...this.config,
                params: filter,
                paramsSerializer: params => qs.stringify(params)
            });
        const result = resp.data.map(u => {
            return {
                ...u,
                lastMessageDate: u.lastMessageDate && parseDate(u.lastMessageDate),
            } as ChatUser;
        });
        return result;
    }

    public async getUser(userId: number): Promise<ChatUser> {
        const resp = await axios.get<any>(`${apiUrl}/users/${userId}`, this.config);
        const result: ChatUser = {
            ...resp.data,
            lastMessageDate: resp.data.lastMessageDate && parseDate(resp.data.lastMessageDate),
        };
        return result;
    }

    public async saveUser(request: UpdateUserRequest): Promise<void> {
        await axios.post<void>(`${apiUrl}/users`, request, this.config);
    }

    public async setRole(userId: number, role: string): Promise<void> {
        await axios.put<void>(`${apiUrl}/users/${userId}/role?role=${role}`, null, this.config);
    }

    public async setAvatarUrl(userId: number, avatarUrl: string): Promise<void> {
        await axios.put<void>(`${apiUrl}/users/${userId}/avatar?avatarUrl=${avatarUrl}`, null, this.config);
    }

    public async setChatAssistantEnabled(userId: number, isChatAssistantEnabled: boolean): Promise<void> {
        const request: SetChatAssistantEnabledRequest = {enabled: isChatAssistantEnabled};
        await axios.put<void>(`${apiUrl}/users/${userId}/chat-assistant-enabled`, request, this.config);
    }

    public async checkBasecampUser(userId: number): Promise<ChatUser | undefined> {
        const resp = await axios.get<any>(`${apiUrl}/users/${userId}/check`, this.config);
        const result: ChatUser | undefined = resp.data ?
            {
                ...resp.data,
                lastMessageDate: resp.data.lastMessageDate && parseDate(resp.data.lastMessageDate),
            }
            : undefined;
        return result;
    }

    public async getUserCoachId(userId: number | undefined): Promise<number | undefined> {
        if (!userId) {
            return undefined;
        }
        const resp = await axios.get<number | undefined>(`${apiUrl}/users/${userId}/coach`, this.config);
        return resp.data;
    }

    public async getUserCoachInfo(userId: number): Promise<ChatUser | undefined> {
        const resp = await axios.get<ChatUser | undefined>(`${apiUrl}/users/${userId}/coach-info`, this.config);
        return resp.data;
    }

    public async importUsers(): Promise<void> {
        await axios.post<void>(`${apiUrl}/users/import`, null, this.config);
    }
}