import React from 'react';

const Loader = (props: LoaderProps) => {
    return (
        <div className={`spinner spinner-border ${props?.className}`} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}

export default Loader;

interface LoaderProps {
    className?: string;
}