import { AutoMessageBotActionType } from "../shared/Primitives";

export const autoMessageBotActionTypeValues: AutoMessageBotActionTypeItem[] = [
    {
        value: AutoMessageBotActionType.Command,
        label: 'Command',
        description: 'Optional. Bot command. /run, /profile, /update, etc'
    },
    {
        value: AutoMessageBotActionType.StravaUrl,
        label: 'Strava Url',
        description: 'Action will be generated automatically with url to strava connect.'
    },
    {
        value: AutoMessageBotActionType.Script,
        label: 'Script',
        description: 'Optional. Bot script command to run script on telegram side.'
    },
    {
        value: AutoMessageBotActionType.Paywall,
        label: 'Paywall',
        description: 'Action will be generated automatically with payment url.'
    }
]

export interface AutoMessageBotActionTypeItem {
    value: AutoMessageBotActionType;
    label: string;
    description: string;
}