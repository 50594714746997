import React, { useState } from 'react';

import { PlannerClient } from '../../api/PlannerClient';
import { PlanOverviewWeek } from '../../api/types';

import { PlanConfiguration } from './Plan';
import PlanParams from './PlanParams';
import PlanOverview from './PlanOverview';

const PlansView = () => {
    const planner: PlannerClient = new PlannerClient();

    const [weeks, setWeeks] = useState(
        [] as PlanOverviewWeek[]
    );

    const [isPlanRequested, setPlanRequested] = useState(false);

    const generatePlan = async (planParams: PlanConfiguration) => {
        console.log('Plan params to generate:', planParams);

        const longRunMeters = planParams.longRunKm * 1000;

        const plan = await planner.generatePlanOverview(planParams.planType, planParams.athleteLevel, planParams.vdot, planParams.weeksCount, longRunMeters);
        console.log(plan);

        setPlanRequested(true);
        setWeeks(plan);
    }

    return (<div className="plan-viewer">
        <div className="plan-viewer__params">
            <PlanParams onGeneratePlan={generatePlan}></PlanParams>
        </div>
        <div className="plan-viewer__overview">
            {weeks.length > 0 && <PlanOverview weeks={weeks}></PlanOverview>}
            {isPlanRequested && weeks.length === 0 && <div className="alert alert-primary" role="alert">No plan can be generated with these parameters!</div>}
        </div>
    </div>);
}

export default PlansView;
