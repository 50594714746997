import { ActivityTopItem, UserFullInfo, WeekPlanInfo, TimeZoneType, TrainingFileInfo, Race, UserRegistrationResponse, UserTelegramMessageList, UserTelegramMessage, PlanOverviewResponse, PlanOverviewWeekResponse, PlanOverviewWeekLabel, PlanOverviewDayContent, PlanOverviewWorkout, StatisticsResponse, UsersDayStats, SubscriptionPlanInfo, RaceResult, BroadcastingMessage, UserShortInfo, UserFullInfoList, SurveyResult, UserTrainingPlanList, UserTrainingPlan, TrainingInfo, UserSubscriptionInfo, AthleteParametersHistoryResponse, AthleteParameters, Survey, SurveyDefinition, SurveyDefinitionOption, UserUpdateRequest, UserSuspendedData, AutoMessageListResponse, AutoMessage, BlogItemList, BlogItem, TrainingUpdateResponse, UserAction, ActivityParamsZoneItem, TrainingActivityData, TrainingActivityDataResponse, TrainingFullInfoResponse, TrainingFullInfo, UserChartsAdminDataItem, TrainingExportStatus, PromoOfferHistory, ActivityInfo, UserPayment } from './types';
import { parseDate, getRandomKey } from './Helpers';
import moment from 'moment';

export function mapToUserShortInfo(response: any): UserShortInfo {
    const user: UserShortInfo = {
        id: response.id,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email
    }
    return user;
}

export function mapToUserFullInfoList(response: any): UserFullInfoList {
    const user: UserFullInfoList = {
        selectedUserIds: response.selectedUserIds,
        totalCount: response.totalCount,
        users: response.users.map(mapToUserFullInfo)
    }
    return user;
}

export const mapActions = (action:UserAction) => {
      return {
        id: action.id,
        date: moment(action.createdAt).format('DD-MM-YYYY HH:MM:SS'),
        medium: action.actor,
        actionType: action.event,
        params: action.description
      }
  }

export const mapPayments = (pmt: UserPayment) => {
  return {
    paymentId: pmt.paymentId,
    createdAt: moment(pmt.createdAt).format('DD-MM-YYYY HH:MM:SS'),
    userId: pmt.userId,
    email: pmt.email,
    firstName: pmt.firstName,
    lastName: pmt.lastName,
    productId: pmt.productId,
    store: pmt.store,
    subscriptionPeriod: pmt.subscriptionPeriod,
    transactionType: pmt.transactionType,
    priceEstimated: pmt.priceEstimated,
    price: pmt.price,
    externalId: pmt.externalId,
    parentExternalTransactionId: pmt.parentExternalTransactionId,
    promoCodeOriginal: pmt.promoCodeOriginal,
    promoOfferId: pmt.promoOfferId,
    promoOfferCodeId: pmt.promoOfferCodeId
  }
}

export function mapToUserFullInfo(response: any): UserFullInfo {
    const user: UserFullInfo = {
        ...response,
        userSubscriptionInfo: response.userSubscriptionInfo && mapToUserSubscriptionInfo(response.userSubscriptionInfo),
        createdAt: parseDate(response.createdAt),
        telegramInitDate: parseDate(response.telegramInitDate),
        activeRunningPlanStartedAt: response.activeRunningPlanStartedAt && parseDate(response.activeRunningPlanStartedAt),
        races: response.races && response.races.map(mapToRace),
        stravaConnectedAt: response.stravaConnectedAt && parseDate(response.stravaConnectedAt),
        garminConnectedAt: response.garminConnectedAt && parseDate(response.garminConnectedAt),
        appleConnectedAt: response.appleConnectedAt && parseDate(response.appleConnectedAt),
        polarConnectedAt: response.polarConnectedAt && parseDate(response.polarConnectedAt),
        userSuspendedData: response.userSuspendedData && response.userSuspendedData.map(mapToUserSuspendedData),
        goalRace: response.goalRace && mapToRace(response.goalRace),
        bikeGoalRace: response.bikeGoalRace && mapToRace(response.bikeGoalRace)
    }
    return user;
}

export function mapToUserSuspendedData(response: any): UserSuspendedData {
    const data: UserSuspendedData = {
        ...response,
        suspensionDate: response.suspensionDate && parseDate(response.suspensionDate)
    }
    return data;
}

export function mapToUserSubscriptionInfo(response: any): UserSubscriptionInfo {
    const user: UserSubscriptionInfo = {
        ...response,
        subscriptionPlanId: response.subscriptionPlanId,
        subscriptionPlanTitle: response.subscriptionPlanTitle,
        subscriptionPeriod: response.subscriptionPeriod,
        externalProductSource: response.externalProductSource,
        externalProductId: response.externalProductId,
        createdAt: parseDate(response.createdAt),
        prolongedAt: response.prolongedAt && parseDate(response.prolongedAt),
        expireAt: parseDate(response.expireAt),
        cancelledAt: response.cancelledAt && parseDate(response.cancelledAt)
    }
    return user;
}

export function mapToActivity(response: any): ActivityTopItem {
    const model: ActivityTopItem = {
        ...response,
        activityId: response.activityId,
        userId: response.userId,
        name: response.name,
        type: response.type,
        distance: response.distance,
        elapsedTime: response.elapsedTime,
        startDate: parseDate(response.startDate),
        averageHeartRate: response.averageHeartRate,
        maxHeartRate: response.maxHeartRate
    }
    return model;
}

export function mapToTrainingFileInfo(response: any): TrainingFileInfo {
    const model: TrainingFileInfo = {
        ...response,
        id: response.id,
        createdAt: parseDate(response.createdAt),
        trainingId: response.trainingId,
        filename: response.filename,
        fileUrl: response.fileUrl
    }
    return model;
}

export function mapToRace(response: any): Race {
    const race: Race = {
        ...response,
        raceId: response.raceId,
        userId: response.userId,
        raceDiscipline: response.raceDiscipline,
        type: response.type,
        date: parseDate(response.date),
        distanceKm: response.distanceKm,
        name: response.name,
        place: response.place,
        hasRegistration: response.hasRegistration,
        createdAt: parseDate(response.createdAt),
        resultTime: response.resultTime,
        resultDistanceMeters: response.resultDistanceMeters,
        comments: response.comments
    };
    return race;
}
export function mapToTimeZoneType(response: any): TimeZoneType {
    const model: TimeZoneType = {
        ...response,
        displayName: response.displayName,
        timezoneOffset: response.timezoneOffset
    };
    return model;
}

export function mapToWeekPlanInfo(response: any): WeekPlanInfo {
    const model: WeekPlanInfo = {
        ...response,
        id: response.id,
        startAt: parseDate(response.startAt),
        planType: response.planType,
        weekType: response.weekType,
        weekSubType: response.weekSubType,
        title: response.title,
        coachingType: response.coachingType,
        distanceCompleted: response.distanceCompleted,
        distancePlanned: response.distancePlanned,
        durationCompleted: response.durationCompleted,
        durationPlanned: response.durationPlanned
    };
    return model;
}

export function mapToUserRegistrationResponse(response: any): UserRegistrationResponse {
    const model: UserRegistrationResponse = {
        ...response,
        userId: response.userId,
        email: response.email,
        telegramBotUrl: response.telegramBotUrl,
        telegramBotCode: response.telegramBotCode
    };
    return model;
}

export function mapToUserTelegramMessageList(response: any): UserTelegramMessageList {
    const model: UserTelegramMessageList = {
        ...response,
        messages: response.messages.map(mapToUserTelegramMessage),
        totalCount: response.totalCount
    };
    return model;
}

export function mapToUserTelegramMessage(response: any): UserTelegramMessage {
    const model: UserTelegramMessage = {
        ...response,
        id: response.id,
        telegramUserId: response.telegramUserId,
        message: response.message,
        createdAt: parseDate(response.createdAt),
        createdAtFormat: response.createdAtFormat,
        createdAtTimeFormat: response.createdAtTimeFormat,
        showDay: response.showDay,
        sender: response.sender,
        type: response.type
    };
    return model;
}

export function mapToPlanOverviewResponse(response: any): PlanOverviewResponse {
    const model: PlanOverviewResponse = {
        ...response,
        weeks: response.weeks.map(mapToPlanOverviewWeekResponse)
    };
    return model;
}

export function mapToPlanOverviewWeekResponse(response: any): PlanOverviewWeekResponse {
    const model: PlanOverviewWeekResponse = {
        ...response,
        weekNumber: response.weekNumber,
        planLongRunMeters: response.planLongRunMeters,
        planWeeklyVolumeMeters: response.planWeeklyVolumeMeters,
        factWeeklyVolumeMeters: response.factWeeklyVolumeMeters,
        labels: response.labels.map(mapToPlanOverviewWeekLabel),
        content: response.content.map(mapToPlanOverviewDayContent)
    };
    return model;
}

export function mapToPlanOverviewWeekLabel(response: any): PlanOverviewWeekLabel {
    const model: PlanOverviewWeekLabel = {
        ...response,
        coachingType: response.coachingType,
        label: response.label
    };
    return model;
}

export function mapToPlanOverviewDayContent(response: any): PlanOverviewDayContent {
    const model: PlanOverviewDayContent = {
        ...response,
        dayOfWeek: response.dayOfWeek,
        workouts: response.workouts
    };
    return model;
}

export function mapToPlanOverviewWorkout(response: any): PlanOverviewWorkout {
    const model: PlanOverviewWorkout = {
        ...response,
        coachingType: response.coachingType,
        workoutType: response.workoutType,
        totalDistanceMeters: response.totalDistanceMeters,
        description: response.description
    };
    return model;
}

export function mapToTrainingExportStatus(response: any): TrainingExportStatus {
    const model: TrainingExportStatus = {
        ...response,
        isGarminConnected: response?.isGarminConnected,
        garminExportDate: response?.garminExportDate && parseDate(response.garminExportDate),
        hasAlternativeStructure: response?.hasAlternativeStructure ?? false
    };
    return model;
}

export function mapToStatisticsResponse(response: any): StatisticsResponse {
    const model: StatisticsResponse = {
        ...response,
        statistics: response.statistics.map(mapToUsersDayStats),
        registeredUsersCount: response.registeredUsersCount,
        landedUsersCount: response.landedUsersCount,
        activeUsersCount: response.activeUsersCount
    };
    return model;
}

export function mapToUsersDayStats(response: any): UsersDayStats {
    const model: UsersDayStats = {
        ...response,
        date: parseDate(response.date),
        newUserCount: response.newUserCount,
        botNewUserCount: response.botNewUserCount,
        webNewUserCount: response.webNewUserCount,
        mobileNewUserCount: response.mobileNewUserCount,
        runCommandCount: response.runCommandCount,
        surveyCommandCount: response.surveyCommandCount,
        //updateStartNewTrainingPlanCommandCount: response.updateStartNewTrainingPlanCommandCount,
        userMessageCount: response.userMessageCount,
        weekFeedbackCount: response.weekFeedbackCount,
        newStravaUserCount: response.newStravaUserCount,
        botNewPlanCount: response.botNewPlanCount,
        mobileNewPlanCount: response.mobileNewPlanCount,
        webNewPlanCount: response.webNewPlanCount,
        oneMonthPaymentCount: response.oneMonthPaymentCount,
        quarterlyPaymentCount: response.quarterlyPaymentCount,
        semiannuallyPaymentCount: response.semiannuallyPaymentCount,
        annuallyPaymentCount: response.annuallyPaymentCount
    };
    return model;
}

export function mapToSubscriptionPlanInfo(response: any): SubscriptionPlanInfo {
    const model: SubscriptionPlanInfo = {
        ...response,
        id: response.id,
        title: response.title,
        description: response.description,
        code: response.code
    };
    return model;
}

export function mapToRaceResult(response: any): RaceResult {
    const model: RaceResult = {
        ...response,
        raceId: response.raceId,
        distanceMeters: response.distanceMeters,
        time: response.time,
        vdot: response.vdot,
        createdAt: parseDate(response.createdAt)
    };
    return model;
}

export function mapToBroadcastingMessage(response: any): BroadcastingMessage {
    const model: BroadcastingMessage = {
        ...response,
        id: response.id,
        createdAt: parseDate(response.createdAt),
        message: response.message,
        language: response.language,
        channel: response.channel,
        status: response.status,
        info: response.info
    };
    return model;
}

export function mapToSurveyResult(response: any): SurveyResult {
    const model: SurveyResult = {
        ...response,
        id: response.id,
        userId: response.userId,
        createdAt: parseDate(response.createdAt),
        confirmedAt: response.confirmedAt && parseDate(response.confirmedAt),
        resultJson: response.resultJson,
        surveyType: response.surveyType,
        showDay: response.showDay,
        isActive: response.isActive,
        createdAtTime: parseDate(response.createdAtTime)
    };
    return model;
}

export function mapToUserTrainingPlanList(response: any): UserTrainingPlanList {
    const model: UserTrainingPlanList = {
        ...response,
        plans: response.plans.map(mapToUserTrainingPlan)
    };
    return model;
}

export function mapToUserTrainingPlan(response: any): UserTrainingPlan {
    const model: UserTrainingPlan = {
        ...response,
        id: response.id,
        userId: response.userId,
        coachingType: response.coachingType,
        startDate: parseDate(response.startDate),
        endDate: parseDate(response.endDate),
        isActive: response.isActive,
        createdAt: parseDate(response.createdAt),
        createdAtTime: parseDate(response.createdAtTime),
        params: response.params,
        showDay: response.showDay,
        goal: response.goal
    };
    return model;
}

export function mapToTrainingInfo(response: any): TrainingInfo {
    const model: TrainingInfo = {
        ...response,
        id: response.id,
        date: parseDate(response.date),
        dateOriginal: response.dateOriginal ? parseDate(response.dateOriginal) : undefined,
        workoutType: response.workoutType,
        workoutGenerator: response.workoutGenerator,
        title: response.title,
        description: response.description,
        descriptionEn: response.descriptionEn,
        descriptionRu: response.descriptionRu,
        descriptionAltEn: response.descriptionAltEn,
        descriptionAltRu: response.descriptionAltRu,
        isDescriptionEnEdited: response.isDescriptionEnEdited,
        isDescriptionRuEdited: response.isDescriptionRuEdited,
        isDescriptionAltEnEdited: response.isDescriptionAltEnEdited,
        isDescriptionAltRuEdited: response.isDescriptionAltRuEdited,
        distancePlanned: response.distancePlanned,
        distancePlannedMeters: response.distancePlannedMeters,
        durationPlanned: response.durationPlanned,
        durationPlannedAlt: response.durationPlannedAlt,
        durationCompleted: response.durationCompleted,
        distanceCompleted: response.distanceCompleted,
        paceCompleted: response.paceCompleted,
        heartRateAverageCompleted: response.heartRateAverageCompleted,
        heartRateMaximumCompleted: response.heartRateMaximumCompleted,
        completionStatus: response.completionStatus,
        coachingType: response.coachingType,
        activity: response.activity && mapToActivityInfo(response.activity),
    };
    return model;
}

export function mapToTrainingFullInfo(response: TrainingFullInfoResponse): TrainingFullInfo {
    const result: TrainingFullInfo = {
        id: response.id,
        sportType: response.sportType,
        title: response.title ?? '',
        userId: response.userId,
        workoutType: response.workoutType ?? '',
        activityExternalAthleteId: response.activityExternalAthleteId,
        activityExternalId: response.activityExternalId,
        alternativeDescription: response.descriptionAlternate,
        alternativeSchemaImage: response.trainingSchemaAlternativePictureUrl,
        averageCadence: response.averageCadence,
        averageHeartRate: response.averageHeartRate,
        averageSpeed: response.averageSpeed,
        averageWatts: response.averageWatts,
        calories: response.calories,
        completionStatus: response.completionStatus,
        contentAvailable: response.contentAvailable,
        description: response.description,
        distanceCompleted: response.distanceCompleted,
        distancePlanned: response.distancePlanned,
        durationAlternativePlanned: response.durationPlannedAlternative,
        durationCompleted: response.durationCompleted,
        durationPlanned: response.durationPlanned,
        mapPolyline: response.mapPolyline,
        mapSummaryPolyline: response.mapSummaryPolyline,
        maxHeartRate: response.maxHeartRate,
        maxSpeed: response.maxSpeed,
        maxWatts: response.maxWatts,
        movingTime: response.movingTime,
        pace: response.pace,
        schemaImage: response.trainingSchemaPictureUrl,
        splitsData: response.splitsMetric,
        createdAt: parseDate(response.createdAt),
        date: parseDate(response.date),
        activityData: mapToTrainingActivityData(response.activityStreams)
    }

    return result;
}

export function mapToTrainingActivityData(response?: TrainingActivityDataResponse): TrainingActivityData[] | undefined {
    const result = response?.time?.map((time, ix) => ({
        time: time!,
        heartrate: response!.heartrate?.[ix],
        cadence: response!.cadence?.[ix],
        watts: response!.watts?.[ix],
        temp: response!.temp?.[ix],
        distance: response!.distance?.[ix],
        altitude: response!.altitude?.[ix],
        velocitySmooth: response!.velocitySmooth?.[ix],
        velocitySmoothKmPerHour: response!.velocitySmoothKmPerHour?.[ix],
        gradeSmooth: response!.gradeSmooth?.[ix],
        pace: response!.pace?.[ix]?.value,
        paceLabel: response!.pace?.[ix]?.label,
        latlng: response!.latlng?.[ix] && [
            response!.latlng?.[ix][0],
            response!.latlng?.[ix][1],
        ],
        moving: response!.moving?.[ix],
      } as TrainingActivityData));

    return result;
}

export function mapToTrainingUpdateResponse(response: any): TrainingUpdateResponse {
    const model: TrainingUpdateResponse = {
        ...response,
        id: response.id,
        date: parseDate(response.date),
        dateOriginal: response.dateOriginal ? parseDate(response.dateOriginal) : undefined,
    };
    return model;
}

export function mapToActivityInfo(response: any): ActivityInfo {
    const model: ActivityInfo = {
        id: response.id,
        url: response.url,
        appType: response.appType,
        category: response.category,
        trainingLoad: response.trainingLoad,
        trainingLoadType: response.trainingLoadType,
        heartTrainingLoad: response.heartTrainingLoad,
        rTSS: response.rTSS,
        TSS: response.TSS,
        runningFitnessScore: response.runningFitnessScore,
        heartRateZones: response.heartRateZones?.map((h: any) => mapToActivityParamsZoneItem(h)),
        paceZones: response.paceZones?.map((h: any) => mapToActivityParamsZoneItem(h)),
        powerZones: response.powerZones?.map((h: any) => mapToActivityParamsZoneItem(h))
    };
    return model;
}

export function mapToActivityParamsZoneItem(response: any): ActivityParamsZoneItem {
    const model: ActivityParamsZoneItem = {
        type: response.type,
        value: response.value
    };
    return model;
}

export function mapToAthleteParametersHistoryResponse(response: any): AthleteParametersHistoryResponse {
    const model: AthleteParametersHistoryResponse = {
        ...response,
        action: response.action,
        createdAt: parseDate(response.createdAt),
        actor: response.actor,
        snapshot: response.snapshot,
        changes: response.changes
    };
    return model;
}

export function mapToAthleteParameters(response: any): AthleteParameters {
    const model: AthleteParameters = {
        ...response,
        distanceKmTrainFor: response.distanceKmTrainFor,
        level: response.level,
        longRunKm: response.longRunKm,
        vdot: response.vdot,
        ftp: response.ftp,
        bikeMaxHeartRate: response.bikeMaxHeartRate,
        currentBikeLoadVolumeSeconds: response.currentBikeLoadVolumeSeconds,
        currentBikeLongRideVolumeSeconds: response.currentBikeLongRideVolumeSeconds,
        currentBikeIntensityVolumeSeconds: response.currentBikeIntensityVolumeSeconds,
        availableTrainingDays: response.availableTrainingDays,
        longRunDay: response.longRunDay,
        longBikeDay: response.longBikeDay,
        bikeIntensityIndication: response.bikeIntensityIndication,
        maxHeartRate: response.maxHeartRate
    };
    return model;
}

export function mapToSurvey(response: any): Survey {
    const model: Survey = {
        ...response,
        createdAt: parseDate(response.createdAt),
        id: response.id,
        isActive: response.isActive,
        surveyType: response.surveyType,
        version: response.version,
        definitions: response.definitions && response.definitions.map(mapToSurveyDefinition),
        title: response.title,
        isCompleted: response.isCompleted
    };
    return model;
}

export function mapToSurveyDefinition(response: any): SurveyDefinition {
    const model: SurveyDefinition = {
        ...response,
        id: response.id,
        questionId: response.questionId,
        questionType: response.questionType,
        questionTextEn: response.questionTextEn,
        questionTextRu: response.questionTextRu,
        questionTextDe: response.questionTextDe,
        nextQuestionId: response.nextQuestionId,
        isQuestionOptional: response.isQuestionOptional,
        questionHintEn: response.questionHintEn,
        questionHintRu: response.questionHintRu,
        questionHintDe: response.questionHintDe,
        questionIcon: response.questionIcon,
        options: response.options && response.options.map(mapToSurveyDefinitionOption),
        constraints: response.constraints
    };
    return model;
}

export function mapToSurveyDefinitionOption(response: any): SurveyDefinitionOption {
    const model: SurveyDefinitionOption = {
        ...response,
        key: getRandomKey(),
        captionEn: response.captionEn,
        captionRu: response.captionRu,
        captionDe: response.captionDe,
        value: response.value,
        nextQuestionId: response.nextQuestionId,
        hintEn: response.hintEn,
        hintRu: response.hintRu,
        hintDe: response.hintDe,
        icon: response.icon
    };
    return model;
}

export function mapToUserUpdateRequest(user: UserFullInfo): UserUpdateRequest {
    const model: UserUpdateRequest = {
        age: user.age,
        availableTrainingDays: user.availableTrainingDays,
        baseRideIndoorMinutes: user.baseRideIndoorMinutes,
        baseRideOutdoorMinutes: user.baseRideOutdoorMinutes,
        bikeAvailableTrainingDays: user.bikeAvailableTrainingDays,
        bikeMaxHeartRate: user.bikeMaxHeartRate,
        bikeStrictNumberOfDays: user.bikeStrictNumberOfDays,
        distanceKmTrainFor: user.distanceKmTrainFor,
        firstName: user.firstName,
        ftp: user.ftp,
        gender: user.gender,
        goalRace: undefined, // for now it is read only in user profile
        language: user.language,
        lastName: user.lastName,
        level: user.level,
        longBikeDay: user.longBikeDay,
        longRideOutdoorMinutes: user.longRideOutdoorMinutes,
        longRunDay: user.longRunDay,
        longRunKm: user.longRunKm,
        maxHeartRate: user.maxHeartRate,
        qualityWorkoutsNumber: user.qualityWorkoutsNumber,
        runWeekVolumeKm: user.runWeekVolumeKm,
        strictNumberOfDays: user.strictNumberOfDays,
        timezoneOffset: user.timezoneOffset,
        vdot: user.vdot,
        coachId: user.coachId,
        useImperialSystem: user.useImperialSystem,
        swimAvailableTrainingDays: user.swimAvailableTrainingDays,
        swimStrictNumberOfDays: user.swimStrictNumberOfDays,
        swimAverageWorkoutMeters: user.swimAverageWorkoutMeters,
        adminComments: user.adminComments
    };

    return model;
}

export function mapToAutoMessageListResponse(response: any): AutoMessageListResponse {
    const model: AutoMessageListResponse = {
        totalCount: response.totalCount,
        autoMessages: response.autoMessages.map(mapToAutoMessage)
    }
    return model;
}

export function mapToAutoMessage(response: any): AutoMessage {
    const model: AutoMessage = {
        ...response,
        createdAt: parseDate(response.createdAt)
    };
    return model;
}

export function mapToBlogItemListResponse(response: any): BlogItemList {
    const model: BlogItemList = {
        totalCount: response.totalCount,
        items: response.items.map(mapToBlogItemResponse)
    }
    return model;
}

export function mapToBlogItemResponse(response: any): BlogItem {
    const model: BlogItem = {
        ...response,
        createdAt: parseDate(response.createdAt)
    };
    return model;
}

export function mapToUserChartsAdminDataItem(response: any): UserChartsAdminDataItem {
    const parsedDate = parseDate(response.date);
    const model: UserChartsAdminDataItem = {
        ...response,
        date: moment(parsedDate),
        dateEpoch: moment(parsedDate).valueOf()
    }
    return model;
}

export function mapToPromoOfferHistory(response: any): PromoOfferHistory {
    const model: PromoOfferHistory = {
        ...response,
        createdAt: parseDate(response.createdAt)
    };
    return model;
}