import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { AthleteParametersHistoryResponse, AthleteParameters } from '../../../api/types';
import ParametersHistoryRow from './profile-view-components/ParametersHistoryRow';

import UserProfileButtons, { ProfileType } from './UserProfileButtons';
import './UsersProfileView.scss';
import Loader from "../../Loader";

interface Props extends RouteComponentProps<any> { }

const UserProfileParametersHistoryView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const [loading, setLoading] = useState(true);
    const [parametersHistory, setParametersHistory] = useState([] as AthleteParametersHistoryResponse[]);

    const userId = props.match.params.userId;

    useEffect(() => {
        setLoading(true);

        baseCamp.getAthleteParametersHistory(userId)
            .then(r => { 
                const data = r.sort((a, b) => {return a.createdAt > b.createdAt ? -1 : 1});
                setParametersHistory(data); 
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <div className="users-profile users-profile-race-results">
            <div className="users-profile users-profile-messages">
                <div>
                    <h3 className="float-left">User ID: {userId}</h3>
                    <div className="float-right">
                        <UserProfileButtons userId={userId} type={ProfileType.ParametersHistory} />
                    </div>
                    <div className="clear-both p-2" />
                </div>
            </div>

            <h4>Parameters History</h4>
            {
                parametersHistory.length > 0
                    ?
                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th style={{ width: "30px" }}></th>
                                <th scope="col" style={{ width: "160px" }}>Date</th>
                                <th scope="col">Actor</th>
                                <th scope="col" style={{ width: "200px" }}>Action</th>
                                <th scope="col">Changes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                parametersHistory.map((p, index) => {
                                    return (
                                        <ParametersHistoryRow key={index} rowIndex={index} parametersHistory={p} />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    : <div>History is empty</div>
            }
        </div>
    )
}

export default UserProfileParametersHistoryView;