import { DayOfWeak } from '../shared/Primitives';
import { DaysOfWeek } from '../shared/Primitives';
import { UserWeekDays } from "../api/types";

export const dayOfWeakValues = [
    {
        value: DayOfWeak.Sunday, 
        name: 'Sun'
    },
    {
        value: DayOfWeak.Monday, 
        name: 'Mon'
    },
    {
        value: DayOfWeak.Tuesday, 
        name: 'Tue'
    },
    {
        value: DayOfWeak.Wednesday, 
        name: 'Wed'
    },
    {
        value: DayOfWeak.Thursday, 
        name: 'Thu'
    },
    {
        value: DayOfWeak.Friday, 
        name: 'Fri'
    },
    {
        value: DayOfWeak.Saturday, 
        name: 'Sat'
    }
];

export const daysOfWeakValues = [
    {
        value: "sunday",
        name: 'Sun',
        func: (d?: UserWeekDays) => d?.sunday,
        changeTrainingDays: (d?: UserWeekDays) => {
            return {
                ...(d || {} as UserWeekDays),
                sunday: !d?.sunday
            };
        }
    },
    {
        value: "monday", 
        name: 'Mon',
        func: (d?: UserWeekDays) => d?.monday,
        changeTrainingDays: (d?: UserWeekDays) => {
            return {
                ...(d || {} as UserWeekDays),
                monday: !d?.monday
            };
        }
    },
    {
        value: "tuesday", 
        name: 'Tue',
        func: (d?: UserWeekDays) => d?.tuesday,
        changeTrainingDays: (d?: UserWeekDays) => {
            return {
                ...(d || {} as UserWeekDays),
                tuesday: !d?.tuesday
            };
        }
    },
    {
        value: "wednesday", 
        name: 'Wed',
        func: (d?: UserWeekDays) => d?.wednesday,
        changeTrainingDays: (d?: UserWeekDays) => {
            return {
                ...(d || {} as UserWeekDays),
                wednesday: !d?.wednesday
            };
        }
    },
    {
        value: "thursday", 
        name: 'Thu',
        func: (d?: UserWeekDays) => d?.thursday,
        changeTrainingDays: (d?: UserWeekDays) => {
            return {
                ...(d || {} as UserWeekDays),
                thursday: !d?.thursday
            };
        }
    },
    {
        value: "friday", 
        name: 'Fri',
        func: (d?: UserWeekDays) => d?.friday,
        changeTrainingDays: (d?: UserWeekDays) => {
            return {
                ...(d || {} as UserWeekDays),
                friday: !d?.friday
            };
        }
    },
    {
        value: "Saturday", 
        name: 'Sat',
        func: (d?: UserWeekDays) => d?.saturday,
        changeTrainingDays: (d?: UserWeekDays) => {
            return {
                ...(d || {} as UserWeekDays),
                saturday: !d?.saturday
            };
        }
    }
];

export const daysOfWeekFlaged = [
    {
        value: DaysOfWeek.Sunday, 
        name: 'Sun'
    },
    {
        value: DaysOfWeek.Monday, 
        name: 'Mon'
    },
    {
        value: DaysOfWeek.Tuesday, 
        name: 'Tue'
    },
    {
        value: DaysOfWeek.Wednesday, 
        name: 'Wed'
    },
    {
        value: DaysOfWeek.Thursday, 
        name: 'Thu'
    },
    {
        value: DaysOfWeek.Friday, 
        name: 'Fri'
    },
    {
        value: DaysOfWeek.Saturday, 
        name: 'Sat'
    }
]