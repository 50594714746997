import { Language, SendTimeType } from "../shared/Primitives";
import { AutoMessage, SurveyDefinitionAnswerConstraints } from "./types";
import { SurveyDefinitionOption } from "./types";
import { ChatClient } from "./types/chatDirectorCoreTypes";
const jwt_decode = require('jwt-decode');

/**
 * Parse date and time from string
 * @param date Date (String)
 */
export function parseDate(date: string): Date {
  const d = new Date(Date.parse(date));
  const utc = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
  return utc;
};

/**
 * Convert local date to UTC date
 * @param date Date
 */
export function toUTC(d: Date): Date {
  const utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds(), d.getMilliseconds());
  return utc;
};

/**
 * Format local date to ISO format
 * @param date Date
 */
export function toISOString(date: Date | undefined): string | undefined {
  if (!date) return undefined;
  // Substract timezone offset cause build-in toISOString works with UTC representation
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date.toISOString();
}

/**
 * Format Date object like yyyy-MM-dd
 * @param date Input date
 * @returns yyyy-MM-dd
 */
export function formatDate(date: Date | undefined): string | undefined {
  if (!date) return undefined;
  let month = (date.getMonth() + 1).toString(), day = date.getDate().toString();
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [date.getFullYear(), month, day].join('-');
}

/**
 * Return true if date1 and date2 is the same day
 * @param date1 
 * @param date2 
 */
export function isTheSameDay(date1: Date, date2: Date): boolean {
  const isSame = date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate();
  return isSame;
}

export function getRandomKey(): string {
  return Math.random().toString(36).substring(2);
}

export function isDefinitionConstraintsEmpty(constraints: SurveyDefinitionAnswerConstraints | undefined): boolean {
  return !(constraints?.digits || constraints?.max || constraints?.min || constraints?.default);
}

export function isDefinitionOptionsEmpty(options: SurveyDefinitionOption[] | undefined): boolean {
  return !(options?.length && options!.length > 0);
}

export function hasRoles(accessToken: string, ...roles: string[]): boolean {
  if (!accessToken) return true;
  const decoded = jwt_decode(accessToken);
  const result = roles.length === 0 || roles.some(x => decoded.permissions.includes(x));
  return result;
}

export function getSendTimeText(msg: AutoMessage): string {
  const at = msg.sendTimeType === SendTimeType.Exact ? "at " : "";
  return `in ${msg.sendTimeDays}d ${at}${String(msg.sendTimeHours).padStart(2, "0")}:${String(msg.sendTimeMinutes).padStart(2, "0")}:${String(msg.sendTimeSeconds).padStart(2, "0")}`;
}

export function getNameAbbr(name: string): string {
  let names = name.split(' ')
  let result = "";
  names.forEach(el => {
    if (el.trim().length > 0 && result.length < 2) {
      result += el[0];
    }
  });
  result = result.length > 0 ? result : "?";
  return result;
}

export function getChatClientIcon(chatClient: ChatClient | undefined): string {
  if (!chatClient) {
    return "";
  }
  switch (chatClient) {
    case ChatClient.Mobile:
      return "📱";
    case ChatClient.Web:
      return "🌐";
    case ChatClient.Bot:
      return "🤖";
    case ChatClient.Admin:
      return "🅰️";
  }
}

export function getTextLocalization(textEn: string | undefined, textRu: string | undefined, textDe: string | undefined, lang: Language): string {
  switch (lang) {
    case Language.Ru:
      return textRu ?? "";
    case Language.De:
      return textDe ?? "";
    default:
      return textEn ?? "";
  }
}

/** Convert time (in seconds) to time format HH:mm:ss */
export function secondsToTimeString(time: number, includeHours?: boolean | undefined): string {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = time - hours * 3600 - minutes * 60;
  const res = [];
  if (includeHours || hours > 0) res.push(hours < 10 ? "0" + hours : hours);
  res.push(minutes < 10 ? "0" + minutes : minutes);
  res.push(seconds < 10 ? "0" + seconds : seconds);
  return res.join(":");
}

export function round(val: number, n: number): number {
  const d = Math.pow(10, n);
  return Math.round((val + Number.EPSILON) * d) / d;
}