import { UserType } from '../shared/Primitives';

export const userTypes = [
    {
        value: UserType.Unknown, 
        name: 'Unknown'
    },
    {
        value: UserType.Telegram, 
        name: 'Telegram'
    },
    {
        value: UserType.System, 
        name: 'System'
    },
    {
        value: UserType.Web, 
        name: 'Web'
    },
    {
        value: UserType.Mobile, 
        name: 'Mobile'
    }
];