import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { UserTrainingPlan } from "../../../api/types";
import Loader from '../../Loader';
import { format } from 'date-fns';
import { coachingTypeValues } from "../../../fixtures/coaching-types";
import UserProfileButtons, { ProfileType } from './UserProfileButtons';

import './UsersProfileView.scss';

interface Props extends RouteComponentProps<any> { }

const UsersProfileTrainingPlansView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
    const userId = props.match.params.userId;

    const [loading, setLoading] = useState(true);
    const [trainingPlans, setTrainingPlans] = useState([] as UserTrainingPlan[]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setLoading(true);
        baseCamp.getUserTrainingPlans(userId)
            .then(data => {
                setTrainingPlans(prepareTrainingPlans(data.plans));
            })
            .catch(e => {
                console.error(e);
                setErrorMessage(e.response?.data?.title ? e.response.data.title : `Error: ${JSON.stringify(e)}`);
            })
            .finally(() => setLoading(false))
    }, []);

    const prepareTrainingPlans = (trainingPlans: UserTrainingPlan[]) => {
        const dates = [] as Date[];
        return trainingPlans
            .map(plan => {
                plan.createdAtTime = new Date(plan.createdAt);
                plan.createdAt = new Date(new Date(plan.createdAt).setHours(0,0,0,0)); 
                return plan;
            })  
            .sort((a,b) => b.createdAtTime.getTime() - a.createdAtTime.getTime())          
            .map(plan => {
                plan.createdAtTime = new Date(plan.createdAt);
                plan.createdAt = new Date(new Date(plan.createdAt).setHours(0,0,0,0)); 

                if (!dates.some(d => d.getTime() === plan.createdAt.getTime())){ 
                    dates.push(plan.createdAt);
                    plan.showDay = true;
                }
                return plan;
            });
    }

    return (
        <div className="users-profile users-profile-trainings">
            <div>
                <h3 className="float-left">User ID: {userId}</h3>
                <div className="float-right">
                    <UserProfileButtons userId={userId} type={ProfileType.TrainingPlans} />
                </div>
                <div className="clear-both p-2" />
            </div>
            <h4>Training plans</h4>
            <div className={`alert alert-danger ${errorMessage ? "d-block" : "d-none"}`}>
                {errorMessage}
            </div>
            {
                trainingPlans.length ?
                    <div className={`users-profile-trainings-list ${errorMessage ? "d-none" : "d-block"}`}>
                        {
                            trainingPlans.map(t =>
                                <div key={`trainingPlan${t.id}`}>
                                    {
                                        t.showDay ?
                                            <div className="message-day my-3"><span className="px-2">
                                                {format(t.createdAt, "dd MMM yyyy")}
                                            </span></div> : ""
                                    }
                                    <div className={`alert alert-${t.isActive ? "success" : "secondary"}`}>
                                        <div>Coaching type: {coachingTypeValues.find(c => c.value === t.coachingType)?.name}</div>
                                        {t.goal ? <div>Goal: {t.goal}</div> : null}
                                        <div>{format(t.startDate, "dd MMM yyyy")} - {format(t.endDate, "dd MMM yyyy")}</div>
                                        <div className="my-1">
                                            {
                                                Object.keys(t.params).map((key, index) => {

                                                    return <span key={`params${key}${index}`} className="plan-parameter d-inline-block mr-3 px-2">
                                                        {key} {t.params[key]}
                                                    </span>;
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    : !loading ?
                    <h5>No training plans</h5> : "" 

            }
            {
                loading ? <Loader/> : ""
            }
        </div>
    );
}

export default UsersProfileTrainingPlansView;
