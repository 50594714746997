import React, { useState, useEffect } from 'react';
import { useAuth0 } from "./../../react-auth0-spa";
import { RouteComponentProps } from 'react-router';
import { ChatDirectorClient } from '../../api/ChatDirectorClient';
import './ChatView.scss';
import ChatUserList from './components/ChatUserList';
import ChatMessageList from './components/ChatMessageList';
import ChatUserInfo from './components/ChatUserInfo';
import { ChatUser, SearchUserFilterRequest } from '../../api/types/chatDirectorV2Types';
import AddNewUserModal from './components/AddNewUserModal';
import * as Icon from 'react-feather';
import classNames from 'classnames';
import { ChatRole } from '../../api/types/chatDirectorCoreTypes';

interface Props extends RouteComponentProps<any> { }

const ChatView = (props: Props) => {
    const { accessToken } = useAuth0();
    const chatDirectorClient = new ChatDirectorClient(accessToken);

    const [users, setUsers] = useState<ChatUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<ChatUser | undefined>(undefined);

    const [filterRequest, setFilterRequest] = useState<SearchUserFilterRequest>({
        roles: [],
        searchString: undefined
    } as SearchUserFilterRequest);

    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);

    const [isUserListLoading, setIsUserListLoading] = useState<boolean>(false);
    const [isLoadUserListSilent, setIsLoadUserListSilent] = useState<boolean>(false);

    const [showUsersMobile, setShowUsersMobile] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const userId = props.match.params.userId && parseInt(props.match.params.userId);
        loadUsers(userId);
    }, [filterRequest]);

    const loadUsers = (selectedUserId: number = 0) => {
        if (!isLoadUserListSilent) {
            setIsUserListLoading(true);
        }
        chatDirectorClient.getUsers(filterRequest)
            .then((res) => {
                setUsers(res);
                selectUser(selectedUserId, false, res);
            })
            .finally(() => setIsUserListLoading(false));
    }

    const changeFilterRole = (role: ChatRole, checked: boolean) => {
        setIsLoadUserListSilent(false);
        const roles = filterRequest.roles;
        if (checked) {
            roles.push(role);
            setFilterRequest({
                ...filterRequest,
                roles: roles
            });
        }
        else {
            const newRoles = roles.filter(x => x != role);
            setFilterRequest({
                ...filterRequest,
                roles: newRoles
            });
        }
    }

    let timer: NodeJS.Timeout | undefined;
    const changeFilterSearchString = (s: string) => {
        setIsLoadUserListSilent(true);
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {

            setFilterRequest({
                ...filterRequest,
                searchString: s.length > 0 ? s : undefined
            });

        }, 400);
    }

    const importUsers = () => {
        setIsUserListLoading(true);
        setIsLoadUserListSilent(false);

        chatDirectorClient.importUsers()
            .then(() => {
                loadUsers();
                alert("Users were imported successfully.");
            })
            .finally(() => setIsUserListLoading(false));
    }

    const selectUser = (userId: number, hideUserList: boolean, usersParam: ChatUser[] | undefined = undefined) => {
        var currentUsers = usersParam ?? users;
        var selectedUser = currentUsers.filter(x => x.userId === userId)[0];
        setSelectedUser(selectedUser);

        if (selectedUser) {
            props.history.push(`/chat/${userId}`);
            chatDirectorClient.checkBasecampUser(selectedUser.userId)
                .then((resp) => {
                    selectedUser.coachId = resp?.coachId;
                    selectedUser.subscriptionPlanTitle=resp?.subscriptionPlanTitle;
                    selectedUser.hasFreePlan = resp?.hasFreePlan ?? false;
                    setSelectedUser(selectedUser);
                })
                .catch((ex) => {
                    console.log("Load basecamp user error: ", ex);
                })
                .finally(() => {});
        }
        else {
            props.history.push(`/chat`);
        }

        if (hideUserList) {
            setShowUsersMobile(undefined);
        }
    }

    const updateSelectedUser = (userParam: ChatUser) => {
        let currentUsers = [...users];
        let i = currentUsers.findIndex(x => x.userId === userParam.userId);
        if (i >= 0) {
            currentUsers[i] = userParam;
            setUsers(currentUsers);
        }

        if (selectedUser?.userId === userParam.userId) {
            setSelectedUser(userParam);
        }
    }

    const handleMobileUsersButton = () => {
        const newVal = showUsersMobile === undefined ? true : !showUsersMobile;
        setShowUsersMobile(newVal);
    }

    const getUserListCss = () => {
        return classNames({
            "user-list-container": true,
            "d-md-flex": showUsersMobile === undefined,
            "d-none": !showUsersMobile,
            "d-flex": showUsersMobile
        });
    }

    const getMessageListCss = () => {
        return classNames({
            "message-list-container": true,
            "d-md-flex": showUsersMobile === undefined,
            "d-none": showUsersMobile,
            "d-flex": showUsersMobile === undefined || !showUsersMobile
        });
    }

    return <div className="omy-chat">
        <div className="mobile-users-button-container d-flex d-md-none">
            <button type="button" className="btn btn-secondary" onClick={e => handleMobileUsersButton()}><Icon.Users /> Users</button>
        </div>
        <div className="chat-container">
            <div className={getUserListCss()}>
                <ChatUserList
                    users={users}
                    selectedUser={selectedUser}
                    filterSelectedRoles={filterRequest.roles}
                    isLoading={isUserListLoading}
                    filterRoleChanged={changeFilterRole}
                    filterSerchStringChanges={changeFilterSearchString}
                    importUsers={importUsers}
                    addUserClick={() => { setShowAddUserModal(true); }}
                    selectUser={selectUser}
                />
            </div>
            <div className={getMessageListCss()}>
                {selectedUser && 
                    <ChatMessageList
                        key={selectedUser.userId} // https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
                        selectedUser={selectedUser}
                        selectedUserUpdated={updateSelectedUser}
                    />
                }
            </div>
            <div className="user-info-container d-none d-md-block">
                <ChatUserInfo
                    selectedUser={selectedUser}
                    avatarUrlUpdated={updateSelectedUser}
                    roleUpdated={updateSelectedUser}
                    chatAssistantEnabledUpdated={updateSelectedUser}
                />
            </div>
        </div>

        <AddNewUserModal show={showAddUserModal} onClose={() => { setShowAddUserModal(false); loadUsers(); }} />
    </div>
}

export default ChatView;