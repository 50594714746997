import React from 'react';

import { PlanOverviewWeek } from '../../api/types';
import { TrainingType } from '../../shared/Primitives';

import './PlanOverview.scss';

interface Props {
    weeks: PlanOverviewWeek[]
}

const PlanOverview = ({ weeks }: Props) =>
    <div className="plan-overview">
        <table className="table">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Week</th>
                    <th scope="col" className="plan-day">Mon</th>
                    <th scope="col" className="plan-day">Tue</th>
                    <th scope="col" className="plan-day">Wed</th>
                    <th scope="col" className="plan-day">Thu</th>
                    <th scope="col" className="plan-day">Fri</th>
                    <th scope="col" className="plan-day">Sat</th>
                    <th scope="col" className="plan-day">Sun</th>
                </tr>
            </thead>
            <tbody>
                {weeks.map((week) => {
                    return (
                        <tr key={week.number}>
                            <th scope="row"><h4>{week.number}</h4></th>
                            <td><h3>{week.subTypeString}</h3></td>
                            <td>{getWorkoutOfDay(week, 1)}</td>
                            <td>{getWorkoutOfDay(week, 2)}</td>
                            <td>{getWorkoutOfDay(week, 3)}</td>
                            <td>{getWorkoutOfDay(week, 4)}</td>
                            <td>{getWorkoutOfDay(week, 5)}</td>
                            <td>{getWorkoutOfDay(week, 6)}</td>
                            <td>{getWorkoutOfDay(week, 0)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>

const getWorkoutOfDay = (week: PlanOverviewWeek, dayOfWeek: number) => {
    const workout = week.trainingDays.find((el) => {
        return el.dayOfWeek === dayOfWeek;
    });

    if (!workout) {
        return <span></span>
    }

    const badgeType = 'badge ' + getBadgeType(workout.trainingType);

    return <div className="workout">
        <div className="workout__type"><span className={badgeType}>{TrainingType[workout.trainingType]}</span></div>
        <div className="workout__desc">{workout.description}</div>
        <div className="workout__distance">{workout.totalDistance / 1000} km</div>
    </div>
}

const getBadgeType = (trainingType: TrainingType) => {
    switch (trainingType) {
        case TrainingType.LongRun:
            return 'badge-primary';
        case TrainingType.EasyRun:
            return 'badge-success';
        case TrainingType.IntervalRun:
            return 'badge-danger';
        case TrainingType.RunWalk:
            return 'badge-info';
        case TrainingType.StridesRun:
            return 'badge-dark';
        case TrainingType.TempoRun:
            return 'badge-warning';
        case TrainingType.ProgressiveRun:
            return 'badge-warning';
        default:
            return 'badge-secondary';
    }
}

export default PlanOverview;
