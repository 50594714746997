import React, { useState, useEffect } from 'react';
import './Blog.scss';
import { BaseCampClient } from '../../api/BaseCampClient';
import { BlogItem, BlogSaveRequest } from '../../api/types';
import { useAuth0 } from '../../react-auth0-spa';
import Loader from '../Loader';
import * as Icon from 'react-feather';
import { Col, Form, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import useForm from 'react-hook-form';
import Editor from "rich-markdown-editor";

interface Props extends RouteComponentProps<any> { }

const BlogEditView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const blogItemId = props.match.params.blogId;

    const [blogItem, setBlogItem] = useState<BlogItem>({
        id: 0,
        summary: "",
        content: "",
        language: "en",
        title: ""
    } as BlogItem);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editorKey, sestEidtorKey] = useState<string>("eidtor_key_loading");

    const languages = ["en", "ru"];

    const { handleSubmit } = useForm();

    useEffect(() => {
        setError(undefined);
        if (blogItemId > 0) {
            setIsLoading(true);
            baseCamp.getBlogItem(blogItemId)
                .then((res) => {
                    setBlogItem(res);
                    sestEidtorKey("eidtor_key_loaded");
                })
                .catch((ex) => {
                    console.log("loading blog item error:", ex);
                    let msg = "There was an error during load data. Please try latter.";
                    if (ex.response?.status == 400) {
                        msg = ex.response.data;
                    }
                    setError(msg);
                })
                .finally(() => setIsLoading(false));
        }
    }, []);

    const validate = (): boolean => {
        let validateList: string[] = [];
        if (blogItem.content.length === 0) {
            validateList.push("Content must be specified.");
        }
        if (blogItem.summary.length === 0) {
            validateList.push("Summary must be specified.");
        }
        if (blogItem.language.length === 0) {
            validateList.push("Language must be specified.");
        }
        if (blogItem.title.length === 0) {
            validateList.push("Title must be specified.");
        }

        setError(validateList.join(" "));

        return validateList.length === 0;
    }

    const saveData = () => {
        if (!validate()) {
            return;
        }

        let req: BlogSaveRequest = {
            summary: blogItem.summary,
            content: blogItem.content,
            language: blogItem.language,
            title: blogItem.title,
            userId: undefined
        };

        setIsLoading(true);
        baseCamp.saveBlogItem(blogItemId, req)
            .then((res) => {
                setBlogItem(res);
                alert("Blog item was successfully saved!");
                window.location.href = `/blog`;
            })
            .catch((ex) => {
                console.log("Save blog item error:", ex);
                let msg = "There was an error during saving blog item. Please try latter.";
                if (ex.response?.status == 400) {
                    msg = ex.response.data;
                }
                setError(msg);
            })
            .finally(() => setIsLoading(false));
    }

    return <div className="blog blog-edit">
        {
            error ? <div className="fade alert alert-danger show">{error}</div> : null
        }
        <h3>
            {
                blogItemId > 0 ? `Edit Blog Item: ${blogItemId}` : "New Blog Item"
            }
        </h3>
        <Form>
            <Form.Group controlId="itemTitle">
                <Form.Label>Title:</Form.Label>
                <Form.Control type="text" placeholder="Title" value={blogItem?.title || ""}
                    onChange={(e) => {
                        setBlogItem({ ...blogItem, title: e.currentTarget.value });
                    }}
                    isValid={false}
                />
            </Form.Group>
            <Form.Group controlId="messageEvent">
                <Row>
                    <Form.Label column sm="2">Language:</Form.Label>
                    <Col sm={2}>
                        <Form.Control as="select" value={blogItem.language} onChange={(e) => { setBlogItem({ ...blogItem, language: e.target.value }); }}>
                            {
                                languages.map((x) => {
                                    return <option key={x} value={x}>{x}</option>
                                })
                            }
                        </Form.Control>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group controlId="blogItemSummary">
                <Form.Label>Summary:</Form.Label>
                <div>
                    <Form.Control as="textarea" rows={3}
                        value={blogItem.summary}
                        onChange={e => setBlogItem({ ...blogItem, summary: e.target.value })}
                    ></Form.Control>
                </div>
            </Form.Group>
            <Form.Group controlId="blogItemContent">
                <Form.Label>Content:</Form.Label>
                <div className="editor-container">
                    <Editor
                        key={editorKey}
                        disableExtensions={[]}
                        defaultValue={blogItem.content}
                        autoFocus={true}
                        onChange={val => setBlogItem({ ...blogItem, content: val() })}
                    />
                </div>
            </Form.Group>
            <Row className="bottom-button-panel">
                <Col>
                    <button type="submit" className="btn btn-sm btn-success" onClick={handleSubmit(saveData)}>Save</button>
                    <button className="btn btn-sm btn-primary" onClick={() => { props.history.push("/blog") }}>Cancel</button>
                </Col>
            </Row>
        </Form>
    </div >
}

export default BlogEditView;