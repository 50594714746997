import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { hasRoles } from '../../api/Helpers'
import { useAuth0 } from '../../react-auth0-spa'

import * as Icon from 'react-feather';
import classNames from 'classnames';

import './NavPanel.scss';

const NavPanel: React.FunctionComponent = (props) => {
    const { accessToken } = useAuth0();
    const [showMenuMobile, setShowMenuMobile] = useState<boolean | undefined>(undefined);

    const getNavCss = () => {
        return classNames({
            "col-md-2 bg-light sidebar nav": true,
            "d-md-block": showMenuMobile === undefined,
            "d-none": !showMenuMobile,
            "d-block": showMenuMobile
        });
    }

    const navLinkClick = () => {
        setShowMenuMobile(undefined);
    }

    return <>
        <div className="mobile-menu-button-container d-flex d-md-none" style={{left: window.screen.availWidth - 70}}>
            <button type="button" className="btn btn-secondary" onClick={() => { setShowMenuMobile(showMenuMobile === undefined ? true : !showMenuMobile) }}><Icon.Menu /></button>
        </div>
        <Nav className={getNavCss()} defaultActiveKey="/dashboard" as="ul">
            {
                hasRoles(accessToken, "admin") ?
                    <Nav.Item as="li" className="nav-item" >
                        <NavLink className="nav-link" activeClassName="active" to="/dashboard" onClick={() => { navLinkClick() }}
                            isActive={(match, location) => /(\/dashboard)|(\/$)/.test(location?.pathname)}>
                            <Icon.Grid className="nav__icon" /> Dashboard
                        </NavLink>
                    </Nav.Item>
                    : null
            }

            <Nav.Item as="li" className="nav-item" >
                <NavLink className="nav-link" activeClassName="active" to="/users" onClick={() => { navLinkClick() }}
                    isActive={(match, location) => /(\/users)|(\/user\/\d+)/.test(location?.pathname)}>
                    <Icon.Users className="nav__icon" /> Users
                </NavLink>
            </Nav.Item>

            <Nav.Item as="li" className="nav-item" >
                <NavLink className="nav-link" activeClassName="active" to="/chat" onClick={() => { setShowMenuMobile(undefined) }}><Icon.MessageSquare className="nav__icon" />Chat</NavLink>
            </Nav.Item>

            {
                hasRoles(accessToken, "admin") ?
                    <>
                        <Nav.Item as="li" className="nav-item" >
                            <NavLink className="nav-link" activeClassName="active" to="/blog" onClick={() => { navLinkClick() }}><Icon.PenTool className="nav__icon" />Blog</NavLink>
                        </Nav.Item>
                    </>
                    : null
            }

            <Nav.Item as="li" className="nav-item" >
                <NavLink className="nav-link" activeClassName="active" to="/trainings" onClick={() => { navLinkClick() }}><Icon.Calendar className="nav__icon" /> Trainings</NavLink>
            </Nav.Item>

            {
                hasRoles(accessToken, "admin") ?
                    <>
                        <Nav.Item as="li" className="nav-item" >
                            <NavLink className="nav-link" activeClassName="active" to="/new-plans" onClick={() => { navLinkClick() }}><Icon.BarChart2 className="nav__icon" /> New plans</NavLink>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item" >
                            <NavLink className="nav-link" activeClassName="active" to="/auto-messages" onClick={() => { navLinkClick() }}><Icon.Edit className="nav__icon" />Auto Messages</NavLink>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item" >
                            <NavLink className="nav-link" activeClassName="active" to="/surveys" onClick={() => { navLinkClick() }}><Icon.Info className="nav__icon" />Surveys</NavLink>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item" >
                            <NavLink className="nav-link" activeClassName="active" to="/messaging" onClick={() => { navLinkClick() }}><Icon.Send className="nav__icon" /> Broadcasting</NavLink>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item" >
                            <NavLink className="nav-link" activeClassName="active" to="/promo-offer-history" onClick={() => { navLinkClick() }}><Icon.List className="nav__icon" /> Promo Offers</NavLink>
                        </Nav.Item>
                        {/* <Nav.Item as="li" className="nav-item" >
                            <NavLink className="nav-link" activeClassName="active" to="/plans" onClick={() => { navLinkClick() }}><Icon.List className="nav__icon" /> Plans</NavLink>
                        </Nav.Item> */}
                        {/* <Nav.Item as="li" className="nav-item" >
                            <NavLink className="nav-link" activeClassName="active" to="/activities" onClick={() => { navLinkClick() }}><Icon.Activity className="nav__icon" />Activities</NavLink>
                        </Nav.Item> */}
                    </>
                    : null
            }
        </Nav>
    </>
};

export default NavPanel;
