export const workoutTypeValues = [
    {
        value: 'other',
        label: 'other'
    },
    {
        value: 'run',
        label: 'run'
    },
    {
        value: 'easyrun',
        label: 'easyrun'
    },
    {
        value: 'longrun',
        label: 'longrun'
    },
    
    {
        value: 'stridesrun',
        label: 'stridesrun'
    },
    {
        value: 'runwalk',
        label: 'runwalk'
    },
    {
        value: 'shortintervalsrun',
        label: 'shortintervalsrun'
    },
    {
        value: 'longintervalsrun',
        label: 'longintervalsrun'
    },
    {
        value: 'temporun',
        label: 'temporun'
    },
    {
        value: 'progressiverun',
        label: 'progressiverun'
    },
    {
        value: 'fartlek',
        label: 'fartlek'
    },
    {
        value: 'hillsrun',
        label: 'hillsrun'
    },
    {
        value: 'intervalrun',
        label: 'intervalrun'
    },
    {
        value: 'simulatedracerun',
        label: 'simulatedracerun'
    },
    {
        value: 'virtualrun',
        label: 'virtualrun'
    },
    {
        value: 'ride',
        label: 'ride'
    },
    {
        value: 'easyride',
        label: 'easyride'
    },
    {
        value: 'intervalsride',
        label: 'intervalsride'
    },
    {
        value: 'longride',
        label: 'longride'
    },
    {
        value: 'strengthride',
        label: 'strengthride'
    },
    {
        value: 'recoveryride',
        label: 'recoveryride'
    },
    {
        value: 'temporide',
        label: 'temporide'
    },
    {
        value: 'performancetest',
        label: 'performancetest'
    },
    {
        value: 'virtualride',
        label: 'virtualride'
    },
    {
        value: 'swim',
        label: 'swim'
    },
    {
        value: 'enduranceswim',
        label: 'enduranceswim'
    },
    {
        value: 'openwaterswim',
        label: 'openwaterswim'
    },
    {
        value: 'recoveryswim',
        label: 'recoveryswim'
    },
    {
        value: 'temposwim',
        label: 'temposwim'
    },
    {
        value: 'strengthswim',
        label: 'strengthswim'
    },
    {
        value: 'technicswim',
        label: 'technicswim'
    },
    {
        value: 'crossfit',
        label: 'crossfit'
    },
    {
        value: 'gym',
        label: 'gym'
    },
    {
        value: 'fitness',
        label: 'fitness'
    },
    {
        value: 'stretching',
        label: 'stretching'
    },
    {
        value: 'fasting',
        label: 'fasting'
    },
    {
        value: 'yoga',
        label: 'yoga'
    },
    {
        value: 'brick',
        label: 'brick'
    },
    {
        value: 'crosscountryski',
        label: 'crosscountryski'
    },
    {
        value: 'hike',
        label: 'hike'
    },
    {
        value: 'walk',
        label: 'walk'
    },
    {
        value: 'alpineski',
        label: 'alpineski'
    },
    {
        value: 'snowboard',
        label: 'snowboard'
    },
    {
        value: 'multisport',
        label: 'multisport'
    },
    {
        value: 'transition',
        label: 'transition'
    },
    {
        value: 'race',
        label: 'race'
    },
    {
        value: 'triathlonrace',
        label: 'triathlonrace'
    },
    {
        value: 'aquathlonrace',
        label: 'aquathlonrace'
    },
    {
        value: 'duathlonrace',
        label: 'duathlonrace'
    },
    {
        value: 'mtbrace',
        label: 'mtbrace'
    },
    {
        value: 'roadcyclingrace',
        label: 'roadcyclingrace'
    },
    {
        value: 'runrace',
        label: 'runrace'
    }
];