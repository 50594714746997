import { ChatClient, ChatRole } from "./chatDirectorCoreTypes";

export const rolesOptions = [
    { value: ChatRole.User, label: "User" },
    { value: ChatRole.Admin, label: "Admin" },
    { value: ChatRole.Coach, label: "Coach" }
];

export interface SearchUserFilterRequest {
    searchString: string | undefined,
    roles: ChatRole[]
}

export interface SendChatMessageRequest {
    userId: number;
    body: string;
}

export interface BroadcastChatMessageRequest {
    userIds: number[];
    body: string;
}

export interface ChatMessage {
    id: number;
    createdAt: Date;
    updatedAt: Date | undefined;
    userId: number;
    userName: string;
    userRole: ChatRole;
    userAvatarUrl: string | undefined;
    senderUserId: number;
    senderName: string;
    senderRole: ChatRole;
    senderAvatarUrl: string | undefined;
    body: string;
    isRead: boolean;
    isSeen: boolean;
    isYours: boolean;
    chatClient: ChatClient | undefined;
}

export interface MarkReadRequest {
    messageIds: number[],
    isRead: boolean
}

export interface ChatUser {
    userId: number;
    email: string;
    name: string;
    avatarUrl: string | undefined;
    role: ChatRole;
    lastMessageDate: Date | undefined;
    unreadUsersMessageCount: number;
    coachId: number | undefined;
    subscriptionPlanTitle: string | undefined;
    hasFreePlan: boolean;
    isChatAssistantEnabled: boolean;
}

export interface UpdateUserRequest {
    userId: number;
    email: string;
    name: string;
    avatarUrl: string | undefined;
    role: ChatRole;
}

export interface SetChatAssistantEnabledRequest {
    enabled: boolean;
}
