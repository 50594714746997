import React from 'react';
import Select from 'react-select';
import { UserShortInfo } from '../../api/types';

interface Props {
    onUserChanged: (userId: number) => void,
    users: UserShortInfo[],
    selectedUserId: number
}

class UserSelector extends React.Component<Props> {
    handleChange = (item: any, actionMeta: any) => {
        const user = item as UserShortInfo;
        this.props.onUserChanged(user.id);
    };

    render() {
        const selectStyle = { relative: "relative", zIndex: 999 };

        const findSelectedValue = (selectedUserId: number | undefined): UserShortInfo | undefined => {
            if (!selectedUserId) {
                return undefined;
            }
            return this.props.users.filter(user => user.id === selectedUserId)[0]
        };

        return <div>
            <div className="row">
                <div className="col-md-4" style={selectStyle}>
                    <Select
                        options={this.props.users}
                        value={findSelectedValue(this.props.selectedUserId)}
                        getOptionLabel={user => `${user.id}: ${user.email}`}
                        getOptionValue={user => `${user.id}`}
                        onChange={this.handleChange.bind(this)}
                    />
                </div>
            </div>
        </div>
    }
};

export default UserSelector;
