import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { SurveyResult } from "../../../api/types";
import UserProfileButtons, { ProfileType } from './UserProfileButtons';
import Loader from '../../Loader';
import { format } from 'date-fns';
import { surveyTypeValues } from "../../../fixtures/survey-types";
import JSONPretty from 'react-json-pretty';

var JSONPrettyMon = require('react-json-pretty/dist/1337');
interface Props extends RouteComponentProps<any> { }

const UsersProfileSurveyResultView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
    const userId = props.match.params.userId;

    const [loading, setLoading] = useState(true);
    const [surveyResults, setSurveyResults] = useState([] as SurveyResult[]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setLoading(true);
        baseCamp.getUserSurveyResults(userId)
            .then(data => {
                setSurveyResults(prepareSurveyResults(data));
            })
            .catch(e => {
                console.error(e);
                setErrorMessage(e.response?.data?.title ? e.response.data.title : `Error: ${JSON.stringify(e)}`);
            })
            .finally(() => setLoading(false))
    }, []);

    const prepareSurveyResults = (results: SurveyResult[]) => {
        const dates = [] as Date[];
        return results
            .map(r => {
                r.createdAtTime = new Date(r.createdAt);
                r.createdAt = new Date(new Date(r.createdAt).setHours(0,0,0,0)); 
                return r;
            })  
            .sort((a,b) => b.createdAtTime.getTime() - a.createdAtTime.getTime())          
            .map(r => {
                if (!dates.some(d => d.getTime() === r.createdAt.getTime())){ 
                    dates.push(r.createdAt);
                    r.showDay = true;
                }
                return r;
            });
    }

    return (
        <div className="users-profile users-profile-survey">
            <div>
                <h3 className="float-left">User ID: {userId}</h3>
                <div className="float-right">
                    <UserProfileButtons userId={userId} type={ProfileType.SurveyResult} />
                </div>
                <div className="clear-both p-2" />
            </div>
            <h4>Survey results</h4>
            
            <div className={`alert alert-danger ${errorMessage ? "d-block" : "d-none"}`}>
                {errorMessage}
            </div>
            {
                surveyResults.length ?
                <div className={`users-profile-survey-list ${errorMessage ? "d-none" : "d-block"}`}>
                    {
                        surveyResults.map(s => {
                            const surveyType = surveyTypeValues.find(st => st.value === s.surveyType) || {
                                text: s.surveyType.toString(),
                                bgColor: "#888",
                                font: "#fff"
                            };

                            return (
                                <div key={`surveyResult${s.id}`}>
                                    {
                                        s.showDay ?
                                            <div className="message-day my-3"><span className="px-2">
                                                {format(s.createdAt, "dd MMM yyyy")}
                                            </span></div> : ""
                                    }
                                    <div className={`alert alert-${s.isActive ? "success" : "secondary"}`}>
                                        <div>Created: {format(s.createdAtTime, "dd MMM yyyy HH:mm:ss")}</div>
                                        <div>Confirmed: {s.confirmedAt ? format(s.confirmedAt, "dd MMM yyyy HH:mm:ss") : "not"}</div>
                                        <div className="survey-type">Survey type: 
                                            <span style={{color: surveyType.font, backgroundColor: surveyType.bgColor}}>
                                                {surveyType.text}
                                            </span>
                                        </div>
                                        {
                                            !s.resultJson ? "" :
                                            <div className="mt-3 mb-3">
                                                <JSONPretty id="json-pretty" data={s.resultJson} theme={JSONPrettyMon} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                : !loading ?
                <h5>No survey results</h5> : "" 
            }
            {
                loading ? <Loader/> : ""
            }
        </div>
    );
    
};

export default UsersProfileSurveyResultView;