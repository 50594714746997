import React, { useEffect, useState, createRef } from 'react';
import { useAuth0 } from "./../../../react-auth0-spa";
import { ChatUser, rolesOptions } from '../../../api/types/chatDirectorV2Types';
import { getNameAbbr, hasRoles } from '../../../api/Helpers';
import { ChatDirectorClient } from '../../../api/ChatDirectorClient';
import Select from 'react-select';
import { ChatRole } from '../../../api/types/chatDirectorCoreTypes';

type ChatUserInfoProps = {
    selectedUser: ChatUser | undefined,
    avatarUrlUpdated: (user: ChatUser) => void,
    roleUpdated: (user: ChatUser) => void,
    chatAssistantEnabledUpdated: (user: ChatUser) => void
}

const ChatUserInfo = (props: ChatUserInfoProps) => {
    const { accessToken } = useAuth0();
    const chatDirectorClient = new ChatDirectorClient(accessToken);

    const [isEditAvatar, setIsEditAvatar] = useState<boolean>(false);
    const [isEditRole, setIsEditRole] = useState<boolean>(false);
    const [userCoach, setUserCoach] = useState<ChatUser | undefined>(undefined);
    const [isCoachLoading, setIsCoachLoading] = useState<boolean>(false);

    let avatarInputRef = createRef<HTMLInputElement>();
    let roleInputRef: any;

    useEffect(() => {
        setIsEditRole(false);
        setIsEditAvatar(false);
        loadCoach();
    }, [props.selectedUser]);

    const loadCoach = () => {
        if (props.selectedUser) {
            setIsCoachLoading(true);
            chatDirectorClient.getUserCoachInfo(props.selectedUser.userId)
                .then((resp) => {
                    setUserCoach(resp);
                })
                .catch((ex) => {
                    console.log("loadCoach error: ", ex);
                })
                .finally(() => setIsCoachLoading(false));

        }
        else {
            setUserCoach(undefined);
        }
    }

    const saveAvatar = () => {
        const newAvatar = avatarInputRef.current?.value || "";
        if (newAvatar.length === 0) {
            alert("Avatar url can't be empty!");
            return;
        }

        chatDirectorClient.setAvatarUrl(props.selectedUser!.userId, newAvatar)
            .then((resp) => {
                setIsEditAvatar(false);
                const newUser: ChatUser = {
                    ...props.selectedUser!,
                    avatarUrl: newAvatar
                };
                props.avatarUrlUpdated(newUser);
            })
            .catch((ex) => {
                console.log("saveAvatar error: ", ex);
                if (ex.response?.status == 400) {
                    alert("Error: " + ex.response.data);
                }
                else {
                    alert("There was an error. Avatar wasn't saved.");
                }
            });
    }

    const saveRole = () => {
        var selectedValue = roleInputRef.state.value.value as ChatRole;
        if (selectedValue) {
            chatDirectorClient.setRole(props.selectedUser!.userId, selectedValue)
                .then((resp) => {
                    setIsEditAvatar(false);
                    const newUser: ChatUser = {
                        ...props.selectedUser!,
                        role: selectedValue
                    };
                    props.roleUpdated(newUser);
                })
                .catch((ex) => {
                    console.log("saveAvatar error: ", ex);
                    if (ex.response?.status == 400) {
                        alert("Error: " + ex.response.data);
                    }
                    else {
                        alert("There was an error. Avatar wasn't saved.");
                    }
                });


        }
    }

    const saveChatAssistantEnabled = (value: boolean) => {
        chatDirectorClient.setChatAssistantEnabled(props.selectedUser!.userId, value)
            .then((resp) => {
                const newUser: ChatUser = {
                    ...props.selectedUser!,
                    isChatAssistantEnabled: value
                };
                props.chatAssistantEnabledUpdated(newUser);
            })
            .catch((ex) => {
                console.log("saveChatAssistantEnabled error: ", ex);
                if (ex.response?.status == 400) {
                    alert("Error: " + ex.response.data);
                }
                else {
                    alert("There was an error. ChatAssistantEnabled wasn't saved.");
                }
            });
    }

    if (!props.selectedUser) {
        return <div className="no-user"></div>
    }
    return <>
        <div className="avatar-container">
            <h3>{props.selectedUser.name}</h3>
            {
                props.selectedUser.avatarUrl?.length || 0 > 0 ?
                    <img className="avatar" src={props.selectedUser.avatarUrl} />
                    : <div className="text-avatar">{getNameAbbr(props.selectedUser.name)}</div>
            }
            {
                hasRoles(accessToken, "admin") ?
                    <div>
                        {

                            !isEditAvatar ?
                                <button style={{ marginTop: "10px" }} className="btn btn-sm btn-primary" onClick={() => setIsEditAvatar(true)}>change avatar</button>
                                : <div className="avatar-edit-container">
                                    <input type="text" className="form-control form-control-sm" placeholder="Avatar url..."
                                        ref={avatarInputRef}
                                        defaultValue={props.selectedUser.avatarUrl || ""}
                                    />
                                    <button className="btn btn-sm btn-success" onClick={saveAvatar}>Save</button>
                                    <button className="btn btn-sm btn-primary" onClick={() => setIsEditAvatar(false)}>Cancel</button>
                                </div>
                        }
                    </div>
                    : null
            }
        </div>
        <div className="container">
            <div className="row">
                <div className="col col-sm col-sm-3">Plan:</div>
                <div className="col col-sm col-sm-9">
                    {
                        <h5>
                            <span style={{ "color": "#fff" }} className={`badge ${props.selectedUser.hasFreePlan ? "bg-danger" : "bg-success"}`}>
                                {props.selectedUser.subscriptionPlanTitle}
                            </span>
                        </h5>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col col-sm col-sm-3">Name:</div>
                <div className="col col-sm col-sm-9">{props.selectedUser.name}</div>
            </div>
            <div className="row">
                <div className="col col-sm col-sm-3">Email:</div>
                <div className="col col-sm col-sm-9">{props.selectedUser.email}</div>
            </div>
            <div className="row">
                <div className="col col-sm col-sm-3">Profile:</div>
                <div className="col col-sm col-sm-9"><a target="_blank" href={`/user/${props.selectedUser.userId}/profile`} >open</a></div>
            </div>
            <div className="row">
                <div className="col col-sm col-sm-3">Coach:</div>
                <div className="col col-sm col-sm-9">
                    {
                        isCoachLoading ?
                            <div className="spinner-border spinner-border-sm loader" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            : userCoach ? `${userCoach.name} (${userCoach.email})` : "-"
                    }
                </div>
            </div>
            {
                hasRoles(accessToken, "admin") ?
                    <>
                        <div className="row">
                            <div className="col col-sm col-sm-3">UserId:</div>
                            <div className="col col-sm col-sm-9">{props.selectedUser.userId}</div>
                        </div>
                        <div className="row">
                            <div className="col col-sm col-sm-3">Chat GPT:</div>
                            <div className="col col-sm col-sm-9">
                                <input id="cbIsChatAssistantEnabled" type="checkbox"
                                    checked={props.selectedUser.isChatAssistantEnabled}
                                    onChange={(event) => { saveChatAssistantEnabled(event.target.checked) }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-sm col-sm-3">Role:</div>
                            <div className="col col-sm col-sm-9">
                                {
                                    !isEditRole ? <>

                                        <span style={{ marginRight: "10px" }}>{props.selectedUser.role}</span>
                                        <a onClick={() => setIsEditRole(true)}>edit</a>

                                    </>
                                        : <>
                                            <div>
                                                <Select
                                                    placeholder="Role..."
                                                    options={rolesOptions}
                                                    defaultValue={rolesOptions.find(x => x.value === props.selectedUser!.role)}
                                                    ref={ref => { roleInputRef = ref }}
                                                />
                                            </div>
                                            <div className="role-buttons">
                                                <button className="btn btn-sm btn-success" onClick={saveRole}>Save</button>
                                                <button className="btn btn-sm btn-primary" onClick={() => setIsEditRole(false)}>Cancel</button>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </>
                    : null
            }
        </div>
    </>
}

export default ChatUserInfo;