import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { DateFormat, FilterSortDirection } from "../../shared/Primitives";
import ListPagination from "./ListPagination";
import "./DataTable.scss";

export interface DataTableColumn {
  fieldName: string;
  title: string | undefined;
  allowSort?: boolean | undefined;
  cutLongText?: boolean | undefined;
  dateFormat?: DateFormat | undefined;
  renderCell?: (item: any) => ReactNode | undefined;
}

export type DataTableProps = {
  data?: Object[];
  columns?: DataTableColumn[];
  emptyDataMessage: string;
  showCmdButtons?: boolean;
  usePagination?: boolean;
  pageNumber?: number;
  perPage?: number;
  totalCountPagination?: number;

  onEditClick?: (row: any) => void;
  onDeleteClick?: (row: any) => void;
  onSort?: (fieldName: string, sortDirection: FilterSortDirection) => void;
  onPageNumberChanged?: (value: number) => void;
  onPerPageChanged?: (value: number) => void;
};

const DataTable: React.FC<DataTableProps> = ({
  data,
  columns,
  emptyDataMessage,
  showCmdButtons,
  usePagination,
  pageNumber,
  perPage,
  totalCountPagination,
  onEditClick,
  onDeleteClick,
  onSort,
  onPageNumberChanged,
  onPerPageChanged
}) => {
  const [sortField, setSortField] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<FilterSortDirection>(
    FilterSortDirection.Asc
  );

  const hasData = data && data.length > 0;
  const tableColumns = columns ?? (hasData ? Object.getOwnPropertyNames(data![0]).map(propName => {
    return {
      fieldName: propName,
      title: propName,
      cutLongText: true
    } as DataTableColumn;
  }) : []);

  useEffect(() => { }, [sortField, sortDirection]);

  const getValueString = (val: any, col: DataTableColumn): string => {
    if (val instanceof Date) {
      return moment(val).format(col?.dateFormat === DateFormat.Date ? "DD.MM.YYYY" : "DD.MM.YYYY HH:mm:ss");
    } else {
      return col.cutLongText ? cutLongText(String(val)) : String(val);
    }
  };

  const cutLongText = (val: string): string => {
    return val.length > 100 ? `${val.substring(0, 100)}...` : val;
  };

  const sortHeaderSign = (field: string) => {
    if (field !== sortField) {
      return null;
    }
    const sortCss = `sort${sortDirection === FilterSortDirection.Desc ? " sort-desc" : ""}`;
    return <span className={sortCss}>▲</span>;
  };

  const handleSort = (field: string) => {
    const dir =
      sortField === field
        ? sortDirection === FilterSortDirection.Asc
          ? FilterSortDirection.Desc
          : FilterSortDirection.Asc
        : FilterSortDirection.Asc;

    setSortField(field);
    setSortDirection(dir);

    if (onSort) {
      onSort(field, dir);
    }
  };

  return tableColumns.length > 0 && hasData ? (
    <>
      <table className="table table-dashboard data-table-component">
        <thead className="thead-dark">
          <tr>
            {tableColumns.map((c, i) => {
              return (
                <th
                  scope="col"
                  key={`th${i}`}
                  onClick={() => {
                    if (onSort && c.allowSort) {
                      handleSort(c.fieldName);
                    }
                  }}
                >
                  {c.title}{" "}
                  {c.allowSort ? sortHeaderSign(c.fieldName) : null}
                </th>
              );
            })}
            {showCmdButtons && <th colSpan={2}></th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((d, di) => {
            const key = Object.getOwnPropertyDescriptor(d, "id")?.value;
            return (
              <tr key={key}>
                {tableColumns.map((c, ci) => {
                  const keyCol = `col_${ci}`;
                  const val = Object.getOwnPropertyDescriptor(d, c.fieldName)?.value ?? "";
                  return (
                    <td key={keyCol}>
                      {c.renderCell ? c.renderCell(d) : getValueString(val, c)}
                    </td>
                  );
                })}
                {showCmdButtons ? (
                  <>
                    {
                      !onEditClick ? "" :
                        <td>
                          <button className="btn btn-sm btn-primary" onClick={() => onEditClick(d)}>Edit</button>
                        </td>
                    }
                    {
                      !onDeleteClick ? "" :
                        <td>
                          <button className="btn btn-sm btn-secondary" onClick={() => onDeleteClick(d)}>Delete</button>
                        </td>
                    }
                  </>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
      {usePagination ? (
        <div>
          <ListPagination
            page={pageNumber || 0}
            perPage={perPage || 30}
            totalCount={totalCountPagination || 0}
            pageNumberChanged={(value: number) => {
              if (onPageNumberChanged) {
                onPageNumberChanged(value);
              }
            }}
            perPageChanged={(value: number) => {
              if (onPerPageChanged) {
                onPerPageChanged(value);
              }
            }}
          />
        </div>
      ) : null}
    </>
  ) : (
    <div>{emptyDataMessage}</div>
  )
};

export default DataTable;
