import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { WeekFeedback, WeekFeedbackAdaptationResult } from "../../../api/types";
import UserProfileButtons, { ProfileType } from './UserProfileButtons';
import Loader from '../../Loader';
import { format } from 'date-fns';

interface Props extends RouteComponentProps<any> { }

const UsersProfileWeekFeedbackView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
    const userId = props.match.params.userId;

    const [loading, setLoading] = useState(true);
    const [weekFeedbacks, setWeekFeedbacks] = useState([] as WeekFeedback[]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setLoading(true);
        baseCamp.getUserWeekFeedbacks(userId)
            .then(data => {
                setWeekFeedbacks(prepareWeekFeedbacks(data));
            })
            .catch(e => {
                console.error(e);
                setErrorMessage(e.response?.data?.title ? e.response.data.title : `Error: ${JSON.stringify(e)}`);
            })
            .finally(() => setLoading(false))
    }, []);

    const prepareWeekFeedbacks = (feedbacks: WeekFeedback[]) => {
        const dates = [] as Date[];
        return feedbacks
            .map(f => {
                f.weekStartAt = new Date(f.weekStartAt);
                return f;
            })
            .sort((a, b) => b.weekStartAt.getTime() - a.weekStartAt.getTime());
    }

    const getNumberWithSign = (val: number) => {
        return val > 0 ? `+${val}` : val;
    }

    const getAdaptationResultString = (a: WeekFeedbackAdaptationResult): string => {
        var adaptValues = [];
        if (a.vdotUnits) {
            adaptValues.push(`Vdot: ${getNumberWithSign(a.vdotUnits)}`);
        }
        if (a.weeklyVolumeProcent) {
            adaptValues.push(`WeeklyVolume: ${getNumberWithSign(a.weeklyVolumeProcent)}%`);
        }
        var result = `${a.coachingType}: ${adaptValues.join(", ")}`;
        return result;
    }

    return (
        <div className="users-profile users-profile-weekfeedback">
            <div>
                <h3 className="float-left">User ID: {userId}</h3>
                <div className="float-right">
                    <UserProfileButtons userId={userId} type={ProfileType.WeekFeedback} />
                </div>
                <div className="clear-both p-2" />
            </div>
            <h4>Weekly feedbacks</h4>
            <div className={`alert alert-danger ${errorMessage ? "d-block" : "d-none"}`}>
                {errorMessage}
            </div>
            {
                weekFeedbacks.length ?
                    <div className={`${errorMessage ? "d-none" : "d-block"}`}>
                        {
                            weekFeedbacks.map(w => {
                                return (
                                    <div key={`weekFeedback${w.id}`}>
                                        {
                                            w.showDay ?
                                                <div className="message-day my-3"><span className="px-2">
                                                    {format(w.weekStartAt, "dd MMM yyyy")}
                                                </span></div> : ""
                                        }
                                        <div className={`row alert alert-${w.isActive ? "success" : "secondary"}`}>
                                            <div className="col col-sm-6">
                                                <div>Week started at: {format(w.weekStartAt, "dd MMM yyyy")}</div>
                                                <div>Updated at: {w.updatedAt ? format(new Date(w.updatedAt), "dd MMM yyyy HH:mm:ss") : " - "}</div>
                                                <div>Emotion: {w.emotion}</div>
                                                <div>Effort: {w.effort}</div>
                                                <div>Notes: {w.notes}</div>
                                                <div>Week volume: {w.weekVolume}</div>
                                                <div>Coaching types: {w.coachingTypes.join(", ")}</div>
                                            </div>
                                            <div className="col col-sm-6">
                                                <div>Adaptation</div>
                                                {
                                                    w.adaptationResult.length === 0 ?
                                                        <div>No adaptation</div>
                                                        :
                                                        w.adaptationResult.map(a => {
                                                            return (
                                                                <div key={`adaptation_${a.coachingType}`}>
                                                                    {getAdaptationResultString(a)}
                                                                </div>
                                                            )
                                                        })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    : !loading ?
                        <h5>No week feedbacks</h5> : ""
            }
            {
                loading ? <Loader /> : ""
            }
        </div>
    );
};

export default UsersProfileWeekFeedbackView;