import { CoachingType, WeekType } from '../shared/Primitives';

export const weekTypeValuesByCoachingType = [
    {
        coachingType: CoachingType.RunningFitness,
        weekTypes: [
            {
                value: WeekType.Walking,
                label: 'Walking'
            },
            {
                value: WeekType.Base,
                label: 'Base'
            },
            {
                value: WeekType.Fun,
                label: 'Fun'
            },
            {
                value: WeekType.Recovery,
                label: 'Recovery'
            },
        ]
    },
    {
        coachingType: CoachingType.RunningRace,
        weekTypes: [
            {
                value: WeekType.Walking,
                label: 'Walking'
            },
            {
                value: WeekType.Base,
                label: 'Base'
            },
            {
                value: WeekType.Fun,
                label: 'Fun'
            },
            {
                value: WeekType.Recovery,
                label: 'Recovery'
            },
            {
                value: WeekType.Taper,
                label: 'Taper'
            },
            {
                value: WeekType.Threshold,
                label: 'Threshold'
            },
            {
                value: WeekType.Speed,
                label: 'Speed'
            },
            {
                value: WeekType.Vo2Max,
                label: 'Vo2Max'
            },
            {
                value: WeekType.Active,
                label: 'Active'
            },
            {
                value: WeekType.Test,
                label: 'Test'
            },
        ]
    },
    {
        coachingType: CoachingType.CyclingFitness,
        weekTypes: [
            {
                value: WeekType.Fun,
                label: 'Fun'
            },
            {
                value: WeekType.Recovery,
                label: 'Recovery'
            },
            {
                value: WeekType.Preparation,
                label: 'Preparation'
            },
            {
                value: WeekType.Strength1,
                label: 'Strength1'
            },
            {
                value: WeekType.Strength2,
                label: 'Strength2'
            },
            {
                value: WeekType.Aerobic1,
                label: 'Aerobic1'
            },
            {
                value: WeekType.Aerobic2,
                label: 'Aerobic2'
            },
            {
                value: WeekType.Fitness,
                label: 'Fitness'
            },
            {
                value: WeekType.Tempo,
                label: 'Tempo'
            },
            {
                value: WeekType.Taper,
                label: 'Taper'
            }
        ]
    },
    {
        coachingType: CoachingType.CyclingRace,
        weekTypes: [
            {
                value: WeekType.Fun,
                label: 'Fun'
            },
            {
                value: WeekType.Recovery,
                label: 'Recovery'
            },
            {
                value: WeekType.Preparation,
                label: 'Preparation'
            },
            {
                value: WeekType.Strength1,
                label: 'Strength1'
            },
            {
                value: WeekType.Strength2,
                label: 'Strength2'
            },
            {
                value: WeekType.Aerobic1,
                label: 'Aerobic1'
            },
            {
                value: WeekType.Aerobic2,
                label: 'Aerobic2'
            },
            {
                value: WeekType.Fitness,
                label: 'Fitness'
            },
            {
                value: WeekType.Tempo,
                label: 'Tempo'
            },
            {
                value: WeekType.Taper,
                label: 'Taper'
            }
        ]
    },
    {
        coachingType: CoachingType.Swimming,
        weekTypes: [
            {
                value: WeekType.Standard,
                label: 'Standard'
            }
        ]
    }
];