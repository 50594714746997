import React, { useState, useEffect } from 'react';
import { BaseCampClient } from '../../api/BaseCampClient';
import { useAuth0 } from '../../react-auth0-spa';
import Loader from '../Loader';
import DataTable, { DataTableColumn } from '../common/DataTable';
import { PromoOfferHistory } from '../../api/types';
import { ExternalProductSource } from '../../shared/Primitives';
import './PromoOfferHistoryView.scss';

const PromoOfferHistoryView = () => {

    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [promoOfferHistory, setPromoOfferHistory] = useState<PromoOfferHistory[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        loadHistory();
    }, []);

    const loadHistory = () => {
        setIsLoading(true);
        baseCamp.getPromoOfferHistory()
            .then((res) => {
                setPromoOfferHistory(res);
            })
            .catch((ex) => {
                console.log("loading promo offer history error:", ex);
                let msg = "There was an error during load data. Please try latter.";
                if (ex.response?.status == 400) {
                    msg = ex.response.data;
                }
                setError(msg);
            })
            .finally(() => setIsLoading(false));
    }

    const columns: DataTableColumn[] = [
        {
            fieldName: "id",
            allowSort: false,
            title: "Id",
        },
        {
            fieldName: "createdAt",
            allowSort: false,
            title: "Date"
        },
        {
            fieldName: "userId",
            allowSort: false,
            title: "User",
            renderCell: (item: PromoOfferHistory) => {
                return <span>
                    {
                        <a href={`/user/${item.userId}/profile`}>{item.userEmail} (#{item.userId})</a>
                    }
                    </span>
            }
        },
        {
            fieldName: "promoOfferId",
            allowSort: false,
            title: "Promo Offer",
            renderCell: (item: PromoOfferHistory) => {
                return <span>{`${item.promoOfferName ?? ''} (#${item.promoOfferId})`}</span>
            }
        },
        {
            fieldName: "promoOfferCodeId",
            allowSort: false,
            title: "Promo Code Id"
        },
        {
            fieldName: "promoOfferCode",
            allowSort: false,
            title: "Promo Code"
        },
        {
            fieldName: "partnerId",
            allowSort: false,
            title: "Partner",
            renderCell: (item: PromoOfferHistory) => {
                return <span>{`${item.partnerName} (#${item.partnerId})`}</span>
            }
        },
        {
            fieldName: "store",
            allowSort: false,
            title: "Store",
            renderCell: (item: PromoOfferHistory) => {
                return <span>
                    {
                        item.store === ExternalProductSource.AppleStore ? 'Apple' :
                        item.store === ExternalProductSource.GooglePlay ? 'Google' :
                        item.store === ExternalProductSource.Stripe ? 'Stripe' :
                        'None'
                    }
                    </span>
            }
        },
    ];

    return (
        <div className='promo-offer-history'>
            <h3>Promo Offer History</h3>
            {
                error ? <div className="fade alert alert-danger show">{error}</div> : null
            }
            {
                isLoading ? <Loader /> : ""
            }
            <DataTable
                data={promoOfferHistory}
                columns={columns}
                emptyDataMessage="There is no any used offer."
            />
        </div>
    );
}

export default PromoOfferHistoryView;