import { CoachingType } from '../shared/Primitives';

export const coachingTypeValues: CoachingTypeDescription[] = [
    {
        value: CoachingType.RunningRace,
        name: 'RunningRace'
    },
    {
        value: CoachingType.RunningFitness,
        name: 'RunningFitness'
    },
    {
        value: CoachingType.CyclingRace,
        name: 'CyclingRace'
    },
    {
        value: CoachingType.CyclingFitness,
        name: 'CyclingFitness'
    },
    {
        value: CoachingType.Swimming,
        name: 'Swimming'
    },
    {
        value: CoachingType.TriathlonRace,
        name: 'TriathlonRace'
    },
    {
        value: CoachingType.TriathlonFitness,
        name: 'TriathlonFitness'
    }
];

export interface CoachingTypeDescription {
    value: CoachingType;
    name: string;
}