import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { AutoMessageSenderUser } from '../../../api/types';
import { useAuth0 } from '../../../react-auth0-spa';
import { AutoMessageSender } from '../../../shared/Primitives';

type SenderSelectorProps = {
    senderType: AutoMessageSender,
    senderId: number | undefined,
    senderTypeChanged: (value: AutoMessageSender) => void,
    senderIdChanged: (value: number | undefined) => void,
};

const SenderSelector = (props: SenderSelectorProps) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const senderTypes = [AutoMessageSender.Admin, AutoMessageSender.Coach, AutoMessageSender.Bot, AutoMessageSender.SingleUser];
    const [senderUsers, setSenderUsers] = useState<AutoMessageSenderUser[]>([]);

    useEffect(() => {
        if (props.senderType === AutoMessageSender.SingleUser) {
            baseCamp.getAutoMessageSenders()
                .then((res) => {
                    setSenderUsers(res);
                })
                .catch((ex) => {
                    console.log("loading auto message senders error:", ex);
                    let msg = "There was an error during load data. Please try latter.";
                    if (ex.response?.status == 400) {
                        msg = ex.response.data;
                    }
                    alert(msg);
                });
        }
        else {
            setSenderUsers([]);
            props.senderIdChanged(undefined);
        }
    }, [props.senderType]);

    return <>
        <Col sm={2}>
            <Form.Control as="select" value={props.senderType} onChange={(e) => props.senderTypeChanged(e.target.value as AutoMessageSender)}>
                {
                    senderTypes.map((x) => {
                        return <option key={x} value={x}>{x}</option>
                    })
                }
            </Form.Control>
            <Form.Text id="passwordHelpBlock" muted>
                {
                    props.senderType === AutoMessageSender.Admin ? "Message is send from random admin for chat and from coach for telegram." : null
                }
                {
                    props.senderType === AutoMessageSender.Bot ? "Message is send from Bot. Telegram only." : null
                }
                {
                    props.senderType === AutoMessageSender.Coach ? "Message is send from random or assigned coach for chat and from coach for telegram." : null
                }
                {
                    props.senderType === AutoMessageSender.SingleUser ? "Message is send from single specified user (admin or coach) for chat and from coach for telegram." : null
                }
            </Form.Text>
        </Col>
        {
            props.senderType === AutoMessageSender.SingleUser ?
                <Col sm={2}>
                    <Form.Control as="select" value={props.senderId || ""} onChange={(e) => props.senderIdChanged(parseInt(e.target.value))}>
                        {
                            senderUsers ? senderUsers.map((x) => {
                                return <option key={x.userId} value={x.userId}>{x.name} ({x.email})</option>
                            })
                            : null
                        }
                    </Form.Control>
                </Col>
                : null
        }
    </>
}

export default SenderSelector;