import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns';

import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import Loader from '../../Loader';
import { SubscriptionPeriods } from '../../../shared/Primitives';
import { UserFullInfo, SubscriptionPlanInfo } from '../../../api/types';
import { subscriptionPeriods } from '../../../fixtures/subscription';

import UserProfileButtons, { ProfileType } from './UserProfileButtons';
import UserSubscriptionInfoBlock from './profile-view-components/UserSubscriptionInfoBlock';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props extends RouteComponentProps<any> { }

const UserProfileSubscriptionView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
    const userId = props.match.params.userId;

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [user, setUser] = useState({} as UserFullInfo);
    const [showAssignment, setShowAssignment] = useState(false);
    const [plans, setPlans] = useState([] as SubscriptionPlanInfo[]);
    const [newPlanId, setNewPlanId] = useState(0);
    const [newPeriod, setNewPeriod] = useState(SubscriptionPeriods.Monthly);
    const [applying, setApplying] = useState(false);
    const [showAppliedSuccess, setShowAppliedSuccess] = useState(false);
    const [expDate, setExpDate] = useState(undefined as Date | undefined);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);

        baseCamp.getUserProfile(userId)
            .then(u => {
                setUser(u);
                setExpDate(u.userSubscriptionInfo?.expireAt);
                setNewPlanId(u.userSubscriptionInfo?.subscriptionPlanId ?? 0);
                setNewPeriod(u.userSubscriptionInfo?.subscriptionPeriod ?? SubscriptionPeriods.Monthly);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => setLoading(false))

        baseCamp.getSubscriptionPlansActive()
            .then(x => setPlans(x))
            .catch(e => {
                console.error(e);
            })
            .finally(() => setLoading(false))
    }

    const apply = async () => {
        setApplying(true);
        setErrorMessage("");

        if (newPlanId > 0 && !expDate)
        {
            setErrorMessage("Expiration date can't be empty.");
            setApplying(false);
            return;
        }

        try {
            if(newPlanId == 0){
                await baseCamp.removeUserSubscription(userId);
            } 
            else {
                await baseCamp.changeUserSubscription(userId, newPlanId, newPeriod, expDate);
            }
            setShowAppliedSuccess(true);
            loadData();
        }
        catch (e) {
            console.error(e);
            setErrorMessage(`Error: ${JSON.stringify(e)}`);
        }
        finally {
            setApplying(false);
        }
    };

    return (
        <div className="users-profile">
            <div>
                <h3 className="float-left">User ID: {userId}</h3>
                <div className="float-right">
                    <UserProfileButtons userId={userId} type={ProfileType.WeekFeedback} />
                </div>
                <div className="clear-both p-2" />
            </div>
            <h4>Subscription</h4>
            {
                loading ? <Loader/> : null
            }
            <div>
                <UserSubscriptionInfoBlock 
                    plan={user.userSubscriptionInfo?.subscriptionPlanTitle}
                    period={user.userSubscriptionInfo?.subscriptionPeriod}
                    source={user.userSubscriptionInfo?.externalProductSource}
                    product={user.userSubscriptionInfo?.externalProductId}
                    createdAt={user.userSubscriptionInfo?.createdAt}
                    prolongedAt={user.userSubscriptionInfo?.prolongedAt}
                    expireAt={user.userSubscriptionInfo?.expireAt}
                    cancelledAt={user.userSubscriptionInfo?.cancelledAt}
                    autoProlongation={user.userSubscriptionInfo?.autoProlongation}
                />
            
                {!showAssignment && !user.userSubscriptionInfo?.autoProlongation && <button className="btn btn-primary" onClick={() => setShowAssignment(true) }>Assign subscription</button>}
            </div>

            {showAssignment && !user.userSubscriptionInfo?.autoProlongation && <div className="p-2">
                <h5>Assign subscription</h5>
                <div className="pb-2">
                    <div className="btn-group" role="group" aria-label="Plan">
                        <button key={0} type="button" className={!newPlanId ? 'btn btn-dark' : 'btn btn-light'} 
                                onClick={() => setNewPlanId(0)}>None</button>
                        {plans.map(x => 
                            <button key={x.id} type="button" className={x.id === newPlanId ? 'btn btn-dark' : 'btn btn-light'} 
                                onClick={() => setNewPlanId(x.id)}>{x.title}</button>
                        )}
                    </div>
                </div>
                {
                    newPlanId>0 && !user.userSubscriptionInfo?.autoProlongation
                    ?
                    <div className="pb-3">
                    <label style={{margin:"0px 10px 0px 0px"}}>Expiration date:</label>
                    <DatePicker
                        className="form-control training-date-container"
                        selected={expDate}
                        onChange={date => setExpDate(date ?? undefined)}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select exp date"/>
                    </div>
                    :
                    null
                }
                
                {/*<div className="pb-3">
                    <div className="btn-group" role="group" aria-label="Period">
                        {subscriptionPeriods.map(x => 
                            <button key={x.value} type="button" className={x.value === newPeriod ? 'btn btn-dark' : 'btn btn-light'} 
                                onClick={() => setNewPeriod(x.value)}>{x.caption}</button>
                        )}
                    </div>
                </div>*/}
                <div>
                    <button className="btn btn-primary" type="button" onClick={apply} disabled={applying}>Apply</button>
                    <div className="p-2">{showAppliedSuccess&&  <span className="badge badge-success">Done</span>}</div>
                    <div className={`alert alert-danger ${errorMessage ? "d-block" : "d-none"}`}>
                        {errorMessage}
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default UserProfileSubscriptionView;