import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useForm from "react-hook-form";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import { parseISO, format } from "date-fns";
import Select from "react-select";
import * as Icon from "react-feather";

import { useAuth0 } from "../../../react-auth0-spa";
import { BaseCampClient } from "../../../api/BaseCampClient";
import Loader from "../../Loader";
import {
  UserFullInfo,
  Race,
  TimeZoneType,
  CoachInfo,
} from "../../../api/types";
import { genders } from "../../../fixtures/genders";
import { userTypes } from "../../../fixtures/user-types";
import {
  dayOfWeakValues,
  daysOfWeakValues,
} from "../../../fixtures/days-of-weak";
import * as shared from "../../../shared/Primitives";

import UserProfileButtons, { ProfileType } from "./UserProfileButtons";
import UserSubscriptionInfoBlock from "./profile-view-components/UserSubscriptionInfoBlock";


import "./UsersProfileView.scss";

import { ReactComponent as StravaSvg } from "../../../imgs/strava.svg";
import { coachingTypeValues } from "../../../fixtures/coaching-types";
import { hasRoles } from "../../../api/Helpers";
import { operationSystems } from "../../../fixtures/operation-systems";

registerLocale("en-GB", enGB);
setDefaultLocale("en-GB");

enum OptionalParameterMode {
  Auto = 1,
  Manual = 2,
}

interface Props extends RouteComponentProps<any> { }
type OptionType = { label: string; value: string };

const UsersProfileView = (props: Props) => {
  const { accessToken } = useAuth0();
  const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
  const history = useHistory();

  const { handleSubmit, register, errors } = useForm();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [errorLoad, setErrorLoad] = useState(false);
  const [submitStatusShown, setSubmitStatusShown] = useState(false);
  const [submitMessageType, setSubmitMessageType] = useState(0);
  const [submitMessage, setSubmitMessage] = useState("");
  const [editGoalRace, setEditGoalRace] = useState(false);
  const [user, setUser] = useState({} as UserFullInfo);
  const [showUserPlan, setShowUserPlan] = useState(false);
  const [requestStartRunPlan, setRequestStartRunPlan] = useState({
    startAt: new Date(),
    allowNotifications: true,
  });
  const [loadUserPlan, setLoadUserPlan] = useState(false);
  const [successUpdateUserPlan, setSuccessUpdateUserPlan] = useState(false);

  const userId = props.match.params.userId;
  const languages = [
    { label: "English", value: "en" },
    { label: "Russian", value: "ru" },
    { label: "German", value: "de" },
  ] as OptionType[];

  const [timezones, setTimezones] = useState({} as TimeZoneType[]);
  const [coaches, setCoaches] = useState({} as CoachInfo[]);

  const [runStrictNumberDaysMode, setRunStrictNumberDaysMode] = useState(
    OptionalParameterMode.Auto
  );
  const [longRunMode, setLongRunMode] = useState(OptionalParameterMode.Auto);
  const [qualityWorkoutsNumberMode, setQualityWorkoutsNumberMode] = useState(
    OptionalParameterMode.Auto
  );

  useEffect(() => {
    baseCamp
      .getProfileDefaultData()
      .then((data) => {
        setTimezones(data.timezones);
        setCoaches(data.coaches);
        baseCamp
          .getUserProfile(userId)
          .then((u) => {
            setUser(u);
            if (u.longRunKm) {
              setLongRunMode(OptionalParameterMode.Manual);
            }
            if (u.strictNumberOfDays) {
              setRunStrictNumberDaysMode(OptionalParameterMode.Manual);
            }
            if (u.qualityWorkoutsNumber || u.qualityWorkoutsNumber === 0) {
              setQualityWorkoutsNumberMode(OptionalParameterMode.Manual);
            }
          })
          .catch((e) => {
            if (e.response.status === 403) {
              window.location.href = "/users";
            }
            SetLoadApiDataError(e);
          });
      })
      .catch((e) => {
        SetLoadApiDataError(e);
      })
      .finally(() => setLoading(false));

    /*baseCamp.getUserProfile(userId)
            .then(u => setUser(u))
            .catch(e => {
                console.error(e);
                setErrorLoad(true);
                setSubmitStatusShown(true);
                setSubmitMessageType(1);
                setSubmitMessage(e.response ? e.response.data : `Error: ${JSON.stringify(e)}`);
            })
            .finally(() => setLoading(false))*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const SetLoadApiDataError = (e: any) => {
    console.error(e);
    setErrorLoad(true);
    setSubmitStatusShown(true);
    setSubmitMessageType(1);
    setSubmitMessage(
      e.response ? e.response.data : `Error: ${JSON.stringify(e)}`
    );
  };

  const showMessage = () => {
    let className =
      submitMessageType === 0 ? "alert alert-success" : "alert alert-danger";
    return (
      <div className={className} role="alert">
        {submitMessage}
      </div>
    );
  };

  const saveChanges = () => {
    setLoading(true);
    baseCamp
      .updateUser(userId, user)
      .then(() => setRefresh(!refresh))
      .catch((e) => {
        console.error(e);
        setErrorLoad(true);
        setSubmitStatusShown(true);
        setSubmitMessageType(1);
        setLoading(false);
        setSubmitMessage(
          e.response ? e.response.data : `Error: ${JSON.stringify(e)}`
        );
      });
  };

  const getViewGoalRace = () => {
    if (!user.goalRace) {
      return "";
    } else {
      const distance = user.goalRace.distanceKm
        ? `${user.goalRace.distanceKm}km`
        : "";
      const date = !user.goalRace?.date
        ? ""
        : format(user.goalRace.date, "yyyy-MM-dd");

      return `${user.goalRace.name || ""} ${distance} ${date}`;
    }
  };

  const getViewBikeGoalRace = () => {
    if (!user.bikeGoalRace) {
      return "";
    } else {
      const distance = user.bikeGoalRace.distanceKm
        ? `${user.bikeGoalRace.distanceKm}km`
        : "";
      const date = !user.bikeGoalRace?.date
        ? ""
        : format(user.bikeGoalRace.date, "yyyy-MM-dd");

      return `${user.bikeGoalRace.name || ""} ${distance} ${date}`;
    }
  };

  const getOffsetUTCDate = (date: Date) => {
    const d = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return new Date(d.getTime() - d.getTimezoneOffset() * 60000);
  };

  const handleDeleteClick = (userId: number) => {
    var isConfirm = window.confirm(
      "User will be deleted with all related data. Are you sure you want to delete this user?"
    );
    if (isConfirm) {
      const basecamp: BaseCampClient = new BaseCampClient(accessToken);
      setLoading(true);
      basecamp
        .deleteUser(userId)
        .then(() => {
          window.location.href = "/users";
        })
        .finally(() => setLoading(false));
    }
  };

  const getForm = () => {
    return (
      <div className="p-2">
        <div className="w-50 float-left">
          <div className="form-group row">
            <label htmlFor="inputEmail" className="col-sm-3 col-form-label">
              Email
            </label>
            <div className="col-sm-9">
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                name="email"
                defaultValue={user.email}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputFirstName" className="col-sm-3 col-form-label">
              First name
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputFirstName"
                value={user.firstName || ""}
                onChange={(event) =>
                  setUser({ ...user, firstName: event.target.value })
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputLastName" className="col-sm-3 col-form-label">
              Last name
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputLastName"
                value={user.lastName || ""}
                onChange={(event) =>
                  setUser({ ...user, lastName: event.target.value })
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputGender" className="col-sm-3 col-form-label">
              Gender
            </label>
            <div className="col-sm-9">
              <div className="btn-group" role="group" aria-label="Plan type">
                {genders.map((g) => (
                  <button
                    key={g.value}
                    type="button"
                    className={
                      g.value === user.gender ? "btn btn-dark" : "btn btn-light"
                    }
                    onClick={(event) => setUser({ ...user, gender: g.value })}
                  >
                    {g.caption}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputAge" className="col-sm-3 col-form-label">
              Age
            </label>
            <div className="col-sm-3">
              <input
                type="number"
                className="form-control"
                id="inputAge"
                value={user.age || ""}
                onChange={(event) =>
                  setUser({ ...user, age: parseInt(event.target.value) })
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputLanguage" className="col-sm-3 col-form-label">
              Language
            </label>
            <div className="col-sm-3">
              <Select
                id="inputLanguage"
                options={languages}
                value={languages.find((l) => l.value === user.language)}
                getOptionValue={(v) => v.value}
                onChange={(value) =>
                  setUser({ ...user, language: (value as OptionType).value })
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="inputTimezoneOffset"
              className="col-sm-3 col-form-label"
            >
              Time zone
            </label>
            <div className="col-sm-3">
              <Select
                id="inputTimezoneOffset"
                options={timezones}
                value={timezones.find(
                  (zone) => zone.timezoneOffset === user.timezoneOffset
                )}
                getOptionLabel={(t) => `${t.displayName}`}
                getOptionValue={(t) => `${t.timezoneOffset}`}
                onChange={(value) =>
                  setUser({
                    ...user,
                    timezoneOffset: (value as TimeZoneType).timezoneOffset,
                  })
                }
              />
            </div>
          </div>
          {hasRoles(accessToken, "admin") ? (
            <>
              <div className="form-group row">
                <label htmlFor="inputCoach" className="col-sm-3 col-form-label">
                  Coach
                </label>
                <div className="col-sm-4">
                  <Select
                    id="inputCoach"
                    options={coaches}
                    isClearable
                    value={
                      coaches
                        ? coaches.find((b) => b.coachId === user.coachId)
                        : null
                    }
                    getOptionLabel={(b) => `${b.nameEn ?? b.nameRu ?? b.nameDe}`}
                    getOptionValue={(b) => `${b.coachId}`}
                    onChange={(value) =>
                      setUser({ ...user, coachId: (value as CoachInfo)?.coachId })
                    }
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="cbUseImperialSystem" className="col-sm-3 col-form-label">
                  Use imperial system
                </label>
                <div className="col-sm-4">
                  <input id="cbUseImperialSystem" 
                    type="checkbox" 
                    checked={user.useImperialSystem || false} 
                    onChange={(e) => setUser({
                      ...user,
                      useImperialSystem: e.target.checked
                    })}
                  />
                </div>
              </div>
            </>
          ) : null}

          <div className="form-group row pt-3">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
              <h4>Run</h4>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              Available training days
            </label>
            <div className="col-sm-9">
              <div
                className="btn-group"
                role="group"
                aria-label="Training days"
              >
                {daysOfWeakValues.map((d) => (
                  <button
                    key={d.value}
                    type="button"
                    className={
                      d.func(user.availableTrainingDays)
                        ? "btn btn-dark"
                        : "btn btn-light"
                    }
                    onClick={(event) =>
                      setUser({
                        ...user,
                        availableTrainingDays: d.changeTrainingDays(
                          user.availableTrainingDays
                        ),
                      })
                    }
                  >
                    {d.name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputStrictNumberOfDays"
              className="col-sm-3 col-form-label"
            >
              Strict number of days
            </label>
            <div className="col-sm-9">
              <div
                className="btn-group"
                role="group"
                aria-label="Strict number of days"
              >
                <button
                  type="button"
                  className={
                    runStrictNumberDaysMode === OptionalParameterMode.Auto
                      ? "btn btn-dark"
                      : "btn btn-light"
                  }
                  onClick={(e) => {
                    if (
                      runStrictNumberDaysMode !== OptionalParameterMode.Auto
                    ) {
                      setRunStrictNumberDaysMode(OptionalParameterMode.Auto);
                      setUser({ ...user, strictNumberOfDays: -1 });
                    }
                  }}
                >
                  Auto
                </button>
                <button
                  type="button"
                  className={
                    runStrictNumberDaysMode === OptionalParameterMode.Manual
                      ? "btn btn-dark"
                      : "btn btn-light"
                  }
                  onClick={(e) => {
                    if (
                      runStrictNumberDaysMode !== OptionalParameterMode.Manual
                    ) {
                      setRunStrictNumberDaysMode(OptionalParameterMode.Manual);
                    }
                  }}
                >
                  Manual
                </button>

                {runStrictNumberDaysMode === OptionalParameterMode.Manual ? (
                  <div style={{ marginLeft: "20px" }}>
                    <input
                      min={1}
                      type="number"
                      className="form-control"
                      id="inputStrictNumberOfDays"
                      name="runningStrictNumberOfDays"
                      defaultValue={user.strictNumberOfDays || ""}
                      placeholder="Required"
                      onChange={(event) =>
                        setUser({
                          ...user,
                          strictNumberOfDays: parseInt(event.target.value),
                        })
                      }
                      ref={register({ required: true })}
                    />
                  </div>
                ) : null}
              </div>
              {runStrictNumberDaysMode === OptionalParameterMode.Manual ? (
                <div>
                  {errors.runningStrictNumberOfDays && (
                    <span className="text-danger">
                      Strict number of days is required
                    </span>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="inputGoalRace">
              Goal race
            </label>
            <div className="col-sm-9">
              <div
                className={`input-group goal-race ${editGoalRace ? "d-none" : "d-flex"
                  }`}
              >
                <input
                  type="text"
                  className="form-control"
                  id="inputGoalRace"
                  value={getViewGoalRace()}
                  readOnly
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      (window.location.href = `/user/${userId}/new-plan`)
                    }
                  >
                    Change
                  </button>
                </div>
                {/* <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => setEditGoalRace(true)}>Edit</button>
                                </div> */}
              </div>
              <div
                className={`input-group goal-race ${!editGoalRace ? "d-none" : "d-flex"
                  }`}
              >
                <input
                  type="text"
                  className="form-control"
                  id="inputGoalRaceName"
                  placeholder="Name..."
                  defaultValue={user.goalRace?.name || ""}
                  onChange={(event) =>
                    setUser({
                      ...user,
                      goalRace: {
                        ...(user.goalRace || ({} as Race)),
                        type: shared.RaceType.Goal,
                        name: event.target.value || "",
                      },
                    })
                  }
                />
                <input
                  type="number"
                  className="form-control"
                  id="inputGoalRaceDistance"
                  placeholder="Distance, km..."
                  defaultValue={user.goalRace?.distanceKm || ""}
                  onChange={(event) =>
                    setUser({
                      ...user,
                      goalRace: {
                        ...(user.goalRace || ({} as Race)),
                        type: shared.RaceType.Goal,
                        distanceKm: parseInt(event.target.value || ""),
                      },
                    })
                  }
                />
                <DatePicker
                  selected={user.goalRace?.date}
                  onChange={(date) => {
                    if (date) {
                      setUser({
                        ...user,
                        goalRace: {
                          ...(user.goalRace || ({} as Race)),
                          type: shared.RaceType.Goal,
                          date: getOffsetUTCDate(date),
                        },
                      });
                    }
                  }}
                  className="form-control"
                  placeholderText="Date..."
                  id="inputGoalRaceDate"
                  dateFormat="yyyy-MM-dd"
                  locale="en-GB"
                  filterDate={(date) => date > new Date()}
                  aria-describedby="goalDateHelpBlock"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => setEditGoalRace(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputVdot" className="col-sm-3 col-form-label">
              VDot
            </label>
            <div className="col-sm-3">
              <input
                type="number"
                className="form-control"
                id="inputVdot"
                name="vdot"
                placeholder="Required"
                defaultValue={user.vdot}
                onChange={(event) =>
                  setUser({ ...user, vdot: parseFloat(event.target.value) })
                }
                ref={register({ required: true })}
              />
              {errors.vdot && (
                <span className="text-danger">VDot is required</span>
              )}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputRunWeekVolume"
              className="col-sm-3 col-form-label"
            >
              Run week volume
            </label>
            <div className="col-sm-4">
              <div className="input-group">
                <input
                  min={0}
                  type="number"
                  className="form-control"
                  id="inputRunWeekVolume"
                  name="inputRunWeekVolume"
                  placeholder="Required"
                  defaultValue={user.runWeekVolumeKm || ""}
                  onChange={(event) =>
                    setUser({
                      ...user,
                      runWeekVolumeKm: parseFloat(event.target.value),
                    })
                  }
                  ref={register({ required: true })}
                />
                <div className="input-group-append">
                  <span className="input-group-text">km</span>
                </div>
              </div>
              {errors.inputRunWeekVolume && (
                <span className="text-danger">Run week volume is required</span>
              )}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputMaxHeartRate"
              className="col-sm-3 col-form-label"
            >
              Max Heart Rate
            </label>
            <div className="col-sm-3">
              <input
                type="number"
                className="form-control"
                id="inputMaxHeartRate"
                name="maxHeartRate"
                placeholder="Max Heart Rate"
                defaultValue={user.maxHeartRate}
                onChange={(event) =>
                  setUser({
                    ...user,
                    maxHeartRate: parseInt(event.target.value),
                  })
                }
                ref={register({ min: 160, max: 220 })}
              />
              {errors.maxHeartRate && (
                <span className="text-danger">
                  Heart Rate must be between 160 and 220
                </span>
              )}
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputLogRun" className="col-sm-3 col-form-label">
              Long run
            </label>
            <div className="col-sm-9">
              <div className="btn-group" role="group" aria-label="Long run">
                <button
                  type="button"
                  className={
                    longRunMode === OptionalParameterMode.Auto
                      ? "btn btn-dark"
                      : "btn btn-light"
                  }
                  onClick={(e) => {
                    if (longRunMode !== OptionalParameterMode.Auto) {
                      setLongRunMode(OptionalParameterMode.Auto);
                      setUser({ ...user, longRunKm: -1 });
                    }
                  }}
                >
                  Auto
                </button>
                <button
                  type="button"
                  className={
                    longRunMode === OptionalParameterMode.Manual
                      ? "btn btn-dark"
                      : "btn btn-light"
                  }
                  onClick={(e) => {
                    if (longRunMode !== OptionalParameterMode.Manual) {
                      setLongRunMode(OptionalParameterMode.Manual);
                    }
                  }}
                >
                  Manual
                </button>
                {longRunMode === OptionalParameterMode.Manual ? (
                  <React.Fragment>
                    <div className="input-group" style={{ marginLeft: "20px" }}>
                      <input
                        min={0}
                        type="number"
                        className="form-control"
                        id="inputLongRun"
                        name="inputLongRun"
                        placeholder="Required"
                        defaultValue={user.longRunKm || ""}
                        onChange={(event) =>
                          setUser({
                            ...user,
                            longRunKm: parseInt(event.target.value),
                          })
                        }
                        ref={register({ required: true })}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">km</span>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>

              {longRunMode === OptionalParameterMode.Manual ? (
                <div>
                  {errors.inputLongRun && (
                    <span className="text-danger">Long run is required</span>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Long run day</label>
            <div className="col-sm-9">
              <div className="btn-group" role="group" aria-label="Long run">
                {dayOfWeakValues.map((d) => (
                  <button
                    key={d.value}
                    type="button"
                    className={
                      d.value === user.longRunDay
                        ? "btn btn-dark"
                        : "btn btn-light"
                    }
                    onClick={(event) =>
                      setUser({ ...user, longRunDay: d.value })
                    }
                  >
                    {d.name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputNumberOfQualityWorkouts"
              className="col-sm-3 col-form-label"
            >
              Number of quality workouts
            </label>
            <div className="col-sm-9">
              <div
                className="btn-group"
                role="group"
                aria-label="Number of quality workouts"
              >
                <button
                  type="button"
                  className={
                    qualityWorkoutsNumberMode === OptionalParameterMode.Auto
                      ? "btn btn-dark"
                      : "btn btn-light"
                  }
                  onClick={(e) => {
                    if (
                      qualityWorkoutsNumberMode !== OptionalParameterMode.Auto
                    ) {
                      setQualityWorkoutsNumberMode(OptionalParameterMode.Auto);
                      setUser({ ...user, qualityWorkoutsNumber: -1 });
                    }
                  }}
                >
                  Auto
                </button>
                <button
                  type="button"
                  className={
                    qualityWorkoutsNumberMode === OptionalParameterMode.Manual
                      ? "btn btn-dark"
                      : "btn btn-light"
                  }
                  onClick={(e) => {
                    if (
                      qualityWorkoutsNumberMode !== OptionalParameterMode.Manual
                    ) {
                      setQualityWorkoutsNumberMode(
                        OptionalParameterMode.Manual
                      );
                    }
                  }}
                >
                  Manual
                </button>

                {qualityWorkoutsNumberMode === OptionalParameterMode.Manual ? (
                  <div style={{ marginLeft: "20px" }}>
                    <input
                      min={1}
                      type="number"
                      className="form-control"
                      id="inputNumberOfQualityWorkouts"
                      name="inputNumberOfQualityWorkouts"
                      defaultValue={
                        user.qualityWorkoutsNumber ||
                          user.qualityWorkoutsNumber === 0
                          ? user.qualityWorkoutsNumber
                          : ""
                      }
                      placeholder="Required"
                      onChange={(event) =>
                        setUser({
                          ...user,
                          qualityWorkoutsNumber: parseInt(event.target.value),
                        })
                      }
                      ref={register({ required: true })}
                    />
                  </div>
                ) : null}
              </div>
              {qualityWorkoutsNumberMode === OptionalParameterMode.Manual ? (
                <div>
                  {errors.inputNumberOfQualityWorkouts && (
                    <span className="text-danger">
                      Number of quality workouts is required
                    </span>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row pt-3">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
              <h4>Bike</h4>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              Available training days
            </label>
            <div className="col-sm-9">
              <div
                className="btn-group"
                role="group"
                aria-label="Training days"
              >
                {daysOfWeakValues.map((d) => (
                  <button
                    key={d.value}
                    type="button"
                    className={
                      d.func(user.bikeAvailableTrainingDays)
                        ? "btn btn-dark"
                        : "btn btn-light"
                    }
                    onClick={(event) =>
                      setUser({
                        ...user,
                        bikeAvailableTrainingDays: d.changeTrainingDays(
                          user.bikeAvailableTrainingDays
                        ),
                      })
                    }
                  >
                    {d.name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputBikeStrictNumberOfDays"
              className="col-sm-3 col-form-label"
            >
              Strict number of days
            </label>
            <div className="col-sm-3">
              <input
                type="number"
                className="form-control"
                id="inputBikeStrictNumberOfDays"
                name="inputBikeStrictNumberOfDays"
                placeholder="Required"
                defaultValue={user.bikeStrictNumberOfDays || ""}
                onChange={(event) =>
                  setUser({
                    ...user,
                    bikeStrictNumberOfDays: parseInt(event.target.value),
                  })
                }
                ref={register({ required: true })}
              />
            </div>
            <div>
              {errors.inputBikeStrictNumberOfDays && (
                <span className="text-danger">
                  Strict number of days is required
                </span>
              )}
            </div>
          </div>

          {user.bikeGoalRace ? (
            <div className="form-group row">
              <label
                className="col-sm-3 col-form-label"
                htmlFor="inputGoalRace"
              >
                Goal race
              </label>
              <div className="col-sm-9">
                <div className="input-group goal-race">
                  <input
                    type="text"
                    className="form-control"
                    id="inputGoalRace"
                    value={getViewBikeGoalRace()}
                    readOnly
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="form-group row">
            <label htmlFor="inputFtp" className="col-sm-3 col-form-label">
              FTP
            </label>
            <div className="col-sm-3">
              <input
                type="number"
                className="form-control"
                id="inputFtp"
                value={user.ftp || ""}
                onChange={(event) =>
                  setUser({ ...user, ftp: parseInt(event.target.value) })
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputBikeMaxHeartRate"
              className="col-sm-3 col-form-label"
            >
              Max heart rate
            </label>
            <div className="col-sm-3">
              <input
                type="number"
                className="form-control"
                id="inputBikeMaxHeartRate"
                value={user.bikeMaxHeartRate || ""}
                onChange={(event) =>
                  setUser({
                    ...user,
                    bikeMaxHeartRate: parseInt(event.target.value),
                  })
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputBaseRideIndoorMinutes"
              className="col-sm-3 col-form-label"
            >
              Indoor base
            </label>
            <div className="col-sm-4">
              <div className="input-group">
                <input
                  min={0}
                  type="number"
                  className="form-control"
                  id="inputBaseRideIndoorMinutes"
                  name="inputBaseRideIndoorMinutes"
                  defaultValue={user.baseRideIndoorMinutes || ""}
                  onChange={(event) =>
                    setUser({
                      ...user,
                      baseRideIndoorMinutes: parseInt(event.target.value),
                    })
                  }
                />
                <div className="input-group-append">
                  <span className="input-group-text">minutes</span>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputBaseRideOutdoorMinutes"
              className="col-sm-3 col-form-label"
            >
              Outdoor base
            </label>
            <div className="col-sm-4">
              <div className="input-group">
                <input
                  min={0}
                  type="number"
                  className="form-control"
                  id="inputBaseRideOutdoorMinutes"
                  name="inputBaseRideOutdoorMinutes"
                  defaultValue={user.baseRideOutdoorMinutes || ""}
                  onChange={(event) =>
                    setUser({
                      ...user,
                      baseRideOutdoorMinutes: parseInt(event.target.value),
                    })
                  }
                />
                <div className="input-group-append">
                  <span className="input-group-text">minutes</span>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputLongRideOutdoorMinutes"
              className="col-sm-3 col-form-label"
            >
              Outdoor long
            </label>
            <div className="col-sm-4">
              <div className="input-group">
                <input
                  min={0}
                  type="number"
                  className="form-control"
                  id="inputLongRideOutdoorMinutes"
                  name="inputLongRideOutdoorMinutes"
                  defaultValue={user.longRideOutdoorMinutes || ""}
                  onChange={(event) =>
                    setUser({
                      ...user,
                      longRideOutdoorMinutes: parseInt(event.target.value),
                    })
                  }
                />
                <div className="input-group-append">
                  <span className="input-group-text">minutes</span>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Long bike day</label>
            <div className="col-sm-9">
              <div className="btn-group" role="group" aria-label="Long bike">
                {dayOfWeakValues.map((d) => (
                  <button
                    key={d.value}
                    type="button"
                    className={
                      d.value === user.longBikeDay
                        ? "btn btn-dark"
                        : "btn btn-light"
                    }
                    onClick={(event) =>
                      setUser({ ...user, longBikeDay: d.value })
                    }
                  >
                    {d.name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group row pt-3">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
              <h4>Swimming</h4>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              Available training days
            </label>
            <div className="col-sm-9">
              <div
                className="btn-group"
                role="group"
                aria-label="Training days"
              >
                {daysOfWeakValues.map((d) => (
                  <button
                    key={d.value}
                    type="button"
                    className={
                      d.func(user.swimAvailableTrainingDays)
                        ? "btn btn-dark"
                        : "btn btn-light"
                    }
                    onClick={(event) =>
                      setUser({
                        ...user,
                        swimAvailableTrainingDays: d.changeTrainingDays(
                          user.swimAvailableTrainingDays
                        ),
                      })
                    }
                  >
                    {d.name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputSwimStrictNumberOfDays"
              className="col-sm-3 col-form-label"
            >
              Strict number of days
            </label>
            <div className="col-sm-3">
              <input
                type="number"
                className="form-control"
                id="inputSwimStrictNumberOfDays"
                name="inputSwimStrictNumberOfDays"
                placeholder="Required"
                defaultValue={user.swimStrictNumberOfDays || ""}
                onChange={(event) =>
                  setUser({
                    ...user,
                    swimStrictNumberOfDays: parseInt(event.target.value),
                  })
                }
                ref={register({ required: true })}
              />
            </div>
            <div>
              {errors.inputSwimStrictNumberOfDays && (
                <span className="text-danger">
                  Strict number of days is required
                </span>
              )}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputSwimAverageWorkoutMeters"
              className="col-sm-3 col-form-label"
            >
              Average swimming workout length
            </label>
            <div className="col-sm-4">
              <div className="input-group">
                <input
                  min={1000}
                  max={4000}
                  type="number"
                  className="form-control"
                  id="inputSwimAverageWorkoutMeters"
                  name="inputSwimAverageWorkoutMeters"
                  defaultValue={user.swimAverageWorkoutMeters || ""}
                  onChange={(event) =>
                    setUser({
                      ...user,
                      swimAverageWorkoutMeters: parseInt(event.target.value),
                    })
                  }
                />
                <div className="input-group-append">
                  <span className="input-group-text">meters</span>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row py-3">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit(saveChanges)}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>

        <div className="w-50 float-left">
          <div>
            <h4>Subscription</h4>
            <UserSubscriptionInfoBlock
              plan={user.userSubscriptionInfo?.subscriptionPlanTitle}
              period={user.userSubscriptionInfo?.subscriptionPeriod}
              source={user.userSubscriptionInfo?.externalProductSource}
              product={user.userSubscriptionInfo?.externalProductId}
              createdAt={user.userSubscriptionInfo?.createdAt}
              prolongedAt={user.userSubscriptionInfo?.prolongedAt}
              expireAt={user.userSubscriptionInfo?.expireAt}
              cancelledAt={user.userSubscriptionInfo?.cancelledAt}
              autoProlongation={user.userSubscriptionInfo?.autoProlongation}
            />
            {!user.userSubscriptionInfo?.autoProlongation ? (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => history.push(`/user/${userId}/subscription`)}
              >
                Manage
              </button>
            ) : user.userSubscriptionInfo?.externalProductSource ==
              shared.ExternalProductSource.Stripe ? (
              <a
                target="_blank"
                href={
                  "https://dashboard.stripe.com/customers/" +
                  user.stripeCustomerId
                }
              >
                Manage subscription in Stripe
              </a>
            ) : (
              <p>You can manage subscription on payment provider dashboard!</p>
            )}
          </div>

          <div className="form-group row pt-3">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
              <h4>Other</h4>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputAdminComments" className="col-sm-3 col-form-label">
              Comments
            </label>
            <div className="col-sm-9">
            <textarea
                    className="form-control"
                    id="inputAdminComments"
                    rows={5}
                    value={user.adminComments}
                    onChange={(event) =>
                      setUser({ ...user, adminComments: event.target.value })
                    }
                ></textarea>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputCreatedAt" className="col-sm-3 col-form-label">
              Created At
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputCreatedAt"
                defaultValue={user.createdAt?.toString().slice(0, 15)}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputCountry" className="col-sm-3 col-form-label">
              Country
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputCountry"
                defaultValue={user.country}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputCity" className="col-sm-3 col-form-label">
              City
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputCity"
                defaultValue={user.city}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputAvatarUrl" className="col-sm-3 col-form-label">
              Avatar URL
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputAvatarUrl"
                defaultValue={user.avatarUrl}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputUserType" className="col-sm-3 col-form-label">
              User Type
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputUserType"
                defaultValue={
                  userTypes.find((ut) => ut.value === user.userType)?.name
                }
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputOperationSystem" className="col-sm-3 col-form-label">
              OS
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputOperationSystem"
                defaultValue={
                  operationSystems.find((os) => os.value === user.operationSystem)?.name
                }
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputTelegramId"
              className="col-sm-3 col-form-label"
            >
              Telegram Id
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputTelegramId"
                defaultValue={user.telegramId}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputTelegramUsername"
              className="col-sm-3 col-form-label"
            >
              Telegram Username
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputTelegramUsername"
                defaultValue={user.telegramUsername}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputHasConnectedStrava"
              className="col-sm-3 col-form-label"
            >
              Connected Strava
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputHasConnectedStrava"
                value={user.hasConnectedStrava ? "yes" : "no"}
                readOnly
              />
              {user.hasConnectedStrava ? (
                <React.Fragment>
                  <div className="device-info">
                    {user.stravaConnectedAt ? (
                      <span>
                        Connected at:{" "}
                        {format(user.stravaConnectedAt, "yyyy-MM-dd HH:mm:ss")}
                      </span>
                    ) : null}
                    {user.stravaAccountLink ? (
                      <a target="_blank" href={user.stravaAccountLink}>
                        <StravaSvg title="Strava Account Link" />
                      </a>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputHasConnectedGarmin"
              className="col-sm-3 col-form-label"
            >
              Connected Garmin
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputHasConnectedGarmin"
                value={user.hasConnectedGarmin ? "yes" : "no"}
                readOnly
              />
              {user.hasConnectedGarmin ? (
                <React.Fragment>
                  <div className="device-info">
                    {user.garminConnectedAt ? (
                      <span>
                        Connected at:{" "}
                        {format(user.garminConnectedAt, "yyyy-MM-dd HH:mm:ss")}
                      </span>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputHasConnectedApple"
              className="col-sm-3 col-form-label"
            >
              Connected Apple
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputHasConnectedApple"
                value={user.hasConnectedApple ? "yes" : "no"}
                readOnly
              />
              {user.hasConnectedApple ? (
                <React.Fragment>
                  <div className="device-info">
                    {user.appleConnectedAt ? (
                      <span>
                        Connected at:{" "}
                        {format(user.appleConnectedAt, "yyyy-MM-dd HH:mm:ss")}
                      </span>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputHasConnectedPolar"
              className="col-sm-3 col-form-label"
            >
              Connected Polar
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputHasConnectedPolar"
                value={user.hasConnectedPolar ? "yes" : "no"}
                readOnly
              />
              {user.hasConnectedPolar ? (
                <React.Fragment>
                  <div className="device-info">
                    {user.polarConnectedAt ? (
                      <span>
                        Connected at:{" "}
                        {format(user.polarConnectedAt, "yyyy-MM-dd HH:mm:ss")}
                      </span>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputIsSurveyCompleted"
              className="col-sm-3 col-form-label"
            >
              Survey Completed
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputIsSurveyCompleted"
                value={user.isSurveyCompleted ? "yes" : "no"}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputHasConsent"
              className="col-sm-3 col-form-label"
            >
              Has Consent
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputHasConsent"
                value={user.hasConsent ? "yes" : "no"}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputIsRunner" className="col-sm-3 col-form-label">
              Is Runner
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputIsRunner"
                value={user.isRunner ? "yes" : "no"}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputIsBiker" className="col-sm-3 col-form-label">
              Is Biker
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputIsBiker"
                value={user.isBiker ? "yes" : "no"}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputPostWorkoutAdaptation" className="col-sm-3 col-form-label">
              Post Workout Adaptation
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="inputPostWorkoutAdaptation"
                value={user.postWorkoutAdaptationEnabled ? "yes" : "no"}
                readOnly
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputPartner" className="col-sm-3 col-form-label">
              Partners
            </label>
            <div className="col-sm-9">
              <label className="col-form-label">
                {user.partners
                  ? user.partners.map(x => `${x.name} (#${x.id})`).join("; ")
                  : "none"}
              </label>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              Push-Notification Tokens
            </label>
            <div className="col-sm-9">
              <label className="col-form-label">
                {user.pushNotificationTokens
                  ? user.pushNotificationTokens.join("; ")
                  : "none"}
              </label>
            </div>
          </div>

          {/* <div className="form-group row">
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button className="btn btn-danger btn-sm" onClick={() => { handleDeleteClick(user.id) }}>
                                <Icon.Trash /> Delete user
                            </button>
                        </div>
                    </div> */}
        </div>
      </div>
    );
  };

  return (
    <div className="users-profile">
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div>
            <h3 className="float-left">
              User ID: {userId}{" "}
              {user.isActive ? <Icon.Activity color="green" /> : ""}
            </h3>
            <div className="float-right">
              <UserProfileButtons userId={userId} type={ProfileType.Main} />
            </div>
            <div className="clear-both p-2" />
          </div>
          {user.userSuspendedData?.length ?? 0 > 0 ? (
            <React.Fragment>
              <h4>Suspension info</h4>
              <div style={{ width: 350 }}>
                <table className="py-1 table table-sm">
                  <tbody>
                    {user.userSuspendedData?.map((x) => {
                      return (
                        <tr>
                          <td>
                            {format(x.suspensionDate, "yyyy-MM-dd HH:mm:ss")}{" "}
                            UTC
                          </td>
                          <td>
                            {
                              coachingTypeValues.find(
                                (c) => c.value === x.coachingType
                              )?.name
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          ) : null}
          <h4>Profile</h4>
          <form>
            {errorLoad ? "" : getForm()}

            <div className="form-group row pb-4">
              <div className="col-sm-12">
                {submitStatusShown && showMessage()}
              </div>
            </div>
          </form>
        </React.Fragment>
      )}
    </div>
  );
};

export default UsersProfileView;
