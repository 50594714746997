import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';

type TrainingLinkProps = {
    link: string,
    rowIndex: number,
    onSave: (link: string, rowIndex: number) => void,
    onDelete: (rowIndex: number) => void
};

const TrainingLinkRow = (props: TrainingLinkProps) => {
    const [isEditable, setIsEditable] = useState(false);
    const [link, setLink] = useState("");
    const [linkError, setLinkError] = useState<string | undefined>();

    useEffect(() => {
        setLink(props.link);

        if (props.link.length === 0) {
            setIsEditable(true);
        }
    }, []);

    const editLink = () => {
        setIsEditable(true);
    }

    const saveLink = () => {
        if (link.length === 0) {
            setLinkError("Link can't be empty.")
            return;
        }

        setLinkError(undefined);

        props.onSave(link, props.rowIndex);

        setIsEditable(false);
    }

    const deleteLink = () => {
        if (window.confirm("Are you sure you want to delete link?")) {
            props.onDelete(props.rowIndex);
        }
    }

    return (
        <tr className="py-1">
            <td className="p-2" style={{ fontSize: "90%" }}>{props.rowIndex + 1}</td>
            <td className="middle p-1">
                {
                    !isEditable
                        ?
                        <a target="_blank" href={props.link}>{props.link}</a>
                        :
                        <React.Fragment>
                            <input type="text" value={link} className="form-control"
                                onChange={(e) => {
                                    setLink(e.target.value);
                                }}
                            ></input>
                            <div style={{"fontSize": "smaller"}}>Click "Save link" at the right to keep your changes.</div>
                            {
                                linkError ? <div className="error-text">{linkError}</div> : null
                            }
                        </React.Fragment>
                }

            </td>
            <td className="p-1" style={{ fontSize: "80%" }}>
                {
                    !isEditable
                        ?
                        <React.Fragment>
                            <a onClick={() => { editLink(); }} title="Edit link">
                                <Icon.Edit className="nav__icon" />
                            </a>
                            <a onClick={() => { deleteLink() }} title="Delete link">
                                <Icon.Trash className="nav__icon" />
                            </a>
                        </React.Fragment>
                        :
                        <a onClick={() => { saveLink() }} title="Save link">
                            <Icon.Save className="nav__icon" />
                        </a>
                }
            </td>
        </tr>
    );
}

export default TrainingLinkRow;