import { CoachingType, SportType, SurveySportType } from '../shared/Primitives';

// commented code is code for the future. For now we use only Running -> Fitness by task https://omysport.myjetbrains.com/youtrack/issue/OMY-293
export const sportTypeValues: SportTypeDescription[] = [
    {
        value: SportType.Running,
        label: 'Running',
        isDisabled: false,
        coachingTypes: [
            {
                value: CoachingType.RunningFitness,
                label: 'RunningFitness',
                isDisabled: false
            },
            {
                value: CoachingType.RunningRace,
                label: 'RunningRace',
                isDisabled: false
            }
        ]
    },
    {
        value: SportType.Cycling,
        label: 'Cycling',
        isDisabled: false,
        coachingTypes: [
            {
                value: CoachingType.CyclingRace,
                label: 'CyclingRace',
                isDisabled: false
            },
            {
                value: CoachingType.CyclingFitness,
                label: 'CyclingFitness',
                isDisabled: false
            }
        ]
    },
    {
        value: SportType.Swimming,
        label: 'Swimming',
        isDisabled: true,
        coachingTypes: [
            {
                value: CoachingType.Swimming,
                label: 'Swimming',
                isDisabled: true
            }
        ]
    }
]

export const surveySportTypeValues: SurveySportTypeDescription[] = [
    {
        value: SurveySportType.Running,
        label: 'Running',
        isDisabled: false,
        coachingTypes: [
            {
                value: CoachingType.RunningFitness,
                label: 'RunningFitness',
                isDisabled: false
            },
            {
                value: CoachingType.RunningRace,
                label: 'RunningRace',
                isDisabled: false
            }
        ]
    },
    {
        value: SurveySportType.Cycling,
        label: 'Cycling',
        isDisabled: false,
        coachingTypes: [
            {
                value: CoachingType.CyclingRace,
                label: 'CyclingRace',
                isDisabled: false
            },
            {
                value: CoachingType.CyclingFitness,
                label: 'CyclingFitness',
                isDisabled: false
            }
        ]
    },
    {
        value: SurveySportType.Triathlon,
        label: 'Triathlon',
        isDisabled: false,
        coachingTypes: [
            {
                value: CoachingType.TriathlonFitness,
                label: 'TriathlonFitness',
                isDisabled: false
            },
            {
                value: CoachingType.TriathlonRace,
                label: 'TriathlonRace',
                isDisabled: true
            },
        ]
    }
]


export interface SportTypeDescription {
    value: SportType;
    label: String;
    isDisabled: Boolean;
    coachingTypes: SportTypeCoachingTypeDescription[];
}

export interface SurveySportTypeDescription {
    value: SurveySportType;
    label: String;
    isDisabled: Boolean;
    coachingTypes: SportTypeCoachingTypeDescription[];
}

export interface SportTypeCoachingTypeDescription {
    value: CoachingType;
    label: String;
    isDisabled: Boolean;
}