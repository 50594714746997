import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { hasRoles } from "../api/Helpers";
import { useAuth0 } from "../react-auth0-spa";

const PrivateRoute = ({
    component: Component,
    path,
    roles,
    ...rest
}) => {
    const {
        loading,
        isAuthenticated,
        loginWithRedirect,
        accessToken,
        isAuthorizedByRole = hasRoles(accessToken, ...roles.split(',').filter(x => x !== ""))
    } = useAuth0();

    useEffect(() => {
        if (loading || (isAuthenticated)) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: {
                    targetUrl: path
                }
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect, path, accessToken, roles]);

    const render = props => (isAuthenticated === true && isAuthorizedByRole) ? < Component {
        ...props
    }
    /> 
    : !isAuthorizedByRole ? <Redirect to="/users" />
    : null;

    return <Route path = {
        path
    }
    render = {
        render
    } {
        ...rest
    }
    />;
};

export default PrivateRoute;
