import React, { useState, useEffect } from 'react';
import '../TrainingsView.scss';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { useAuth0 } from "./../../../react-auth0-spa";

import { TrainingExportStatus } from '../../../api/types';
import { Loader } from 'react-feather';
import { Button } from 'react-bootstrap';
import moment from 'moment';

type TrainingExportProps = {
    trainingId: number,
    userId: number
}

const TrainingExport = (props: TrainingExportProps) => {
    const { accessToken } = useAuth0();
    const basecamp = new BaseCampClient(accessToken);

    const [status, setTrainingExportStatus] = useState<TrainingExportStatus | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        getExportStatus();
    }, [props.trainingId]);

    const getExportStatus = () => basecamp.getTrainingExportStatus(props.trainingId)
        .then(res => setTrainingExportStatus(res))
        .finally(() => setIsLoading(false));

    const exportToGarmin = (isAlternate: boolean) => {
        setIsLoading(true);
        basecamp.createOrUpdateGarminWorkout(props.trainingId, isAlternate)
            .then(() => getExportStatus())
            .catch(ex => {
                alert(ex && ex.message || ex);
                setIsLoading(false)
            });
    }
    const deleteFromGarmin = () => {
        setIsLoading(true);
        basecamp.deleteGarminWorkout(props.trainingId)
            .then(() => getExportStatus())
            .catch(ex => {
                alert(ex && ex.message || ex);
                setIsLoading(false)
            });
    }

    return (
        <div className='training-export-wrapper'>
            {
                isLoading ? <Loader alignmentBaseline='middle' style={{textAlign: 'center'}} /> : <div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td className='app-name'>Garmin</td>
                                <td>
                                    {status?.isGarminConnected ? 
                                    (status?.garminExportDate ? <>Export date: {moment(status.garminExportDate).format("YYYY-MM-DD HH:mm:ss")}</> : null)
                                    : <>App is not connected</>
                                    }
                                </td>
                                <td>
                                {
                                    status?.isGarminConnected ?
                                        status.garminExportDate == null
                                            ? <div className='float-right'>
                                                <Button variant="primary" onClick={() => exportToGarmin(false)}>Export workout</Button>
                                                {
                                                    status?.hasAlternativeStructure && status.garminExportDate == null
                                                        ? <Button style={{"marginLeft": "10px"}} variant="primary" onClick={() => exportToGarmin(true)}>Export outdoor workout</Button>
                                                        : null
                                                }
                                              </div>
                                            : <Button className='float-right' variant="danger" onClick={() => deleteFromGarmin()}>Delete Workout</Button>
                                        : null
                                }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div >
    );
}

export default TrainingExport;