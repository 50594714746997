import React from 'react';
import { SurveyDefinition, SurveyQuestionTypeFilterData } from '../../../api/types';
import * as Icon from 'react-feather';
import { getTextLocalization, isDefinitionConstraintsEmpty, isDefinitionOptionsEmpty } from '../../../api/Helpers';
import { Language } from '../../../shared/Primitives';


type DefinitionInfoProps = {
    definition: SurveyDefinition,
    selectedLanguage: Language,
    index: number,
    questionTypes: SurveyQuestionTypeFilterData[],
    questionIds: string[],
    definitionsCount: number,
    onMoveDefinition: (step: -1 | 1) => void,
    onEditDefinition: () => void,
    onDeleteDefinition: () => void
};

const DefinitionInfoBlock = (props: DefinitionInfoProps) => {

    const getTextLocalizationWithDefault = (textEn: string | undefined, textRu: string | undefined, textDe: string | undefined, defaultVal: string): string => {
        const text = getTextLocalization(textEn, textRu, textDe, props.selectedLanguage);
        return text.length > 0 ? text : defaultVal;
    }

    const getTextLocalizationYesNo = (textEn: string | undefined, textRu: string | undefined, textDe: string | undefined): string => {
        const text = getTextLocalization(textEn, textRu, textDe, props.selectedLanguage);
        return text.length > 0 ? "✔️" : " - ";
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col questionText">{getTextLocalizationWithDefault(props.definition.questionTextEn, props.definition.questionTextRu, props.definition.questionTextDe, " - ")}</div>
            </div>
            <div className="row">
                <div className="col col-md-3">
                    <div><span className="bold">QuestionId:</span> {props.definition.questionId}</div>
                    <div><span className="bold">Question Type:</span> {props.questionTypes.filter(x => x.value === props.definition.questionType)[0]?.caption}</div>
                    <div><span className="bold">Next QuestionId:</span> {props.definition.nextQuestionId}</div>
                    <div><span className="bold">Question Hint:</span> {getTextLocalizationWithDefault(props.definition.questionHintEn, props.definition.questionHintRu, props.definition.questionHintDe, " - ")}</div>
                    <div><span className="bold">Is optional:</span> {props.definition.isQuestionOptional ? 'true' : 'false'}</div>
                    <div><span className="bold">Question icon:</span> {props.definition.questionIcon && props.definition.questionIcon?.length > 0 ? props.definition.questionIcon : " - "}</div>
                </div>
                <div className="col  col-md-3 col-bordered">
                    <div className="bold">Constraints:</div>
                    {
                        !isDefinitionConstraintsEmpty(props.definition.constraints) ?
                            <React.Fragment>
                                <div><span className="bold">Digits:</span> {props.definition.constraints?.digits}</div>
                                <div><span className="bold">Min:</span> {props.definition.constraints?.min}</div>
                                <div><span className="bold">Max:</span> {props.definition.constraints?.max}</div>
                                <div><span className="bold">Default:</span> {props.definition.constraints?.default}</div>
                                <div><span className="bold">Measurement Type:</span> {getTextLocalizationWithDefault(props.definition.constraints?.measurementTypeEn, props.definition.constraints?.measurementTypeRu, props.definition.constraints?.measurementTypeDe, " - ")}</div>
                            </React.Fragment>
                            : <div>None</div>
                    }
                </div>
                <div className="col  col-md-6">
                    <div className="bold">Options:</div>
                    {
                        !isDefinitionOptionsEmpty(props.definition.options) ?
                            <React.Fragment>
                                <div className="row">
                                    <div className="col bold">Caption</div>
                                    <div className="col bold">Value</div>
                                    <div className="col bold">NextQuestionId</div>
                                    <div className="col bold">Hint</div>
                                    <div className="col bold">Icon</div>
                                </div>
                                {
                                    props.definition.options?.map((o, index) => {
                                        return (
                                            <div className="row" key={o.key}>
                                                <div className="col">{getTextLocalizationWithDefault(o.captionEn, o.captionRu, o.captionDe, " - ")}</div>
                                                <div className="col">{o.value}</div>
                                                <div className="col">{o.nextQuestionId}</div>
                                                <div className="col" title={getTextLocalization(o.hintEn, o.hintRu, o.hintDe, props.selectedLanguage)}>{getTextLocalizationYesNo(o.hintEn, o.hintRu, o.hintDe)}</div>
                                                <div className="col" title={o.icon}>{o.icon && o.icon.length > 0 ? "✔️" : " - "}</div>
                                            </div>
                                        );
                                    })
                                }
                            </React.Fragment>
                            : <div>Options are empty.</div>
                    }
                </div>
            </div>
            <div className="btn-panel">
                <div>
                    <a onClick={(e) => { props.onEditDefinition() }}>Edit</a>
                    <a onClick={(e) => { props.onDeleteDefinition() }}>Delete</a>
                </div>
                <div>
                    <button onClick={(e) => { props.onMoveDefinition(-1) }} className="btn btn-sm btn-primary" disabled={props.index <= 0} title={"Move question up"}><Icon.ChevronUp /></button>
                    &nbsp;
                    <button onClick={(e) => { props.onMoveDefinition(1) }} className="btn btn-sm btn-primary" disabled={props.index >= props.definitionsCount - 1} title={"Move question down"}><Icon.ChevronDown /></button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DefinitionInfoBlock;