import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/App";
import * as serviceWorker from './serviceWorker';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme.css';

import { Auth0Provider } from "./react-auth0-spa";
import config from "./config"

import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const bugsnagClient = bugsnag({ 
    apiKey: '285731c400d6f40a8b4fa81f5ce071c2',
    releaseStage: process.env.NODE_ENV, // By default, if the URL contains localhost this is set to development. 
                                        // The default value in all other circumstances is production.
                                        // TODO: need to specify 'staging' on staging
    notifyReleaseStages: [ 'production', 'staging' ]
})
bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
    <ErrorBoundary>
        <Auth0Provider
            domain={config.auth0.domain}
            client_id={config.auth0.clientId}
            redirect_uri={window.location.origin}
            audience={config.auth0.audience}
            cacheLocation="localstorage"
        >
            <App bugsnag={bugsnagClient}/>
        </Auth0Provider>
    </ErrorBoundary>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
