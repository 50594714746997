import React, { useState } from 'react';
import { SurveyDefinition, SurveyDefinitionOption, SurveyDefinitionAnswerConstraints, SurveyQuestionTypeFilterData } from '../../../api/types';
import Select from 'react-select';
import * as Icon from 'react-feather';
import { getRandomKey, getTextLocalization, isDefinitionOptionsEmpty } from '../../../api/Helpers';
import DefinitionOptionEditBlock from './DefinitionOptionEditBlock'
import { Language } from '../../../shared/Primitives';
import ShowHintLocalization from './ShowHintLocalization';

type DefinitionEditProps = {
    definition: SurveyDefinition,
    selectedLanguage: Language,
    index: number,
    questionTypes: SurveyQuestionTypeFilterData[],
    questionIds: string[],
    onCancel: () => void,
    onKeepDefinition: (definition: SurveyDefinition) => void
};

const DefinitionEditBlock = (props: DefinitionEditProps) => {

    const [definition, setDefinition] = useState(props.definition);

    const onQuestionTextChange = (value: string) => {
        switch (props.selectedLanguage) {
            case Language.De:
                setDefinition({ ...definition, questionTextDe: value });
                break;
            case Language.Ru:
                setDefinition({ ...definition, questionTextRu: value });
                break;
            default:
                setDefinition({ ...definition, questionTextEn: value });
                break;
        }
    }

    const onQuestionIdChange = (value: string) => {
        setDefinition({ ...definition, questionId: value });
    }

    const onNextQuestionIdChange = (selectedOption: any) => {
        setDefinition({ ...definition, nextQuestionId: selectedOption?.value });
    }

    const onQuestionTypeChange = (selectedOption: any) => {
        setDefinition({ ...definition, questionType: (selectedOption as SurveyQuestionTypeFilterData).value });
    };

    const onQuestionHintChange = (value: string) => {
        switch (props.selectedLanguage) {
            case Language.De:
                setDefinition({ ...definition, questionHintDe: value });
                break;
            case Language.Ru:
                setDefinition({ ...definition, questionHintRu: value });
                break;
            default:
                setDefinition({ ...definition, questionHintEn: value });
                break;
        }
    }

    const onQuestionIconChange = (value: string) => {
        setDefinition({ ...definition, questionIcon: value });
    }

    const onConstraintsDigitsChange = (value: string) => {
        const newValue = parseInt(value) || undefined;
        const result = { ...definition.constraints, digits: newValue }

        setDefinition({ ...definition, constraints: result as SurveyDefinitionAnswerConstraints });
    }

    const onConstraintsMinChange = (value: string) => {
        const newValue = parseInt(value) || undefined;
        const result = { ...definition.constraints, min: newValue }

        setDefinition({ ...definition, constraints: result as SurveyDefinitionAnswerConstraints });
    }

    const onConstraintsMaxChange = (value: string) => {
        const newValue = parseInt(value) || undefined;
        const result = { ...definition.constraints, max: newValue }

        setDefinition({ ...definition, constraints: result as SurveyDefinitionAnswerConstraints });
    }

    const onConstraintsDefaultChange = (value: string) => {
        const newValue = parseInt(value) || undefined;
        const result = { ...definition.constraints, default: newValue }

        setDefinition({ ...definition, constraints: result as SurveyDefinitionAnswerConstraints });
    }

    const onConstraintsMeasurementTypeChange = (value: string) => {
        const result = { ...definition.constraints || {} as SurveyDefinitionAnswerConstraints }
        switch (props.selectedLanguage) {
            case Language.De:
                result.measurementTypeDe = value;
                break;
            case Language.Ru:
                result.measurementTypeRu = value;
                break;
            default:
                result.measurementTypeEn = value;
                break;
        }
        setDefinition({ ...definition, constraints: result as SurveyDefinitionAnswerConstraints });
    }

    const onOptionChanged = (option: SurveyDefinitionOption, index: number) => {
        const updatedOptions = definition.options?.map(x => x);
        if (updatedOptions) {
            updatedOptions[index] = option;
            setDefinition({ ...definition, options: updatedOptions });
        }
    }

    const onOptionAdd = () => {
        const newOption = { key: getRandomKey(), captionEn: "", captionRu: "", captionDe: "", value: "" } as SurveyDefinitionOption;

        const updatedOptions = definition.options ?? [];
        updatedOptions.push(newOption);
        setDefinition({ ...definition, options: updatedOptions });
    }

    const onOptionDelete = (index: number) => {
        if (definition.options && index < definition.options.length) {
            definition.options.splice(index, 1)
            setDefinition({ ...definition, options: definition.options });
        }
    }

    const keep = () => {
        props.onKeepDefinition(definition);
    }

    const cancel = () => {
        props.onCancel();
    }

    return (
        <React.Fragment>
            <div className="form-group row">
                <div className="col">
                    <label>Question Text</label>
                    <input className="form-control" type="text" value={getTextLocalization(definition.questionTextEn, definition.questionTextRu, definition.questionTextDe, props.selectedLanguage)}
                        onChange={(e) => { onQuestionTextChange(e.target.value) }}
                    />
                    <ShowHintLocalization selectedLanguage={props.selectedLanguage} 
                        txtEn={definition.questionTextEn} txtRu={definition.questionTextRu}  txtDe={definition.questionTextDe} />
                </div>
                <div className="col col-md-2">
                    <label htmlFor="inputIsOptional">Optional: </label>
                    <div className="active-checkbox-wrapper">
                        <input id="inputIsOptional" type="checkbox" checked={definition.isQuestionOptional}
                            onChange={(event) => setDefinition({ ...definition, isQuestionOptional: event.target.checked })} />
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <div className="col">
                    <label>QuestionId:</label>
                    <input className="form-control" type="text" value={definition.questionId || ""}
                        onChange={(e) => { onQuestionIdChange(e.target.value) }}
                    />
                </div>
                <div className="col">
                    <label>Question Type:</label>
                    <Select style={{ "padding-left": "0px" }}
                        options={props.questionTypes}
                        value={props.questionTypes.filter(x => x.value === definition.questionType)[0]}
                        getOptionLabel={x => x.caption}
                        getOptionValue={x => `${x.value}`}
                        onChange={onQuestionTypeChange}
                    />
                </div>
                <div className="col">
                    <label>NextQuestionId:</label>
                    <Select style={{ "padding-left": "0px" }}
                        isClearable
                        options={props.questionIds.map((x) => { return { label: x, value: x } })}
                        getOptionLabel={x => x.label || ""}
                        getOptionValue={x => x.value}
                        value={{ value: definition.nextQuestionId || "", label: definition.nextQuestionId || "" }}
                        onChange={onNextQuestionIdChange}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className="col">
                    <label>Question Hint:</label>
                    <input className="form-control" type="text" value={getTextLocalization(definition.questionHintEn, definition.questionHintRu, definition.questionHintDe, props.selectedLanguage)}
                        onChange={(e) => { onQuestionHintChange(e.target.value) }}
                    />
                    <ShowHintLocalization selectedLanguage={props.selectedLanguage} 
                        txtEn={definition.questionHintEn} txtRu={definition.questionHintRu}  txtDe={definition.questionHintDe} />
                </div>
                <div className="col">
                    <label>Question Icon:</label>
                    <input className="form-control" type="text" value={definition.questionIcon || ""}
                        onChange={(e) => { onQuestionIconChange(e.target.value) }}
                    />
                </div>
            </div>
            <div className="form-group row split-col">
                <div className="col col-md-3">
                    <div className="form-group row"><div className="col"><h6>Constraints:</h6></div></div>
                    <div className="form-group row">
                        <div className="col">
                            <label className="form-label">Digits:</label>
                            <input className="form-control" type="number" value={definition.constraints?.digits || ""}
                                onChange={(e) => { onConstraintsDigitsChange(e.target.value) }}
                            />
                        </div>
                        <div className="col">
                            <label className="form-label">Min:</label>
                            <input className="form-control" type="number" value={definition.constraints?.min || ""}
                                onChange={(e) => { onConstraintsMinChange(e.target.value) }}
                            />
                        </div>
                        <div className="col">
                            <label className="form-label">Max:</label>
                            <input className="form-control" type="number" value={definition.constraints?.max || ""}
                                onChange={(e) => { onConstraintsMaxChange(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col">
                            <label className="form-label">Default value:</label>
                            <input className="form-control" type="number" value={definition.constraints?.default || ""}
                                onChange={(e) => { onConstraintsDefaultChange(e.target.value) }}
                            />
                        </div>
                        <div className="col">
                            <label className="form-label">Measurement type:</label>
                            <input className="form-control" value={getTextLocalization(definition.constraints?.measurementTypeEn, definition.constraints?.measurementTypeRu, definition.constraints?.measurementTypeDe, props.selectedLanguage)}
                                onChange={(e) => { onConstraintsMeasurementTypeChange(e.target.value) }}
                            />
                            <ShowHintLocalization selectedLanguage={props.selectedLanguage} 
                                txtEn={definition.constraints?.measurementTypeEn} txtRu={definition.constraints?.measurementTypeRu}  txtDe={definition.constraints?.measurementTypeDe} />
                        </div>
                    </div>
                </div>
                <div className="col col-md-7">
                    <div className="form-group row"><h6>Options:</h6></div>
                    <div className="form-group row">
                        {
                            !isDefinitionOptionsEmpty(definition.options) ?
                                definition.options!.map((o, index) => {
                                    return (
                                        <DefinitionOptionEditBlock
                                            key={o.key}
                                            selectedLanguage={props.selectedLanguage}
                                            index={index}
                                            option={o}
                                            questionIds={props.questionIds}
                                            onDelete={onOptionDelete}
                                            onChanged={onOptionChanged}
                                        />
                                    )
                                })
                                : null
                        }
                    </div>
                    <div className="btn-panel">
                        <a onClick={(e) => { onOptionAdd() }}><Icon.Plus /> Add Option</a>
                    </div>
                </div>
            </div>
            <div className="btn-panel">
                <div>
                    <a onClick={(e) => { keep() }}>Ok</a>
                    <a onClick={(e) => { cancel() }}>Cancel</a>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DefinitionEditBlock