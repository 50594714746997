import React, { useState, useEffect } from 'react';

import * as Icon from 'react-feather';

import { useAuth0 } from "./../../react-auth0-spa";

import { BaseCampClient } from '../../api/BaseCampClient';

import { BroadcastingMessage } from "../../api/types";

import DataTable, { IDataTableColumn } from 'react-data-table-component';

// @ts-ignore
import ShowMore from "react-simple-show-more";

import './MessagingView.scss';
import Select from 'react-select';
import { BroadcastChannel, ProgressStatus } from '../../shared/Primitives';

type OptionType = { label: string, value: string }

const MessagingView = () => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const [broadcastMessage, setBroadcastMessage] = useState("");
    const [sendingMessage, setSendingMessage] = useState(false);
    const [messageSent, setMessageSent] = useState("");
    const [messageError, setMessageError] = useState("");
    const [realSendShown, setRealSendShown] = useState(false);

    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [messages, setMessages] = useState({} as BroadcastingMessage[])
    const [selectedLanguage, setSelectedLanguage] = useState("ru");
    const [selectedChannel, setSelectedChannel] = useState("");

    const languages = [
        { label: "ru", value: "ru" },
        { label: "en", value: "en" }
    ] as OptionType[];

    const channels = [
        { label: "Select...", value: "" },
        { label: "All", value: BroadcastChannel.All },
        { label: "Bot", value: BroadcastChannel.Bot },
        { label: "Chat", value: BroadcastChannel.Chat }
    ] as OptionType[];

    useEffect(() => {
        setLoading(true);
        baseCamp.getBroadcastingMessage()
            .then(data => {
                setMessages(data);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => setLoading(false))
    }, [refresh]);

    const sendMessageConfirm = async () => {

    };

    const sendBroadcastMessage = async () => {
        setSendingMessage(true);

        try {
            const channel = selectedChannel as BroadcastChannel;
            await baseCamp.broadcastMessage(broadcastMessage, selectedLanguage, channel);
            setMessageSent("Broadcast message was queued!");
            setMessageError("");
            setBroadcastMessage("");
            setRefresh(!refresh);
        }
        catch (error) {
            console.error(error);
            setMessageSent("");
            let message = 'Unknown Error'
            if (error instanceof Error) message = error.message
            setMessageError(message);
        }
        finally {
            setSendingMessage(false);
            toggleRealSend();
        }
    }

    const toggleRealSend = () => {
        if (!selectedChannel) {
            alert("Please select where to send message!");
            return;
        }
        setRealSendShown(!realSendShown);
    }

    const renderCell = (message: BroadcastingMessage) => {
        return <div className={`messaging-view-cell-width`}>
            <div className={`message-data message-data-coach`}>
                <span className="message-data-time">
                    {new Intl.DateTimeFormat("en", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false
                    }).format(new Date(message.createdAt))}
                    {message.language ? `, ${message.language}` : null}, sent to {message.channel}
                    {message.status === ProgressStatus.Ok ? " ✔️" : message.status === ProgressStatus.Fail ? " ❌" : " ⏱️"}
                </span>
            </div>
            <div className="message-text message-text-coach">
                <ShowMore text={message.message} showMoreLabel="  More" showLessLabel="  Less" className="show-more-link" />
            </div>
        </div>
    }

    const baseColumnSetting: IDataTableColumn<BroadcastingMessage> = {
        name: "",
        width: "100%"
    };
    const columns: IDataTableColumn<BroadcastingMessage>[] = [
        {
            ...baseColumnSetting,
            name: "",
            selector: "createdAt",
            cell: renderCell
        }
    ];

    return <div className="bot-stats">
        <h2><Icon.AlertCircle className="nav__icon" /> Broadcast message to all users</h2>
        <div className="w-50">
            <div className="form-group clearfix">
                <textarea
                    className="form-control"
                    id="outgoing-message"
                    rows={10}
                    value={broadcastMessage}
                    maxLength={4096}
                    onChange={e => {
                        setBroadcastMessage(e.target.value);
                        setMessageSent("");
                    }}
                ></textarea>
                
                <div className="row messaging-view-select-language">
                    <label htmlFor="inputChannel" className="col-sm-2 col-form-label">Send to:</label>
                    <div className="col-sm-3">
                        <Select id="inputChannel"
                            options={channels}
                            value={languages.find(l => l.value === selectedChannel)}
                            getOptionValue={v => v.value}
                            onChange={value => setSelectedChannel((value as OptionType).value)}
                        />
                    </div>

                    <label htmlFor="inputLanguage" className="col-sm-2 col-form-label">Language:</label>
                    <div className="col-sm-3">
                        <Select id="inputLanguage"
                            options={languages}
                            value={languages.find(l => l.value === selectedLanguage)}
                            getOptionValue={v => v.value}
                            onChange={value => setSelectedLanguage((value as OptionType).value)}
                        />
                    </div>
                </div>

                {messageSent !== "" ? <span className="badge badge-success">{messageSent}</span> : null}
                {messageError !== "" ? <span className="badge badge-danger">{messageError}</span> : null}
                
                {!realSendShown ? <button
                    onClick={toggleRealSend}
                    className="btn btn-danger btn-sm my-2 float-right"
                >Send</button> : null}

                {realSendShown ? <div className="py-2 float-right">
                    <button className="btn btn-light btn-sm" disabled={true}>Are you sure?</button>
                    <button className="btn btn-success btn-sm float-right" onClick={toggleRealSend}>Nope</button>
                    <button
                        onClick={sendBroadcastMessage}
                        className="btn btn-secondary btn-sm float-right mr-2"
                        disabled={sendingMessage}
                    >
                        {sendingMessage ?? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        {sendingMessage ? " Sending..." : "Yes!"}
                    </button>
                </div> : null}
            </div>
        </div>
        <div className="w-50 d-block">
            <h2>History</h2>
            {
                !loading ?
                    messages.length > 0 ?
                        <div className="users-profile-messages">
                            <DataTable columns={columns} data={messages} noHeader noTableHead={true}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 30]}
                                paginationPerPage={5}
                            />
                        </div>
                        : <h5>No messages</h5>
                    : null
            }
        </div>
    </div>;
}

export default MessagingView;
