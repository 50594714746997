import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { useAuth0 } from "../../react-auth0-spa";
import { Survey, SurveyFilterData, SurveyLanguageFilterData, SurveyTypeFilterData } from '../../api/types';
import { Language } from '../../shared/Primitives';
import { BaseCampClient } from '../../api/BaseCampClient';
import './SurveyView.scss';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import * as Icon from 'react-feather';
import SurveyExportDataModal from './survey-components/SurveyExportDataModal';
import SurveyImportStageResultModal from './survey-components/SurveyImportStageResultModal';

const SurveysView = () => {
    const { accessToken } = useAuth0();
    const basecamp = new BaseCampClient(accessToken);

    const [allSurveys, setAllSurveys] = useState<Survey[]>([]);
    const [surveyFilterData, setSurveyFilterData] = useState<SurveyFilterData | undefined>(undefined);
    const [selectedSurveyType, setSelectedSurveyType] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const [isShowExportModal, setIsShowExportModal] = useState(false);
    const [jsonExportData, setJsonExportData] = useState("");
    const [isShowImportModal, setIsShowImportModal] = useState(false);
    const [jsonImportData, setJsonImportData] = useState("");

    const [isShowImportStageResultModal, setIsShowImportStageResultModal] = useState(false);
    const [importStageResult, setImportStageResult] = useState<string[]>([]);

    const history = useHistory();

    useEffect(() => {
        loadSurveys();
    }, []);

    const loadSurveys = () => {
        setLoading(true);
        basecamp.getSurveyFilterData()
            .then(res => {
                setSurveyFilterData(res);
                basecamp.getAllSurveys()
                    .then(res => {
                        setAllSurveys(res);
                    })
                    .catch((ex) => {
                        console.log(ex);
                        alert("Unable to get survey list!");
                    })
            })
            .catch((ex) => {
                console.log(ex);
                alert("Unable to get survey filter data!");
            })
            .finally(() => setLoading(false));
    }

    const getDisplayDate = (d: Date) => {
        return new Intl.DateTimeFormat("de", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
        }).format(d);
    };

    const handleSurveyTypeChange = (selectedOption: any) => {
        setSelectedSurveyType(selectedOption ? (selectedOption as SurveyTypeFilterData).value : undefined)
    };

    const surveyCopy = (id: number) => {
        if (window.confirm("Are you sure you want to copy this survey?")) {
            setLoading(true);
            basecamp.copySurvey(id)
                .then(res => {
                    history.push(`/surveys/${res}`);
                })
                .catch((ex) => {
                    console.log(ex);
                    alert("There was error during copy survey!");
                })
                .finally(() => setLoading(false));
        }
    }

    const deleteSurvey = (id: number) => {
        if (window.confirm("Are you sure you want to delete this survey?")) {
            setLoading(true);
            basecamp.deleteSurvey(id)
                .then(res => {
                    const updatedSurveys = allSurveys.filter(x => x.id !== id);
                    setAllSurveys(updatedSurveys);
                })
                .catch((ex) => {
                    console.log(ex);
                    alert("There was error during survey delete!");
                })
                .finally(() => setLoading(false));
        }
    }

    const showExportData = (id: number) => {
        setLoading(true);
        basecamp.getSurvey(id)
            .then(res => {
                if (!res) {
                    alert("Survey not found");
                    return;
                }
                const json = JSON.stringify(res);
                setJsonExportData(json);
                setIsShowExportModal(true);
            })
            .catch((ex) => {
                console.log(ex);
                alert("Unable to get survey data.");
            })
            .finally(() => setLoading(false));
    }

    const closeExportModal = () => {
        setJsonExportData("");
        setIsShowExportModal(false);
    }

    const showImportModal = () => {
        setJsonImportData("");
        setIsShowImportModal(true);
    }

    const closeImportModal = () => {
        setJsonImportData("");
        setIsShowImportModal(false);
    }

    const closeImportStageModal = () => {
        setImportStageResult([]);
        setIsShowImportStageResultModal(false);
    }

    const importSurvey = (survey: Survey) => {
        setLoading(true);
        basecamp.importSurvey(survey)
            .then(res => {
                setIsShowImportModal(false);
                history.push(`/surveys/${res}`);
            })
            .catch((ex) => {
                console.log(ex);
                alert("There was error during survey import!");
            })
            .finally(() => setLoading(false));
    }

    const importSurveysFromStage = (isPreview: boolean) => {
        if (window.confirm(`Are you sure you want to ${isPreview ? "preview" : ""} import active Surveys from stage?`)) {
            setLoading(true);
            basecamp.importSurveysFromStage(isPreview)
                .then(res => {
                    setImportStageResult(res);
                    setIsShowImportStageResultModal(true);
                    loadSurveys();
                })
                .catch((ex) => {
                    console.log(ex);
                    alert("There was error during survey import!");
                })
                .finally(() => setLoading(false));
        }
    }

    return (
        <div className="surveys">
            {
                loading ? <Loader /> : ""
            }
            <h2>Surveys</h2>
            <div className="content-wrapper">
                <div className="split-col">
                    <div className="filter">
                        <div className="add-link">
                            <Link to={`/surveys/0`}><Icon.Plus /> Add New</Link>
                        </div>
                        <div className="filter-survey-type">
                            <div>Type:</div>
                            <div>
                                <Select
                                    isClearable
                                    options={surveyFilterData?.surveyTypes}
                                    value={selectedSurveyType ? surveyFilterData?.surveyTypes.filter(x => x.value === selectedSurveyType)[0] : undefined}
                                    getOptionLabel={x => x.caption}
                                    getOptionValue={x => `${x.value}`}
                                    onChange={handleSurveyTypeChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm" onClick={(e) => { importSurveysFromStage(true) }}>Preview Import surveys from stage</button>
                        &nbsp;
                        <button className="btn btn-success btn-sm" onClick={(e) => { importSurveysFromStage(false) }}>Import surveys from stage</button>
                        &nbsp;
                        <button className="btn btn-success btn-sm" onClick={(e) => { showImportModal() }}>Import new survey</button>
                    </div>
                </div>
                <table className="table">
                    <thead className="thead-dark">
                        <tr>
                            <th>Id</th>
                            <th>Created</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Version</th>
                            <th>Active</th>
                            <th>Export</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allSurveys
                                .filter(x => (!selectedSurveyType || x.surveyType === selectedSurveyType))
                                .map((s, index) => {
                                    return (
                                        <tr key={s.id} className={!s.isCompleted ? "text-danger" : ""} title={!s.isCompleted ? "There are not populated fields for En or Ru." : ""}>
                                            <td>{s.id}</td>
                                            <td>{getDisplayDate(s.createdAt)}</td>
                                            <td>{!s.isCompleted ? "⚠️ " : ""}{s.title}</td>
                                            <td>{surveyFilterData?.surveyTypes ? surveyFilterData.surveyTypes.filter(x => x.value === s.surveyType)[0]?.caption : ""}</td>
                                            <td>{s.version}</td>
                                            <td>
                                                <input type="checkbox" value={s.id} checked={s.isActive} disabled />
                                            </td>
                                            <td className="table-col-cmd">
                                                <a onClick={(e) => { showExportData(s.id) }} title="Show export data">Show export data</a>
                                            </td>
                                            <td className="table-col-cmd">
                                                <Link to={`/surveys/${s.id}`} title="Edit survey">Edit</Link>
                                                <a onClick={(e) => { surveyCopy(s.id) }} title="Duplicate survey">Copy</a>
                                                {s.isActive ? null : <a onClick={(e) => { deleteSurvey(s.id) }} title="Delete survey">Delete</a>}
                                            </td>
                                        </tr>
                                    )
                                })
                        }
                    </tbody>
                </table>
            </div>

            {
                isShowExportModal ?
                    <SurveyExportDataModal
                        jsonData={jsonExportData}
                        mode="export"
                        onClose={closeExportModal}
                        onImport={() => { }}
                    />
                    : null
            }

            {
                isShowImportModal ?
                    <SurveyExportDataModal
                        jsonData={jsonImportData}
                        mode="import"
                        onClose={closeImportModal}
                        onImport={importSurvey}
                    />
                    : null
            }

            {
                isShowImportStageResultModal ?
                    <SurveyImportStageResultModal
                        data={importStageResult}
                        onClose={closeImportStageModal}
                    />
                    : null
            }
        </div>
    );
}

export default SurveysView;