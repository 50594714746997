import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BaseCampClient } from "../../../api/BaseCampClient";
import { useAuth0 } from "../../../react-auth0-spa";
import UserProfileButtons, { ProfileType } from "./UserProfileButtons";
import { UserChartsAdminDataItem } from "../../../api/types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Line,
    Tooltip,
    TooltipProps,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Legend,
    ComposedChart,
} from 'recharts';
import moment from "moment";
import Loader from "../../Loader";

const formatLegend = (value?: any, entry?: any) => {
    if (!entry) {
        return null;
    }

    switch (entry['dataKey']) {
        case 'runningFitnessScore':
            return 'Running Fitness Score';
        case 'fitness':
            return 'Fitness';
        case 'fatigue':
            return 'Fatigue';
        case 'form':
            return 'Form';
        case 'progress':
            return 'Progress';
        default:
            throw Error(entry['dataKey']);
    }
}

const CustomTooltip = ({ active, payload }: TooltipProps) => {
    if (active && payload?.[0]?.payload) {
        const data = payload[0].payload as UserChartsAdminDataItem;
        return (
            <dl className="tooltip-container">
                <dt className='definition-term'>Date</dt>
                <dt className='definition-data'>{data.date.format('YYYY-MM-DD')}</dt>

                <dt className='definition-term'>RunningFitnessScore</dt>
                <dt className='definition-data'>{data.runningFitnessScore}</dt>

                <dt className='definition-term'>Fintess</dt>
                <dt className='definition-data'>{data.fitness}</dt>

                <dt className='definition-term'>Fatigue</dt>
                <dt className='definition-data'>{data.fatigue}</dt>

                <dt className='definition-term'>Form</dt>
                <dt className='definition-data'>{data.form}</dt>

                <dt className='definition-term'>Progress</dt>
                <dt className='definition-data'>{data.progress}</dt>

                <dt className='definition-term'>Status</dt>
                <dt className='definition-data'>
                    {data.progressZoneName ? `${data.progressZoneName}. ` : null}{data.progressDescription}
                </dt>
            </dl>
        );
    }
};

interface Props extends RouteComponentProps<any> { }

const UserProfileChartsView = (props: Props) => {
    const userId = props.match.params.userId;
    const { accessToken } = useAuth0();
    const baseCamp = new BaseCampClient(accessToken);

    const [disabled, setDisabled] = useState<string[]>([]);
    const [data, setData] = useState<UserChartsAdminDataItem[]>();
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        baseCamp.getUserChartsAdminData(userId, fromDate, toDate)
            .then((res) => {
                setData(res);
            })
            .finally(() => setIsLoading(false))
    }, [fromDate, toDate]);

    const handleLegendClick = (obj: any) => {
        const dataKey = obj && obj.dataKey;
        if (!disabled || !dataKey) return;
        if (disabled.indexOf(dataKey) >= 0) {
            setDisabled(disabled.filter(obj => obj !== dataKey));
        } else {
            setDisabled(disabled.concat(dataKey));
        }
    };

    const dateFormatter = (value: number | string | undefined) => {
        if (value == null) return '';
        return moment(value).format('YYYY-MM-DD');
    };

    return (
        <div className="users-profile users-profile-race-results">
            <div className="users-profile users-profile-messages">
                <div>
                    <h3 className="float-left">User ID: {userId}</h3>
                    <div className="float-right">
                        <UserProfileButtons
                            userId={userId}
                            type={ProfileType.Charts}
                        />
                    </div>
                    <div className="clear-both p-2" />
                </div>
            </div>

            <h4>Athlete's charts data</h4>
            <div className="users-profile-charts">
                <div className="tools-container">
                    <div className="tools-group">
                        <div>Date from: </div>
                        <div>
                            <DatePicker
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                isClearable={true}
                                selected={fromDate}
                                maxDate={toDate}
                                onChange={(val) => setFromDate(val ?? undefined)}
                            />
                        </div>
                    </div>
                    <div className="tools-group">
                        <div>Date to: </div>
                        <div>
                            <DatePicker
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                isClearable={true}
                                selected={toDate}
                                minDate={fromDate}
                                onChange={(val) => setToDate(val ?? undefined)}
                            />
                        </div>
                    </div>
                </div>
                {
                    isLoading ? <Loader /> :
                        data && data.length > 0 ?
                            <ResponsiveContainer width="100%" height={300}>
                                <ComposedChart data={data}>
                                    <Legend verticalAlign="top" formatter={formatLegend} onClick={e => handleLegendClick(e)} />
                                    <XAxis
                                        dataKey="dateEpoch"
                                        domain={['dataMin', 'dataMax']}
                                        tickFormatter={label => dateFormatter(label)}
                                    />
                                    <YAxis type="number" domain={['dataMin', 'dataMax']} />
                                    <Line connectNulls={true} hide={disabled.indexOf("runningFitnessScore") >= 0} type="monotoneX" dataKey="runningFitnessScore" stroke="#ff7300" />
                                    <Line connectNulls={true} hide={disabled.indexOf("fitness") >= 0} type="monotone" dataKey="fitness" stroke="#73ff00" />
                                    <Line connectNulls={true} hide={disabled.indexOf("fatigue") >= 0} type="monotone" dataKey="fatigue" stroke="#7300ff" />
                                    <Line connectNulls={true} hide={disabled.indexOf("form") >= 0} type="monotone" dataKey="form" stroke="#0073ff" />
                                    <Line connectNulls={true} hide={disabled.indexOf("progress") >= 0} type="monotone" dataKey="progress" stroke="#00ff73" />
                                    <Tooltip content={CustomTooltip} />
                                </ComposedChart>
                            </ResponsiveContainer>
                            : <div style={{ textAlign: 'center' }}>No data!</div>
                }
            </div>
        </div>
    );
}

export default UserProfileChartsView;