import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

type SurveyImportStageResultModalProps = {
    data: string[],
    onClose: () => void
}

const SurveyImportStageResultModal = (props: SurveyImportStageResultModalProps) => {

    const [data] = useState(props.data);

    const onClose = () => {
        props.onClose();
    }

    return (
            <div>
                <Modal show={true} onHide={onClose} className="modify-training-modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title-fullwidth">
                            <div className="modal-title-inner">
                                Import surveys from Stage result
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            data.map((x, i)=> {return <div style={{marginBottom: '15px'}} key={i}>{x}</div>})
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button className="btn btn-sm btn-primary" onClick={(e) => { onClose() }}>Close</button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
    );
}

export default SurveyImportStageResultModal;