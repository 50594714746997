import { PlanType, GradedWeekType } from '../shared/Primitives';

export const planTypes = [
    {
        type: PlanType.km5, 
        caption: '5k',
        grades: [ 
            GradedWeekType.Grade1,
            GradedWeekType.Grade1Rest,
            GradedWeekType.Grade2,
            GradedWeekType.Grade2Rest,
            GradedWeekType.GradeFit,
            GradedWeekType.GradeFitRest,
            GradedWeekType.Taper
        ]
    },
    { 
        type: PlanType.km10,
        caption: '10k',
        grades: [ 
            GradedWeekType.Grade1,
            GradedWeekType.Grade1Rest,
            GradedWeekType.Grade2,
            GradedWeekType.Grade2Rest,
            GradedWeekType.GradeFit,
            GradedWeekType.GradeFitRest,
            GradedWeekType.Taper
        ]
     },
    { 
        type: PlanType.HalfMarathon, 
        caption: 'Half-marathon',
        grades: [ 
            GradedWeekType.Grade1,
            GradedWeekType.Grade1Rest,
            GradedWeekType.Grade2,
            GradedWeekType.Grade2Rest,
            GradedWeekType.GradeFit,
            GradedWeekType.GradeFitRest,
            GradedWeekType.Grade3,
            GradedWeekType.Grade3Rest,
            GradedWeekType.Taper
        ]
    },
    { 
        type: PlanType.Marathon, 
        caption: 'Marathon',
        grades: [ 
            GradedWeekType.Grade1,
            GradedWeekType.Grade1Rest,
            GradedWeekType.Grade2,
            GradedWeekType.Grade2Rest,
            GradedWeekType.GradeFit,
            GradedWeekType.GradeFitRest,
            GradedWeekType.Grade3,
            GradedWeekType.Grade3Rest,
            GradedWeekType.Grade4,
            GradedWeekType.Grade4Rest,
            GradedWeekType.Grade5,
            GradedWeekType.Grade5Rest,
            GradedWeekType.Grade6,
            GradedWeekType.Grade6Rest,
            GradedWeekType.Taper
        ]
    },
];