import { TelegramUserChatMessageSender } from '../shared/Primitives';

export const telegramUserChatMessageSenders = [
    {
        value: TelegramUserChatMessageSender.Bot, 
        className: 'bot',
        name: 'Bot'
    },
    {
        value: TelegramUserChatMessageSender.Coach, 
        className: 'coach',
        name: 'Coach'
    },
    {
        value: TelegramUserChatMessageSender.User, 
        className: 'user',
        name: 'User'
    }
];