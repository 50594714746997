import React from 'react';

import { format } from 'date-fns';

import { SubscriptionPeriods, ExternalProductSource } from '../../../../shared/Primitives';

type UserProfileSubscriptionBlockProps = {
    plan: string | undefined,
    period: number | undefined,
    source: number | undefined,
    product: string | undefined,
    createdAt: Date | undefined,
    prolongedAt: Date | undefined,
    expireAt: Date | undefined,
    cancelledAt: Date | undefined,
    autoProlongation: boolean | undefined
};

const UserSubscriptionInfoBlock = (props: UserProfileSubscriptionBlockProps) => {
    return <div className="pb-1">
            <table className="py-1 table table-sm">
                <tbody>
                <tr><td>Plan:</td><td>{props.plan ?? "none"} {/*props.period ? ("(" + SubscriptionPeriods[props.period] + ")") : null*/}</td></tr>
                {props.source ? (
                    <tr><td>Product</td><td>{ExternalProductSource[props.source]} - {props.product}</td></tr>
                ) : null}
                {props.createdAt ? (
                    <tr><td>Created at:</td><td>{format(props.createdAt, 'yyyy-MM-dd HH:mm:ss')}</td></tr>
                ) : null}
                {props.prolongedAt ? (
                    <tr><td>Prolonged at:</td><td>{format(props.prolongedAt, 'yyyy-MM-dd HH:mm:ss')}</td></tr>
                ) : null}
                {props.expireAt ? (
                    <tr><td>Expire at:</td><td>{format(props.expireAt, 'yyyy-MM-dd HH:mm:ss')}</td></tr>
                    ) : null}
                {props.cancelledAt ? (
                    <tr><td>Cancelled at:</td><td>{format(props.cancelledAt, 'yyyy-MM-dd HH:mm:ss')}</td></tr> 
                ) : null}
                {props.autoProlongation ? (
                    <tr><td>Auto prolongation:</td><td>{props.autoProlongation ? "Yes" : "No"}</td></tr> 
                ) : null}
                </tbody>
            </table>
        </div>;
};

export default UserSubscriptionInfoBlock;