import React, { useState } from 'react';
import { ChatUser, rolesOptions, UpdateUserRequest } from '../../../api/types/chatDirectorV2Types';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useAuth0 } from "./../../../react-auth0-spa";
import { ChatDirectorClient } from '../../../api/ChatDirectorClient';

type AddNewUserModalProps = {
    show: boolean,
    onClose: () => void,
};

const AddNewUserModal = (props: AddNewUserModalProps) => {
    const { accessToken } = useAuth0();
    const chatDirectorClient = new ChatDirectorClient(accessToken);

    const [newUserId, setNewUserId] = useState<number | undefined>(undefined);
    const [newUser, setNewUser] = useState<ChatUser | undefined>(undefined);

    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

    const getBasecampUser = () => {
        setNewUser(undefined);
        if (newUserId && newUserId > 0) {
            chatDirectorClient.checkBasecampUser(newUserId)
                .then((resp) => {
                    setNewUser(resp);
                    if (!resp) alert("User not found.");
                });
        }
    }

    const roleSelected = (option: any) => {
        if (newUser && option?.value) {
            setNewUser({
                ...newUser,
                role: option.value
            });
        }
    }

    const saveUser = () => {
        if (!newUser) {
            alert("User is not selected.");
            return;
        }

        if (!(newUser.name.length > 0)) {
            alert("Name is not specified.");
            return;
        }

        if (!(newUser.email.length > 0)) {
            alert("Email is not specified.");
            return;
        }

        setIsSaveDisabled(true);
        const req: UpdateUserRequest = {
            avatarUrl: newUser.avatarUrl,
            email: newUser.email,
            name: newUser.name,
            role: newUser.role,
            userId: newUser.userId
        }
        chatDirectorClient.saveUser(req)
            .then(() => {
                alert("User was successfully saved.");
                props.onClose();
            })
            .catch((ex) => {
                console.log("saveUser error: ", ex);
                if (ex.response?.status == 400) {
                    alert("Error: " + ex.response.data);
                }
                else {
                    alert("There was an error. User wasn't saved.");
                }
            })
            .finally(() => setIsSaveDisabled(false));
    }

    return <Modal show={props.show} onHide={() => props.onClose()} onShow={() => { setNewUserId(undefined); setNewUser(undefined); }}>
        <Modal.Header closeButton>
            <Modal.Title>
                Add user
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form">
                <Form.Group as={Row}>
                    <Form.Label column sm="2">User Id</Form.Label>
                    <Col sm="4">
                        <Form.Control type="number" placeholder="Enter user id..." size="sm" value={newUserId || ""}
                            onChange={(e) => setNewUserId(parseInt(e.target.value))} />
                    </Col>
                    <Col sm="6">
                        <button className="btn btn-sm btn-primary" onClick={getBasecampUser}>Get basecamp user</button>
                    </Col>
                </Form.Group>
                {
                    newUser ? <>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">Name</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" placeholder="Name..." size="sm" value={newUser.name || ""} onChange={(e) => {
                                    setNewUser({
                                        ...newUser,
                                        name: e.target.value.trim()
                                    })
                                }} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="8">
                                <Form.Control type="email" placeholder="Email..." size="sm" value={newUser.email || ""} onChange={(e) => {
                                    setNewUser({
                                        ...newUser,
                                        email: e.target.value.trim()
                                    })
                                }} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2">Avatar URL</Form.Label>
                            <Col sm="8">
                                <Form.Control type="email" placeholder="Url..." size="sm" value={newUser.avatarUrl || ""} onChange={(e) => {
                                    setNewUser({
                                        ...newUser,
                                        avatarUrl: e.target.value.trim()
                                    })
                                }} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="2">Role</Form.Label>
                            <Col sm="8">
                                <Select
                                    placeholder="Role..."
                                    options={rolesOptions}
                                    value={rolesOptions.find(x => x.value === newUser!.role)}
                                    onChange={(option) => roleSelected(option)}
                                />
                            </Col>
                        </Form.Group>
                    </>
                        : null
                }
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-sm btn-success" disabled={isSaveDisabled} onClick={saveUser}>Save</button>
            <button className="btn btn-sm btn-primary" onClick={props.onClose}>Cancel</button>
        </Modal.Footer>
    </Modal>

}

export default AddNewUserModal;