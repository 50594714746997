import React from 'react';

import { Link } from "react-router-dom";

import { UserFullInfo } from '../../api/types';

interface Props {
    user: UserFullInfo;
}

const UserSummary = ({ user }: Props) => {
    const userGoal: string = formUserGoal(user);

    return <div>
        Level: {user.level || "no value"}, VDOT: {user.vdot || "no value"}, Long run: {user.longRunKm ? user.longRunKm + "km" : "no value" }, Goal: {userGoal} <Link to={`/user/${user.id}/profile`}>View profile</Link>
    </div>
}

const formUserGoal = (user: UserFullInfo) => {
    if (user.goalRace !== null) {
        const raceName = user.goalRace!.name ? user.goalRace!.name + " " : "";
        return raceName + user.goalRace!.distanceKm + " km at " + user.goalRace!.date.toString().slice(0, 10);
    }

    if (user.genericGoal !== null) {
        return "Get " + user.genericGoal;
    }

    return "No goal so far";
}

export default UserSummary;
