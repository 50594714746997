import React, { createRef, useEffect, useState, useLayoutEffect } from 'react';
import * as Icon from 'react-feather';
import classNames from 'classnames';
import moment from 'moment';

import { useAuth0 } from "./../../../react-auth0-spa";
import { ChatDirectorClient } from '../../../api/ChatDirectorClient';
import { ChatUser, MarkReadRequest } from '../../../api/types/chatDirectorV2Types';
import { getChatClientIcon, getNameAbbr } from '../../../api/Helpers';
import { ChannelType, ChatMessageV3, SendChatMessageRequestV3 } from '../../../api/types/chatDirectorV3Types';
import { ChatRole } from '../../../api/types/chatDirectorCoreTypes';

type ChatMessageListProps = {
    selectedUser: ChatUser,
    selectedUserUpdated: (user: ChatUser) => void
}

const ChatMessageList = (props: ChatMessageListProps) => {
    const { accessToken } = useAuth0();
    const chatDirectorClient = new ChatDirectorClient(accessToken);

    const pageSize = 10;
    const [offset, setOffset] = useState<number>(0)
    const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
    const [currentChannel, setCurrentChannel] = useState<ChannelType>(ChannelType.Main);
    const [messages, setMessages] = useState<ChatMessageV3[]>([]);
    const [messageText, setMessageText] = useState<string>("");
    const [isSending, setIsSending] = useState<boolean>(false);

    let messagesEndDivRef = createRef<HTMLDivElement>();
    let messagesTextAreaRef = createRef<HTMLTextAreaElement>();

    const [needScrollDown, setNeedScrollDown] = useState<boolean>(true);

    useEffect(() => {
        loadMessages();
    }, [props.selectedUser, currentChannel, offset]);

    const loadMessages = () => {
        if (!props.selectedUser) {
            return;
        }

        chatDirectorClient.getMessages3(
            props.selectedUser.userId,
            currentChannel,
            offset,
            pageSize
        )
        .then((response) => {
            if (response.length < pageSize) {
                setCanLoadMore(false);
            }

            if (offset > 0) {
                setMessages(pre => [...pre, ...response]);
            }
            else {
                setMessages(response);
            }
        })
        .catch((ex) => {
            console.log("loadMessages error: ", ex);
            if (ex.response?.status == 400) {
                alert("Error: " + ex.response.data);
            }
            else {
                alert("Sorry, there was an error.");
            }
        });
    }

    useLayoutEffect(() => {
        if (messagesTextAreaRef.current) {
            messagesTextAreaRef.current.focus();
        }
    }, [messageText]);

    useLayoutEffect(() => {
        if (needScrollDown) {
            messagesEndDivRef?.current?.scrollIntoView(true);
            setNeedScrollDown(false);
        }
        markRead(messages);
    }, [messages]);

    const changeChannel = (channel: ChannelType) => () => {
        setCurrentChannel(channel);
        setOffset(0);
        setCanLoadMore(true);
        setNeedScrollDown(true);
    }

    const markRead = (msgList: ChatMessageV3[]) => {
        const ids = msgList.filter(x => !x.isRead && !x.isYours).map(x => x.id);
        console.log("MarkRead ids:", ids)
        if (ids.length > 0) {
            const req: MarkReadRequest = {
                messageIds: ids,
                isRead: true
            };
            chatDirectorClient.markMessagesRead(req)
                .then((resp) => {
                    updateCurrentMessagesRead(msgList, ids);
                });
        }
        else {
            updateCurrentMessagesRead(msgList, ids);
        }
    }

    const updateCurrentMessagesRead = (msgList: ChatMessageV3[], ids: number[]) => {
        const targetMessages = msgList.filter(x => ids.includes(x.id));
        targetMessages.forEach(m => {
            let index = msgList.findIndex(x => x.id == m.id);
            if (index > 0) {
                const newMessage = msgList[index];
                newMessage.isRead = true;
                msgList[index] = newMessage;
            }
        });

        setMessages(msgList);

        if (targetMessages.length > 0) {
            const newUser: ChatUser = {
                ...props.selectedUser,
                unreadUsersMessageCount: 0
            } as ChatUser;
            props.selectedUserUpdated(newUser);
        }
    }

    const sendMessage = () => {
        setNeedScrollDown(true);
        const newMessageText = messageText.trim();
        if (newMessageText.length === 0) {
            return;
        }

        setIsSending(true);
        let req: SendChatMessageRequestV3 = {
            channelType: currentChannel,
            body: newMessageText,
            userId: props.selectedUser!.userId
        };
        chatDirectorClient.sendMessageV3(req)
            .then((resp) => {
                var newMessages = [...messages];
                newMessages.splice(0, 0, resp);
                setMessages(newMessages);
                setMessageText("");
            })
            .catch((ex) => {
                console.log("loadMessages error: ", ex);
                if (ex.response?.status == 400) {
                    alert("Error: " + ex.response.data);
                }
                else {
                    alert("Sorry, there was an error.");
                }
            })
            .finally(() => setIsSending(false));
    }

    const getRoleColorsCss = (role: ChatRole): string => {
        switch (role) {
            case ChatRole.User:
                return "user-colors";
            case ChatRole.Coach:
                return "coach-colors";
            case ChatRole.Admin:
                return "admin-colors";
            default:
                return "";
        }
    }

    const getTabClasses = (channel: ChannelType) => {
        return classNames({
            "nav-link": true,
            "active": currentChannel === channel
        });
    }

    if (!props.selectedUser) {
        return <div className="no-conversation"><h3>No user selected.</h3></div>;
    }

    return <>
        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={getTabClasses(ChannelType.Main)} onClick={changeChannel(ChannelType.Main)}>Main</a>
            </li>
            <li className="nav-item">
                <a className={getTabClasses(ChannelType.Assistant)} onClick={changeChannel(ChannelType.Assistant)}>Assistant</a>
            </li>
        </ul>
        {
            <div id="messages-list" className="messages-list">
                <div id="lastDiv" ref={messagesEndDivRef}></div>
                {
                    messages.map((m) => {
                        let messageItemCss = classNames({
                            "message-item": true,
                            "is-yours": m.isYours,
                        });

                        let contentWrapperCss = classNames({
                            "content-wrapper": true,
                            "reverse": m.isYours,
                        });

                        return (
                            <div className={messageItemCss} key={m.id}>
                                <div className={contentWrapperCss}>
                                    <div className="avatar">
                                        {
                                            m.senderAvatarUrl ?
                                                <img src={m.senderAvatarUrl} />
                                                : <div className={`text-avatar ${getRoleColorsCss(m.senderRole)}`}>{getNameAbbr(m.senderName)}</div>
                                        }
                                    </div>
                                    <div className="message-content">
                                        <div className={`message-body ${getRoleColorsCss(m.senderRole)}`}>
                                            {m.body}
                                        </div>
                                        <div className="message-info">
                                            {
                                                m.chatClient ?
                                                <span className="chat-client-info" title={`${m.chatClient} client `}>{getChatClientIcon(m.chatClient)}</span>
                                                : null
                                            }
                                            {moment(m.createdAt).format("DD.MM.YYYY HH:mm")}
                                            {
                                                m.isYours ?
                                                    <span style={{ marginLeft: "10px" }}>{m.isSeen ? "Seen" : "Not seen"}</span>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                {canLoadMore ?
                     <div className="d-flex justify-content-center p-3">
                        <button 
                            type="button" 
                            className="btn btn-secondary btn-sm" 
                            onClick={() => setOffset(offset + pageSize)}
                        >
                            Load more...
                        </button>
                    </div>
                : null}
            </div>
        }

        {
            <div className="send-message-container">
                <div className="send-message-inner">
                    <div className="message-hint d-block d-md-none">chat with {props.selectedUser.name ?? props.selectedUser.email} - <a target="_blank" href={`/user/${props.selectedUser.userId}/profile`} >profile</a></div>
                    <div className="message-hint d-block d-md-none">
                        <span style={{ "color": "#fff" }} className={`badge ${props.selectedUser.hasFreePlan ? "bg-danger" : "bg-success"}`}>
                            {props.selectedUser.subscriptionPlanTitle ?? ""}
                        </span>
                    </div>
                    <div><textarea rows={6} value={messageText} onChange={(e) => setMessageText(e.target.value)} ref={messagesTextAreaRef}></textarea></div>
                    <div className="btn-send-panel">
                        {
                            isSending ?
                                <div className="spinner-border loader" role="status">
                                    <span className="sr-only">Sending...</span>
                                </div>
                                : null
                        }
                        <button className="btn btn-sm btn-success" disabled={messageText.trim().length === 0 || isSending} onClick={sendMessage}><Icon.Send /> Send</button>
                    </div>
                </div>
            </div>
        }
    </>
}

export default ChatMessageList;