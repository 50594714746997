import React, { useState, useEffect } from 'react';

import { useAuth0 } from "./../../react-auth0-spa";

import { BaseCampClient } from '../../api/BaseCampClient';
import { StatisticsResponse } from '../../api/types';

import './DashBoardView.scss';
import DashboardRow from './dashboard-components/DashboardRow';

const DashBoardView = () => {
    const { accessToken } = useAuth0();

    const [stats, setStats] = useState<StatisticsResponse>();

    const handleSaveAsCsv = () => {
        const basecamp = new BaseCampClient(accessToken);
        basecamp.getUsersStatsCsv();
    }

    useEffect(() => {
        const basecamp = new BaseCampClient(accessToken);
        basecamp.getSystemStats().then(res => setStats(res));
    }, [accessToken]); // second argument prevent to run on EVERY render.

    return <div className="bot-stats">
        <button type="button" className="btn btn-sm btn-info download" onClick={handleSaveAsCsv}>
            Export new registrations by days
        </button>
        <h2>System stats (last month)</h2>
        {
            stats ?
            <h6>
                Total registrations: {stats?.registeredUsersCount} <span className="d-inline-block p-2"/>
                Total active: {stats?.activeUsersCount}
            </h6> : ""
        }
        <table className="table table-dashboard">
            <thead className="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" colSpan={3}>New users</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" colSpan={3}>New plan</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" colSpan={4}>Payment</th>
                </tr>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Date</th>
                    <th scope="col">Bot</th>
                    <th scope="col">Web</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Bot user messages</th>
                    <th scope="col">Run command</th>
                    <th scope="col">Survey command</th>
                    <th scope="col">Bot</th>
                    <th scope="col">Web</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Week feedback</th>
                    <th scope="col">Strava</th>
                    <th scope="col" title="1 month">1m</th>
                    <th scope="col" title="3 months">3m</th>
                    <th scope="col" title="6 months">6m</th>
                    <th scope="col" title="1 year">1y</th>
                </tr>
            </thead>
            <tbody>
                {
                    stats?.statistics.map((stat, index) => {
                        const d = stat.date;
                        return (
                            <DashboardRow 
                                key={d.toString()}
                                dayStats={stat}
                                rowIndex={index}
                            />
                        );
                    }

                    )
                }
            </tbody>
        </table>
    </div>;
}

export default DashBoardView;
