import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useParams } from "react-router";
import DataTable from "../../common/DataTable";
import { BaseCampClient } from "../../../api/BaseCampClient";
import { useAuth0 } from "../../../react-auth0-spa";
import UserProfileButtons, { ProfileType } from "./UserProfileButtons";
import { UserPayment } from "../../../api/types";

interface Props extends RouteComponentProps<any> {}

const columns = [
  { fieldName: 'paymentId', title: 'PaymentID', allowSort: true, },
  { fieldName: 'createdAt', title: 'CreatedAt', allowSort: true, },
  { fieldName: 'email', title: 'Email', allowSort: true, },
  { fieldName: 'firstName', title: 'FirstName', allowSort: true, },
  { fieldName: 'lastName', title: 'LastName', allowSort: true, },
  { fieldName: 'productId', title: 'ProductId', allowSort: true, },
  { fieldName: 'store', title: 'Store', allowSort: true, },
  { fieldName: 'subscriptionPeriod', title: 'SubscriptionPeriod', allowSort: true, },
  { fieldName: 'transactionType', title: 'TransactionType', allowSort: true, },
  { fieldName: 'priceEstimated', title: 'PriceEstimated', allowSort: true, },
  { fieldName: 'price', title: 'Price', allowSort: true, },
  { fieldName: 'externalId', title: 'ExternalId', allowSort: true, },
  { fieldName: 'parentExternalTransactionId', title: 'ParentExternalTransactionId', allowSort: true, },
  { fieldName: 'promoCodeOriginal', title: 'PromoCodeOriginal', allowSort: true, },
  { fieldName: 'promoOfferId', title: 'PromoOfferId', allowSort: true, },
  { fieldName: 'promoOfferCodeId', title: 'PromoOfferCodeId', allowSort: true, },
];

const UsersProfilePaymentsView = (props: Props) => {
  const userId = props.match.params.userId;
  const { accessToken } = useAuth0();
  const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

  const [actions, setActions] = useState<UserPayment[]>([])
  const [totalActions, setTotal] = useState(0)
  const [page, setPage] = useState(0);
  const params: { userId: string } = useParams();

  const handlePageChange = (value:any) => {
    setPage(value)
  }
  
  useEffect(() => {
    baseCamp.getUserPayments(Number(params.userId)).then((res)=> {
      setActions(res.items)
      setTotal(res.totalCount)
    })
  }, [page]);

  return (
    <div className="users-profile users-profile-race-results">
      <div className="users-profile users-profile-messages">
        <div>
          <h3 className="float-left">User ID: {userId}</h3>
          <div className="float-right">
            <UserProfileButtons
              userId={userId}
              type={ProfileType.ParametersHistory}
            />
          </div>
          <div className="clear-both p-2" />
        </div>
      </div>

      <h4>User's Payments</h4>
      <DataTable
        data={actions}
        columns={columns}
        emptyDataMessage="No payments found for this user."
        showCmdButtons={false}
        usePagination={true}
        pageNumber={page}
        perPage={30}
        totalCountPagination={totalActions}
        onPageNumberChanged={handlePageChange}
      />
    </div>
  );
};

export default UsersProfilePaymentsView;
