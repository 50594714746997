import { SurveyType } from '../shared/Primitives';

export const surveyTypeValues = [
    {
        value: SurveyType.MobileEntry,
        text: "Mobile entry",
        bgColor: "#3c3",
        font: "#fff"
    },
    {
        value: SurveyType.MobileNewRunningGoal,
        text: "Mobile new plan",
        bgColor: "#94C2ED",
        font: "#fff"
    },
    {
        value: SurveyType.MobileWeekFeedback,
        text: "Mobile week feedback",
        bgColor: "#E38968",
        font: "#fff"
    },
    {
        value: SurveyType.MobileRaceResult,
        text: "Mobile race result",
        bgColor: "#7ce368",
        font: "#fff"
    },
    {
        value: SurveyType.MobileNewCyclingGoal,
        text: "Mobile new cycling goal",
        bgColor: "#94C2ED",
        font: "#fff"
    },
    {
        value: SurveyType.MobileRunningEntry,
        text: "Mobile running entry",
        bgColor: "#3c3",
        font: "#fff"
    },
    {
        value: SurveyType.MobileCyclingEntry,
        text: "Mobile cycling entry",
        bgColor: "#3c3",
        font: "#fff"
    },


    {
        value: SurveyType.WebEntry,
        text: "Web entry",
        bgColor: "#7ce368",
        font: "#fff"
    },
    {
        value: SurveyType.WebNewRunningGoal,
        text: "Web new running goal",
        bgColor: "#94C2ED",
        font: "#fff"
    },
    {
        value: SurveyType.WebNewCyclingGoal,
        text: "Web new cycling goal",
        bgColor: "#94C2ED",
        font: "#fff"
    },
    {
        value: SurveyType.WebRunningEntry,
        text: "Web running entry",
        bgColor: "#7ce368",
        font: "#fff"
    },
    {
        value: SurveyType.WebCyclingEntry,
        text: "Web cycling entry",
        bgColor: "#7ce368",
        font: "#fff"
    },
    {
        value: SurveyType.WebWeekFeedback,
        text: "Web week feedback",
        bgColor: "#E38968",
        font: "#fff"
    },


    {
        value: SurveyType.RunTelegramEntry,
        text: "Telegram Run entry",
        bgColor: "#718de1",
        font: "#fff"
    },
    {
        value: SurveyType.BikeTelegramEntry,
        text: "Telegram Bike entry",
        bgColor: "#718de1",
        font: "#fff"
    },
    {
        value: SurveyType.RunFunTelegramEntry,
        text: "Telegram Run Fun entry",
        bgColor: "#718de1",
        font: "#fff"
    },
    {
        value: SurveyType.RunTilda,
        text: "Tilda Run",
        bgColor: "#fffbd6",
        font: "#000"
    }
];