import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { useAuth0 } from "../../react-auth0-spa";
import { Survey, SurveyFilterData, SurveyLanguageFilterData, SurveyTypeFilterData, SurveyDefinition } from '../../api/types';
import { BaseCampClient } from '../../api/BaseCampClient';
import './SurveyView.scss';
import DefinitionBlock from './survey-components/DefinitionBlock';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isDefinitionConstraintsEmpty, isDefinitionOptionsEmpty } from '../../api/Helpers';
import * as Icon from 'react-feather';
import Loader from '../Loader';
import { useHistory } from "react-router-dom";
import { Language } from '../../shared/Primitives';

interface Props extends RouteComponentProps<any> { }

const SurveyDetailView = (props: Props) => {
    const { accessToken } = useAuth0();
    const basecamp = new BaseCampClient(accessToken);

    const [survey, setSurvey] = useState<Survey>({} as Survey);
    const [surveyFilterData, setSurveyFilterData] = useState<SurveyFilterData | undefined>(undefined);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [nextNewDefinitionId, setNextNewDefinitionId] = useState<number>(0);
    const [surveyErrors, setSurveyErrors] = useState<string[]>([]);
    const [editingDefinitions, setEditingDefinitions] = useState<number[]>([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [obligatoryQuestionIds, setObligatoryQuestionIds] = useState<string[]>([]);

    const [selectedLanguage, setSelectedLanguage] = useState<Language>(Language.En);

    const history = useHistory();

    const surveyId = props.match.params.surveyId;

    useEffect(() => {
        basecamp.getSurveyFilterData()
            .then(res => {
                setSurveyFilterData(res);
                if (surveyId > 0) {
                    setLoading(true);
                    basecamp.getSurvey(surveyId)
                        .then(res => {
                            setSurvey(res);
                            setIsDataLoaded(true);
                            updateObligatoryQuestionIds(res.surveyType);
                        })
                        .catch((ex) => {
                            console.log(ex);
                            alert("Unable to get survey data.");
                        })

                }
                else {
                    setIsDataLoaded(true);
                }
            })
            .catch((ex) => {
                console.log(ex);
                alert("Unable to get filter data.");
            })
            .finally(() => setLoading(false));

    }, []);

    const updateObligatoryQuestionIds = (surveyType: number) => {
        basecamp.getSurveyObligatoryQuestionIds(surveyType)
            .then(res => {
                setObligatoryQuestionIds(res);
            })
            .catch((ex) => {
                console.log(ex);
                alert("Unable to get survey obligatory question ids.");
            })
    }

    const logDefinitionEdit = (definitionId: number, isEdit: boolean) => {
        if (isEdit && !editingDefinitions.includes(definitionId)) {
            editingDefinitions.push(definitionId);
            setEditingDefinitions(editingDefinitions);
        }
        else if (!isEdit && editingDefinitions.includes(definitionId)) {
            const i = editingDefinitions.findIndex(x => x === definitionId);
            if (i >= 0 && i < editingDefinitions.length) {
                editingDefinitions.splice(i, 1);
                setEditingDefinitions(editingDefinitions);
            }
        }
        setIsSaveDisabled(editingDefinitions.length > 0);
    }

    const getQuestionsIds = (): string[] => {
        const ids = survey.definitions?.map((d, index) => {
            return d.questionId;
        })?.sort((a, b) => (a > b ? 1 : -1));
        return ids || [];
    }

    const addDefinition = () => {
        if (!survey.definitions) {
            survey.definitions = [];
        }

        const id = nextNewDefinitionId - 1;
        setNextNewDefinitionId(id);

        survey.definitions!.push({ id: id } as SurveyDefinition);
        setSurvey({ ...survey, definitions: survey.definitions });
    }

    const onKeepDefinition = (definition: SurveyDefinition, index: number) => {
        if (!survey.definitions) {
            survey.definitions = [];
        }

        const currentDefinition = survey.definitions![index];
        currentDefinition.nextQuestionId = definition.nextQuestionId;
        currentDefinition.questionId = definition.questionId;
        currentDefinition.questionTextEn = definition.questionTextEn;
        currentDefinition.questionTextRu = definition.questionTextRu;
        currentDefinition.questionTextDe = definition.questionTextDe;
        currentDefinition.questionType = definition.questionType;
        currentDefinition.constraints = definition.constraints;
        currentDefinition.options = definition.options;
        currentDefinition.questionHintEn = definition.questionHintEn;
        currentDefinition.questionHintRu = definition.questionHintRu;
        currentDefinition.questionHintDe = definition.questionHintDe;
        currentDefinition.isQuestionOptional = definition.isQuestionOptional;
        currentDefinition.questionIcon = definition.questionIcon;

        setSurvey({ ...survey, definitions: survey.definitions });
    }

    const onDeleteDefinition = (index: number) => {
        if (survey.definitions && index < survey.definitions.length) {
            survey.definitions.splice(index, 1)
            setSurvey({ ...survey, definitions: survey.definitions });
        }
    }

    const onMoveDefinition = (index: number, step: -1 | 1) => {
        const swapIndex = index + step;
        if (survey.definitions &&
            survey.definitions.length > 0 &&
            index >= 0 && index < survey.definitions.length &&
            swapIndex >= 0 && swapIndex < survey.definitions.length) {

            [survey.definitions[index], survey.definitions[swapIndex]] = [survey.definitions[swapIndex], survey.definitions[index]];
            setSurvey({ ...survey, definitions: survey.definitions });
        }
    }

    const saveSurvey = () => {
        if (validate(survey)) {
            setLoading(true);
            if (surveyId > 0 && !survey.id) {
                survey.id = surveyId;
            }
            basecamp.saveSurvey(survey)
                .then(res => {
                    alert("Survey was saved successfuly");
                    if (surveyId <= 0 && res.id > 0) {
                        history.push(`/surveys/${res.id}`);
                    }
                })
                .catch(ex => {
                    console.log("Save survey error: ", ex);
                    alert("There was an error during survey saving. " + ex.response.data);
                })
                .finally(() => setLoading(false));
        }
    }

    const validate = (survey: Survey): boolean => {
        const errs = [];
        if (!survey.surveyType) {
            errs.push("SurveyType is required.");
        }
        // if (!survey.version) {
        //     errs.push("Version is required.");
        // }
        if (!survey.definitions?.length) {
            errs.push("Survey must contain at least one definition.");
        }
        else {
            if (obligatoryQuestionIds.length > 0) {
                const currentIds = survey.definitions.map(x => x.questionId);
                const isOk = obligatoryQuestionIds.every(x => currentIds.includes(x));
                if (!isOk) {
                    errs.push("Survey doesn't contain obligatory question Ids: " + obligatoryQuestionIds);
                }
            }

            for (let i = 0; i < survey.definitions!.length; i++) {
                if (!survey.definitions[i].questionId) {
                    errs.push("Question " + (i + 1) + ": QuestionId is required.");
                }
                if (selectedLanguage === Language.En && !survey.definitions[i].questionTextEn) {
                    errs.push("Question " + (i + 1) + ": English QuestionText is required.");
                }
                if (selectedLanguage === Language.Ru && !survey.definitions[i].questionTextRu) {
                    errs.push("Question " + (i + 1) + ": Russian QuestionText is required.");
                }
                if (selectedLanguage === Language.De && !survey.definitions[i].questionTextDe) {
                    errs.push("Question " + (i + 1) + ": German QuestionText is required.");
                }
                if (!survey.definitions[i].questionType) {
                    errs.push("Question " + (i + 1) + ": QuestionType is required.");
                }
                if (!isDefinitionConstraintsEmpty(survey.definitions[i].constraints)) {
                    const digits = survey.definitions[i].constraints?.digits;
                    if (!!digits && digits <= 0) {
                        errs.push("Question " + (i + 1) + ": Constraint digits must be greater than 0.");
                    }
                }
                else {
                    survey.definitions[i].constraints = undefined;
                }
                if (!isDefinitionOptionsEmpty(survey.definitions[i].options)) {
                    for (let j = 0; j < survey.definitions[i].options!.length; j++) {
                        if ((selectedLanguage === Language.En && !survey.definitions[i].options![j].captionEn) ||
                        (selectedLanguage === Language.Ru && !survey.definitions[i].options![j].captionRu) ||
                        (selectedLanguage === Language.De && !survey.definitions[i].options![j].captionDe)) {
                            errs.push("Question " + (i + 1) + ": Option " + (j + 1) + " Caption is required.");
                        }
                        if (!survey.definitions[i].options![j].value) {
                            errs.push("Question " + (i + 1) + ": Option " + (j + 1) + " Value is required.");
                        }
                        if (survey.definitions[i].options![j].nextQuestionId &&
                            !getQuestionsIds().includes(survey.definitions[i].options![j].nextQuestionId!)) {
                            errs.push("Question " + (i + 1) + ": Option " + (j + 1) + " NextQuestionId is wrong.");
                        }
                    }
                }
                else {
                    survey.definitions[i].options = undefined;
                }
            }
        }

        setSurveyErrors(errs);

        return errs.length === 0;
    }

    return (
        <div className="surveys">
            {
                loading ? <Loader /> : ""
            }
            <h2>{surveyId > 0 ? `Edit Survey: ${surveyId}` : "New Survey"}</h2>
            {
                isDataLoaded ?
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-2">
                                <label htmlFor="inputTitle">Title</label>
                                <input id="inputTitle" type="text" className="form-control" value={survey.title || ""}
                                    onChange={(event) => setSurvey({ ...survey, title: event.target.value })} />
                            </div>

                            <div className="col-md-2">
                                <label htmlFor="selectSurveyType">Type:</label>
                                <Select id="selectSurveyType"
                                    options={surveyFilterData?.surveyTypes}
                                    value={survey ? surveyFilterData?.surveyTypes.filter(x => x.value === survey.surveyType)[0] : undefined}
                                    getOptionLabel={x => x.caption}
                                    getOptionValue={x => `${x.value}`}
                                    onChange={(value) => {
                                        setSurvey({ ...survey, surveyType: (value as SurveyTypeFilterData).value });
                                        updateObligatoryQuestionIds((value as SurveyTypeFilterData).value);
                                    }}
                                />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="selectLanguage">Language:</label>
                                <Select id="selectLanguage"
                                    options={surveyFilterData?.languages}
                                    value={surveyFilterData?.languages.filter(x => x.value === selectedLanguage)[0] ?? surveyFilterData?.languages[0]}
                                    getOptionLabel={x => x.caption}
                                    getOptionValue={x => `${x.value}`}
                                    onChange={value => setSelectedLanguage((value as SurveyLanguageFilterData).value)}
                                />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="inputVersion">Version</label>
                                <input disabled id="inputVersion" type="number" className="form-control" value={survey.version || ""} min={1}
                                    onChange={(event) => setSurvey({ ...survey, version: parseInt(event.target.value) })} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="inputIsActive">Active: </label>
                                <div className="active-checkbox-wrapper">
                                    <input id="inputIsActive" type="checkbox" checked={survey.isActive || false}
                                        onChange={(event) => setSurvey({ ...survey, isActive: event.target.checked })} />
                                </div>
                            </div>
                        </div>
                        <h2>Questions</h2>
                        <div>
                            {
                                survey.definitions ?
                                    survey.definitions.map((d, index) => {
                                        return (
                                            <DefinitionBlock
                                                key={d.id}
                                                definition={d}
                                                index={index}
                                                selectedLanguage={selectedLanguage}
                                                questionTypes={surveyFilterData?.questionTypes ?? []}
                                                questionIds={getQuestionsIds()}
                                                definitionsCount={survey.definitions?.length ?? 0}
                                                onKeepDefinition={onKeepDefinition}
                                                onDeleteDefinition={onDeleteDefinition}
                                                onMoveDefinition={onMoveDefinition}
                                                onEditModeChange={logDefinitionEdit}
                                            />
                                        )
                                    })
                                    : <div>There is no any definition yet.</div>
                            }
                        </div>
                        <div className="btn-panel split-col">
                            <div>
                                <Button onClick={addDefinition}><Icon.Plus /> Add Question</Button>
                            </div>
                            <div>
                                <Button variant="success" onClick={(e) => { saveSurvey() }} disabled={isSaveDisabled}><Icon.Save /> Save Survey</Button>
                                &nbsp;
                                <Link className="btn btn-dark" to={`/surveys`}><Icon.ArrowLeft /> Back to Survey List</Link>
                                {
                                    isSaveDisabled ? <div>Please finish edit definition(s) to Save survey</div> : null
                                }
                                {
                                    surveyErrors?.length > 0 ?
                                        <div className="error-summary">
                                            <ul>
                                                {
                                                    surveyErrors.map((err, index) => {
                                                        return (
                                                            <li key={index}>{err}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

export default SurveyDetailView;