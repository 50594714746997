import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import UserProfileButtons, { ProfileType } from './UserProfileButtons';
import Loader from '../../Loader';
import { parseISO, format } from 'date-fns';
import { sportTypeValues } from "../../../fixtures/sport-types";
import { CoachingType, SportType } from '../../../shared/Primitives';
import { CoachingCardResponse } from '../../../api/types';

interface Props extends RouteComponentProps<any> { }

const UserProfileCoachingCardsView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);
    const userId = props.match.params.userId;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [coachingCards, setCoachingCards] = useState<CoachingCardResponse[]>([]);

    useEffect(() => {
        loadCoachingCards();
    }, []);

    const loadCoachingCards = () => {
        setIsLoading(true);
        baseCamp.GetUserCoachingCards(userId)
            .then(res => {
                setCoachingCards(res);
            })
            .catch(e => {
                console.log(e.response.data);
                console.error(e);
            })
            .finally(() => setIsLoading(false));
    }

    const deleteCoachingCard = (id: number) => {
        if (window.confirm("Are you sure you want to delete coaching card " + id)) {
            setIsLoading(true);
            baseCamp.DeleteUserCoachingCard(userId, id)
                .then(() => {
                    loadCoachingCards();
                })
                .catch(e => {
                    console.log(e.response.data);
                    console.error(e);
                })
                .finally(() => setIsLoading(false));
        }
    }

    return (
        <React.Fragment>
            {
                isLoading
                    ? <Loader />
                    :

                    <div className="users-profile users-profile-new-plan">
                        <div>
                            <h3 className="float-left">User ID: {userId}</h3>
                            <div className="float-right">
                                <UserProfileButtons userId={userId} type={ProfileType.CoachingCards} />
                            </div>
                            <div className="clear-both p-2" />
                        </div>
                        <h4>Manage Coaching Cards</h4>
                        {
                            coachingCards.length > 0
                                ?
                                <table className="table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th style={{ width: "30px" }}>Id</th>
                                            <th scope="col">Sport Type</th>
                                            <th scope="col">Coaching Type</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            coachingCards.map((c, index) => {
                                                return (
                                                    <tr key={c.id}>
                                                        <td>{c.id}</td>
                                                        <td>{c.sportType}</td>
                                                        <td>{c.coachingType}</td>
                                                        <td><button className="btn btn-sm btn-primary" onClick={(e) => { deleteCoachingCard(c.id); }}>Delete</button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                : <div>Coaching card list for user is empty</div>
                        }
                    </div>
            }
        </React.Fragment>
    );
}

export default UserProfileCoachingCardsView;