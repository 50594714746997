import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, { DataTableColumn } from '../../common/DataTable';

import { useAuth0 } from '../../../react-auth0-spa';
import { BaseCampClient } from '../../../api/BaseCampClient';
import { RaceResult, Race } from '../../../api/types';

import UserProfileButtons, { ProfileType } from './UserProfileButtons';

import './UsersProfileView.scss';
import Loader from "../../Loader";
import { DateFormat, RaceType } from '../../../shared/Primitives';

interface Props extends RouteComponentProps<any> { }

const UsersProfileRaceView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const [isLoading, setLoading] = useState(true);
    const [raceResults, setRaceResults] = useState([] as RaceResult[]);
    const [races, setRaces] = useState([] as Race[]);

    const userId = props.match.params.userId;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);

        Promise.all([baseCamp.getUserRaces(userId), baseCamp.getUserRaceResult(userId)])
            .then(([races, raceResults]) => {
                setRaces(races);
                setRaceResults(raceResults);
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false));
    }

    const deleteRace = (race: Race) => {
        if (race.type === RaceType.Goal) {
            return alert("Goal race can't be deleted");
        }
        if (!window.confirm(`Are you sure you want to delete race item #${race.raceId}`)) {
            return;
        }

        setLoading(true);
        baseCamp.deleteRace(race.raceId, userId)
            .then(() => {
                setRaces(races.filter(r => r.raceId !== race.raceId));
                setRaceResults(raceResults.map(r => r.raceId !== race.raceId ? r : {
                    ...r,
                    raceId: undefined
                }));
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false));
    }

    const raceColumns: DataTableColumn[] = [
        {
            title: 'Id',
            fieldName: 'raceId'
        },
        {
            title: 'Created date',
            fieldName: 'createdAt',
            dateFormat: DateFormat.DateTime
        },
        {
            title: 'Name',
            fieldName: 'name'
        },
        {
            title: 'Type',
            fieldName: 'type'
        },
        {
            title: 'Place',
            fieldName: 'place'
        },
        {
            title: 'Date',
            fieldName: 'date',
            dateFormat: DateFormat.Date
        },
        {
            title: 'Distance (km)',
            fieldName: 'distanceKm'
        },
        {
            title: 'Discipline',
            fieldName: 'raceDiscipline'
        },
        {
            title: 'Result distance (m)',
            fieldName: 'resultDistanceMeters'
        },
        {
            title: 'Result time',
            fieldName: 'resultTime'
        },
        {
            title: 'Comments',
            fieldName: 'comments'
        },];

    const raceResultColumns: DataTableColumn[] = [
        {
            title: 'Created Date',
            fieldName: 'createdAt',
            dateFormat: DateFormat.DateTime
        },
        {
            title: 'Distance',
            fieldName: 'distanceMeters'
        },
        {
            title: 'Time',
            fieldName: 'time'
        },
        {
            title: 'vdot',
            fieldName: 'vdot'
        },
        {
            title: 'Race ID',
            fieldName: 'raceId'
        }
    ];

    return (
        <div className="users-profile users-profile-race-results">
            <div className="users-profile users-profile-messages">
                <div>
                    <h3 className="float-left">User ID: {userId}</h3>
                    <div className="float-right">
                        <UserProfileButtons userId={userId} type={ProfileType.Races} />
                    </div>
                    <div className="clear-both p-2" />
                </div>
            </div>

            {
                isLoading ? <Loader /> : <>
                    <h4>Races</h4>
                    <div>
                        <DataTable
                            data={races}
                            columns={raceColumns}
                            emptyDataMessage="There is no any race."
                            showCmdButtons={true}
                            usePagination={false}
                            pageNumber={1}
                            perPage={100}
                            totalCountPagination={undefined}
                            onDeleteClick={deleteRace}
                        />
                    </div>
                    <h4>Race results</h4>
                    <div>
                        <DataTable
                            data={raceResults}
                            columns={raceResultColumns}
                            emptyDataMessage="There is no any race result."
                        />
                    </div>
                </>
            }
        </div>
    )
}

export default UsersProfileRaceView;