import React from 'react';

import './App.css';
import Loader from "./Loader";

import NavPanel from "./navigation/NavPanel";
import Routes from "./navigation/Routes";
import { BrowserRouter } from "react-router-dom";
import { useAuth0 } from "./../react-auth0-spa";
import { Client } from '@bugsnag/browser/dist/types/bugsnag-core'

interface IProps {
    bugsnag: Client
}

const App = ({ bugsnag }: IProps) => {
    const { loading, user, logout, isAuthenticated, isAuthorized } = useAuth0();

    bugsnag.user = {
        email: user ? user.email : null
    }

    if (loading) {
        return (<Loader />);
    }

    if (isAuthenticated && !isAuthorized) {
        return (<div className="container-sm"><div className="p-4">
            <div className="alert alert-danger" role="alert">
                Access denied!
            </div>
            <div><button type="button" className="btn btn-outline-secondary btn-sm" onClick={logout}>Logout</button></div>
        </div></div>);
    }

    return (
        <div>
            <BrowserRouter>
                <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
                    <a className="navbar-brand col-md-2 mr-0" href="/">OMY! Run Admin panel</a>
                    <div className="d-flex m-2 align-items-center text-light bg-dark">
                        <span className="p-2">
                            {user ? user.email : null}
                        </span>
                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={logout}>Logout</button>
                    </div>
                </nav>

                <div className="container-fluid">
                    <div className="row">
                        <NavPanel />
                        <main role="main" className="col-md-10 ml-sm-auto col-lg-10 mt-3">
                            <div className="p-2"><Routes /></div>
                        </main>
                    </div>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
