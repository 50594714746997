import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';
import { Survey } from '../../../api/types';

var JSONPrettyMon = require('react-json-pretty/dist/1337');

type SurveyExportDataModalProps = {
    jsonData: string,
    mode: "export" | "import",
    onClose: () => void,
    onImport: (survey: Survey) => void
}

const SurveyExportDataModal = (props: SurveyExportDataModalProps) => {

    const [data, setData] = useState(props.jsonData);
    const [isCopied, setIsCopied] = useState(false);

    const onClose = () => {
        props.onClose();
    }

    const copyToClipboard = () => {
        let tmpElm = document.createElement("input");
        document.body.appendChild(tmpElm);
        tmpElm.setAttribute('value', data);
        tmpElm.select();
        document.execCommand("copy");
        document.body.removeChild(tmpElm);

        setIsCopied(true);
    }

    const pasteFromClipboard = () => {
        if (navigator.clipboard.readText) {
            navigator.clipboard.readText()
                .then(
                    clipText => setData(clipText))
                .catch((ex) => {
                    console.log("Paste text error: ", ex);
                })
        }
        else {
            alert("Your browser does not support 'Paste' functionality! Use Ctrl+V combination.");
        }
    }

    const onImport = () => {
        if (window.confirm("You are about to import new suvey. You will be redirected to edit this survey after import. Are you sure?"))
        try {
            const survey = JSON.parse(data) as Survey;
            if (!survey?.surveyType) {
                throw new Error("Wrong survey json data!");
            }
            
            props.onImport(survey);
        }
        catch (ex) {
            console.log("Parse json error: ", ex);
            alert("Wrong survey json data!");
        }
    }

    return (
        props.mode === "export"
            ?
            <div>
                <Modal show={true} onHide={onClose} className="modify-training-modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title-fullwidth">
                            <div className="modal-title-inner">
                                Export Data for Survey
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ "marginBottom": "15px" }}>
                            <button className="btn btn-sm btn-primary" onClick={(e) => { copyToClipboard() }}>Copy to clipboard</button>
                            &nbsp;
                            {isCopied ? "Copied" : ""}
                        </div>
                        <JSONPretty id="json-pretty" data={data} theme={JSONPrettyMon} mainStyle="height: 300px; overflow-y: scroll;" />
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button className="btn btn-sm btn-primary" onClick={(e) => { onClose() }}>Close</button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
            :
            <div>
                <Modal show={true} onHide={onClose} className="modify-training-modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title-fullwidth">
                            <div className="modal-title-inner">
                                Import Survey Data
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ "marginBottom": "15px" }}>
                            <button className="btn btn-sm btn-primary" onClick={(e) => { pasteFromClipboard() }}>Paste from clipboard</button>
                        </div>
                        <textarea rows={15} className="form-control" value={data} onChange={(e) => { setData(e.target.value); }}></textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button className="btn btn-sm btn-success" onClick={(e) => { onImport() }}>Import</button>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={(e) => { onClose() }}>Close</button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
    );
}

export default SurveyExportDataModal;